import { useLingui } from '@lingui/react';
import React from 'react';

const ProjectLastUpdaterCell = ({ row }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  const app = i18n._(`app.${row.original.app}`);
  const lastUpdater =
    !row.original.updaterFirstName && !row.original.updaterLastName
      ? i18n._('projects.app', { app })
      : i18n._('projects.lastUpdater', {
          app,
          userFirstName: row.original.updaterFirstName,
          userLastName: row.original.updaterLastName
        });
  return (
    <div className='two-lines-max' title={lastUpdater}>
      {lastUpdater}
    </div>
  );
  //#endregion
};

export default React.memo(ProjectLastUpdaterCell);
