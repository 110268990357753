export const SOURCES_CHART_COLORS = Object.freeze({
  tfp: '#D07AE2',
  hpa: '#C2E8FF',
  hpg: '#6FCF97',
  gas: '#828282',
  st: '#FFD18D',
  ndw: '#3C9A8B',
  geocooling: '#D89D6A'
});

export const BASIC_COLORS = Object.freeze([
  '#042264',
  '#FFA385',
  '#911eb4',
  '#42d4f4',
  '#f032e6',
  '#bfef45',
  '#9A6324',
  '#469990',
  '#dcbeff',
  '#000000',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000'
]);

export const COP_EER_CHART_COLORS = Object.freeze({
  SCOP: '#5491E3',
  SCOPWithoutGas: '#65CFB1',
  SEER: '#9878C9',
  COPGlobal: '#CF6FA1'
});

export const DETAILED_RESULTS_CHART_COLORS = Object.freeze({
  BtesFlowRate: '#5D5A79',
  CO2kg: '#916B5A',
  CO2kgref: '#378776',
  COPInjectionHpa: '#507E9E',
  COPItesHpa: '#AAA495',
  NetOPEX: '#B6A5AB',
  OPEX: '#B4B4B4',
  PVNotUsedForThermalNeeds: '#9878C9',
  PVProd: '#9D515B',
  PVSoldToGrid: '#CCCAA8',
  PVUnused: '#B06355',
  QBtes: '#614A68',
  QGeocooling: '#335960',
  QHeadLoss: '#AE875D',
  QItesCharge: '#3A3A39',
  QSolar: '#BFA4AE',
  Qdemand: '#345382',
  RevPV: '#708B92',
  T1: '#216EAC',
  T2: '#6E6A6E',
  TCondHt: '#CCCAA8',
  TCondHw: '#CF9E8A',
  TDistrib: '#4F8E4A',
  TEauInBtes: '#38633F',
  TEauInSolar: '#B891A0',
  TEauOutBtes: '#A18595',
  TEauOutSolar: '#43436B',
  WColdHpa: '#5491E3',
  WColdItes: '#895ADE',
  WGas: '#9878C9',
  WHeatHpa: '#CF6FA1',
  Whpg: '#68B87D',
  WHotwHpa: '#DE6E81',
  WInjectionHpa: '#DC9055',
  WItesHpa: '#EFCD53',
  Wpac: '#B891A0'
});

export const DETAILED_RESULTS_SST_COLORS = Object.freeze({
  QTotal_Hotwater: '#F2536D',
  QTotal_Heat: '#F7A452',
  QTotal_Cold: '#77B5F2',
  QTotalHotwater: '#F2536D',
  QTotalHeat: '#F7A452',
  QTotalCold: '#77B5F2'
});

export const OPEX_TYPES_CHART_COLORS = Object.freeze({
  OpexElecSupply: '#5491E3',
  OpexPVSaved: '#895ADE',
  OpexPVSoldToProject: '#9878C9',
  OpexPVSoldToGrid: '#CF6FA1',
  OpexElecTransportAndTaxes: '#DE6E81',
  OpexGasSupply: '#DC9055',
  OpexGasCarbonTax: '#EFCD53',
  OpexOperationAndMaintenance: '#68B87D',
  OpexGaPerf: '#65CFB1',
  OpexOther: '#58B9C6',
  OpexPerYear: '#804817'
});

export const MODAL_COLORS = Object.freeze({
  warning: 'var(--warning-color)',
  danger: 'var(--danger-color)',
  success: 'var(--success-color)',
  info: 'var(--primary-color)'
});

export const PV_USES_CHART_COLORS = Object.freeze({
  chaufferie: '#5491E3',
  batiment: '#895ADE',
  reseau: '#9878C9',
  total: '#CF6FA1',
  perdu: '#D9534F'
});
