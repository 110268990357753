import {
  GENERAL,
  GOAL,
  ITERATIONS,
  SIMU_GOAL,
  getBoundsConstraints,
  getCommonConstraints,
  getParamsConstraints,
  getSstParamsConstraints
} from '../../../server/models/design/calculationData.model';
import { roundNumber } from './data.utils';
import { getProjectIndex } from './project.utils';

export const isBtesLengthAbsent = (project) => {
  return (
    project.otherValues.ItesEnabled || !project.otherValues.InitBtesEnabled
  );
};

export const isBtesProbeDepthAbsent = (project) => {
  return (
    project.otherValues.ItesEnabled || !project.otherValues.InitBtesEnabled
  );
};

export const isBtesProbeDistanceAbsent = (project) => {
  return (
    project.otherValues.ItesEnabled || !project.otherValues.InitBtesEnabled
  );
};

export const isT2final_BtesAbsent = (project) => {
  return (
    project.otherValues.ItesEnabled || !project.otherValues.InitBtesEnabled
  );
};

export const isHPGHeatBtesPowerMaxAbsent = (project) => {
  return (
    project.otherValues.ItesEnabled ||
    !project.otherValues.InitHPGHeatingEnabled ||
    (!project.otherValues.InitStationHasHeatNeeds &&
      !project.otherValues.InitStationHasHotWaterNeeds)
  );
};

export const isHPGColdBtesPowerMaxAbsent = (project) => {
  return (
    project.otherValues.ItesEnabled ||
    !project.otherValues.InitHPGCoolingEnabled ||
    !project.otherValues.InitStationHasColdNeeds
  );
};

export const isHPAPowerMaxAbsent = (project) => {
  return (
    !project.otherValues.InitHPAInjectionEnabled &&
    !project.otherValues.InitHPAHeatingEnabled &&
    !project.otherValues.InitHPACoolingEnabled
  );
};

export const isGasPowerMaxAbsent = (project) => {
  return !project.otherValues.InitGasEnabled;
};

export const isSolarThermalSurfaceAbsent = (project) => {
  return (
    project.otherValues.ItesEnabled ||
    (!project.otherValues.InitStationHasHeatNeeds &&
      !project.otherValues.InitStationHasHotWaterNeeds) ||
    !project.otherValues.InitSolarThermalEnabled
  );
};

export const isPhotovoltaicAbsent = (project) => {
  return !project.otherValues.InitPhotovoltaicEnabled;
};

export const isQItesMaxAbsent = (project) => {
  return !project.otherValues.ItesEnabled;
};

export const isHPGPowerMaxPerSSTAbsent = (project) => {
  return (
    project.otherValues.ItesEnabled ||
    (!project.otherValues.InitHPGHeatingEnabled &&
      !project.otherValues.InitHPGCoolingEnabled)
  );
};

const isParamAbsent = (key, project) => {
  const constraints = getParamsConstraints();
  const sstConstraints = getSstParamsConstraints();
  const isAbsent = {
    [constraints.BtesLength.key]: isBtesLengthAbsent,
    [constraints.BtesProbeDepth.key]: isBtesProbeDepthAbsent,
    [constraints.BtesProbeDistance.key]: isBtesProbeDistanceAbsent,
    [constraints.T2final_Btes.key]: isT2final_BtesAbsent,
    [constraints.HPGHeatBtesPowerMax.key]: isHPGHeatBtesPowerMaxAbsent,
    [constraints.HPGColdBtesPowerMax.key]: isHPGColdBtesPowerMaxAbsent,
    [constraints.HPAPowerMax.key]: isHPAPowerMaxAbsent,
    [constraints.GasPowerMax.key]: isGasPowerMaxAbsent,
    [constraints.SolarThermalSurface.key]: isSolarThermalSurfaceAbsent,
    [constraints.KiloWattCretePV.key]: isPhotovoltaicAbsent,
    [constraints.QItesMax.key]: isQItesMaxAbsent,
    [sstConstraints.HPGPowerMaxPerSST.key]: isHPGPowerMaxPerSSTAbsent
  };
  return isAbsent[key](project);
};

export const isCalculationDataBoundPresent = (key, calculationData) => {
  return key in calculationData.constraints.bounds[0];
};

export const isCalculationDataParamPresent = (key, calculationData) => {
  return key in calculationData.constraints.params;
};

export const getProjectsUsingParam = (
  i18n,
  key,
  projectParent,
  selectedProjects
) => {
  return selectedProjects.reduce((acc, project) => {
    if (isParamAbsent(key, project)) return acc;
    const projectIndex = getProjectIndex(projectParent, project.AhsID);
    const obj = {
      description:
        project.otherValues.description ||
        i18n._('description', { index: projectIndex + 1 }),
      projectIndex
    };
    return [...acc, obj];
  }, []);
};

export const getDefaultSimuCalculationData = (
  selectedProject,
  selectedDescriptionsIndex
) => {
  const commonConstraints = getCommonConstraints();
  const paramsConstraints = getParamsConstraints(selectedProject);
  const calculationData = {
    resultId: undefined,
    selectedDescriptionsIndexes: [selectedDescriptionsIndex],
    constraints: { inp: {}, params: {} },
    path: location.host
  };
  // inp
  const inpConstraints = [
    ...Object.values(commonConstraints),
    ...Object.values(SIMU_GOAL)
  ];
  inpConstraints.forEach((constraint) => {
    calculationData.constraints.inp[constraint.key] = constraint.default;
  });
  calculationData.constraints.inp.InitSimulationCalcul = true;
  calculationData.constraints.inp.FuncEvaluations = 0;

  // params
  Object.values(paramsConstraints).forEach((constraint) => {
    if (!isParamAbsent(constraint.key, selectedProject)) {
      calculationData.constraints.params[constraint.key] = constraint.default;
    }
  });

  // sst params
  selectedProject.substations.forEach((sst) => {
    const sstParamsConstraints = getSstParamsConstraints(sst);
    Object.values(sstParamsConstraints)
      .filter((constraint) => !isParamAbsent(constraint.key, selectedProject))
      .forEach((constraint) => {
        if (!calculationData.constraints.params[constraint.key]) {
          calculationData.constraints.params[constraint.key] = [];
        }
        calculationData.constraints.params[constraint.key].push(
          constraint.default
        );
      });
  });
  return calculationData;
};

export const getSimuCalculationDataByResult = (
  selectedProject,
  selectedDescriptionsIndex,
  result
) => {
  const calculationData = getDefaultSimuCalculationData(
    selectedProject,
    selectedDescriptionsIndex
  );

  // on set l'id du resultat qui correspond au ComputeID du compute qui avait été lancé
  calculationData.resultId = result.ComputeID;

  // on complète calculationData avec les valeurs du résultat
  Object.keys(calculationData.constraints.inp)
    .filter((key) => key in result.constraints.inp)
    .forEach((key) => {
      const value = result.constraints.inp[key];
      calculationData.constraints.inp[key] =
        typeof value === 'number' ? Math.floor(value) : value;
    });
  calculationData.constraints.inp.InitSimulationCalcul = true;
  calculationData.constraints.inp.FuncEvaluations = 0;

  const sstKeys = Object.values(getSstParamsConstraints()).map((x) => x.key);
  Object.keys(calculationData.constraints.params).forEach((key) => {
    if (sstKeys.includes(key)) {
      calculationData.constraints.params[key] = result.constraints.params[
        key
      ].map((value) => roundNumber(value));
    } else {
      calculationData.constraints.params[key] = Math.ceil(
        result.constraints.params[key] ?? 0
      );
    }
  });
  return calculationData;
};

export const getDefaultOptiCalculationData = (
  selectedProjects,
  selectedDescriptionsIndexes
) => {
  const commonConstraints = getCommonConstraints();
  const boundsConstraints = getBoundsConstraints(selectedProjects);
  const calculationData = {
    resultId: undefined,
    selectedDescriptionsIndexes,
    constraints: { inp: {}, bounds: [{}, {}] },
    path: location.host
  };
  // inp
  const inpConstraints = [
    ...Object.values(commonConstraints),
    ...Object.values(GENERAL),
    ...Object.values(GOAL).filter(
      (constraint) => constraint.key !== GOAL.InitConstraintNone.key
    ),
    ...Object.values(ITERATIONS).filter(
      (constraint) => constraint.key !== ITERATIONS.ConfigurableIterations.key
    )
  ];
  inpConstraints.forEach((constraint) => {
    calculationData.constraints.inp[constraint.key] = constraint.default;
  });
  calculationData.constraints.inp.InitSimulationCalcul = false;

  // bounds
  Object.values(boundsConstraints).forEach((constraint) => {
    const { key, default: defaultValue } = constraint;
    if (!selectedProjects.every((project) => isParamAbsent(key, project))) {
      calculationData.constraints.bounds[0][key] = defaultValue[0];
      calculationData.constraints.bounds[1][key] = defaultValue[1];
    }
  });
  return calculationData;
};

export const getOptiCalculationDataByResult = (
  selectedProjects,
  selectedDescriptionsIndexes,
  result
) => {
  const calculationData = getDefaultOptiCalculationData(
    selectedProjects,
    selectedDescriptionsIndexes
  );
  // on set l'id du resultat qui correspond au ComputeID du compute qui avait été lancé
  calculationData.resultId = result.ComputeID;

  // on complète calculationData avec les valeurs du résultat
  Object.keys(calculationData.constraints.inp)
    .filter((key) => key in result.constraints.inp)
    .forEach((key) => {
      calculationData.constraints.inp[key] = result.constraints.inp[key];
    });
  calculationData.constraints.inp.InitSimulationCalcul = false;
  [0, 1].forEach((index) => {
    Object.keys(calculationData.constraints.bounds[index]).forEach((key) => {
      calculationData.constraints.bounds[index][key] = Math.ceil(
        result.constraints.bounds[index][key] ?? 0
      );
    });
  });

  return calculationData;
};
