import { FILE_TYPE } from '../../../server/constants';
import axios from '../conf/axios.conf';

//#region [GET]
export const fetchHeatpumps = async () => {
  try {
    const response = await axios.get('/heatpumps');
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchHeatpump = async (hpFileId) => {
  try {
    const response = await axios.get(`/heatpumps/${hpFileId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchHpFile = async (hpFileId) => {
  try {
    const response = await axios.get(`/heatpumps/${hpFileId}/file`);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [POST]
export const uploadHPFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append(FILE_TYPE.HP, file);
    const response = await axios.post('/heatpumps', formData);
    return response;
  } catch (err) {
    throw err;
  }
};
//#endregion
