// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compute-lbHpgPartTarget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/ComputeForm/simulation/sections/SimuGoalsSection/SimuGoalsSection.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,SAAS;AACX","sourcesContent":[".compute-lbHpgPartTarget {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
