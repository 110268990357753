import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import NumberBadge from '../../../../../../../../components/NumberBadge/NumberBadge';
import PopupContext from '../../../../../../../../contexts/PopupContext';
import { formatValue } from '../../../../../../../../utils/data.utils';

const MinMaxSelects = ({
  minValue,
  maxValue,
  label,
  param,
  modalInfo,
  modalMaxInfo,
  showBadges,
  projectsUsingParam,
  onMinValueChange,
  onMaxValueChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [render]
  const inputMinId = `input_min_${param.key}`;
  const inputMaxId = `input_max_${param.key}`;
  const badgesId = `badges_${param.key}`;
  const minLessMaxError =
    minValue > maxValue ? i18n._('input.error.minLessMax') : '';
  return (
    <Row>
      <Col className='opti-inputs-label'>
        {label}
        {modalInfo && (
          <FontAwesomeIcon
            icon='circle-question'
            onClick={() => openInfoModal(modalInfo.title, modalInfo.body)}
            className='min-max-info-icon'
          />
        )}
        {showBadges && (
          <span
            className='opti-inputs-badges'
            data-tooltip-content={i18n._('input.minMax.onlyForDescriptions', {
              constraint: i18n._(`constraint.${param.key}`),
              descriptions: projectsUsingParam
                .map((proj) => proj.description)
                .join('\n')
            })}
            data-tooltip-id={'info_' + badgesId}
          >
            {projectsUsingParam.map((desc, index) => (
              <NumberBadge
                number={desc.projectIndex}
                key={'min_max_inputs_desc_' + index}
              />
            ))}
            <Tooltip
              id={'info_' + badgesId}
              place='right'
              className='info-tooltip'
              opacity={1}
            />
          </span>
        )}
      </Col>
      <Col className='ps-0 pe-0'>
        <Row>
          <Col className='min-max-col'>
            <Form.Select
              className={`param-input${
                minLessMaxError ? ' param-input-error' : ''
              }`}
              onChange={(evt) => onMinValueChange(param, +evt.target.value)}
              value={minValue ?? ''}
              name={inputMinId}
            >
              {param.values.map((value) => (
                <option key={param.key + '_min_value_' + value} value={value}>
                  {value}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col className='min-max-col'>
            <InputGroup>
              <Form.Select
                className={`param-input${
                  minLessMaxError ? ' param-input-error' : ''
                }`}
                onChange={(evt) => onMaxValueChange(param, +evt.target.value)}
                value={maxValue ?? ''}
                name={inputMaxId}
              >
                {param.values.map((value) => (
                  <option key={param.key + '_max_value_' + value} value={value}>
                    {value}
                  </option>
                ))}
              </Form.Select>
              {modalMaxInfo && (
                <InputGroup.Text
                  className='opti-input-max-icon'
                  data-tooltip-content={i18n._('input.minMax.max', {
                    max: formatValue(param.max, 0)
                  })}
                  data-tooltip-id={'info_' + inputMaxId}
                  onClick={() =>
                    openInfoModal(modalMaxInfo.title, modalMaxInfo.body)
                  }
                >
                  <FontAwesomeIcon icon='calculator' />
                  <Tooltip
                    id={'info_' + inputMaxId}
                    place='bottom'
                    className='info-tooltip'
                    opacity={1}
                  />
                </InputGroup.Text>
              )}
            </InputGroup>
          </Col>
        </Row>
        {minLessMaxError && (
          <Row>
            <Col xs={12}>
              <span className='minLessMax-error'>
                <FontAwesomeIcon icon='xmark' />
                {i18n._('input.error.minLessMax')}
              </span>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
  //#endregion
};

export default MinMaxSelects;
