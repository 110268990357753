import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Badge, Button, Form, Modal } from 'react-bootstrap';
import PopupContext from '../../../../../contexts/PopupContext';
import { getOptionName } from '../../../../../utils/compute.utils';
import './DeleteModal.css';

const DeleteModal = ({
  groupedOptions,
  selectedResultId,
  isOpen,
  onClose,
  onResultsDelete
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [modalList, setModalList] = useState([]);
  //#endregion

  //#region [refs]
  const lastCheckboxIndexClicked = useRef();
  //#endregion

  //#region [effects]
  useEffect(() => {
    setModalList(() => {
      if (!groupedOptions || !selectedResultId || !isOpen) return [];
      const list = [];
      let resultIndex = 0;
      groupedOptions.forEach((group) => {
        group.options.forEach((option) => {
          if (option.value === selectedResultId) {
            lastCheckboxIndexClicked.current = resultIndex;
          }
          list.push({
            ...option,
            checked: option.value === selectedResultId,
            name: i18n._('result.fullName', {
              group: group.label,
              option: option.label
            })
          });
          resultIndex++;
        });
      });
      return list;
    });
  }, [groupedOptions, selectedResultId, isOpen]);
  //#enedregion

  //#region [methods]
  const handleDeleteCheckboxClick = (evt, index) => {
    const prevLastIndex = lastCheckboxIndexClicked.current;
    setModalList((list) => {
      const copyList = [...list];
      if (copyList[index].checked || !evt.shiftKey || prevLastIndex >= index) {
        copyList[index].checked = !copyList[index].checked;
      } else {
        for (let i = prevLastIndex; i <= index; ++i) {
          copyList[i].checked = true;
        }
      }
      return copyList;
    });
    lastCheckboxIndexClicked.current = index;
  };

  const handleResultsDelete = async () => {
    try {
      await onResultsDelete(idsToDelete);
      onClose();
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  if (!modalList) return null;
  const idsToDelete = modalList
    .filter((result) => result.checked)
    .map((result) => result.value);
  return (
    <Modal show={isOpen} onHide={onClose} size='xl' className='delete-modal'>
      <Modal.Header closeButton>
        {i18n._('results.deleteResults.title')}
        <FontAwesomeIcon icon='trash-alt' />
      </Modal.Header>
      <Modal.Body>
        <p className='delete-warning'>
          {i18n._('results.deleteResults.select')}{' '}
          <b>{i18n._('results.deleteResults.warning')}</b>
        </p>
        <div className='delete-list'>
          {modalList.map((option, index) => {
            const label = i18n._('result.fullName', {
              group: option.groupLabel,
              option: getOptionName(i18n, option.goalPart, option.comment)
            });
            return (
              <div
                key={'delete_option_' + index}
                className='result-page-option'
              >
                <Form.Check
                  type='checkbox'
                  checked={option.checked}
                  onChange={() => {}} // ce onChange vide est obligatoire pour ne pas avoir de warning/erreur dans la console
                  onClick={(evt) => handleDeleteCheckboxClick(evt, index)} // on choisit onClick plutôt que onChange pour avoir accès à la propriété evt.shiftKey
                  label={
                    <div className='result-option'>
                      <span
                        className='result-option-label ellipsis'
                        title={label}
                      >
                        {label}
                      </span>
                      <span className='results-select-tags'>
                        {option.tags.feasibility && (
                          <Badge className='feasibility-badge'>
                            {i18n._('tag.feasibility')}
                          </Badge>
                        )}
                        {option.tags.project && (
                          <Badge className='project-badge'>
                            {i18n._('tag.project')}
                          </Badge>
                        )}
                        {option.tags.contractCommitment && (
                          <Badge className='contract-commitment-badge'>
                            {i18n._('tag.contractCommitment')}
                          </Badge>
                        )}
                      </span>
                    </div>
                  }
                  name={'delete_option_' + option.value}
                  id={'delete_option_' + option.value}
                />
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          {i18n._('cancel')}
        </Button>
        <Button
          variant='danger'
          onClick={handleResultsDelete}
          disabled={idsToDelete.length === 0}
        >
          {i18n._('results.deleteResults', { nb: idsToDelete.length })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
