import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ITES } from '../../../../../../server/models/design/performance.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import ParamSelect from '../../../../components/ParamForm/ParamSelect';
import { INPUT_TYPE } from '../../../../constants';
import { getDefaultValue } from '../../../../utils/param.utils';

const ItesBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('performance.coldStorage')}>
      <ParamSelect
        label={i18n._('performance.ites')}
        value={form.InitItesModel}
        param={ITES.InitItesModel}
        onChange={(value) => {
          onInputChange('InitItesModel', value);
        }}
      />
      <ParamInput
        label={i18n._('performance.InitItesPumpConsumptionPercentage')}
        value={form.InitItesPumpConsumptionPercentage}
        param={ITES.InitItesPumpConsumptionPercentage}
        onChange={(value) =>
          onInputChange('InitItesPumpConsumptionPercentage', value)
        }
        bottomText={getDefaultValue(
          i18n,
          ITES.InitItesPumpConsumptionPercentage
        )}
        unit
        type={INPUT_TYPE.NUMBER}
      />
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.InitItesChargingTemperatureIn')}
            value={form.InitItesChargingTemperatureIn}
            param={ITES.InitItesChargingTemperatureIn}
            onChange={(value) =>
              onInputChange('InitItesChargingTemperatureIn', value)
            }
            topText={i18n._('performance.topInitItesChargingTemperatureIn')}
            bottomText={getDefaultValue(
              i18n,
              ITES.InitItesChargingTemperatureIn
            )}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('performance.InitItesChargingTemperatureOut')}
            value={form.InitItesChargingTemperatureOut}
            param={ITES.InitItesChargingTemperatureOut}
            onChange={(value) =>
              onInputChange('InitItesChargingTemperatureOut', value)
            }
            topText={i18n._('performance.topInitItesChargingTemperatureOut')}
            bottomText={getDefaultValue(
              i18n,
              ITES.InitItesChargingTemperatureOut
            )}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.InitItesDischargingTemperatureIn')}
            value={form.InitItesDischargingTemperatureIn}
            param={ITES.InitItesDischargingTemperatureIn}
            onChange={(value) =>
              onInputChange('InitItesDischargingTemperatureIn', value)
            }
            topText={i18n._('performance.topInitItesDischargingTemperatureIn')}
            bottomText={getDefaultValue(
              i18n,
              ITES.InitItesDischargingTemperatureIn
            )}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('performance.InitItesDischargingTemperatureOut')}
            value={form.InitItesDischargingTemperatureOut}
            param={ITES.InitItesDischargingTemperatureOut}
            onChange={(value) =>
              onInputChange('InitItesDischargingTemperatureOut', value)
            }
            topText={i18n._('performance.topInitItesDischargingTemperatureOut')}
            bottomText={getDefaultValue(
              i18n,
              ITES.InitItesDischargingTemperatureOut
            )}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default ItesBloc;
