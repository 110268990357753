import { useLingui } from '@lingui/react';
import React from 'react';
import { GAS } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import EditTable from '../../../../components/EditTable/EditTable';

const GasBoilerBloc = ({ form, onTableValueChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('cost.gasBoiler')} level={2}>
      <div>
        <span className='param-input-label'>
          {i18n._('cost.InitCapexGasLabel')}
        </span>
        <EditTable
          titles={[
            i18n._('cost.InitCapexGasSlices'),
            i18n._('cost.InitCapexGas')
          ]}
          values={[form.InitCapexGas]}
          params={[GAS.InitCapexGas]}
          onChange={onTableValueChange}
        />
      </div>
    </Bloc>
    //#endregion
  );
};

export default GasBoilerBloc;
