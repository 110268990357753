import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  GENERAL,
  getCommonConstraints
} from '../../../../../../../../../server/models/design/calculationData.model';
import NumberBadge from '../../../../../../../components/NumberBadge/NumberBadge';
import ParamInput from '../../../../../../../components/ParamForm/ParamInput';
import ParamSelect from '../../../../../../../components/ParamForm/ParamSelect';
import { INPUT_TYPE } from '../../../../../../../constants';
import './OptiGeneralSection.css';

const OptiGeneralSection = ({
  project,
  calculationData,
  onConstraintChange,
  onSelectedProjectChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  const { InitYearsSimulations } = calculationData.constraints.inp;
  const { selectedDescriptionsIndexes } = calculationData;
  const commonConstraints = getCommonConstraints(InitYearsSimulations);
  const projectAndChildren = [project, ...project.children];
  return (
    <div className='opti-section opti-general-section'>
      <h2>{i18n._('compute.opti.general')}</h2>
      <Row>
        <Col xs='7'>
          <span className='param-input-label'>
            {i18n._('compute.InitYearsSimulations')}
          </span>
        </Col>
        <Col xs='5'>
          <ParamInput
            value={calculationData.constraints.inp.InitYearsSimulations}
            param={commonConstraints.InitYearsSimulations}
            onChange={(value) => {
              onConstraintChange(commonConstraints.InitYearsSimulations, value);
            }}
            type={INPUT_TYPE.NUMBER}
            notDefaultValueHilighted={false}
          />
        </Col>
      </Row>
      <Row>
        <Col xs='7'>
          <span className='param-input-label'>
            {i18n._('compute.InitBalanceDuration')}
          </span>
        </Col>
        <Col xs='5'>
          <ParamInput
            value={calculationData.constraints.inp.InitBalanceDuration}
            param={commonConstraints.InitBalanceDuration}
            onChange={(value) => {
              onConstraintChange(commonConstraints.InitBalanceDuration, value);
            }}
            type={INPUT_TYPE.NUMBER}
            notDefaultValueHilighted={false}
          />
        </Col>
      </Row>
      <Row>
        <Col xs='7'>
          <span className='param-input-label'>
            {i18n._('compute.InitObjective')}
          </span>
        </Col>
        <Col xs='5'>
          <ParamSelect
            value={calculationData.constraints.inp.InitObjective}
            param={GENERAL.InitObjective}
            onChange={(value) => {
              onConstraintChange(GENERAL.InitObjective, value);
            }}
            notDefaultValueHilighted={false}
          />
        </Col>
      </Row>
      <Row>
        <Col xs='7'>
          <span className='param-input-label'>
            {i18n._('compute.InitReferenceSolution')}
          </span>
        </Col>
        <Col xs='5'>
          <ParamSelect
            value={calculationData.constraints.inp.InitReferenceSolution}
            param={GENERAL.InitReferenceSolution}
            onChange={(value) => {
              onConstraintChange(GENERAL.InitReferenceSolution, value);
            }}
            notDefaultValueHilighted={false}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col xs='7'>
          <span className='param-input-label'>
            {projectAndChildren.length === 1
              ? i18n._('compute.description')
              : i18n._('compute.selectDescriptions')}
          </span>
        </Col>
        <Col xs='5'>
          {projectAndChildren.map((proj, projIndex) => (
            <span key={'opti_check_' + projIndex}>
              <Form.Check
                label={
                  <>
                    <span className='opti-general-description'>
                      {proj.otherValues.description ||
                        i18n._('description', { index: projIndex + 1 })}
                    </span>
                    {selectedDescriptionsIndexes.length > 1 &&
                      selectedDescriptionsIndexes.includes(projIndex) && (
                        <span className='opti-general-badge'>
                          <NumberBadge number={projIndex} />
                        </span>
                      )}
                  </>
                }
                onChange={() => onSelectedProjectChange(projIndex)}
                value={projIndex}
                checked={selectedDescriptionsIndexes.includes(projIndex)}
                disabled={
                  selectedDescriptionsIndexes.includes(projIndex) &&
                  selectedDescriptionsIndexes.length === 1
                }
                name={'opti_check_' + projIndex}
                id={'opti_check_' + projIndex}
              />
            </span>
          ))}
        </Col>
      </Row>
    </div>
  );
  //#endregion
};

export default React.memo(OptiGeneralSection);
