import { useLingui } from '@lingui/react';
import React from 'react';
import Section from '../../../../../components/Section/Section';
import ResultsTable from '../../../../../components/TemplateTables/ResultsTable/ResultsTable';
import './SummarySection.css';
import ResultsCharts from './components/ResultsCharts/ResultsCharts';
import { summaryTemplate } from './templates';

const SummarySection = ({ comparedResults }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Section title={i18n._('results.summary')} open>
      <ResultsTable
        comparedResults={comparedResults}
        level={2}
        template={summaryTemplate(i18n, comparedResults)}
      />
      {comparedResults.length > 1 && (
        <ResultsCharts comparedResults={comparedResults} />
      )}
    </Section>
  );
  //#endregion
};

export default React.memo(SummarySection);
