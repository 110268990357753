import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchProjectParent } from '../../../api/project.api';
import PopupContext from '../../../contexts/PopupContext';
import ComputeForm from './ComputeForm/ComputeForm';
import './ComputePage.css';
import ProjectSummary from './ProjectSummary/ProjectSummary';

const ComputePage = () => {
  //#region [router]
  const { projectId } = useParams();
  //#endregion

  //#region [states]
  const [project, setProject] = useState();
  //#endregion

  //#region [contexts]
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [effects]
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const projectParent = await fetchProjectParent(projectId);
        setProject(projectParent);
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, []);
  //#endregion

  //#region [render]
  return project ? (
    <div className='compute-page'>
      <ProjectSummary project={project} />
      <ComputeForm project={project} />
    </div>
  ) : null;
  //#endregion
};

export default ComputePage;
