import { useLingui } from '@lingui/react';
import React, { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { isNull } from '../../../../utils/data.utils';
import './ComputeForm.css';
import OptimizationForm from './optimization/OptimizationForm';
import SimulationForm from './simulation/SimulationForm';

const ComputeForm = ({ project }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const [searchParams, setSearchParams] = useSearchParams();
  //#endregion

  //#region [effects]
  useEffect(() => {
    const computeType = searchParams.get('type');
    if (isNull(computeType)) setSearchParams({ type: 'opti' });
  }, [searchParams]);
  //#endregion

  //#region [render]
  const computeType = searchParams.get('type');
  return !isNull(computeType) ? (
    <div className='compute-forms'>
      <Nav variant='pills' className='compute-form-nav'>
        <Nav.Item>
          <Nav.Link
            active={computeType === 'opti'}
            onClick={() => setSearchParams({ type: 'opti' })}
          >
            {i18n._('computeType.opti')}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            active={computeType === 'simu'}
            onClick={() => setSearchParams({ type: 'simu' })}
          >
            {i18n._('computeType.simu')}
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {computeType === 'opti' ? (
        <OptimizationForm project={project} />
      ) : (
        <SimulationForm project={project} />
      )}
    </div>
  ) : null;
  //#endregion
};

export default ComputeForm;
