import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import TwoWayTable from '../../../../../../../components/TwoWayTable/TwoWayTable';
import {
  formatValue,
  getSafeData
} from '../../../../../../../utils/data.utils.js';

const COPsDistributionTable = ({ result, sstName }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    const cops = sstName
      ? result.ComputeResult.summary.PerSub.Cops?.[sstName]
      : result.ComputeResult.summary.COPs;
    if (!cops) return null;

    const uses = Object.keys(cops);
    const sources = Object.keys(cops.Global);
    const values = [];
    for (let i = 0; i < uses.length; ++i) {
      values[i] = [];
      for (let j = 0; j < sources.length; ++j) {
        values[i][j] = getSafeData(() =>
          formatValue(cops[uses[i]][sources[j]], 2)
        );
      }
      values[i][sources.length] = getSafeData(() =>
        formatValue(cops[uses[i]].Sum, 2)
      );
    }
    return {
      sources: sources.map((src) => i18n._(`source.${src.toLowerCase()}`)),
      uses: uses.map((use) => i18n._(`use.${use.toLowerCase()}`)),
      values
    };
  }, [result.ComputeID, sstName]);
  //#endregion

  //#region [render]
  return data ? (
    <TwoWayTable
      horizontalTitles={data.sources}
      verticalTitles={data.uses}
      values={data.values}
    />
  ) : (
    <Alert variant='info'>{i18n._('results.noData')}</Alert>
  );
  //#endregion
};

export default COPsDistributionTable;
