import React from 'react';
import './TwoWayTable.css';

const TwoWayTable = ({ horizontalTitles, verticalTitles, values }) => {
  //#region [render]
  return (
    <div className='two-way-table-wrapper'>
      <table className='two-way-table'>
        <thead>
          <tr>
            <th></th>
            {horizontalTitles?.map((title, index) => (
              <th key={'th_' + title + '_' + index} title={title}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {verticalTitles?.map((title, verticalIndex) => (
            <tr key={'tr_' + title + '_' + verticalIndex}>
              <td title={title}>{title}</td>
              {horizontalTitles.map((title, horizontalIndex) => {
                return (
                  <td
                    key={'td_' + title + '_' + horizontalIndex}
                    title={values[verticalIndex][horizontalIndex]}
                  >
                    {values[verticalIndex][horizontalIndex]}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
  //#endregion
};

export default React.memo(TwoWayTable);
