// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected-hps-multi-select.error .multi-select__control {
  border: 1px solid var(--danger-color);
}

.selected-hps-multi-select .multi-select__control {
  height: 30px;
}

.selected-hps-multi-select .multi-select__value-container {
  height: 30px;
  line-height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0;
  padding: 0 0 0 3px;
}

.selected-hps-multi-select .multi-select__multi-value {
  height: 22px;
  line-height: 22px;
  background-color: var(--primary-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
  color: white;
  font-size: 15px;
}

.selected-hps-multi-select .multi-select__multi-value__label {
  color: inherit;
}

.selected-hps-multi-select .multi-select__multi-value__remove:hover {
  color: var(--danger-color);
  background-color: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configs/thermalProduction/heatpumps/selection/cells/NeedsSelectCellRenderer/NeedsSelectCellRenderer.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;AACvC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;EAC3B,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,sCAAsC;EACtC,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;EAC3B,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,0BAA0B;EAC1B,sCAAsC;AACxC","sourcesContent":[".selected-hps-multi-select.error .multi-select__control {\n  border: 1px solid var(--danger-color);\n}\n\n.selected-hps-multi-select .multi-select__control {\n  height: 30px;\n}\n\n.selected-hps-multi-select .multi-select__value-container {\n  height: 30px;\n  line-height: 30px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin: 0;\n  padding: 0 0 0 3px;\n}\n\n.selected-hps-multi-select .multi-select__multi-value {\n  height: 22px;\n  line-height: 22px;\n  background-color: var(--primary-color);\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  border-radius: 5px;\n  color: white;\n  font-size: 15px;\n}\n\n.selected-hps-multi-select .multi-select__multi-value__label {\n  color: inherit;\n}\n\n.selected-hps-multi-select .multi-select__multi-value__remove:hover {\n  color: var(--danger-color);\n  background-color: var(--primary-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
