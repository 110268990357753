import { useEffect, useState } from 'react';

const useScreenSize = () => {
  //#region [states]
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  //#endregion

  //#region [effects]
  useEffect(() => {
    const debounce = (fn, ms) => {
      let timer;
      return () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          timer = null;
          fn.apply(this, arguments);
        }, ms);
      };
    };

    const debounceHandleResize = debounce(function handleResize() {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }, 50);

    window.addEventListener('resize', debounceHandleResize);
    return () => window.removeEventListener('resize', debounceHandleResize);
  }, []);
  //#endregion

  return screenSize;
};

export default useScreenSize;
