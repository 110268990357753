import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import PopupContext from '../../../../../../contexts/PopupContext';
import { formatValue, isNull } from '../../../../../../utils/data.utils';

const SimuSelect = ({
  label,
  value,
  param,
  disabled,
  modalInfo,
  modalMaxInfo,
  onChange
}) => {
  //#region [contexts]
  const { openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [render]
  const inputId = `input_simu_${param.key}`;
  return (
    <Row>
      <Col xs='7'>
        <span className='param-input-label'>
          {label}
          {modalInfo && (
            <FontAwesomeIcon
              icon='circle-question'
              className='compute-info-icon modal-info-icon'
              onClick={() => openInfoModal(modalInfo.title, modalInfo.body)}
            />
          )}
        </span>
      </Col>
      <Col xs='5'>
        <InputGroup>
          <Form.Select
            className='compute-input param-input'
            disabled={disabled}
            onChange={(evt) => onChange(param, +evt.target.value)}
            value={!isNull(value) ? value : ''}
            name={`select_simu_${param.key}`}
          >
            {param.values.map((val, index) => (
              <option
                key={'input_' + param.key + '_value_' + index}
                value={val}
              >
                {val}
              </option>
            ))}
          </Form.Select>
          {modalMaxInfo && (
            <InputGroup.Text
              className='opti-input-max-icon'
              data-tooltip-content={i18n._('input.minMax.max', {
                max: formatValue(param.max, 0)
              })}
              data-tooltip-id={'info_' + inputId}
              onClick={() =>
                openInfoModal(modalMaxInfo.title, modalMaxInfo.body)
              }
            >
              <FontAwesomeIcon icon='calculator' />
              <Tooltip
                id={'info_' + inputId}
                place='bottom'
                className='info-tooltip'
                opacity={1}
              />
            </InputGroup.Text>
          )}
        </InputGroup>
      </Col>
    </Row>
  );
  //#endregion
};

export default SimuSelect;
