import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { getResultFullName } from '../../../utils/compute.utils';
import { getTableValue } from '../../../utils/data.utils';
import SectionTitle from '../../SectionTitle';
import './ResultsTable.css';

const ResultsTable = ({ comparedResults, template, level }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return template ? (
    <div>
      {template.title && (
        <SectionTitle level={level}>{template.title}</SectionTitle>
      )}
      <table className='template-table results-table'>
        {comparedResults.length > 1 && (
          <thead>
            <tr>
              <th></th>
              {comparedResults.map((result, i) => (
                <th
                  key={'th_result_' + i}
                  title={getResultFullName(i18n, result)}
                >
                  <p className='result-table-title'>
                    {i18n._('result', { index: i + 1 })}
                  </p>
                  {result.Comment && (
                    <p className='result-table-details ellipsis'>
                      {result.Comment}
                    </p>
                  )}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {template.rows.map((row, rowIndex) => (
            <tr key={'tr_result_' + rowIndex}>
              <td className='table-label'>
                {row.label}
                {row.tooltipInfo && (
                  <>
                    <FontAwesomeIcon
                      icon='fa-regular fa-circle-question'
                      data-tooltip-id={'tooltipInfo_' + rowIndex}
                      data-tooltip-content={row.tooltipInfo}
                    />
                    <Tooltip
                      id={'tooltipInfo_' + rowIndex}
                      place='right'
                      className='info-tooltip'
                      opacity={1}
                    />
                  </>
                )}
              </td>
              {row.values.map((value, colIndex) => {
                const tableValue = getTableValue(
                  i18n,
                  value,
                  row.unit,
                  row.precision
                );
                return (
                  <td
                    key={'td_result_' + rowIndex + '_' + colIndex}
                    title={tableValue}
                    className={`${
                      row.valuesClassNames ? row.valuesClassNames[colIndex] : ''
                    } ${
                      comparedResults.length > 1
                        ? 'compare-table-value'
                        : 'table-value'
                    }`}
                  >
                    {tableValue}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null;
  //#endregion
};

export default ResultsTable;
