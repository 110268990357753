// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.set-filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 2px;
    padding: 10px 10px 5px 10px;
}

.set-filter .form-check {
    margin: 0;
    line-height: 15px;
}

.set-filter .form-check-label {
    padding-top: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/SetFilter/SetFilter.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,4BAA4B;IAC5B,6BAA6B;QACzB,0BAA0B;YACtB,sBAAsB;IAC9B,QAAQ;IACR,2BAA2B;AAC/B;;AAEA;IACI,SAAS;IACT,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".set-filter {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n        -ms-flex-direction: column;\n            flex-direction: column;\n    gap: 2px;\n    padding: 10px 10px 5px 10px;\n}\n\n.set-filter .form-check {\n    margin: 0;\n    line-height: 15px;\n}\n\n.set-filter .form-check-label {\n    padding-top: 2px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
