// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accenta-ref-table th:nth-of-type(1) {
  background-color: transparent;
}

.accenta-ref-table th:nth-child(2n + 2) {
  background-color: black;
}

.accenta-ref-table th:nth-child(2n + 3) {
  background-color: var(--primary-color);
}

.accenta-ref-table th:nth-child(4n + 5) {
  background-color: #838996;
}

.ref-td,
.accenta-td {
  text-align: right;
  padding: 5px;
}

.ref-td p,
.accenta-td p {
  margin: 0;
}

.ref-td p:nth-of-type(2),
.accenta-td p:nth-of-type(2) {
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
}

.ref-td p:nth-of-type(2) {
  visibility: hidden;
}

.accenta-ref-table th:nth-of-type(2) {
  border-top-left-radius: 5px !important;
}

.accenta-ref-table th:last-of-type {
  border-top-right-radius: 5px;
}

.accenta-ref-table tbody tr:nth-of-type(2n + 1) {
  background-color: rgb(233, 233, 233) !important;
}

.accenta-ref-table tbody tr:nth-of-type(2n) {
  background-color: rgb(248, 248, 248) !important;
}

.accenta-ref-table tbody tr td {
  border-left: 1px solid var(--border-color);
}

.accenta-ref-table > tbody > tr:first-of-type > td {
  border-top: 1px solid var(--border-color);
}

.accenta-ref-table > tbody > tr:last-of-type > td {
  border-bottom: 1px solid var(--border-color);
}

.accenta-ref-table > tbody > tr > td:last-of-type {
  border-right: 1px solid var(--border-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/TemplateTables/AccentaVsRefTable/AccentaVsRefTable.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,iBAAiB;EACjB,YAAY;AACd;;AAEA;;EAEE,SAAS;AACX;;AAEA;;EAEE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":[".accenta-ref-table th:nth-of-type(1) {\n  background-color: transparent;\n}\n\n.accenta-ref-table th:nth-child(2n + 2) {\n  background-color: black;\n}\n\n.accenta-ref-table th:nth-child(2n + 3) {\n  background-color: var(--primary-color);\n}\n\n.accenta-ref-table th:nth-child(4n + 5) {\n  background-color: #838996;\n}\n\n.ref-td,\n.accenta-td {\n  text-align: right;\n  padding: 5px;\n}\n\n.ref-td p,\n.accenta-td p {\n  margin: 0;\n}\n\n.ref-td p:nth-of-type(2),\n.accenta-td p:nth-of-type(2) {\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 15px;\n}\n\n.ref-td p:nth-of-type(2) {\n  visibility: hidden;\n}\n\n.accenta-ref-table th:nth-of-type(2) {\n  border-top-left-radius: 5px !important;\n}\n\n.accenta-ref-table th:last-of-type {\n  border-top-right-radius: 5px;\n}\n\n.accenta-ref-table tbody tr:nth-of-type(2n + 1) {\n  background-color: rgb(233, 233, 233) !important;\n}\n\n.accenta-ref-table tbody tr:nth-of-type(2n) {\n  background-color: rgb(248, 248, 248) !important;\n}\n\n.accenta-ref-table tbody tr td {\n  border-left: 1px solid var(--border-color);\n}\n\n.accenta-ref-table > tbody > tr:first-of-type > td {\n  border-top: 1px solid var(--border-color);\n}\n\n.accenta-ref-table > tbody > tr:last-of-type > td {\n  border-bottom: 1px solid var(--border-color);\n}\n\n.accenta-ref-table > tbody > tr > td:last-of-type {\n  border-right: 1px solid var(--border-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
