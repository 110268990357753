import { useLingui } from '@lingui/react';
import React from 'react';
import { getTableValue } from '../../../../../../../../utils/data.utils';
import './DescriptionsTable.css';

const DescriptionsTable = ({ template }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <table className='template-table descriptions-table'>
      <thead>
        <tr>
          <th></th>
          {template.descriptions.map((description, i) => (
            <th
              key={'th_desc_' + i}
              title={description || i18n._('description', { index: i + 1 })}
            >
              {description || i18n._('description', { index: i + 1 })}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {template.rows.map((row, rowIndex) => (
          <tr key={'description_tr_' + rowIndex}>
            <td className='table-label'>{row.label}</td>
            {row.values.map((value, colIndex) => (
              <td
                key={'description_td_' + rowIndex + '_' + colIndex}
                className='descriptions-table-value'
                title={getTableValue(i18n, value, row.unit)}
              >
                {getTableValue(i18n, value, row.unit)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
  //#endregion
};

export default React.memo(DescriptionsTable);
