import axios from '../conf/axios.conf';

//#region [GET]
export const fetchConfig = async (configId) => {
  try {
    const response = await axios.get(`/config/${configId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchDiagramScope = async (configId) => {
  try {
    const response = await axios.get(`/config/${configId}/scope`);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [PUT]
export const updateConfig = async (config) => {
  try {
    const response = await axios.put(`/config/${config.ConfigID}`, config);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [DELETE]
export const deleteConfig = async (configId) => {
  try {
    const response = await axios.delete(`/config/${configId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion
