import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Button } from 'react-bootstrap';
import { getResultFullName } from '../../../../../../utils/compute.utils';
import { downloadFile } from '../../../../../../utils/file.utils';

const DlJsonResultBtn = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [methods]
  const handleBtnClick = () => {
    const filename = getResultFullName(i18n, result);
    downloadFile(
      `${filename}.json`,
      new Blob([JSON.stringify(result.ComputeResult)], {
        type: 'text/json'
      })
    );
  };
  //#endregion

  //#region [render]
  return (
    <Button
      variant='outline-secondary'
      onClick={handleBtnClick}
      className='mb-3'
    >
      <FontAwesomeIcon icon='download' className='me-2' />
      {i18n._('result.dlDataSent')}
    </Button>
  );
  //#endregion
};

export default React.memo(DlJsonResultBtn);
