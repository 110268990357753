import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { NEED_PARAMS } from '../../../../../../../../server/models/config/buildingDescription/services/settings.model';
import FormInput from '../../../../../../components/Form/FormInput';
import Section from '../../../../../../components/Section/Section';
import { INPUT_TYPE } from '../../../../../../constants';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import { getSortedNeeds } from '../../../../../../utils/config.utils';

const PositionsSection = ({ onFormChange, addError, removeErrors }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const handlePositionChange = (need, value) => {
    config.ConfigsSst[0].Data.services[need].position = value;
    onFormChange(config);
  };
  //#endregion

  //#region [render]
  const sortedNeeds = getSortedNeeds(config.ConfigsSst[0].Data.services.needs);
  return (
    <Section
      title={i18n._('config.services.hydraulic.needsPosition')}
      level={2}
      open
    >
      <table className='custom-table'>
        <thead>
          <tr>
            <th></th>
            <th>{i18n._('config.services.hydraulic.position')}</th>
          </tr>
        </thead>
        <tbody>
          {sortedNeeds.map((need, index) => {
            const { position } = config.ConfigsSst[0].Data.services[need];
            return (
              <tr key={'presentNeeds_' + index}>
                <td>{i18n._(`need.${need}`)}</td>
                <td>
                  <FormInput
                    param={NEED_PARAMS[need].position}
                    value={position}
                    onChange={(value) => handlePositionChange(need, value)}
                    className='form-input-small'
                    addError={addError}
                    removeError={() => removeErrors(1)}
                    type={INPUT_TYPE.NUMBER}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Section>
  );
};
//#endregion

export default PositionsSection;
