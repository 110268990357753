import { useLingui } from '@lingui/react';
import React from 'react';

const KilowattCretePVModal = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <div>
      {i18n._('compute.KiloWattCretePV.info1')}
      <br />
      <br />
      <p style={{ textAlign: 'center', fontWeight: 500 }}>
        {i18n._('compute.KiloWattCretePV.info2')}
      </p>
    </div>
  );
  //#endregion
};

export default KilowattCretePVModal;
