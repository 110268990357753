import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import './UsersTable.css';
import Pagination from '../../../../components/Pagination/Pagination';

const COLS_WIDTHS = ['50px', 'auto', 'auto', 'auto'];
const UsersTable = ({ users }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const columns = useMemo(
    () => [
      {
        id: 'UserRole',
        Cell: (props) => {
          const { IsSuperAdmin } = props.row.original;
          return (
            <FontAwesomeIcon
              icon={IsSuperAdmin ? 'user-shield' : 'user'}
              className='users-table-role'
              title={
                IsSuperAdmin
                  ? i18n._('users.role.admin')
                  : i18n._('users.role.user')
              }
            />
          );
        }
      },
      {
        id: 'UserName',
        accessor: 'UserName',
        Header: () => <span>{i18n._('users.header.name')}</span>,
        Cell: (props) => {
          const { UserFirstName, UserLastName } = props.row.original;
          return i18n._('users.name', {
            firstName: UserFirstName,
            lastName: UserLastName
          });
        },
        sortType: (a, b) =>
          a.original.UserFirstName.toLowerCase().localeCompare(
            b.original.UserFirstName.toLowerCase()
          )
      },
      {
        id: 'UserJobTitle',
        accessor: 'UserJobTitle',
        Header: () => <span>{i18n._('users.header.job')}</span>
      },
      {
        id: 'UserEmail',
        accessor: 'UserEmail',
        Header: () => <span>{i18n._('users.header.email')}</span>
      }
    ],
    []
  );
  //#endregion

  //#region [react-table]
  const {
    headerGroups,
    page,
    state,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    getTableProps,
    getTableBodyProps,
    prepareRow
  } = useTable(
    {
      columns,
      data: users,
      initialState: {
        sortBy: [
          {
            id: 'UserName',
            desc: false
          }
        ]
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  //#endregion

  //#region [states]
  const [searchValue, setSearchValue] = useState(state.globalFilter);
  //#endregion

  //#region [effects]
  useEffect(() => {
    const debounce = (fn, ms) => {
      let timer;
      return () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          timer = null;
          fn.apply(this, arguments);
        }, ms);
      };
    };

    const debounceHandleResize = debounce(function handleResize() {
      const pageSize = parseInt((window.innerHeight - 290) / 60);
      setPageSize(pageSize < 1 ? 1 : pageSize);
    }, 50);

    window.addEventListener('resize', debounceHandleResize);
    return () => window.removeEventListener('resize', debounceHandleResize);
  }, []);

  useEffect(() => {
    const pageSize = parseInt((window.innerHeight - 290) / 60);
    setPageSize(pageSize < 1 ? 1 : pageSize);
  }, []);
  //#endregion

  //#region [render]
  return (
    <div>
      <div className='users-input-body'>
        <FontAwesomeIcon icon='magnifying-glass' />
        <Form.Control
          type='text'
          placeholder={i18n._('users.search')}
          value={searchValue ?? ''}
          onChange={(evt) => {
            setSearchValue(evt.target.value);
            onChange(evt.target.value);
          }}
          name='search'
        />
      </div>
      <table className='users-table' {...getTableProps()} border='1'>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{ width: COLS_WIDTHS[i] }}
                >
                  {column.render('Header')}
                  {column.isSorted && (
                    <FontAwesomeIcon
                      icon={column.isSortedDesc ? 'arrow-up' : 'arrow-down'}
                    />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      style={{
                        width: COLS_WIDTHS[i]
                      }}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {pageOptions.length > 1 && (
        <Pagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          nbPages={pageOptions.length}
          pageIndex={state.pageIndex}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
        />
      )}
    </div>
  );
  //#endregion
};

export default UsersTable;
