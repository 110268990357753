import { useLingui } from '@lingui/react';
import { UNIT } from '../../../../../../../../server/constants';
import { formatValueWithUnit } from '../../../../../../utils/data.utils';
import { getGapStr } from '../../../../../../utils/heatpump.utils';

const GapCellRenderer = ({ data, value, need, gapError }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  if (!data[need].gap) return value;
  const newValue = value < 0 ? -value * 100 : value * 100;
  const gapWUnit = formatValueWithUnit(i18n, newValue, UNIT.PERCENT, 0);
  return getGapStr(i18n, value, gapWUnit, gapError);
  //#endregion
};

export default GapCellRenderer;
