import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import {
  PIPE_MATERIAL,
  PIPE_NOMINAL_DIAMETER,
  UNIT
} from '../../../../../../../../../server/constants';
import {
  PROBES,
  getProbesHdpeNdParam
} from '../../../../../../../../../server/models/config/thermalProduction/geostorage/settings.model';
import FormInput from '../../../../../../../components/Form/FormInput';
import FormSelect from '../../../../../../../components/Form/FormSelect';
import Section from '../../../../../../../components/Section/Section';
import { INPUT_TYPE } from '../../../../../../../constants';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../../contexts/PopupContext';
import { formatValueWithUnit } from '../../../../../../../utils/data.utils';
import './ProbesSection.css';

const ProbesSection = ({ onFormChange, addError, removeErrors }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const handleParamChange = (param, value) => {
    config.ConfigsSst[0].Data.geostorage.settings.probes[param.key] = value;
    onFormChange(config);
  };

  const handleHdpeNdChange = (value) => {
    try {
      const index = PIPE_NOMINAL_DIAMETER[PIPE_MATERIAL.HDPE].findIndex(
        (nd) => nd === value
      );
      const { probes, horizontalConnections } =
        config.ConfigsSst[0].Data.geostorage.settings;
      probes.hdpeNd = value;
      horizontalConnections.hdpeNd =
        PIPE_NOMINAL_DIAMETER[PIPE_MATERIAL.HDPE][index + 1];
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const { count, depth, hdpeNd } =
    config.ConfigsSst[0].Data.geostorage.settings.probes;
  const HDPE = getProbesHdpeNdParam(depth);
  return (
    <Section title={i18n._('geostorage.settings.probes')} level={2} open>
      <div className='config-form-row'>
        <FormInput
          label={i18n._('config.geostorage.settings.probesNumber')}
          param={PROBES.count}
          value={count}
          type={INPUT_TYPE.NUMBER}
          onChange={(value) => handleParamChange(PROBES.count, value)}
          addError={addError}
          removeError={() => removeErrors(1)}
          className='config-form-input'
        />
        <FormInput
          label={i18n._('config.geostorage.settings.depth')}
          param={PROBES.depth}
          unit={PROBES.depth.unit}
          value={depth}
          type={INPUT_TYPE.NUMBER}
          onBlur={(value) => handleParamChange(PROBES.depth, value)}
          addError={addError}
          removeError={() => removeErrors(1)}
          className='config-form-input'
        />
        <div>
          <span className='form-input-label'>
            {i18n._('config.geostorage.settings.totalLength')}
          </span>
          <span className='probes-section-totalLength'>
            {formatValueWithUnit(i18n, (count ?? 0) * depth, UNIT.METER)}
          </span>
        </div>
      </div>
      <FormSelect
        label={i18n._('config.geostorage.settings.hdpeNd')}
        param={HDPE.hdpeNd}
        value={hdpeNd}
        onChange={(value) => handleHdpeNdChange(value)}
        className='config-form-input'
        showInfoIcon
        infoTooltip={i18n._('config.geostorage.settings.probesHdpeNd.info')}
        bottomText={i18n._('param.geostorage.settings.defaultDn', {
          value: HDPE.hdpeNd.default
        })}
      />
    </Section>
  );
  //#endregion
};

export default ProbesSection;
