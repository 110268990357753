import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { WATERTANK_COLD } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../constants';
import { getDefaultValue } from '../../../../utils/param.utils';

const WaterTankColdBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('cost.waterTankCold')} level={2}>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitWaterTankColdCostA')}
            value={form.InitWaterTankColdCostA}
            param={WATERTANK_COLD.InitWaterTankColdCostA}
            onChange={(value) => onInputChange('InitWaterTankColdCostA', value)}
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_COLD.InitWaterTankColdCostA
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitWaterTankColdCostB')}
            value={form.InitWaterTankColdCostB}
            param={WATERTANK_COLD.InitWaterTankColdCostB}
            onChange={(value) => onInputChange('InitWaterTankColdCostB', value)}
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_COLD.InitWaterTankColdCostB
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitWaterTankColdExchangerCostA')}
            value={form.InitWaterTankColdExchangerCostA}
            param={WATERTANK_COLD.InitWaterTankColdExchangerCostA}
            onChange={(value) =>
              onInputChange('InitWaterTankColdExchangerCostA', value)
            }
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_COLD.InitWaterTankColdExchangerCostA
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitWaterTankColdExchangerCostB')}
            value={form.InitWaterTankColdExchangerCostB}
            param={WATERTANK_COLD.InitWaterTankColdExchangerCostB}
            onChange={(value) =>
              onInputChange('InitWaterTankColdExchangerCostB', value)
            }
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_COLD.InitWaterTankColdExchangerCostB
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitWaterTankColdPumpCostA')}
            value={form.InitWaterTankColdPumpCostA}
            param={WATERTANK_COLD.InitWaterTankColdPumpCostA}
            onChange={(value) =>
              onInputChange('InitWaterTankColdPumpCostA', value)
            }
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_COLD.InitWaterTankColdPumpCostA
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitWaterTankColdPumpCostB')}
            value={form.InitWaterTankColdPumpCostB}
            param={WATERTANK_COLD.InitWaterTankColdPumpCostB}
            onChange={(value) =>
              onInputChange('InitWaterTankColdPumpCostB', value)
            }
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_COLD.InitWaterTankColdPumpCostB
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default WaterTankColdBloc;
