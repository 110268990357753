import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import { AgGridReact } from 'ag-grid-react';
import { BroadcastChannel } from 'broadcast-channel';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchDiagramScope } from '../../../../api/config.api';
import Card from '../../../../components/Card/Card';
import Section from '../../../../components/Section/Section';
import TextFilter from '../../../../components/TextFilter/TextFilter';
import PopupContext from '../../../../contexts/PopupContext';
import SchemaContext from '../../../../contexts/SchemaContext';
import { downloadFile } from '../../../../utils/file.utils';
import './HydraulicDiagramPage.css';
import { SVGMain } from './components/SVGMain';

const HydraulicDiagramPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { configId } = useParams();
  //#endregion

  //#region [contexts]
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [scope, setScope] = useState();
  //#endregion

  //#region [memos]
  const rowData = useMemo(() => {
    if (!scope) return null;
    return Object.entries(scope).map(([variable, value]) => ({
      variable,
      value
    }));
  }, [scope]);

  const columnDefs = useMemo(() => {
    return [
      {
        field: 'variable',
        headerName: i18n._('config.diagram.variable'),
        width: 300,
        filter: TextFilter,
        filterParams: {
          filterPlaceholder: i18n._('config.diagram.variable.filter')
        }
      },
      {
        field: 'value',
        headerName: i18n._('config.diagram.value'),
        sortable: false,
        width: 75
      }
    ];
  }, []);
  //#endregion

  //#region [refs]
  const channel = useRef();
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        channel.current = new BroadcastChannel(configId);
        const msg = { type: 'connection' };
        channel.current.postMessage(JSON.stringify(msg));
        channel.current.onmessage = async (msg) => {
          try {
            msg = JSON.parse(msg);
            switch (msg.type) {
              case 'scope':
                const newScope = await fetchDiagramScope(configId);
                setScope(() => newScope);
                break;
              default:
                console.warn('unknown broadcast channel type', msg.type);
            }
          } catch (err) {
            throw err;
          }
        };
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();

    return async () => {
      await channel.current?.close();
    };
  }, []);
  //#endregion

  //#region [methods]
  const handleDownloadClick = () => {
    const entries = Object.entries(scope);
    const csv = entries.reduce((acc, [key, value], index) => {
      acc += `${key};${value}${index < entries.length - 1 ? '\n' : ''}`;
      return acc;
    }, '');
    downloadFile(
      `config_${configId}.csv`,
      new Blob([csv], { type: 'text/csv' })
    );
  };
  //#endregion

  //#region [render]
  return rowData ? (
    <SchemaContext.Provider
      value={{ showPipeNames: false, showFrames: false, showBlockNames: true }}
    >
      <div className='hydraulic-diagram-page'>
        <h1>{i18n._('config.diagram.hydraulicSchema')}</h1>
        <Section level={2} title={i18n._('config.diagram.variables')}>
          <Card>
            <FontAwesomeIcon icon='file-csv' onClick={handleDownloadClick} />
            <div className='ag-theme-alpine hydraulic-diagram-variables-wrapper'>
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                headerHeight={40}
                rowHeight={40}
                defaultColDef={{
                  editable: false,
                  sortable: true,
                  resizable: false
                }}
                suppressCellFocus
                suppressMenuHide
                suppressMovableColumns
                suppressHorizontalScroll
              />
            </div>
          </Card>
        </Section>
        <Section level={2} title={i18n._('config.diagram.schema')} open>
          <SVGMain
            width='100%'
            showAll={false}
            id='schema'
            scope={scope}
            svcOn={{
              ServiceSolaireThermique: false,
              ServiceSolaireSurInjection: false
            }}
          />
        </Section>
      </div>
    </SchemaContext.Provider>
  ) : null;
  //#endregion
};

export default HydraulicDiagramPage;
