const { SST } = require('./design/description.model');

const EMPTY_SUBSTATION = {
  InitStationID: null,
  AhsID: null,
  ParentInitStationID: null,
  StdFileID: SST.StdFileID.default,
  InitStationOrder: SST.InitStationOrder.default,
  InitBuildingSurface: SST.InitBuildingSurface.default,
  InitStationName: SST.InitStationName.default,
  InitStationUse: SST.InitStationUse.default
};
module.exports = { EMPTY_SUBSTATION };
