const {
  EXCHANGER_POSITION,
  HP_EXCHANGER,
  HP_HE,
  HP_STATUS,
  HP_TYPE,
  NEED
} = require('../../../../constants');
const { getDefaultValues } = require('../../../../utils/param.utils');

const NEEDS = Object.freeze({ key: 'needs', default: [], required: true });

const getPositionParam = (hpsCount) => ({
  key: 'position',
  required: true,
  default: null,
  values: new Array(hpsCount).fill(0).map((_, index) => index + 1)
});

const STATUS = Object.freeze({
  key: 'status',
  required: true,
  default: HP_STATUS.MAIN,
  values: Object.values(HP_STATUS),
  translateValuesIds: Object.values(HP_STATUS).map(
    (status) => `heatpump.status.${status.toLowerCase()}`
  ) // TODO côté front
});

const getSizingParams = (value) => {
  return {
    levels: {
      key: 'levels',
      default: value,
      min: value === 0 ? 0 : 1,
      max: value
    }
  };
};

const getHydraulicEquipmentParams = (hp) => {
  const isAeroExchanger = hp.exchangers.ExHp0 !== EXCHANGER_POSITION.ABSENT;
  const isCombined =
    hp.needs.includes(NEED.COLD) &&
    (hp.needs.includes(NEED.HEAT) || hp.needs.includes(NEED.DHW));
  return {
    coldWaterTank: {
      key: HP_HE.COLD_WATER_TANK,
      default: null,
      enabled: hp.type === HP_TYPE.AERO && hp.needs.includes(NEED.COLD)
    },
    PuE: {
      key: HP_HE.PUE,
      default:
        hp.type === HP_TYPE.GEO ||
        (hp.type === HP_TYPE.AERO && isAeroExchanger),
      enabled:
        hp.type === HP_TYPE.GEO || (hp.type === HP_TYPE.AERO && isAeroExchanger)
    },
    PuC: {
      key: HP_HE.PUC,
      default: true
    },
    Vde: {
      key: HP_HE.VDE,
      default:
        hp.type === HP_TYPE.GEO || (hp.type === HP_TYPE.AERO && isCombined)
    },
    Vdc: {
      key: HP_HE.VDC,
      default:
        hp.type === HP_TYPE.GEO || (hp.type === HP_TYPE.AERO && isCombined)
    },
    Ec0: {
      key: HP_HE.EC0,
      default: true
    }
  };
};

const getExHp0Param = (hp) => {
  const enabled =
    hp.type === HP_TYPE.AERO &&
    hp.needs.includes(NEED.COLD) &&
    (hp.needs.includes(NEED.HEAT) || hp.needs.includes(NEED.DHW));
  return {
    ExHp0: {
      key: HP_EXCHANGER.EXHP0,
      values: Object.values(EXCHANGER_POSITION),
      default: enabled
        ? EXCHANGER_POSITION.COLD_SIDE
        : EXCHANGER_POSITION.ABSENT,
      enabled,
      translateValuesIds: Object.values(EXCHANGER_POSITION).map(
        (position) => `heatpump.exchangerPosition.${position}`
      ) // TODO côté front
    }
  };
};

const getExchangersParams = (ExHp0) => {
  const isAeroExchanger = ExHp0 !== EXCHANGER_POSITION.ABSENT;
  return {
    Ve: {
      key: HP_EXCHANGER.VE,
      default: isAeroExchanger,
      enabled: isAeroExchanger
    },
    Vc: {
      key: HP_EXCHANGER.VC,
      default: isAeroExchanger,
      enabled: isAeroExchanger
    },
    Hp0_Pr01: {
      key: HP_EXCHANGER.HP0_PR01,
      default: isAeroExchanger,
      enabled: isAeroExchanger
    },
    Hp0_Pr02: {
      key: HP_EXCHANGER.HP0_PR02,
      default: isAeroExchanger,
      enabled: isAeroExchanger
    }
  };
};

const getDefaultSelectedHpValues = (hp, hpsAlreadySelectedCount) => {
  hp.levels = hp.compressors;
  hp.position = hpsAlreadySelectedCount + 1;
  Object.keys(hp.capacity).forEach((need) => {
    hp.capacity[need] = {
      ...hp.capacity[need],
      ...getDefaultValues(getSizingParams(hp.levels))
    };
    delete hp.capacity[need].compressors;
  });
  return {
    id: crypto.randomUUID(),
    needs: NEEDS.default,
    status: STATUS.default,
    ...hp
  };
};

const getDefaultHpHydraulicConfigValues = (hp) => {
  const defaultExHp0 = getExHp0Param(hp).ExHp0.default;
  hp.exchangers = {
    ExHp0: defaultExHp0,
    ...getDefaultValues(getExchangersParams(defaultExHp0))
  };
  hp.hydraulicEquipment = getDefaultValues(getHydraulicEquipmentParams(hp));
  return hp;
};

module.exports = {
  STATUS,
  getPositionParam,
  getSizingParams,
  getHydraulicEquipmentParams,
  getExHp0Param,
  getExchangersParams,
  getDefaultSelectedHpValues,
  getDefaultHpHydraulicConfigValues
};
