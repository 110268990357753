import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useState } from 'react';
import { Button, Modal as RBModal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { Outlet } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Modal from '../../components/Modal/Modal';
import Toast from '../../components/Toast/Toast';
import PopupContext from '../../contexts/PopupContext';

const RootPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [modal, setModal] = useState();
  const [dirtyFormModal, setDirtyFormModal] = useState();
  //#endregion

  //#region [methods]
  const openToast = (title, body, level = 'danger') => {
    toast(() => <Toast title={title} body={body} level={level} />);
  };

  const openErrorToast = (error) => {
    const status = error?.response?.status || error?.status;
    const title = status ? i18n._('statusError', { status }) : i18n._('error');
    const body =
      error?.response?.data?.message ||
      error?.response?.data?.error ||
      error?.message ||
      i18n._('error.unknown');
    openToast(title, body);
  };

  const openInfoModal = (title, body) => {
    setModal({ title, body });
  };

  const openConfirmModal = (title, body, level, action) => {
    setModal({
      title,
      body,
      level,
      action
    });
  };

  const openDirtyFormModal = (notSave, save) => {
    setDirtyFormModal({ notSave, save });
  };
  //#endregion

  //#region [render]
  return (
    <div className='root-page'>
      <PopupContext.Provider
        value={{
          openToast,
          openErrorToast,
          openInfoModal,
          openConfirmModal,
          openDirtyFormModal
        }}
      >
        <Header />
        <Outlet />
      </PopupContext.Provider>
      <Modal
        isOpen={!!modal}
        level={modal?.level}
        header={modal?.title}
        body={modal?.body}
        action={modal?.action}
        onClose={() => setModal(null)}
      />
      <Toaster position='top-right' reverseOrder={false} />
      <RBModal
        show={!!dirtyFormModal}
        onHide={() => setDirtyFormModal(null)}
        size='lg'
        className='custom-modal'
      >
        <RBModal.Header closeButton style={{ color: 'var(--warning-color)' }}>
          <span>{i18n._('unsavedChanges.title')}</span>
          <FontAwesomeIcon icon='exclamation-triangle' />
        </RBModal.Header>
        <RBModal.Body>{i18n._('unsavedChanges.body')}</RBModal.Body>
        <RBModal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              dirtyFormModal.notSave();
              setDirtyFormModal(null);
            }}
          >
            {i18n._('unsavedChanges.notSave')}
          </Button>
          <Button
            variant='primary'
            onClick={async () => {
              await dirtyFormModal.save();
              setDirtyFormModal(null);
            }}
          >
            {i18n._('save')}
          </Button>
        </RBModal.Footer>
      </RBModal>
      <RBModal
        isOpen={!!dirtyFormModal}
        level='warning'
        header={i18n._('unsavedChanges.title')}
        body={i18n._('unsavedChanges.body')}
        action={dirtyFormModal?.action}
        onClose={() => setModal(null)}
      />
    </div>
  );
  //#endregion
};

export default RootPage;
