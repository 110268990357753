import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import TwoWayTable from '../../../../../../../components/TwoWayTable/TwoWayTable';
import { formatValue } from '../../../../../../../utils/data.utils.js';

const EnergyProducedDistributionTable = ({ result, sstName }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    const productions = sstName
      ? result.ComputeResult.summary.PerSub.Production?.[sstName]
      : result.ComputeResult.summary.Productions;
    if (!productions) return null;

    const { InitYearsSimulations } = result.ComputeResult.inp;
    const uses = Object.keys(productions);
    const sources = Object.keys(productions.Sum);
    const values = [];

    for (let i = 0; i < uses.length; ++i) {
      values[i] = [];
      for (let j = 0; j < sources.length; ++j) {
        values[i][j] = formatValue(
          (productions[uses[i]][sources[j]] ?? 0) / InitYearsSimulations
        );
      }
      values[i][sources.length] = formatValue(
        productions[uses[i]].Sum / InitYearsSimulations
      );
    }

    return {
      sources: sources.map((src) => i18n._(`source.${src.toLowerCase()}`)),
      uses: uses.map((use) => i18n._(`use.${use.toLowerCase()}`)),
      values
    };
  }, [result.ComputeID, sstName]);
  //#endregion

  //#region [render]
  return data ? (
    <TwoWayTable
      horizontalTitles={data.sources}
      verticalTitles={data.uses}
      values={data.values}
    />
  ) : (
    <Alert variant='info'>{i18n._('results.noData')}</Alert>
  );
  //#endregion
};

export default EnergyProducedDistributionTable;
