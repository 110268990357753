import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { COMPUTE_TYPE } from '../../../../../../../server/constants';
import {
  getBoundsConstraints,
  GOAL,
  ITERATIONS,
  OPTIONS_GOAL
} from '../../../../../../../server/models/design/calculationData.model';
import { getOptiSchema } from '../../../../../../../server/validation/calculationData.validation';
import { fetchResultsForNextCompute } from '../../../../../api/project.api';
import ResultSelect from '../../../../../components/ResultSelect/ResultSelect';
import AjvContext from '../../../../../contexts/AjvContext';
import PopupContext from '../../../../../contexts/PopupContext';
import {
  getDefaultOptiCalculationData,
  getOptiCalculationDataByResult,
  isCalculationDataBoundPresent
} from '../../../../../utils/calculationData.utils';
import {
  isArrNullOrEmpty,
  isObjNullOrEmpty
} from '../../../../../utils/data.utils';
import { IS_DEV } from '../../../../../utils/env.utils';
import { getProjectIndex } from '../../../../../utils/project.utils';
import CalculationForm from '../components/CalculationForm/CalculationForm';
import './OptimizationForm.css';
import OptiGeneralSection from './sections/OptiGeneralSection/OptiGeneralSection';
import OptiGoalsSection from './sections/OptiGoalsSection/OptiGoalsSection';
import OptiIterationsSection from './sections/OptiIterationsSection/OptiIterationsSection';
import OptiSizingSection from './sections/OptiSizingSection/OptiSizingSection';

const OptimizationForm = ({ project }) => {
  //#region [router]
  const { projectId } = useParams();
  //#endregion

  //#region [contexts]
  const { ajv } = useContext(AjvContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [calculationData, setCalculationData] = useState();
  const [groupedResults, setGroupedResults] = useState([]);
  const [selectedResultIndexes, setSelectedResultIndexes] = useState([0, 0]);
  //#endregion

  //#region [methods]
  const refreshResults = async () => {
    try {
      const groups = await fetchResultsForNextCompute(
        projectId,
        COMPUTE_TYPE.OPTI
      );
      setGroupedResults(() => groups);
      if (selectedResultIndexes[0] !== 0 || selectedResultIndexes[1] !== 0) {
        setSelectedResultIndexes(() => [0, 0]);
      }
      const lastResult = groups[0]?.results[0];
      if (!lastResult && calculationData) return;
      if (lastResult) {
        const projectAndChildren = [project, ...project.children];
        const data = getOptiCalculationDataByResult(
          [projectAndChildren[groups[0].descriptionIndex]],
          [groups[0].descriptionIndex],
          lastResult
        );
        setCalculationData(() => data);
      } else {
        setCalculationData(() => getDefaultOptiCalculationData([project], [0]));
      }
    } catch (err) {
      throw err;
    }
  };

  const handleSelectedProjectChange = (index) => {
    const { selectedDescriptionsIndexes } = calculationData;
    const indexes = selectedDescriptionsIndexes.includes(index)
      ? selectedDescriptionsIndexes.filter((i) => i !== index)
      : [...selectedDescriptionsIndexes, index];
    const projectAndChildren = [project, ...project.children];
    const selectedProjects = indexes.map((index) => projectAndChildren[index]);
    const selectedResult =
      groupedResults[selectedResultIndexes[0]]?.results[
        selectedResultIndexes[1]
      ];
    if (selectedResult) {
      const data = getOptiCalculationDataByResult(
        selectedProjects,
        indexes,
        selectedResult
      );
      setCalculationData(() => data);
    } else {
      setCalculationData(() =>
        getDefaultOptiCalculationData(selectedProjects, indexes)
      );
    }
  };

  const handleResultChange = async (indexes) => {
    try {
      setSelectedResultIndexes(() => indexes);
      const selectedResult = groupedResults[indexes[0]].results[indexes[1]];
      let projectIndex = getProjectIndex(project, selectedResult.ProjectID);
      if (projectIndex === -1) projectIndex = 0;
      const projectAndChildren = [project, ...project.children];
      const data = getOptiCalculationDataByResult(
        [projectAndChildren[projectIndex]],
        [projectIndex],
        selectedResult
      );
      setCalculationData(() => data);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleConstraintChange = (param, value) => {
    setCalculationData((data) => {
      data.constraints.inp[param.key] = value;
      return { ...data };
    });
  };

  const handleConstraintMinValueChange = (param, value) => {
    setCalculationData((data) => {
      data.constraints.bounds[0][param.key] = value;
      return { ...data };
    });
  };

  const handleConstraintMaxValueChange = (param, value) => {
    setCalculationData((data) => {
      data.constraints.bounds[1][param.key] = value;
      return { ...data };
    });
  };

  const handleBtesLengthMaxValueChange = (value) => {
    const constraints = getBoundsConstraints();
    setCalculationData((data) => {
      data.constraints.bounds[1].BtesLength = value;
      if (
        isCalculationDataBoundPresent(constraints.QItesMax.key, calculationData)
      ) {
        data.constraints.bounds[1].QItesMax = 0;
      }
      return { ...data };
    });
  };

  const handleSTSurfaceMaxValueChange = (value) => {
    const constraints = getBoundsConstraints();
    setCalculationData((data) => {
      data.constraints.bounds[1].SolarThermalSurface = value;
      if (
        isCalculationDataBoundPresent(
          constraints.KiloWattCretePV.key,
          calculationData
        )
      ) {
        data.constraints.bounds[1].KiloWattCretePV = 0;
      }
      return { ...data };
    });
  };

  const handleGoalsSelectChange = (evt) => {
    const newGoalKey = evt.target.value;
    setCalculationData((data) => {
      data.constraints.inp.InitConstraintEnabled =
        newGoalKey !== GOAL.InitConstraintNone.key;
      data.constraints.inp.InitConstraintReference =
        GOAL.InitConstraintReference.default;
      data.constraints.inp.InitConstraintNbOptim =
        GOAL.InitConstraintNbOptim.default;
      const optionsParams = OPTIONS_GOAL.filter(
        (param) => param.key !== GOAL.InitConstraintNone.key
      );
      optionsParams.forEach((param) => {
        data.constraints.inp[param.key] = param.key !== newGoalKey ? 0 : 10;
      });
      return { ...data };
    });
  };
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        await refreshResults();
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, []);
  //#endregion

  //#region [render]
  if (isObjNullOrEmpty(calculationData)) return null;
  const { InitYearsSimulations } = calculationData.constraints.inp;
  const projectAndChildren = [project, ...project.children];
  const selectedProjects = calculationData.selectedDescriptionsIndexes
    .sort((a, b) => a - b)
    .map((index) => projectAndChildren[index]);
  const schema = getOptiSchema(InitYearsSimulations, selectedProjects);
  const validate = ajv.compile(schema);
  const isFormValid = validate(calculationData);
  if (IS_DEV && !isFormValid) console.log(validate.errors);
  return (
    <CalculationForm
      project={project}
      calculationData={calculationData}
      isFormValid={isFormValid}
      refreshResults={refreshResults}
      className='optimization-form'
    >
      {!isArrNullOrEmpty(groupedResults) && (
        <ResultSelect
          onResultChange={handleResultChange}
          groupedResults={groupedResults}
          selectedResultIndexes={selectedResultIndexes}
        />
      )}
      <OptiGeneralSection
        project={project}
        calculationData={calculationData}
        onConstraintChange={handleConstraintChange}
        onSelectedProjectChange={handleSelectedProjectChange}
      />
      <OptiSizingSection
        project={project}
        calculationData={calculationData}
        selectedProjects={selectedProjects}
        onMinValueChange={handleConstraintMinValueChange}
        onMaxValueChange={handleConstraintMaxValueChange}
        onBtesLengthMaxValueChange={handleBtesLengthMaxValueChange}
        onSTSurfaceMaxValueChange={handleSTSurfaceMaxValueChange}
      />
      <OptiGoalsSection
        calculationData={calculationData}
        onConstraintChange={handleConstraintChange}
        onSelectChange={handleGoalsSelectChange}
      />
      <OptiIterationsSection
        calculationData={calculationData}
        onIterationsChange={(iterations) =>
          handleConstraintChange(ITERATIONS.FuncEvaluations, iterations)
        }
      />
    </CalculationForm>
  );
  //#endregion
};

export default OptimizationForm;
