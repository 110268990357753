import { useLingui } from '@lingui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { STORAGE_KEYS } from '../../../../server/constants';
import { sendMailToResetPwd } from '../../api/auth.api';
import PwdInput from '../../components/PwdInput/PwdInput';
import Toast from '../../components/Toast/Toast';
import useAuth from '../../hooks/useAuth';
import logo from '../../image/logo.svg';
import './LoginPage.css';

const LoginPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [auth]
  const { handleLogin } = useAuth();
  //#endregion

  //#region [states]
  const [lostPwdView, setLostPwdView] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  //#endregion

  //#region [refs]
  const emailInputRef = useRef();
  const pwdInputRef = useRef();
  const lostPwdEmailInputRef = useRef();
  //endregion

  //#region [effects]
  useEffect(() => {
    localStorage.removeItem(STORAGE_KEYS.ACCESS);
    localStorage.removeItem(STORAGE_KEYS.COMPANY);
  }, []);
  //#endregion

  //#region [methods]
  const handleLoginFormSubmit = async (evt) => {
    evt.preventDefault();
    try {
      await handleLogin(emailInputRef.current.value, pwdInputRef.current.value);
    } catch (err) {
      console.error(err);
      toast(() => (
        <Toast
          title={i18n._('error.connect.title')}
          body={i18n._('error.connect.body')}
          level='danger'
        />
      ));
    }
  };

  const handleLostPwdFormSubmit = async (evt) => {
    evt.preventDefault();
    try {
      await sendMailToResetPwd(lostPwdEmailInputRef.current.value, i18n.locale);
      setEmailSent(true);
    } catch (err) {
      console.error(err);
      toast(() => (
        <Toast
          title={i18n._('error')}
          body={
            err.response?.status === 404
              ? i18n._('error.userNotFound')
              : i18n._('error.unknown')
          }
          level='danger'
        />
      ));
    }
  };
  //#endregion

  //#region [render]
  return (
    <div className='login-body'>
      <div className='login-left-body'>
        <img src={logo} alt='logo' className='login-logo' />
        {i18n._('login.optimizedDesign')}
      </div>
      <div className='login-right-body'>
        <Form
          className={!lostPwdView ? 'login-form' : 'hidden'}
          onSubmit={handleLoginFormSubmit}
        >
          <h1>{i18n._('login.loginToDesign')}</h1>
          <Form.Group>
            <Form.Label className='param-input-label' htmlFor='email'>
              {i18n._('login.email.label')}
            </Form.Label>
            <Form.Control
              placeholder={i18n._('login.email.placeholder')}
              className='login-param-input'
              ref={emailInputRef}
              name='email'
              id='email'
              autoComplete='on'
            />
          </Form.Group>
          <PwdInput
            label={i18n._('login.pwd.label')}
            placeholder={i18n._('login.pwd.placeholder')}
            ref={pwdInputRef}
          />
          <span
            className='login-form-forgot-pwd'
            onClick={() => setLostPwdView(true)}
          >
            {i18n._('login.forgotPwd')}
          </span>
          <Button type='submit' variant='primary'>
            {i18n._('login.logIn')}
          </Button>
        </Form>
        <Form
          className={lostPwdView ? 'login-form' : 'hidden'}
          onSubmit={handleLostPwdFormSubmit}
        >
          <h1>{i18n._('login.forgotPwd')}</h1>
          {i18n._('login.forgotPwd.instructions')}
          <Form.Group className='login-form-group' controlId='lostPwdEmail'>
            <Form.Label className='login-form-label'>
              {i18n._('login.email.label')}
            </Form.Label>
            <Form.Control
              placeholder={i18n._('login.email.placeholder')}
              className='login-param-input'
              ref={lostPwdEmailInputRef}
            />
          </Form.Group>
          <div className='login-btns'>
            <Button onClick={() => setLostPwdView(false)} variant='secondary'>
              {i18n._('login.forgotPwd.cancel')}
            </Button>
            <Button type='submit' variant='primary'>
              {i18n._('login.send')}
            </Button>
          </div>
          {emailSent && (
            <span className='login-email-sent'>
              {i18n._('login.forgotPwd.emailSent')}
            </span>
          )}
        </Form>
      </div>
      <Toaster position='top-right' reverseOrder={false} />
    </div>
  );
  //#endregion
};

export default LoginPage;
