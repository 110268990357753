import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { DESTINATIONS } from '../../../../../../../../../server/models/config/hydraulicEquipment/pumps/heatingPlant.model';
import FormInput from '../../../../../../../components/Form/FormInput';
import Section from '../../../../../../../components/Section/Section';
import { INPUT_TYPE } from '../../../../../../../constants';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import { getDefaultValue } from '../../../../../../../utils/param.utils';
import './DestinationsSection.css';

const DestinationsSection = ({ onFormChange, addError, removeErrors }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const handleParamChange = (param, value) => {
    config.ConfigsSst[0].Data.pumps.heatingPlant.destinations[param.key] =
      value;
    onFormChange(config);
  };
  //#endregion

  //#region [render]
  const { exchangerHeadLoss, waterTankHeadLoss } =
    config.ConfigsSst[0].Data.pumps.heatingPlant.destinations;
  return (
    <Section
      title={i18n._('config.pumps.heatingPlant.destinations.title')}
      level={2}
      open
    >
      <div className='config-form-row'>
        <FormInput
          label={i18n._(
            'config.pumps.heatingPlant.destinations.exchangerHeadLoss'
          )}
          value={exchangerHeadLoss}
          param={DESTINATIONS.exchangerHeadLoss}
          type={INPUT_TYPE.NUMBER}
          unit
          bottomText={getDefaultValue(i18n, DESTINATIONS.exchangerHeadLoss)}
          onChange={(value) =>
            handleParamChange(DESTINATIONS.exchangerHeadLoss, value)
          }
          addError={addError}
          removeError={() => removeErrors(1)}
          className='config-form-input'
        />
        <FormInput
          label={i18n._(
            'config.pumps.heatingPlant.destinations.waterTankHeadLoss'
          )}
          value={waterTankHeadLoss}
          param={DESTINATIONS.waterTankHeadLoss}
          type={INPUT_TYPE.NUMBER}
          unit
          bottomText={getDefaultValue(i18n, DESTINATIONS.waterTankHeadLoss)}
          onChange={(value) =>
            handleParamChange(DESTINATIONS.waterTankHeadLoss, value)
          }
          addError={addError}
          removeError={() => removeErrors(1)}
          className='config-form-input waterTankHeadLoss'
        />
      </div>
    </Section>
  );
  //#endregion
};

export default DestinationsSection;
