// arrondi un nombre à un nombre de décimales spécifié
export const roundNumber = (num, precision = 2) => {
  return (
    Math.round((num + Number.EPSILON) * Math.pow(10, precision)) /
    Math.pow(10, precision)
  );
};

// arrondi un nombre à l'entier supérieur
export const roundUp = (num, precision = 2) => {
  if (isNaN(num) || isNull(num)) return num;
  const multiplier = Math.pow(10, precision);
  return Math.ceil(num * multiplier) / multiplier;
};

/**
 * Return the deeper value from object by passing its path.
 * For example if path is 'summary.CAPEX.BTES.Marginless.BTES', this function
 * will return the value of obj.summary.CAPEX.BTES.Marginless.BTES
 */
export const getDeepValue = (obj, path) => {
  if (isObjNullOrEmpty(obj)) return null;
  const pathObjKeys = path.split('.');
  let result = obj;
  pathObjKeys.forEach((key) => {
    result = result[key];
  });
  return result;
};

export const getSafeData = (callback) => {
  try {
    return callback();
  } catch (err) {
    console.warn(err.message);
    return null;
  }
};

const formatData = (data, precision = 0, round = true) => {
  try {
    if (
      data === null ||
      data === undefined ||
      (typeof data === 'string' &&
        (data.length === 0 ||
          data === 'null' ||
          data === 'undefined' ||
          data === 'nil'))
    )
      return null;

    if (data === 0 || data === '0') return 0;
    if (typeof data === 'boolean') return data.toString();
    if (typeof data !== 'number' && typeof data !== 'string') return data;

    let formattedData = '';
    if (typeof data === 'number') {
      formattedData = round ? roundNumber(data, precision) : data;
    } else if (typeof data === 'string') {
      if (data === 'true' || data === 'false') return data;

      formattedData = data.trim().replace(',', '.');
      if (isNaN(formattedData)) return data;

      formattedData = round
        ? roundNumber(parseFloat(formattedData, 10), precision)
        : parseFloat(formattedData, 10);
    }

    const parts = formattedData.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join(',');
  } catch (err) {
    console.warn(err);
    return null;
  }
};

export const formatValueWithUnit = (
  i18n,
  value,
  unit,
  precision = 0,
  round = true
) => {
  const formattedValue = getSafeData(() => formatData(value, precision, round));
  if (formattedValue === null || !unit) return formattedValue;
  return `${formattedValue} ${i18n._(`unit.${unit}`)}`;
};

export const formatValue = (value, precision = 0, round = true) => {
  return getSafeData(() => formatData(value, precision, round));
};

export const getPercentsDifference = (value, ref) => {
  return -100 + (value * 100) / ref;
};

export const getAverage = (values) => {
  if (isArrNullOrEmpty(values)) return null;
  const total = values.reduce((acc, value) => {
    return acc + value;
  }, 0);
  return total / values.length;
};

export const isObjNullOrEmpty = (obj) => !obj || Object.keys(obj).length === 0;

export const isArrNullOrEmpty = (arr) => !arr || arr.length === 0;

export const isObject = (obj) =>
  obj != null && obj.constructor.name === 'Object';

export const isNull = (val) => val === null || val === undefined;

export const hasUniqueElements = (arr) => {
  const set = new Set(arr);
  return set.size === arr.length;
};

export const getTableValue = (i18n, value, unit, precision) => {
  return isNull(value)
    ? i18n._('noValue')
    : formatValueWithUnit(i18n, value, unit, precision);
};

export const getArraySum = (arr) => arr.reduce((acc, val) => acc + val, 0);
