import React from 'react';

const ProjectDescriptionCell = ({ row }) => {
  //#region [render]
  return (
    <div className='two-lines-max' title={row.original.AhsDescription}>
      {row.original.AhsDescription}
    </div>
  );
  //#endregion
};

export default React.memo(ProjectDescriptionCell);
