import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import './ErrorBadge.css';

const ErrorBadge = ({
  tooltipContent,
  tooltipId,
  portalContainer,
  children
}) => {
  //#region [render]
  return (
    <div
      className='error-badge'
      data-tooltip-content={tooltipContent}
      data-tooltip-id={tooltipId}
    >
      {children}
      <FontAwesomeIcon icon='fa-regular fa-circle-question' />
      {createPortal(
        <Tooltip
          id={tooltipId}
          place='bottom'
          className='error-tooltip'
          arrowColor='#f04460'
          opacity={1}
        />,
        portalContainer
      )}
    </div>
  );
  //#endregion
};

export default ErrorBadge;
