import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Form } from 'react-bootstrap';

const ParamSelect = ({
  className,
  value,
  disabled,
  onChange,
  param,
  label,
  bottomText,
  topText,
  notDefaultValueHilighted = true,
  onInfoClick,
  disabledOptions
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [methods]
  const handleValueChange = (val) => {
    if (param.type === 'number') val = +val;
    onChange(val);
  };
  //#endregion

  //#region [render]
  const inputClassName =
    !disabled && value && value != param.default && notDefaultValueHilighted
      ? 'param-input-changed'
      : '';
  return (
    <div className={className}>
      {label && (
        <span className='param-input-label'>
          {label}
          {onInfoClick && (
            <FontAwesomeIcon
              className='modal-info-icon'
              icon='circle-question'
              onClick={onInfoClick}
            />
          )}
        </span>
      )}
      <div className='param-input-body'>
        {topText && (
          <p className='param-input-small-text param-input-top-text'>
            {topText}
          </p>
        )}
        <Form.Select
          disabled={disabled}
          onChange={(evt) => handleValueChange(evt.target.value)}
          value={value !== null || value !== undefined ? value : ''}
          className={`${inputClassName} param-input ellipsis`}
          name={param.key}
        >
          {param.values.map((value, index) => (
            <option
              key={'input_' + param.key + '_value_' + index}
              value={value}
              disabled={disabledOptions?.includes(value)}
            >
              {param.type === 'number'
                ? value
                : i18n._(`${param.translateValuesIds[index]}`)}
            </option>
          ))}
        </Form.Select>
        {bottomText && (
          <span className='param-input-small-text'>{bottomText}</span>
        )}
      </div>
    </div>
  );
  //#endregion
};

export default React.memo(ParamSelect);
