import { useLingui } from '@lingui/react';
import React from 'react';
import { WEATHER_COLS } from '../../../../../../../../server/models/design/description.model';
import { isNull } from '../../../../../../utils/data.utils';

const WeatherModal = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <div>
      <p>{i18n._('description.weatherDesc.acceptedFiles')}</p>
      <div className='description-file-modal-hl' />
      {Object.entries(WEATHER_COLS).map(([colName, colObj], colIndex) => {
        let text = [colName];
        if (!isNull(colObj.min)) {
          text.push(
            i18n._('description.modal.min', {
              min: colObj.min
            })
          );
        }
        if (!isNull(colObj.max)) {
          text.push(
            i18n._('description.modal.max', {
              max: colObj.max
            })
          );
        }
        if (colObj.unit) {
          text.push(
            i18n._('description.modal.unit', {
              unit: i18n._(`unit.${colObj.unit}`)
            })
          );
        }
        if (!colObj.mandatory) {
          text.push(i18n._('description.modal.optional'));
        }
        return (
          <p key={'weatherNeeds_' + colName + '_' + colIndex}>
            {text.join(i18n._('description.modal.separator'))}
          </p>
        );
      })}
    </div>
  );
  //#endregion
};

export default WeatherModal;
