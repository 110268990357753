import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import { getPositionParam } from '../../../../../../../../../server/models/config/thermalProduction/heatpumps/heatpump.model';
import FormSelect from '../../../../../../../components/Form/FormSelect';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../../contexts/PopupContext';
import { hasUniqueElements } from '../../../../../../../utils/data.utils';
import './PositionCellRenderer.css';

const PositionCellRenderer = ({ data, onFormChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const handlePositionChange = (value) => {
    try {
      const { list } = config.ConfigsSst[0].Data.heatpumps;
      const index = list.findIndex((hp) => hp.id === data.id);
      list[index].position = value;
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  // on vérifie qu'il n'y ait pas deux fois la même position
  const { list } = config.ConfigsSst[0].Data.heatpumps;
  let error;
  if (!hasUniqueElements(list.map((hp) => hp.position))) {
    error = i18n._('config.hps.positionError');
  }
  const POSITION_PARAM = getPositionParam(list.length);
  const selectId = 'positions_select' + data.id;
  return (
    <div data-tooltip-content={error} data-tooltip-id={'error_' + selectId}>
      <FormSelect
        param={POSITION_PARAM}
        value={data.position}
        onChange={(value) => handlePositionChange(value)}
        className={error ? 'position-cell-error' : ''}
      />
      {error &&
        createPortal(
          <Tooltip
            id={'error_' + selectId}
            place='bottom'
            className='error-tooltip'
            arrowColor='#f04460'
            opacity={1}
          />,
          document.body
        )}
    </div>
  );
  //#endregion
};

export default PositionCellRenderer;
