import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';
import {
  DAYS_PER_MONTH,
  HOURS_PER_YEAR,
  MONTHS
} from '../../../server/constants';

export const getHoursInterval = (month, year) => {
  const hoursByMonth = Object.entries(DAYS_PER_MONTH).reduce(
    (acc, [month, nbDays]) => {
      acc[month] = nbDays * 24;
      return acc;
    },
    {}
  );
  const monthIndex = MONTHS.findIndex((m) => m === month);
  let hoursSinceJanuary = 0;

  for (let i = 0; i < monthIndex; i++) {
    const currentMonth = MONTHS[i];
    hoursSinceJanuary += hoursByMonth[currentMonth];
  }

  const startHour = hoursSinceJanuary + year * HOURS_PER_YEAR + 1;
  const endHour = startHour + hoursByMonth[month];

  return [startHour, endHour];
};

export const formatDateAndHour = (i18n, serverDateStr, format) => {
  // on convertit la date en UTC (le serveur MySQL étant en Europe/Paris)
  const dateUtc = fromZonedTime(serverDateStr, 'Europe/Paris');

  // on convertit et on formatte la date UTC en heure locale du client
  return formatInTimeZone(
    dateUtc,
    Intl.DateTimeFormat().resolvedOptions().timeZone,
    format ?? i18n._('formatDateAndHour')
  );
};
