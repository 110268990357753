import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import { HP_STATUS } from '../../../../../../../../../server/constants';
import { STATUS } from '../../../../../../../../../server/models/config/thermalProduction/heatpumps/heatpump.model';
import FormSelect from '../../../../../../../components/Form/FormSelect';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../../contexts/PopupContext';
import './StatusCellRenderer.css';

const StatusCellRenderer = ({ data, value, onFormChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const handleStatusChange = (val) => {
    try {
      const { list } = config.ConfigsSst[0].Data.heatpumps;
      const index = list.findIndex((hp) => hp.id === data.id);
      list[index].status = val;
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  // si tous les status des PACS sont à "Secours" : erreur
  const { list } = config.ConfigsSst[0].Data.heatpumps;
  const statusError = !list.some((hp) => hp.status === HP_STATUS.MAIN);
  return (
    <div
      data-tooltip-content={
        statusError ? i18n._('config.hps.statusError') : null
      }
      data-tooltip-id={'error_' + data.id}
    >
      <FormSelect
        param={STATUS}
        onChange={handleStatusChange}
        value={value}
        className={statusError ? 'status-cell-error' : ''}
      />
      {statusError &&
        createPortal(
          <Tooltip
            id={'error_' + data.id}
            place='bottom'
            className='error-tooltip'
            arrowColor='#f04460'
            opacity={1}
          />,
          document.body
        )}
    </div>
  );
  //#endregion
};

export default StatusCellRenderer;
