import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useEffect } from 'react';
import { Form, Nav } from 'react-bootstrap';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { STORAGE_KEYS } from '../../../../server/constants';
import useAuth from '../../hooks/useAuth';
import useScreenSize from '../../hooks/useScreenSize';
import { isNull } from '../../utils/data.utils';
import './ProjectsPage.css';
import ActiveProjects from './components/ActiveProjects';
import DeletedProjects from './components/DeletedProjects';

const ProjectsPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  const { companyId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  //#endregion

  //#region [auth]
  const { user } = useAuth();
  //#endregion

  //#region [effects]
  useEffect(() => {
    if (!user.IsSuperAdmin) return;
    const isActive = searchParams.get('active');
    if (isNull(isActive)) setSearchParams({ active: 'true' });
  }, [searchParams]);
  //#endregion

  //#region [methods]
  const handleCompanyChange = (companyId, isActive) => {
    localStorage.setItem(STORAGE_KEYS.COMPANY, companyId);
    navigate(`/company/${companyId}/projects?active=${isActive}`);
  };
  //#endregion

  //#region [custom hooks]
  const screenSize = useScreenSize();
  //#endregion

  //#region [render]
  if (!user) return null;
  const isActive = searchParams.get('active');
  if (user.IsSuperAdmin && isNull(isActive)) return null;
  let activeProjects, deletedProjects, newProject;
  if (screenSize.width <= 1007) {
    activeProjects = i18n._('projects.active.short');
    deletedProjects = i18n._('projects.deleted.short');
    newProject = i18n._('projects.new.short');
  } else {
    activeProjects = i18n._('projects.active');
    deletedProjects = i18n._('projects.deleted');
    newProject = i18n._('projects.new');
  }
  return (
    <div className='projects-body'>
      <div className='projects-body-top-row'>
        <div className='projects-company-select'>
          <label htmlFor='projects_company_select'>{i18n._('company')}</label>
          <Form.Select
            aria-label='select company'
            onChange={(evt) => handleCompanyChange(evt.target.value, isActive)}
            value={companyId}
            name='projects_company_select'
            id='projects_company_select'
          >
            {user.companies.map((company) => (
              <option key={'company_' + company.CoID} value={company.CoID}>
                {company.CoName}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className='projects-body-buttons-row'>
          {user.IsSuperAdmin && (
            <Nav variant='pills'>
              <Nav.Item>
                <Nav.Link
                  active={isActive === 'true'}
                  onClick={() => setSearchParams({ active: 'true' })}
                >
                  {activeProjects}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  active={isActive === 'false'}
                  onClick={() => setSearchParams({ active: 'false' })}
                >
                  {deletedProjects}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          )}
          <Link
            to={`/company/${companyId}/new-project`}
            className='projects-new-project'
          >
            <FontAwesomeIcon icon='circle-plus' />
            {newProject}
          </Link>
        </div>
      </div>
      {user.IsSuperAdmin ? (
        <>
          {isActive === 'true' && <ActiveProjects />}
          {isActive === 'false' && <DeletedProjects />}
        </>
      ) : (
        <ActiveProjects />
      )}
    </div>
  );
  //#endregion
};

export default ProjectsPage;
