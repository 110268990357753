import React from 'react';

const defaultState = {
  user: undefined,
  handleLogin: async () => {},
  handleLogout: async () => {},
  handleUserChange: async () => {}
};

const AuthContext = React.createContext(defaultState);
export default AuthContext;
