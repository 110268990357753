import { useLingui } from '@lingui/react';
import React from 'react';
import { HPA } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import EditTable from '../../../../components/EditTable/EditTable';

const HpaBloc = ({ form, onTableValueChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('cost.hpa')} level={2}>
      <div>
        <span className='param-input-label'>
          {i18n._('cost.InitHPACostLabel')}
        </span>
        <EditTable
          titles={[
            i18n._('cost.InitHPACostSlices'),
            i18n._('cost.InitHPACostHeat'),
            i18n._('cost.InitHPACostHeatCold')
          ]}
          values={[form.InitHPACostHeat, form.InitHPACostHeatCold]}
          params={[HPA.InitHPACostHeat, HPA.InitHPACostHeatCold]}
          onChange={onTableValueChange}
        />
      </div>
    </Bloc>
  );
  //#endregion
};

export default HpaBloc;
