import axios from '../conf/axios.conf';

//#region [GET]
export const fetchResult = async (computeId) => {
  try {
    const response = await axios.get(`/compute/${computeId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchListOfResults = async (ids) => {
  try {
    const url = `/compute/list?id=${ids.join('&id=')}`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchListOfComputesDetails = async (ids) => {
  try {
    const url = `/compute/list/details?id=${ids.join('&id=')}`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchCalculation = async (computeId) => {
  try {
    const response = await axios.get(`/compute/${computeId}/calculation`);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [PUT]
export const updateComputeComment = async (computeId, comment) => {
  try {
    const response = await axios.put(`/compute/${computeId}/comment`, {
      comment
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const addComputeTag = async (computeId, tag) => {
  try {
    const response = await axios.put(`/compute/${computeId}/addTag`, { tag });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const removeComputeTag = async (computeId, tag) => {
  try {
    const response = await axios.put(`/compute/${computeId}/removeTag`, {
      tag
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const replaceComputeTag = async (
  computeId,
  computeIdForReplacingTag,
  tag
) => {
  try {
    const response = await axios.put(`/compute/${computeId}/replaceTag`, {
      computeIdForReplacingTag,
      tag
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [DELETE]
export const deleteResults = async (ids) => {
  try {
    const idsUrl = ids.map((id) => `id=${id}`).join('&');
    await axios.delete(`/compute?${idsUrl}`);
  } catch (err) {
    throw err;
  }
};
//#endregion
