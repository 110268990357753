import { Car, Junction, Pipe, Pump, Valve } from './MiscComponents.js';
import { h } from './RR.js';

export function VerticalPipesStrokeInfos(props) {
  let { scope, svcOn, X, position } = props;

  const cond = { Chaud: 1, ECS: 2, Piscine: 3, BTES: 4, DRY: 5, Aero: 6 },
    evap = {
      BTES: 1,
      Froid: 2,
      DRY: 3,
      StockFroid: 4,
      GeoCooling: 5,
      Aero: 6,
      Solaire: 7
    },
    PositionCond = {
      [cond.Chaud]: scope[`Cxx_syst_PositionChaud`],
      [cond.ECS]: scope[`Cxx_syst_PositionEcs`],
      [cond.Piscine]: scope[`Cxx_syst_PositionPiscine`],
      [cond.BTES]: 9,
      [cond.DRY]: position,
      [cond.Aero]: position
    },
    PositionEvap = {
      [evap.BTES]: 9,
      [evap.Froid]: scope[`Cxx_syst_PositionFroid`],
      [evap.DRY]: position,
      [evap.StockFroid]: scope[`Cxx_syst_PositionStockFroid`],
      [evap.GeoCooling]: position,
      [evap.Aero]: position,
      Solaire: scope[`Cxx_syst_PositionSolaire`]
    },
    digit = (mode, d) => Math.floor(mode / d) % 10,
    modes = Object.fromEntries(
      Object.keys(scope)
        .filter(
          (k) => /^\Pxx_gene_Hp0\d+_mode$/.test(k) && digit(scope[k], 10) > 0
        )
        .map((k) => [k.substr(9, 4), scope[k]])
    ),
    mode = modes[`Hp0${X}`],
    CentaineEvap = digit(mode, 100),
    Dizaine = digit(mode, 10),
    UniteCond = digit(mode, 1),
    on = Dizaine > 0,
    ColdNeed = [2, 4].indexOf(CentaineEvap) >= 0,
    HeatNeed = [1, 2, 3].indexOf(UniteCond) >= 0,
    present = (x) => scope[`Cxx_syst_${x}_Presente`] > 0,
    HpGOn = [1, 2, 3, 4, 5, 6, 7, 8].reduce(
      (acc, i) =>
        acc ||
        (i <= X &&
          present(`Hp0${i}`) &&
          scope[`Cxx_syst_Hp0${i}_Type`] < 2 &&
          digit(modes[`Hp0${i}`], 10) > 0),
      false
    ),
    injSol =
      !HpGOn &&
      svcOn.SolaireThermique &&
      position >= scope[`Cxx_syst_PositionSolaire`],
    gc =
      CentaineEvap == evap.GeoCooling &&
      position >= scope[`Cxx_syst_PositionFroid`],
    gcRight =
      Object.keys(modes).filter(
        (hp) => hp <= 'Hp0' + X && digit(modes[hp], 100) == evap.GeoCooling
      ).length > 0,
    gcLeft =
      X > 8
        ? gcRight
        : position >= scope[`Cxx_syst_PositionFroid`] &&
          Object.keys(modes).filter(
            (hp) => hp > 'Hp0' + X && digit(modes[hp], 100) == evap.GeoCooling
          ).length > 0,
    isDBF = (hp) => scope[`Cxx_syst_${hp}_ConnectDirectBallonFroid`] > 0,
    onRight =
      false ||
      Object.keys(modes).filter(
        (hp) => hp <= 'Hp0' + X && PositionCond[digit(modes[hp], 1)] > position
      ).length > 0 ||
      Object.keys(modes).filter(
        (hp) => hp > 'Hp0' + X && PositionCond[digit(modes[hp], 1)] <= position
      ).length > 0,
    onLeft =
      false ||
      Object.keys(modes).filter(
        (hp) =>
          hp <= 'Hp0' + X &&
          PositionEvap[digit(modes[hp], 100)] > position &&
          !isDBF(hp) &&
          digit(modes[hp], 100) != eval.GeoCooling
      ).length > 0 ||
      Object.keys(modes).filter(
        (hp) =>
          hp > 'Hp0' + X &&
          PositionEvap[digit(modes[hp], 100)] <= position &&
          !isDBF(hp) &&
          digit(modes[hp], 100) != eval.GeoCooling
      ).length > 0 ||
      injSol,
    invertedRight =
      Object.keys(modes).filter(
        (hp) => hp > 'Hp0' + X && PositionCond[digit(modes[hp], 1)] <= position
      ).length > 0,
    invertedLeft =
      Object.keys(modes).filter(
        (hp) =>
          hp > 'Hp0' + X && PositionEvap[digit(modes[hp], 100)] <= position
      ).length > 0,
    _ = '';
  //if(position==1)console.log({modes,onLeft,gcLeft});

  return {
    onLeft,
    onRight,
    invertedLeft,
    invertedRight,
    injSol,
    gc,
    gcLeft,
    gcRight,
    HeatNeed,
    ColdNeed,
    modes
  };
}

export function PacBgRect({ showAll }) {
  return h('rect', {
    x: -200,
    y: -100,
    width: 400,
    height: 150,
    className: 'dotted'
  });
}
/** HasLeftVertPipes  is called from PacG1 and PacM1 */
export function HasLeftVertPipes({ X, scope, position }) {
  const hasHPGBefore =
    Array(X - 1)
      .fill('')
      .map((_, i) => `Hp0` + (i + 1))
      .filter(
        (i) =>
          scope[`Cxx_syst_${i}_Presente`] == 1 &&
          scope[`Cxx_syst_${i}_Type`] < 2
      ).length > 0;
  const coldBefore =
    (scope[`Cxx_syst_serviceFroid`] > 0 &&
      scope[`Cxx_syst_PositionFroid`] < position) ||
    (scope[`Cxx_syst_ServiceSolaireThermique`] > 0 &&
      scope[`Cxx_syst_PositionSolaire`] < position) ||
    (scope[`Cxx_syst_ServiceStockageFroid`] > 0 &&
      scope[`Cxx_syst_PositionStockFroid`] < position);
  return hasHPGBefore || coldBefore;
}

export function PacG1({
  X,
  scope,
  position,
  npacs,
  pacbefore,
  svcOn,
  showAll,
  showBlockNames
}) {
  const present = (x) => showAll || scope[`Cxx_syst_${x}_Presente`] > 0;
  const aboveleftPipes = HasLeftVertPipes({ X, scope, position });

  const on = svcOn['Hp0' + X],
    {
      onLeft,
      onRight,
      invertedLeft,
      invertedRight,
      injSol,
      gc,
      gcLeft,
      gcRight
    } = VerticalPipesStrokeInfos({ scope, svcOn, X, position }),
    mode = scope[`Pxx_gene_Hp0${X}_mode`],
    gch = Math.floor(mode / 100) == 5,
    hotColor = svcOn.ECS ? 'red' : 'orange';

  return !present(`Hp0${X}`)
    ? ''
    : //translate(`200 ${position*100-50})`)(
      h('g', { transform: `translate(200 ${position * 150 - 50})` }, [
        PacBgRect({ showAll }),
        showBlockNames
          ? h(
              'text',
              { x: -150, y: -100 + 20, className: 'block-label' },
              'PAC G1'
            )
          : '',
        //h('rect',{x:-25,y:-35-mt/2,width:50,height:70+mt/2,stroke:'#888',fill:'#ddd'}),
        h('g', { transform: `rotate(0)` }, [
          h(
            'use',
            {
              href: '#hpg',
              x: -25,
              y: -60,
              width: 50,
              height: 95,
              transform: `rotate(0)`
            },
            []
          )
          //h('animateTransform',{attributeName:'transform',attributeType:'XML','type':'rotate',from:0,to:360,dur:'2s',repeatCount:on?'2':'0'})
        ]),
        h(
          'text',
          { y: -20, 'text-anchor': 'middle', fill: on ? '#0C0' : '#000' },
          'Pac ' + X
        ),
        h('g', { className: 'hline1', transform: `translate(0 -25)` }, [
          h(Pipe, {
            name: 'LV11',
            d: `M -175 150 v -75`,
            stroke: onLeft || gcLeft ? (injSol ? '#0aa' : 'blue') : 'black',
            invertedFlow: invertedLeft
          }),
          h(Pipe, {
            name: 'LV12',
            d: `M -175 75 v -75`,
            stroke: onLeft || gcLeft ? (injSol ? 'black' : 'blue') : 'black',
            invertedFlow: invertedLeft
          }),
          h(Pipe, {
            name: 'LH1',
            d: `M -175 0 h15 ${aboveleftPipes ? 'm 10 0' : 'h10'} h 125`,
            stroke: on || gch ? 'blue' : 'black'
          }),
          h(Pipe, {
            name: 'RH1',
            d: `M 25 0 h 125 ${pacbefore ? 'm 10 0' : 'h 10'} h 15`,
            stroke: on && !gch ? hotColor : 'black'
          }),
          h(Pipe, {
            name: 'RV1',
            d: `M 175 0 v 150`,
            stroke: onRight ? hotColor : 'black',
            invertedFlow: invertedRight
          }),
          ...[-175, 175].map((x) => Junction({ x })),
          ...[-50, 50].map((x) => h('use', { href: '#arrowrightred', x }))
        ]),
        h('g', { className: 'hline2', transform: `translate(0 25)` }, [
          h(Pipe, {
            name: 'LV2',
            d: `M -155 0 v 150`,
            stroke: gc ? 'blue' : onLeft ? (injSol ? 'red' : '#0aa') : 'black',
            invertedFlow: invertedLeft
          }),
          h(Pipe, {
            name: 'RH2',
            d: `M 155 0 h -130`,
            stroke: on && !gch ? 'blue' : 'black'
          }),
          h(Pipe, {
            name: 'LH2',
            d: `M -25 0 h -130`,
            stroke: on || gch ? (gc ? 'blue' : '#0aa') : 'black'
          }),
          h(Pipe, {
            name: 'RV2',
            d: `M 155 150 v -150`,
            stroke: onRight ? 'blue' : 'black',
            invertedFlow: invertedRight
          }),
          ...[-155, 155].map((x) => Junction({ x })),
          ...[-50, 50].map((x) => h('use', { href: '#arrowleftblue', x }))
        ]),

        //h('path',{d:`M 45 25 l 5 -3 v 6 z`,stroke:'blue',fill:'blue'}),
        present(`Vc${X}`)
          ? h(
              'g',
              { transform: `translate(75,-25)` },
              h(Valve, { X, scope, rotate: -90, label: 'Vc' + X, on })
            )
          : npacs <= 1
          ? ''
          : h(
              'g',
              { transform: `translate(75 -25)` },
              h(Car, { X, scope, rotate: 0 })
            ),
        !present(`PuC${X}`)
          ? ''
          : h(
              'g',
              { transform: `translate(75 25)` },
              h(Pump, {
                X,
                scope,
                label: 'PuC' + X,
                rotate: 0,
                double: scope[`Cxx_regu_PuC${X}_Double`] > 0,
                on
              })
            ),
        !present(`Vdc${X}`)
          ? ''
          : h(
              'g',
              { transform: `translate(175,50)` },
              h(Valve, { X, scope, rotate: 0, label: 'Vdc' + X, on: onRight })
            ),
        present(`Ve${X}`)
          ? h(
              'g',
              { transform: `translate(-75 25)` },
              h(Valve, { X, scope, rotate: -90, label: 'Ve' + X, on })
            )
          : npacs <= 1
          ? ''
          : h(
              'g',
              { transform: `translate(-75 25)` },
              h(Car, { X, scope, rotate: 180 })
            ),
        !present(`PuE${X}`)
          ? ''
          : h(
              'g',
              { transform: `translate(-75 -25)` },
              h(Pump, {
                X,
                scope,
                label: 'PuE' + X,
                rotate: 180,
                double: scope[`Cxx_regu_PuE${X}_Double`] > 0,
                on
              })
            ),
        !present(`Vde${X}`)
          ? ''
          : h(
              'g',
              { transform: `translate(-175,37.5)` },
              h(Valve, {
                X,
                scope,
                rotate: 180,
                label: 'Vde' + X,
                on: onLeft || gc
              })
            )
      ]);
}
