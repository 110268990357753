import { useLingui } from '@lingui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import ExpandableSection from '../../../../../../components/ExpandableSection/ExpandableSection';
import Section from '../../../../../../components/Section/Section';
import Table from '../../../../../../components/Table/Table';
import TwoColsTemplateTable from '../../../../../../components/TemplateTables/TwoColsTemplateTable/TwoColsTemplateTable';
import { btesTemplate, capexTemplates, opexTemplates } from './templates';

const CostSection = ({ project }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { companyId, projectId } = useParams();
  //#endregion

  //#region [render]
  const btesTableCols = [
    Object.keys(project.otherValues.InitBtesDiscountDict),
    Object.values(project.otherValues.InitBtesDiscountDict)
  ];
  const hpgTableCols = [
    Object.keys(project.otherValues.InitHPGCost),
    Object.values(project.otherValues.InitHPGCost)
  ];
  const hpaTableCols = [
    Object.keys(project.otherValues.InitHPACostHeat),
    Object.values(project.otherValues.InitHPACostHeat),
    Object.values(project.otherValues.InitHPACostHeatCold)
  ];
  const gasTableCols = [
    Object.keys(project.otherValues.InitCapexGas),
    Object.values(project.otherValues.InitCapexGas)
  ];
  return (
    <Section
      title={i18n._('page.costs')}
      to={`/company/${companyId}/project/${projectId}/design/costs`}
    >
      <h2 level={2}>{i18n._('capex.hypotheses')}</h2>
      <TwoColsTemplateTable template={btesTemplate(i18n, project)} level={3} />
      <ExpandableSection>
        <Table
          title={i18n._('cost.InitBtesDiscountLabel')}
          header={[
            i18n._('cost.InitBtesDiscountDictSlices'),
            i18n._('cost.InitBtesDiscountDict')
          ]}
          cols={btesTableCols}
          className='cost-section-table'
        />
      </ExpandableSection>
      <ExpandableSection level={3} title={i18n._('cost.hpg')}>
        <Table
          title={i18n._('cost.InitHPGCostLabel')}
          header={[
            i18n._('cost.InitHPGCostSlices'),
            i18n._('cost.InitHPGCost')
          ]}
          cols={hpgTableCols}
          className='cost-section-table'
        />
      </ExpandableSection>
      <ExpandableSection level={3} title={i18n._('cost.hpa')}>
        <Table
          title={i18n._('cost.InitHPACostLabel')}
          header={[
            i18n._('cost.InitHPACostSlices'),
            i18n._('cost.InitHPACostHeat'),
            i18n._('cost.InitHPACostHeatCold')
          ]}
          cols={hpaTableCols}
          className='cost-section-table'
        />
      </ExpandableSection>
      <ExpandableSection level={3} title={i18n._('cost.gasBoiler')}>
        <Table
          title={i18n._('cost.InitCapexGasLabel')}
          header={[
            i18n._('cost.InitCapexGasSlices'),
            i18n._('cost.InitCapexGas')
          ]}
          cols={gasTableCols}
          className='cost-section-table'
        />
      </ExpandableSection>
      {capexTemplates(i18n, project).map((template, index) => (
        <TwoColsTemplateTable
          key={'cost_table_capex_' + index}
          template={template}
          level={3}
        />
      ))}
      <h2 level={2}>{i18n._('opex.hypotheses')}</h2>
      {opexTemplates(i18n, project).map((template, index) => (
        <TwoColsTemplateTable
          key={'cost_table_opex_' + index}
          template={template}
          level={3}
        />
      ))}
    </Section>
  );
  //#endregion
};

export default React.memo(CostSection);
