import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import {
  EXCHANGER_POSITION,
  HP_EXCHANGER,
  HP_HE
} from '../../../../../../../../server/constants';
import {
  getExHp0Param,
  getExchangersParams
} from '../../../../../../../../server/models/config/thermalProduction/heatpumps/heatpump.model';
import FormSelect from '../../../../../../components/Form/FormSelect';
import Section from '../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../contexts/PopupContext';
import { getSortedNeeds } from '../../../../../../utils/config.utils';
import { isNull } from '../../../../../../utils/data.utils';
import NeedBadge from '../components/NeedBadge';

const ExchangersSection = ({ onFormChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const checkForExchangerWarning = (checked, defaultValue) =>
    isNull(defaultValue) ? false : defaultValue !== checked;

  const handleExHp0Change = (value, hpId) => {
    try {
      const { list } = config.ConfigsSst[0].Data.heatpumps;
      const index = list.findIndex((hp) => hp.id === hpId);
      const isExHp0Absent = value === EXCHANGER_POSITION.ABSENT;
      Object.values(HP_EXCHANGER)
        .filter((exchanger) => exchanger !== HP_EXCHANGER.EXHP0)
        .forEach((exchanger) => {
          list[index].exchangers[exchanger] = !isExHp0Absent;
        });
      list[index].hydraulicEquipment[HP_HE.PUE] = !isExHp0Absent;
      list[index].exchangers.ExHp0 = value;
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleExchangerChange = (evt, hpId) => {
    try {
      const { checked, value } = evt.target;
      const { list } = config.ConfigsSst[0].Data.heatpumps;
      const index = list.findIndex((hp) => hp.id === hpId);
      list[index].exchangers[value] = checked;
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const { list } = config.ConfigsSst[0].Data.heatpumps;
  return (
    <Section title={i18n._('config.hps.hydraulic.exchangers')} level={2} open>
      <div className='custom-table-wrapper'>
        <table className='custom-table'>
          <thead>
            <tr>
              <th>{i18n._('config.hps.position')}</th>
              <th>{i18n._('config.hps.model')}</th>
              <th>{i18n._('config.hps.type')}</th>
              <th>{i18n._('config.hps.needs')}</th>
              <th>{i18n._('hydraulicConfig.table.th.ExHp0')}</th>
              {Object.values(HP_EXCHANGER)
                .filter((exchanger) => exchanger !== HP_EXCHANGER.EXHP0)
                .map((exchanger) => (
                  <th key={'col_' + exchanger}>
                    {i18n._(`hydraulicConfig.table.th.${exchanger}`)}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {list
              .sort((hp1, hp2) => hp1.position - hp2.position)
              .map((hp, index) => {
                const EXHP0 = getExHp0Param(hp).ExHp0;
                const sortedNeeds = getSortedNeeds(hp.needs);
                return (
                  <tr key={'row_ex_' + index}>
                    <td>{hp.position}</td>
                    <td>{hp.model}</td>
                    <td>{i18n._(`heatpump.type.${hp.type}`)}</td>
                    <td>
                      <div className='equipment-table-badges'>
                        {sortedNeeds.map((need) => (
                          <NeedBadge
                            key={'badge_' + index + need}
                            need={need}
                          />
                        ))}
                      </div>
                    </td>
                    <td>
                      <FormSelect
                        param={EXHP0}
                        value={hp.exchangers.ExHp0}
                        onChange={(value) => handleExHp0Change(value, hp.id)}
                        disabled={!EXHP0.enabled}
                      />
                    </td>
                    {Object.values(
                      getExchangersParams(hp.exchangers.ExHp0)
                    ).map((param) => {
                      if (!EXHP0.enabled)
                        return (
                          <td
                            className='empty'
                            key={'col_ex_' + param.key}
                          ></td>
                        );
                      const checked = hp.exchangers[param.key];
                      const warning = checkForExchangerWarning(
                        checked,
                        param.default
                      );
                      const checkboxId = 'hp_ex_' + index + param.key;
                      return (
                        <td key={'col_ex_' + param.key}>
                          <div
                            data-tooltip-content={
                              warning
                                ? checked
                                  ? i18n._('equipment.notChecked.warning')
                                  : i18n._('equipment.checked.warning')
                                : null
                            }
                            data-tooltip-id={'error_' + checkboxId}
                            style={{ width: 'fit-content' }}
                          >
                            <Form.Check
                              type='checkbox'
                              label={i18n._(
                                `hydraulicConfig.table.td.${param.key}`,
                                {
                                  position: hp.position
                                }
                              )}
                              value={param.key}
                              onChange={(evt) =>
                                handleExchangerChange(evt, hp.id)
                              }
                              checked={!!checked}
                              className={warning ? 'warning' : ''}
                              disabled={!param.enabled}
                              id={'hps_ex_' + param.key}
                            />
                            {warning &&
                              createPortal(
                                <Tooltip
                                  id={'error_' + checkboxId}
                                  place='bottom'
                                  className='error-tooltip'
                                  arrowColor='#f04460'
                                  opacity={1}
                                />,
                                document.body
                              )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </Section>
  );
  //#endregion
};

export default ExchangersSection;
