import { STORAGE_KEYS } from '../../../server/constants';
import { logout } from '../api/auth.api';
import axios from '../conf/axios.conf';

export const setupInterceptors = (navigate) => {
  axios.interceptors.response.use(
    (response) => {
      const newAccessToken = response.headers.authorization?.split(' ')[1];
      if (newAccessToken)
        localStorage.setItem(STORAGE_KEYS.ACCESS, newAccessToken);
      return response;
    },
    // AXIOS ERROR HANDLING
    async (error) => {
      console.error(error);
      if (error.response?.status === 401) {
        await logout();
        navigate('/login');
      }
      throw error;
    }
  );
};
