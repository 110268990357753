// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.substation-tabs-warning-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  font-size: 13px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}

.substation-tabs-warning-body > svg {
  font-size: 18px;
  color: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/containers/DescriptionsTabs/DescriptionTab/blocs/SubstationBloc/SubstationBloc.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,QAAQ;EACR,eAAe;EACf,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;EAC3B,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,2BAA2B;AAC7B","sourcesContent":[".substation-tabs-warning-body {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  gap: 5px;\n  font-size: 13px;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin-bottom: 10px;\n}\n\n.substation-tabs-warning-body > svg {\n  font-size: 18px;\n  color: var(--primary-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
