import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { PIPING } from '../../../../../../../../server/models/config/hydraulicEquipment/pumps/heatingPlant.model';
import FormInput from '../../../../../../components/Form/FormInput';
import FormSelect from '../../../../../../components/Form/FormSelect';
import Section from '../../../../../../components/Section/Section';
import { INPUT_TYPE } from '../../../../../../constants';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import { getDefaultValue } from '../../../../../../utils/param.utils';

const HpPipingSection = ({ onFormChange, addError, removeErrors }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const handleParamChange = (param, value) => {
    config.ConfigsSst[0].Data.pumps.heatingPlant.piping[param.key] = value;
    onFormChange(config);
  };
  //#endregion

  //#region [render]
  const { material, distanceToCollector, hpaExchangerHeadLoss } =
    config.ConfigsSst[0].Data.pumps.heatingPlant.piping;
  return (
    <>
      <Section
        title={i18n._('config.pumps.heatingPlant.piping.title')}
        level={2}
        open
      >
        <div className='config-form-row'>
          <FormSelect
            label={i18n._('config.pumps.heatingPlant.piping.material')}
            param={PIPING.material}
            bottomText={i18n._('param.defaultValue', {
              value: i18n._(`material.${PIPING.material.default}`)
            })}
            onChange={(value) => handleParamChange(PIPING.material, value)}
            value={material}
            className='config-form-input'
          />
          <FormInput
            label={i18n._(
              'config.pumps.heatingPlant.piping.distanceToCollector'
            )}
            value={distanceToCollector}
            param={PIPING.distanceToCollector}
            type={INPUT_TYPE.NUMBER}
            unit
            bottomText={getDefaultValue(i18n, PIPING.distanceToCollector)}
            onChange={(value) =>
              handleParamChange(PIPING.distanceToCollector, value)
            }
            addError={addError}
            removeError={() => removeErrors(1)}
            className='config-form-input'
          />
          <FormInput
            label={i18n._(
              'config.pumps.heatingPlant.piping.hpaExchangerHeadLoss'
            )}
            value={hpaExchangerHeadLoss}
            param={PIPING.hpaExchangerHeadLoss}
            type={INPUT_TYPE.NUMBER}
            unit
            bottomText={getDefaultValue(i18n, PIPING.hpaExchangerHeadLoss)}
            onChange={(value) =>
              handleParamChange(PIPING.hpaExchangerHeadLoss, value)
            }
            addError={addError}
            removeError={() => removeErrors(1)}
            className='config-form-input'
          />
        </div>
      </Section>
    </>
  );
  //#endregion
};

export default HpPipingSection;
