// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.breadcrumb a {
  color: var(--primary-color);
}

.breadcrumb a:hover {
  color: #011a39;
}

.breadcrumb span {
  color: gray;
}

.breadcrumb svg {
  font-size: 13px;
  margin: 0 2px 2px 0;
}

.breadcrumb > a:not(:last-child):after,
.breadcrumb > span:not(:last-child):after {
  content: attr(data-content);
  padding: 0 7px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Breadcrumb/Breadcrumb.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;AAC7B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;;EAEE,2BAA2B;EAC3B,cAAc;AAChB","sourcesContent":[".breadcrumb {\n  margin-bottom: 10px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n.breadcrumb a {\n  color: var(--primary-color);\n}\n\n.breadcrumb a:hover {\n  color: #011a39;\n}\n\n.breadcrumb span {\n  color: gray;\n}\n\n.breadcrumb svg {\n  font-size: 13px;\n  margin: 0 2px 2px 0;\n}\n\n.breadcrumb > a:not(:last-child):after,\n.breadcrumb > span:not(:last-child):after {\n  content: attr(data-content);\n  padding: 0 7px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
