import axios from '../conf/axios.conf';

//#region [GET]
export const isRedisReady = async () => {
  try {
    const response = await axios.get(`/redis/isReady`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getVersion = async () => {
  try {
    const response = await axios.get(`/redis/version`);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [POST]
export const connectRedis = async () => {
  try {
    const response = await axios.post(`/redis/connect`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const disconnectRedis = async () => {
  try {
    const response = await axios.post(`/redis/disconnect`);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion
