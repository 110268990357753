import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import {
  CALCULATION_STATUS,
  COMPUTE_STATUS,
  COMPUTE_TYPE
} from '../../../../../../../../server/constants';
import { fetchEngineDataSent } from '../../../../../../api/calculation.api';
import ExpandableCard from '../../../../../../components/ExpandableCard/ExpandableCard';
import PopupContext from '../../../../../../contexts/PopupContext';
import { BASIC_COLORS } from '../../../../../../styles/colors';
import { getComputeType } from '../../../../../../utils/compute.utils';
import { formatDateAndHour } from '../../../../../../utils/date.utils';
import { downloadFile } from '../../../../../../utils/file.utils';
import { getProjectIndex } from '../../../../../../utils/project.utils';
import ComputeCard from '../ComputeCard/ComputeCard';
import Dataviz from '../Dataviz/Dataviz';
import './CalculationCard.css';

const CalculationCard = ({ calculation, project }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { companyId, projectId } = useParams();
  //#endregion

  //#region [contexts]
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const handleDlDataSentClick = async (type, date, descName, projectId) => {
    try {
      const dataSent = await fetchEngineDataSent(calculation.id, projectId);
      const format = i18n._('calculation.dataSent.dateFormat');
      const filename = i18n._('calculation.dataSent.filename', {
        type,
        date: formatDateAndHour(i18n, date, format),
        descName
      });
      downloadFile(
        `${filename}.json`,
        new Blob([JSON.stringify(dataSent)], {
          type: 'text/json'
        })
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const cardTitle =
    calculation.status === CALCULATION_STATUS.IN_PROGRESS
      ? i18n._('calculation.dataviz.inProgress')
      : i18n._('calculation.dataviz.finished', {
          type: getComputeType(i18n, calculation.type),
          date: formatDateAndHour(i18n, calculation.created_at)
        });
  return (
    <ExpandableCard title={cardTitle} className='calculation-card'>
      {calculation.descriptions.map((desc, descIndex) => {
        let url;
        const successComputes = desc.computes.filter(
          (compute) => compute.Status === COMPUTE_STATUS.SUCCESS
        );
        if (successComputes.length > 1) {
          url = `/company/${companyId}/project/${projectId}/design/results/${successComputes[0].ComputeID}`;
          const ids = successComputes
            .slice(1)
            .map((compute) => compute.ComputeID);
          url += `?compare=${ids.join('&compare=')}`;
        }
        const descName =
          desc.name ??
          i18n._('description', {
            index: getProjectIndex(project, desc.projectId) + 1
          });
        const computesForDesc =
          desc.computes.length > 1
            ? i18n._('calculation.computesForDesc', {
                desc: descName,
                nbComputes: desc.computes.length
              })
            : i18n._('calculation.computeForDesc', {
                desc: descName
              });
        return (
          <div
            className='calculation-desc'
            key={'calculation_desc_' + desc.projectId}
          >
            <span className='calculation-desc-title'>{computesForDesc}</span>
            <div className='calculation-btns'>
              <Button
                variant='outline-secondary'
                onClick={async () =>
                  await handleDlDataSentClick(
                    getComputeType(i18n, calculation.type),
                    calculation.created_at,
                    descName,
                    desc.projectId
                  )
                }
              >
                <FontAwesomeIcon icon='download' />
                {i18n._('calculation.dataSent.dl')}
              </Button>
              {url && (
                <Link className='btn btn-outline-secondary' to={url}>
                  <FontAwesomeIcon icon='table' />
                  {i18n._('calculation.compare')}
                </Link>
              )}
            </div>
            {desc.computes.map((compute, compIndex) => (
              <ComputeCard
                compute={compute}
                key={'calculation_comp_' + compute.ComputeID}
                chartColor={
                  BASIC_COLORS[descIndex * desc.computes.length + compIndex]
                }
              />
            ))}
          </div>
        );
      })}
      {calculation.type === COMPUTE_TYPE.OPTI && (
        <Dataviz calculation={calculation} project={project} />
      )}
    </ExpandableCard>
  );
  //#endregion
};
export default CalculationCard;
