import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { getCollectorParams } from '../../../../../../../../server/models/config/hydraulicEquipment/pumps/heatingPlant.model';
import FormInput from '../../../../../../components/Form/FormInput';
import FormSelect from '../../../../../../components/Form/FormSelect';
import Section from '../../../../../../components/Section/Section';
import { INPUT_TYPE } from '../../../../../../constants';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import { getDefaultValue } from '../../../../../../utils/param.utils';

const CollectorsSection = ({ onFormChange, addError, removeErrors }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const handleParamChange = (param, value) => {
    config.ConfigsSst[0].Data.pumps.heatingPlant.collectors[param.key] = value;
    onFormChange(config);
  };

  const handleMaterialChange = (value) => {
    const PARAMS = getCollectorParams(value);
    config.ConfigsSst[0].Data.pumps.heatingPlant.collectors.material = value;
    config.ConfigsSst[0].Data.pumps.heatingPlant.collectors.coldSideNd =
      PARAMS.coldSideNd.default;
    config.ConfigsSst[0].Data.pumps.heatingPlant.collectors.heatSideNd =
      PARAMS.heatSideNd.default;
    onFormChange(config);
  };
  //#endregion

  //#region [render]
  const {
    material,
    coldSideNd,
    heatSideNd,
    distanceBetweenHps,
    distanceToDestination
  } = config.ConfigsSst[0].Data.pumps.heatingPlant.collectors;
  const PARAMS = getCollectorParams(material);
  return (
    <Section
      title={i18n._('config.pumps.heatingPlant.collectors.title')}
      level={2}
      open
    >
      <div className='config-form-row'>
        <FormSelect
          label={i18n._('config.pumps.heatingPlant.collectors.material')}
          param={PARAMS.material}
          bottomText={i18n._('param.defaultValue', {
            value: i18n._(`material.${PARAMS.material.default}`)
          })}
          onChange={(value) => handleMaterialChange(value)}
          value={material}
          className='config-form-input'
        />
        <FormSelect
          label={i18n._('config.pumps.heatingPlant.collectors.coldSideNd')}
          param={PARAMS.coldSideNd}
          bottomText={i18n._('config.pumps.heatingPlant.collectors.nd.info')}
          onChange={(value) => handleParamChange(PARAMS.coldSideNd, value)}
          value={coldSideNd}
          className='config-form-input'
        />
        <FormSelect
          label={i18n._('config.pumps.heatingPlant.collectors.heatSideNd')}
          param={PARAMS.heatSideNd}
          bottomText={i18n._('config.pumps.heatingPlant.collectors.nd.info')}
          onChange={(value) => handleParamChange(PARAMS.heatSideNd, value)}
          value={heatSideNd}
          className='config-form-input'
        />
      </div>
      <div className='config-form-row'>
        <FormInput
          label={i18n._(
            'config.pumps.heatingPlant.collectors.distanceBetweenHps'
          )}
          value={distanceBetweenHps}
          param={PARAMS.distanceBetweenHps}
          type={INPUT_TYPE.NUMBER}
          unit
          bottomText={getDefaultValue(i18n, PARAMS.distanceBetweenHps)}
          onBlur={(value) =>
            handleParamChange(PARAMS.distanceBetweenHps, value)
          }
          addError={addError}
          removeError={() => removeErrors(1)}
          className='config-form-input'
        />
        <FormInput
          label={i18n._(
            'config.pumps.heatingPlant.collectors.distanceToDestination'
          )}
          value={distanceToDestination}
          param={PARAMS.distanceToDestination}
          type={INPUT_TYPE.NUMBER}
          unit
          bottomText={getDefaultValue(i18n, PARAMS.distanceToDestination)}
          onBlur={(value) =>
            handleParamChange(PARAMS.distanceToDestination, value)
          }
          addError={addError}
          removeError={() => removeErrors(1)}
          className='config-form-input'
        />
      </div>
    </Section>
  );
  //#endregion
};

export default CollectorsSection;
