import { useLingui } from '@lingui/react';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { getColdProductionParams } from '../../../../../../../server/models/design/description.model';
import ParamInput from '../../../../../components/ParamForm/ParamInput';
import Section from '../../../../../components/Section/Section';
import TileCheckbox from '../../../../../components/Tile/TileCheckbox';
import { INPUT_TYPE } from '../../../../../constants';
import hpaImg from '../../../../../image/equipment-hpa.png';
import hpgImg from '../../../../../image/equipment-hpg.png';
import './ColdProductionSection.css';

const ColdProductionSection = ({
  form,
  onInputChange,
  onTemperatureColdBackChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  const coldProductionParams = getColdProductionParams(
    form.InitTemperatureCold
  );
  const noEquipment =
    !form.InitHPGCoolingEnabled && !form.InitHPACoolingEnabled;
  return (
    <Section
      title={i18n._('description.coldProduction')}
      open
      className='description-production-section'
    >
      <div className='description-production-regimes'>
        <h2>{i18n._('description.coldProduction.regimes')}</h2>
        <div className='description-production-regimes-need-row'>
          <span className='param-input-label'>
            {i18n._('description.coldProduction.regimes.cold')}
          </span>
          <div>
            <ParamInput
              value={form.InitTemperatureCold}
              onChange={(value) => onInputChange('InitTemperatureCold', value)}
              param={coldProductionParams.InitTemperatureCold}
              unit
              type={INPUT_TYPE.NUMBER}
            />
            <ParamInput
              value={form.InitTemperatureColdBack}
              onChange={onTemperatureColdBackChange}
              param={coldProductionParams.InitTemperatureColdBack}
              unit
              type={INPUT_TYPE.NUMBER}
            />
          </div>
          <span className='param-input-small-text'>
            {i18n._('description.heatProduction.regimes.cold.default', {
              InitTemperatureCold:
                coldProductionParams.InitTemperatureCold.default,
              InitTemperatureColdBack:
                coldProductionParams.InitTemperatureColdBack.default
            })}
          </span>
        </div>
      </div>
      <div>
        <h2>{i18n._('description.coldProduction.equipment')}</h2>
        <div className='description-production-equipment-row'>
          {form.InitBtesEnabled && (
            <TileCheckbox
              checked={form.InitHPGCoolingEnabled}
              image={hpgImg}
              onChange={(checked) =>
                onInputChange('InitHPGCoolingEnabled', checked)
              }
              title={i18n._('description.InitHPGCoolingEnabled')}
            />
          )}
          <TileCheckbox
            checked={form.InitHPACoolingEnabled}
            image={hpaImg}
            onChange={(checked) =>
              onInputChange('InitHPACoolingEnabled', checked)
            }
            title={i18n._('description.InitHPACoolingEnabled')}
            disabled={form.ItesEnabled}
          />
        </div>
        {noEquipment && (
          <Alert variant='danger' className='description-equipment-alert'>
            {i18n._('description.error.noEquipment')}
          </Alert>
        )}
        {form.InitBtesEnabled &&
          !form.InitHPGHeatingEnabled &&
          !form.InitHPGCoolingEnabled && (
            <Alert variant='danger' className='description-equipment-alert'>
              {i18n._('description.error.noHpgEquipment')}
            </Alert>
          )}
      </div>
    </Section>
  );
  //#endregion
};

export default React.memo(ColdProductionSection);
