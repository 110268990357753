const {
  DST_TEMP_DELTA,
  HP_TYPE,
  NEED,
  UNIT
} = require('../../../../constants');
const { getDefaultValues } = require('../../../../utils/param.utils');

const HEAT_HPA = Object.freeze({
  outsideTemp: {
    key: 'outsideTemp',
    default: 3,
    required: true,
    unit: UNIT.CELSIUS_DEGREE
  }
});

const COLD_HPA = Object.freeze({
  outsideTemp: {
    key: 'outsideTemp',
    default: 25,
    required: true,
    unit: UNIT.CELSIUS_DEGREE
  }
});

const DHW_HPA = Object.freeze({
  outsideTemp: {
    key: 'outsideTemp',
    default: 3,
    required: true,
    unit: UNIT.CELSIUS_DEGREE
  }
});

const HPA = {
  [NEED.HEAT]: { ...HEAT_HPA },
  [NEED.COLD]: { ...COLD_HPA },
  [NEED.DHW]: { ...DHW_HPA }
};

const HEAT_HPG_BTES = {
  srcTemp: {
    key: 'srcTemp',
    default: 5,
    required: true,
    unit: UNIT.CELSIUS_DEGREE
  },
  srcTempDelta: {
    key: 'srcTempDelta',
    default: 4,
    required: true,
    unit: UNIT.KELVIN
  }
};

const COLD_HPG_BTES = {
  srcTemp: {
    key: 'srcTemp',
    default: 35,
    required: true,
    unit: UNIT.CELSIUS_DEGREE
  },
  srcTempDelta: {
    key: 'srcTempDelta',
    default: 5,
    required: true,
    unit: UNIT.KELVIN
  }
};

const DHW_HPG_BTES = {
  srcTemp: {
    key: 'srcTemp',
    default: 5,
    required: true,
    unit: UNIT.CELSIUS_DEGREE
  },
  srcTempDelta: {
    key: 'srcTempDelta',
    default: 4,
    required: true,
    unit: UNIT.KELVIN
  }
};

const BTES_HPG = {
  [NEED.HEAT]: { ...HEAT_HPG_BTES },
  [NEED.COLD]: { ...COLD_HPG_BTES },
  [NEED.DHW]: { ...DHW_HPG_BTES }
};

// les points de fonctionnement dimensionnants
const getDefaultCriticalPoints = (services, hpsTypes) => {
  return services.needs.reduce((acc, need) => {
    acc[need] = {};

    // PAC aéro
    if (hpsTypes.includes(HP_TYPE.AERO)) {
      acc[need].hpa = getDefaultValues(HPA[need]);
    }

    // PAC géo, côté BTES
    if (hpsTypes.includes(HP_TYPE.GEO)) {
      const srcTemp = BTES_HPG[need].srcTemp.default;
      const srcTempDelta = BTES_HPG[need].srcTempDelta.default;
      const srcTempOut =
        need === NEED.COLD ? srcTemp + srcTempDelta : srcTemp - srcTempDelta;
      acc[need].hpgBtes = { srcTemp, srcTempOut, srcTempDelta };
    }

    const { temperature, temperatureBack } = services[need];
    let dstTempIn, dstTemp, dstTempDelta;
    if (need === NEED.COLD) {
      dstTempIn = temperatureBack - 1;
      dstTemp = temperature - 1;
      dstTempDelta = temperatureBack - temperature;
    } else if (need === NEED.HEAT) {
      dstTempIn = temperatureBack + 1;
      dstTemp = temperature + 1;
      dstTempDelta = temperature - temperatureBack;
    } else if (need === NEED.DHW) {
      dstTemp = temperature + 1;
      dstTempDelta = DST_TEMP_DELTA;
      dstTempIn = dstTemp - dstTempDelta;
    }
    // PAC aéro/géo, côté production
    acc[need].hpProd = { dstTempIn, dstTemp, dstTempDelta };

    // Distribution bâtiment
    acc[need].buildingDistribution = { temperature, temperatureBack };

    // débits max
    acc[need].flowRateMax = { heatSide: 0, coldSide: 0 };
    return acc;
  }, {});
};

module.exports = {
  HPA,
  BTES_HPG,
  getDefaultCriticalPoints
};
