import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useState } from 'react';
import { getTableValue } from '../../../../../../../../utils/data.utils';
import './StdTable.css';

const StdTable = ({ template }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [unfolded, setUnfolded] = useState(false);
  //#endregion

  //#region [render]
  return (
    <table className='template-table descriptions-table'>
      <thead>
        <tr>
          <th></th>
          {template.descriptions.map((description, i) => (
            <th
              key={'std_th_desc_' + i}
              title={description || i18n._('description', { index: i + 1 })}
            >
              {description || i18n._('description', { index: i + 1 })}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr className='std-table-first-row'>
          <td
            className='table-label cursor-pointer'
            onClick={() => setUnfolded(!unfolded)}
          >
            <span>
              <FontAwesomeIcon
                icon={unfolded ? 'caret-square-down' : 'caret-square-right'}
              />
              {i18n._('description.std')}
            </span>
          </td>
          {template.files.map((file, i) => (
            <td
              key={'std_td_filename_' + i}
              title={file}
              className='descriptions-table-value'
            >
              {file}
            </td>
          ))}
        </tr>
        {unfolded &&
          template.rows.map((row, rowIndex) => (
            <tr key={'std_tr_' + rowIndex}>
              <td className='table-label'>{i18n._(row.label)}</td>
              {row.values.map((value, colIndex) => (
                <td
                  key={'description_td_' + rowIndex + '_' + colIndex}
                  className='descriptions-table-value'
                >
                  {getTableValue(i18n, value, row.unit)}
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  );
  //#endregion
};

export default StdTable;
