import { useLingui } from '@lingui/react';
import React from 'react';
import { Form } from 'react-bootstrap';
import Bloc from '../../../../components/Bloc/Bloc';

const GeostorageBloc = ({ form, formIndex, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('description.geostorage')}>
      <div>
        <Form.Check
          type='checkbox'
          label={i18n._('description.InitLoopIsUsed')}
          checked={!!form.InitLoopIsUsed}
          onChange={(evt) =>
            onInputChange('InitLoopIsUsed', evt.target.checked)
          }
          disabled={!form.InitBtesEnabled}
          name='InitLoopIsUsed'
          id={'InitLoopIsUsed_' + formIndex}
          className='mb-2'
        />
        <Form.Check
          type='checkbox'
          label={i18n._('description.InitLoopWaterType')}
          checked={form.InitLoopWaterType === 'B'}
          onChange={(evt) =>
            onInputChange('InitLoopWaterType', evt.target.checked ? 'B' : 'W')
          }
          disabled={!form.InitBtesEnabled}
          name='InitLoopWaterType'
          id={'InitLoopWaterType_' + formIndex}
          className='mb-2'
        />
        <Form.Check
          type='checkbox'
          label={i18n._('description.InitHPAInjectionEnabled')}
          checked={!!form.InitHPAInjectionEnabled}
          onChange={(evt) =>
            onInputChange('InitHPAInjectionEnabled', evt.target.checked)
          }
          disabled={!form.InitBtesEnabled}
          name='InitHPAInjectionEnabled'
          id={'InitHPAInjectionEnabled_' + formIndex}
        />
      </div>
    </Bloc>
  );
  //#endregion
};

export default GeostorageBloc;
