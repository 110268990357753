import { useLingui } from '@lingui/react';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { getCompareResultName } from '../../utils/compute.utils';
import Card from '../Card/Card';
import './ResultsCards.css';

const ResultsCards = ({ element, elementProps, comparedResults, ...props }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [visibilities, setVisibilities] = useState(
    [true].concat(new Array(comparedResults.length - 1).fill(false))
  );
  //#endregion

  //#region [methods]
  const handleBtnClick = (index) => {
    setVisibilities((visibilities) => {
      visibilities[index] = !visibilities[index];
      return [...visibilities];
    });
  };
  //#endregion

  //#region [render]
  let Element = element;
  return (
    <div className='results-cards-wrapper'>
      {comparedResults.length > 1 && (
        <div className='results-btns'>
          {comparedResults.map((_, index) => (
            <Button
              key={'btn_' + props.title + '_' + index}
              onClick={() => handleBtnClick(index)}
              variant={visibilities[index] ? 'primary' : 'outline-secondary'}
              disabled={
                visibilities[index] &&
                visibilities.filter((v) => v).length === 1
              }
            >
              {i18n._('result', { index: index + 1 })}
            </Button>
          ))}
        </div>
      )}
      <div className='results-cards'>
        {comparedResults.map((result, index) => {
          return visibilities[index] ? (
            <Card
              key={'child_' + props.title + '_' + index}
              title={
                comparedResults.length > 1
                  ? getCompareResultName(i18n, result.Comment, index)
                  : null
              }
            >
              <Element {...elementProps} result={result} />
            </Card>
          ) : null;
        })}
      </div>
    </div>
  );
  //#endregion
};

export default ResultsCards;
