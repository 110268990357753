import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { HP_TYPE, NEED } from '../../../../../../../../../server/constants';
import {
  HPA_BTES,
  HPA_P_COLD,
  HPA_P_HEAT
} from '../../../../../../../../../server/models/config/thermalProduction//heatpumps/settings.model';
import FormInput from '../../../../../../../components/Form/FormInput';
import Section from '../../../../../../../components/Section/Section';
import { INPUT_TYPE } from '../../../../../../../constants';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import './HpaSection.css';

const HpaSection = ({ onFormChange, addError, removeErrors }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [refs]
  const nbErrors = useRef(0);
  //#endregion

  //#region [effects]
  useEffect(() => {
    return () => {
      if (nbErrors.current !== 0) removeErrors(nbErrors.current);
    };
  }, []);
  //#endregion

  //#region [methods]
  const handleAddError = () => {
    nbErrors.current++;
    addError();
  };

  const handleRemoveError = () => {
    nbErrors.current--;
    removeErrors(1);
  };

  const handleParamChange = (param, value) => {
    config.ConfigsSst[0].Data.heatpumps.settings[HP_TYPE.AERO][param.key] =
      value;
    onFormChange(config);
  };
  //#endregion

  //#region [render]
  const { services, heatpumps } = config.ConfigsSst[0].Data;
  return (
    <Section title={i18n._('config.hps.settings.hpa.title')} level={2} open>
      <div className='config-form-row'>
        {(services.needs.includes(NEED.HEAT) ||
          services.needs.includes(NEED.DHW)) && (
          <FormInput
            value={heatpumps.settings[HP_TYPE.AERO].pHeat}
            param={HPA_P_HEAT.pHeat}
            type={INPUT_TYPE.NUMBER}
            onChange={(value) => handleParamChange(HPA_P_HEAT.pHeat, value)}
            unit
            label={i18n._('config.hps.settings.pHeat')}
            addError={handleAddError}
            removeError={handleRemoveError}
            className='config-form-input'
          />
        )}
        {services.needs.includes(NEED.COLD) && (
          <FormInput
            value={heatpumps.settings[HP_TYPE.AERO].pCold}
            param={HPA_P_COLD.pCold}
            type={INPUT_TYPE.NUMBER}
            onChange={(value) => handleParamChange(HPA_P_COLD.pCold, value)}
            unit
            label={i18n._('config.hps.settings.pCold')}
            addError={handleAddError}
            removeError={handleRemoveError}
            className='config-form-input'
          />
        )}
        <Form.Check
          type='switch'
          label={i18n._('config.hps.settings.hpa.btesInjection')}
          checked={heatpumps.settings[HP_TYPE.AERO].btesInjection}
          onChange={(evt) =>
            handleParamChange(HPA_BTES.btesInjection, evt.target.checked)
          }
          className='hpa-section-check'
        />
      </div>
    </Section>
  );
  //#endregion
};

export default HpaSection;
