import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { TAG } from '../../../../../../../../../server/constants';
import { fetchComputeIdByTag } from '../../../../../../../api/project.api';
import SelectBtn from '../../../../../../../components/SelectBtn/SelectBtn';
import PopupContext from '../../../../../../../contexts/PopupContext';
import './Tags.css';

const Tags = ({ result, onTagAdd, onTagRemove, onTagReplace }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { projectId } = useParams();
  //#endregion

  //#region [contexts]
  const { openConfirmModal } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const handleTagChange = async (tag) => {
    result.tags[tag.key]
      ? await onTagRemove(result.ComputeID, tag)
      : await onTagAdd(result.ComputeID, tag);
  };

  const handleUniqueTagChange = async (tag) => {
    if (result.tags[tag.key]) {
      await onTagRemove(result.ComputeID, tag);
    } else {
      // on vérifie que l'un des calculs ne possède déjà pas le tag
      const computeId = await fetchComputeIdByTag(projectId, tag.value);
      if (!computeId) {
        // il n'existe pas de calcul avec ce tag, on l'ajoute
        await onTagAdd(result.ComputeID, tag);
      } else {
        // il existe déjà un calcul avec ce tag, on demande à l'utilisateur si il est sûr de vouloir le remplacer
        openConfirmModal(
          i18n._('results.general.tagModal.title', {
            tag: i18n._(`tag.${tag.key}`)
          }),
          i18n._('results.general.tagModal.body', {
            tag: i18n._(`tag.${tag.key}`)
          }),
          'info',
          async () => {
            await onTagReplace(result.ComputeID, computeId, tag);
          }
        );
      }
    }
  };
  //#endregion

  //#region [render]
  return (
    <div className='results-tags'>
      <SelectBtn
        selected={result.tags[TAG.FEASIBILITY.key]}
        color='#62afdc'
        onClick={async () => await handleTagChange(TAG.FEASIBILITY)}
      >
        {i18n._('tag.feasibility')}
      </SelectBtn>
      <SelectBtn
        selected={result.tags[TAG.PROJECT.key]}
        color='#828282'
        onClick={async () => await handleUniqueTagChange(TAG.PROJECT)}
      >
        {i18n._('tag.project')}
      </SelectBtn>
      <SelectBtn
        selected={result.tags[TAG.CONTRACT_COMMITMENT.key]}
        color='#60c088'
        onClick={async () =>
          await handleUniqueTagChange(TAG.CONTRACT_COMMITMENT)
        }
      >
        {i18n._('tag.contractCommitment')}
      </SelectBtn>
    </div>
  );
  //#endregion
};

export default Tags;
