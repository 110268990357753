import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { LOCATION } from '../../../../../server/models/design/general.model';
import { INPUT_TYPE } from '../../../constants';
import Bloc from '../../../components/Bloc/Bloc';
import ParamInput from '../../../components/ParamForm/ParamInput';
import ParamSelect from '../../../components/ParamForm/ParamSelect';

const LocationBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('general.projectLocation')}>
      <ParamInput
        label={i18n._('general.AhsAddress1')}
        value={form.AhsAddress1 ?? ''}
        onChange={(value) => onInputChange('AhsAddress1', value)}
        param={LOCATION.AhsAddress1}
        type={INPUT_TYPE.TEXT}
        notDefaultValueHilighted={false}
      />
      <Row>
        <Col>
          <ParamInput
            label={i18n._('general.AhsZipCode')}
            value={form.AhsZipCode ?? ''}
            onChange={(value) => onInputChange('AhsZipCode', value)}
            param={LOCATION.AhsZipCode}
            type={INPUT_TYPE.TEXT}
            notDefaultValueHilighted={false}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('general.AhsCity')}
            value={form.AhsCity ?? ''}
            onChange={(value) => onInputChange('AhsCity', value)}
            param={LOCATION.AhsCity}
            type={INPUT_TYPE.TEXT}
            notDefaultValueHilighted={false}
          />
        </Col>
      </Row>
      <ParamSelect
        label={i18n._('general.AhsCountry')}
        value={form.AhsCountry ?? ''}
        onChange={(value) => onInputChange('AhsCountry', value)}
        param={LOCATION.AhsCountry}
        notDefaultValueHilighted={false}
      />
      <Row>
        <Col>
          <ParamInput
            label={i18n._('general.InitLatitude')}
            value={form.InitLatitude ?? ''}
            onChange={(value) => onInputChange('InitLatitude', value)}
            param={LOCATION.InitLatitude}
            type={INPUT_TYPE.TEXT}
            notDefaultValueHilighted={false}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('general.InitLongitude')}
            value={form.InitLongitude ?? ''}
            onChange={(value) => onInputChange('InitLongitude', value)}
            param={LOCATION.InitLongitude}
            type={INPUT_TYPE.TEXT}
            notDefaultValueHilighted={false}
          />
        </Col>
      </Row>
      <ParamInput
        label={i18n._('general.InitAltitude')}
        value={form.InitAltitude}
        onChange={(value) => onInputChange('InitAltitude', value)}
        param={LOCATION.InitAltitude}
        unit
        type={INPUT_TYPE.NUMBER}
        notDefaultValueHilighted={false}
      />
    </Bloc>
  );
  //#endregion
};

export default LocationBloc;
