import { cloneDeep } from 'lodash';
import { useContext, useEffect } from 'react';
import { FORM_STATUS } from '../constants';
import ConfigsContext from '../contexts/ConfigsContext';
import PopupContext from '../contexts/PopupContext';
import history from '../navigation/history';

const useUnsavedChanges = (formStatus, formErrors, save) => {
  //#region [contexts]
  const { savedConfig, setConfig } = useContext(ConfigsContext);
  const { openDirtyFormModal, openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [effects]
  useEffect(() => {
    let unblock = history.block((tx) => {
      if (
        formStatus !== FORM_STATUS.DIRTY ||
        formErrors ||
        tx.location?.state?.deletedConfig
      ) {
        // le formulaire n'a pas été changé, il présente des erreurs, ou la config à été supprimée : on ne propose pas à l'utilisateur d'enregistrer son formulaire avant de quitter la page
        if (formErrors) {
          setConfig(() => cloneDeep(savedConfig));
        }
        unblock();
        tx.retry();
      } else {
        // le formulaire a été changé et ne présente pas d'erreur : on propose à l'utilisateur d'enregistrer son formulaire avant de quitter la page
        openDirtyFormModal(
          // si l'utilisateur clique sur "ne pas enregistrer" alors on débloque la situation
          () => {
            setConfig(() => cloneDeep(savedConfig));
            unblock();
            tx.retry();
          },
          // si l'utilisateur clique sur "enregistrer" alors enregistre puis on débloque la situation
          async () => {
            try {
              await save();
              unblock();
              tx.retry();
            } catch (err) {
              console.error(err);
              openErrorToast(err);
            }
          }
        );
      }
    });
    return () => {
      unblock();
    };
  }, [formStatus, formErrors]);
  //#endregion
};

export default useUnsavedChanges;
