import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import ResultsTable from '../../../../../../../components/TemplateTables/ResultsTable/ResultsTable';
import PopupContext from '../../../../../../../contexts/PopupContext';
import {
  nonComplianceUserPenaltiesTemplate,
  penaltiesTemplate,
  physicalConstraintsTemplate
} from '../../templates';
import './PenaltiesTable.css';

const PenaltiesTable = ({ comparedResults }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [memos]
  const PCTemplate = useMemo(() => {
    return physicalConstraintsTemplate(i18n, comparedResults);
  }, [comparedResults]);

  const NCUPTemplate = useMemo(() => {
    return nonComplianceUserPenaltiesTemplate(i18n, comparedResults);
  }, [comparedResults]);

  const PTemplate = useMemo(() => {
    return penaltiesTemplate(i18n, comparedResults);
  }, [comparedResults]);
  //#endregion

  //#region [render]
  return (
    <div className='penalties-table'>
      <h2>
        {i18n._('results.simulationParams.penalties')}
        <FontAwesomeIcon
          icon='circle-question'
          className='modal-info-icon'
          onClick={() =>
            openInfoModal(
              i18n._('results.general.penalties.modal.title'),
              i18n._('results.general.penalties.modal.body')
            )
          }
        />
      </h2>
      <h3>
        {i18n._('results.general.penalties.physicalConstraintsPenalties')}
      </h3>
      {PCTemplate && PCTemplate.rows.length > 0 ? (
        <ResultsTable
          level={3}
          template={PCTemplate}
          comparedResults={comparedResults}
        />
      ) : (
        <Alert variant='info'>
          {i18n._(
            'results.general.penalties.physicalConstraintsPenalties.noPenalties'
          )}
        </Alert>
      )}
      <h3>
        {i18n._('results.general.penalties.nonComplianceObjUserPenalties')}
      </h3>
      {NCUPTemplate ? (
        <ResultsTable
          level={3}
          template={NCUPTemplate}
          comparedResults={comparedResults}
        />
      ) : (
        <Alert variant='info'>
          {i18n._(
            'results.general.penalties.nonComplianceObjUserPenalties.noPenalties'
          )}
        </Alert>
      )}
      {PTemplate && (
        <ResultsTable
          level={3}
          template={PTemplate}
          comparedResults={comparedResults}
        />
      )}
    </div>
  );
  //#endregion
};

export default PenaltiesTable;
