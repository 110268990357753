import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { HP_TYPE } from '../../../../../../../../server/constants';
import {
  getDefaultRegimesValues,
  getDefaultSettingsValues
} from '../../../../../../../../server/models/config/thermalProduction/heatpumps/settings.model';
import Section from '../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';

const HpsTypesSection = ({ onFormChange, removeErrors }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const handleTypeChange = (evt) => {
    const { value, checked } = evt.target;
    const { services, heatpumps } = config.ConfigsSst[0].Data;
    const { settings } = heatpumps;
    if (checked) {
      settings.types = [...settings.types, value];
      settings[value] = { ...getDefaultSettingsValues(services.needs)[value] };
      services.needs.forEach((need) => {
        settings.regimes[need][value] = {
          ...getDefaultRegimesValues(need, services)[value]
        };
      });
    } else {
      settings.types = settings.types.filter((hp) => hp !== value);
      delete settings[value];
      services.needs.forEach((need) => {
        delete settings.regimes[need][value];
      });
      // on enlève les erreurs liées aux régimes dimmensionnants concernés
      removeErrors(value);
    }
    onFormChange(config);
  };
  //#endregion

  //#region [render]
  const { types } = config.ConfigsSst[0].Data.heatpumps.settings;
  return (
    <Section title={i18n._('config.hps.settings.heatpumps')} level={2} open>
      {types.length === 0 && (
        <p className='settings-noHp'>{i18n._('config.hps.settings.noHp')}</p>
      )}
      <div className='config-form-row'>
        <div className='config-form-column'>
          <p className='form-input-label'>
            {i18n._('config.hps.settings.hpTypes')}
          </p>
          {Object.values(HP_TYPE).map((type) => (
            <Form.Check
              key={'hp_' + type}
              type='switch'
              label={i18n._(`heatpump.${type}`)}
              onChange={handleTypeChange}
              value={type}
              checked={types.includes(type)}
            />
          ))}
        </div>
      </div>
    </Section>
  );
  //#endregion
};

export default HpsTypesSection;
