import { useLingui } from '@lingui/react';
import React, { useContext, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ELEC_OPEX } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import ParamSelect from '../../../../components/ParamForm/ParamSelect';
import { INPUT_TYPE } from '../../../../constants';
import PopupContext from '../../../../contexts/PopupContext';
import {
  getElecEvols,
  getNextCompassYear
} from '../../../../utils/compass.utils';
import { getDefaultValue } from '../../../../utils/param.utils';

const ElecBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [refs]
  const evols = useRef(getElecEvols(i18n).join('\n'));
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('cost.elec')} level={2}>
      <Row>
        <Col>
          <ParamSelect
            label={i18n._('cost.InitElecPriceEvolMethod')}
            value={form.InitElecPriceEvolMethod}
            param={ELEC_OPEX.InitElecPriceEvolMethod}
            onChange={(value) =>
              onInputChange('InitElecPriceEvolMethod', value)
            }
            onInfoClick={() =>
              openInfoModal(
                i18n._('elecPriceEvolMethod.title'),
                i18n._('elecPriceEvolMethod.body', { evols: evols.current })
              )
            }
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitElecPriceEvol')}
            value={form.InitElecPriceEvol}
            param={ELEC_OPEX.InitElecPriceEvol}
            onChange={(value) => onInputChange('InitElecPriceEvol', value)}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '-15px' }}>
        <Col></Col>
        <Col>
          <span className='param-input-small-text'>
            {i18n._(
              `cost.InitElecPriceEvol.bottom.${form.InitElecPriceEvolMethod}`,
              {
                year: getNextCompassYear(),
                default: ELEC_OPEX.InitElecPriceEvol.default
              }
            )}
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitPVElecToNetworkPriceEvol')}
            value={form.InitPVElecToNetworkPriceEvol}
            param={ELEC_OPEX.InitPVElecToNetworkPriceEvol}
            onChange={(value) =>
              onInputChange('InitPVElecToNetworkPriceEvol', value)
            }
            unit
            bottomText={getDefaultValue(
              i18n,
              ELEC_OPEX.InitPVElecToNetworkPriceEvol
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitPVElecToProjectPriceEvol')}
            value={form.InitPVElecToProjectPriceEvol}
            param={ELEC_OPEX.InitPVElecToProjectPriceEvol}
            onChange={(value) =>
              onInputChange('InitPVElecToProjectPriceEvol', value)
            }
            unit
            bottomText={getDefaultValue(
              i18n,
              ELEC_OPEX.InitPVElecToProjectPriceEvol
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default ElecBloc;
