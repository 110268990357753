import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { GEOCOOLING } from '../../../../../../server/models/design/performance.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../constants';
import { getDefaultValue } from '../../../../utils/param.utils';

const GeocoolingBloc = ({
  form,
  onInputChange,
  onGeocoolingEnabledChange,
  onGeocoolingDisabledClimChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('performance.geocooling')}>
      <Row>
        <Col>
          <Form.Check
            type='switch'
            label={i18n._('performance.InitGeocoolingEnabled')}
            checked={form.InitGeocoolingEnabled}
            onChange={onGeocoolingEnabledChange}
            name='InitGeocoolingEnabled'
            id='InitGeocoolingEnabled'
          />
        </Col>
        <Col>
          <Form.Check
            type='switch'
            label={i18n._('performance.InitGeocoolingDisableClim')}
            checked={form.InitGeocoolingDisableClim}
            onChange={onGeocoolingDisabledClimChange}
            name='InitGeocoolingDisableClim'
            id='InitGeocoolingDisableClim'
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.InitGeocoolingTempOutBtesMax')}
            value={form.InitGeocoolingTempOutBtesMax}
            param={GEOCOOLING.InitGeocoolingTempOutBtesMax}
            onChange={(value) =>
              onInputChange('InitGeocoolingTempOutBtesMax', value)
            }
            bottomText={getDefaultValue(
              i18n,
              GEOCOOLING.InitGeocoolingTempOutBtesMax
            )}
            unit
            disabled={!form.InitGeocoolingEnabled}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('performance.InitGeocoolingPumpConsumptionPercent')}
            value={form.InitGeocoolingPumpConsumptionPercent}
            param={GEOCOOLING.InitGeocoolingPumpConsumptionPercent}
            onChange={(value) =>
              onInputChange('InitGeocoolingPumpConsumptionPercent', value)
            }
            bottomText={getDefaultValue(
              i18n,
              GEOCOOLING.InitGeocoolingPumpConsumptionPercent
            )}
            unit
            disabled={!form.InitGeocoolingDisableClim}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default React.memo(GeocoolingBloc);
