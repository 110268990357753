const { COUNTRY } = require('../constants');

const USER = Object.freeze({
  UserFirstName: {
    key: 'UserFirstName',
    type: 'string',
    required: true,
    default: null,
    pattern: /^[A-Za-zÀ-ÖØ-öø-ÿ\s-]{3,50}$/
  },
  UserLastName: {
    key: 'UserLastName',
    type: 'string',
    required: true,
    default: null,
    pattern: /^[A-Za-zÀ-ÖØ-öø-ÿ\s-]{3,50}$/
  },
  UserEmail: {
    key: 'UserEmail',
    type: 'string',
    required: true,
    default: null,
    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  },
  UserPassword: {
    key: 'UserPassword',
    type: 'string',
    required: true,
    default: null,
    pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
  },
  UserJobTitle: {
    key: 'UserJobTitle',
    type: 'string',
    required: true,
    default: null,
    maxLength: 255
  },
  UserCountry: {
    key: 'UserCountry',
    required: true,
    default: 'FR',
    values: Object.keys(COUNTRY),
    translateValuesIds: Object.keys(COUNTRY).map(
      (country) => `country.${country}`
    ),
    type: 'string'
  },
  IsSuperAdmin: {
    key: 'IsSuperAdmin',
    type: 'boolean',
    default: false,
    required: true
  }
});

const EMPTY_USER = {
  UserFirstName: USER.UserFirstName.default,
  UserLastName: USER.UserLastName.default,
  UserEmail: USER.UserEmail.default,
  UserPassword: USER.UserPassword.default,
  UserJobTitle: USER.UserJobTitle.default,
  UserCountry: USER.UserCountry.default,
  IsSuperAdmin: USER.IsSuperAdmin.default
};

module.exports = { USER, EMPTY_USER };
