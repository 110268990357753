// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recursive-prop-value {
    overflow-x: hidden;
    text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/result/sections/simulationParams/components/RecursiveProperty/RecursiveProperty.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,uBAAuB;AAC3B","sourcesContent":[".recursive-prop-value {\n    overflow-x: hidden;\n    text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
