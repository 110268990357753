const { SOIL, BTES, BTES_TEST } = require('../models/design/geology.model');

const GEOLOGY_SCHEMA = {
  type: 'object',
  properties: {
    [BTES_TEST.InitTrtChecked.key]: {
      type: BTES_TEST.InitTrtChecked.type
    },
    [BTES_TEST.InitBtesTestProbeLength.key]: {
      type: BTES_TEST.InitBtesTestProbeLength.type,
      minimum: BTES_TEST.InitBtesTestProbeLength.min,
      maximum: BTES_TEST.InitBtesTestProbeLength.max
    },
    [SOIL.InitSoilThermalConductivity.key]: {
      type: SOIL.InitSoilThermalConductivity.type,
      minimum: SOIL.InitSoilThermalConductivity.min,
      maximum: SOIL.InitSoilThermalConductivity.max
    },
    [SOIL.InitSoilVolumeHeatCapacity.key]: {
      type: SOIL.InitSoilVolumeHeatCapacity.type,
      minimum: SOIL.InitSoilVolumeHeatCapacity.min,
      maximum: SOIL.InitSoilVolumeHeatCapacity.max
    },
    [SOIL.InitSoilTemperature10m.key]: {
      type: SOIL.InitSoilTemperature10m.type,
      minimum: SOIL.InitSoilTemperature10m.min,
      maximum: SOIL.InitSoilTemperature10m.max
    },
    [BTES.InitBtesTemperatureMin.key]: {
      type: BTES.InitBtesTemperatureMin.type,
      minimum: BTES.InitBtesTemperatureMin.min,
      maximum: BTES.InitBtesTemperatureMin.max
    },
    [BTES.InitBtesTemperatureMax.key]: {
      type: BTES.InitBtesTemperatureMax.type,
      minimum: BTES.InitBtesTemperatureMax.min,
      maximum: BTES.InitBtesTemperatureMax.max
    },
    [BTES.InitBtesTemperatureDelta.key]: {
      type: BTES.InitBtesTemperatureDelta.type,
      minimum: BTES.InitBtesTemperatureDelta.min
    },
    [BTES.YearsT2Final.key]: {
      type: BTES.YearsT2Final.type,
      minimum: BTES.YearsT2Final.min
    },
    [BTES.InitBtesMaxWidth.key]: {
      type: BTES.InitBtesMaxWidth.type,
      minimum: BTES.InitBtesMaxWidth.min,
      maximum: BTES.InitBtesMaxWidth.max
    },
    [BTES.InitBtesProbeResistance.key]: {
      type: BTES.InitBtesProbeResistance.type,
      minimum: BTES.InitBtesProbeResistance.min,
      maximum: BTES.InitBtesProbeResistance.max
    },
    [BTES.InitBtesProbeInternalDiameter.key]: {
      type: BTES.InitBtesProbeInternalDiameter.type,
      minimum: BTES.InitBtesProbeInternalDiameter.min,
      maximum: BTES.InitBtesProbeInternalDiameter.max
    }
  },
  required: [
    BTES_TEST.InitTrtChecked.key,
    BTES_TEST.InitBtesTestProbeLength.key,
    SOIL.InitSoilThermalConductivity.key,
    SOIL.InitSoilVolumeHeatCapacity.key,
    SOIL.InitSoilTemperature10m.key,
    BTES.InitBtesTemperatureMin.key,
    BTES.InitBtesTemperatureMax.key,
    BTES.InitBtesTemperatureDelta.key,
    BTES.YearsT2Final.key,
    BTES.InitBtesMaxWidth.key,
    BTES.InitBtesProbeResistance.key,
    BTES.InitBtesProbeInternalDiameter.key
  ],
  allOf: [
    {
      if: { properties: { [BTES_TEST.InitTrtChecked.key]: { const: false } } },
      then: {
        properties: {
          [BTES_TEST.InitBtesTestProbeLength.key]: {
            const: BTES_TEST.InitBtesTestProbeLength.default
          }
        }
      }
    }
  ],
  additionalProperties: false
};

module.exports = { GEOLOGY_SCHEMA };
