import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import './CommentInput.css';

const CommentInput = ({ result, onCommentSave }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [editing, setEditing] = useState(false);
  //#endregion

  //#region [refs]
  const commentInputRef = useRef();
  const commentBtnRef = useRef();
  //#endregion

  //#region [effects]
  useEffect(() => {
    if (!commentInputRef.current) return;
    if (result.Comment !== commentInputRef.current.value) {
      commentInputRef.current.value = result.Comment;
    }
  }, [result.Comment]);
  //#endregion

  //#region [methods]
  const handleEditBtnClick = () => {
    setEditing(true);
    commentBtnRef.current.blur();
    commentInputRef.current.focus();
  };

  const handleSaveBtnClick = async () => {
    setEditing(() => false);
    await onCommentSave(result.ComputeID, commentInputRef.current.value);
  };
  //#endregion

  //#region [render]
  return (
    <InputGroup className='results-comment-input-group'>
      <Form.Control
        placeholder={i18n._('results.general.noSave')}
        type='text'
        disabled={!editing}
        ref={commentInputRef}
        name='commentInput'
        defaultValue={result.Comment ?? ''}
      />
      <Button
        onClick={editing ? handleSaveBtnClick : handleEditBtnClick}
        variant='primary'
        ref={commentBtnRef}
      >
        <FontAwesomeIcon icon={editing ? 'floppy-disk' : 'pen-to-square'} />
      </Button>
      <Button
        variant='outline-secondary'
        disabled={!editing}
        onClick={() => setEditing(false)}
      >
        <FontAwesomeIcon icon='xmark' />
      </Button>
    </InputGroup>
  );
  //#endregion
};

export default CommentInput;
