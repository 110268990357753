import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import {
  HP_TYPE,
  NEED,
  UNIT
} from '../../../../../../../../../server/constants';
import {
  BTES_HPG,
  HPA
} from '../../../../../../../../../server/models/config/hydraulicEquipment/pumps/regimes.model';
import {
  fetchRegimesDependsOnOutsideTemp,
  fetchRegimesDependsOnSrcTemp,
  fetchRegimesDependsOnSrcTempDelta
} from '../../../../../../../api/configSst.api';
import FormInput from '../../../../../../../components/Form/FormInput';
import Section from '../../../../../../../components/Section/Section';
import { INPUT_TYPE } from '../../../../../../../constants';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../../contexts/PopupContext';
import { getSortedNeeds } from '../../../../../../../utils/config.utils';
import {
  formatValueWithUnit,
  isNull
} from '../../../../../../../utils/data.utils';
import './CriticalPointsSection.css';

const CriticalPointsSection = ({ onLoading, addError, removeErrors }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config, setConfig } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const formatValue = (value, unit, precision) =>
    !isNull(value)
      ? formatValueWithUnit(i18n, value, unit, precision)
      : i18n._('noValue');

  const handleOutsideTempChange = async (need, value) => {
    onLoading(true);
    let error = false;

    // on récupère l'ancienne valeur de outsideTemp au cas où une erreur survient
    const { ConfigSstID, Data } = config.ConfigsSst[0];
    const { hpa } = Data.pumps.regimes.criticalPoints[need];
    const oldValue = hpa.outsideTemp;

    // on met à jour outsideTemp
    hpa.outsideTemp = value;
    try {
      // on récupère les nouvelles données dont dépendaient outsideTemp
      const body = {
        criticalPoints: Data.pumps.regimes.criticalPoints,
        physicalData: Data.pumps.regimes.physicalData,
        need,
        outsideTemp: value
      };
      const { criticalPoints, physicalData } =
        await fetchRegimesDependsOnOutsideTemp(ConfigSstID, body);

      // on set les nouvelles données
      Data.pumps.regimes.criticalPoints = { ...criticalPoints };
      Data.pumps.regimes.physicalData = [...physicalData];
    } catch (err) {
      error = true;
      console.error(err);
      openErrorToast(err);

      // on reset la config
      hpa.outsideTemp = oldValue;
    } finally {
      setConfig(() => ({ ...config }));
      onLoading(false, error);
    }
  };

  const handleSrcTempChange = async (need, value) => {
    onLoading(true);
    let error = false;

    // on récupère les anciennes valeurs de srcTemp et srcTempOut au cas où une erreur survient
    const { ConfigSstID, Data } = config.ConfigsSst[0];
    const { hpgBtes } = Data.pumps.regimes.criticalPoints[need];
    const oldSrcTemp = hpgBtes.srcTemp;
    const oldSrcTempOut = hpgBtes.srcTempOut;

    // on met à jour srcTemp et srcTempOut
    hpgBtes.srcTemp = value;
    hpgBtes.srcTempOut =
      need === NEED.COLD
        ? value + hpgBtes.srcTempDelta
        : value - hpgBtes.srcTempDelta;
    try {
      // on récupère les nouvelles données dont dépendaient srcTemp
      const body = {
        criticalPoints: Data.pumps.regimes.criticalPoints,
        physicalData: Data.pumps.regimes.physicalData,
        need,
        srcTemp: value
      };
      const { criticalPoints, physicalData } =
        await fetchRegimesDependsOnSrcTemp(ConfigSstID, body);

      // on set les nouvelles données
      Data.pumps.regimes.criticalPoints = { ...criticalPoints };
      Data.pumps.regimes.physicalData = [...physicalData];
    } catch (err) {
      error = true;
      console.error(err);
      openErrorToast(err);

      // on reset la config
      hpgBtes.srcTemp = oldSrcTemp;
      hpgBtes.srcTempOut = oldSrcTempOut;
    } finally {
      setConfig(() => ({ ...config }));
      onLoading(false, error);
    }
  };

  const handleSrcTempDeltaChange = async (need, value) => {
    onLoading(true);
    let error = false;

    // on récupère les anciennes valeurs de srcTempDelta et srcTempOut au cas où une erreur survient
    const { ConfigSstID, Data } = config.ConfigsSst[0];
    const { hpgBtes } = Data.pumps.regimes.criticalPoints[need];
    const oldSrcTempDelta = hpgBtes.srcTempDelta;
    const oldSrcTempOut = hpgBtes.srcTempOut;

    // on met à jour srcTempDelta et srcTempOut
    hpgBtes.srcTempDelta = value;
    hpgBtes.srcTempOut =
      need === NEED.COLD ? hpgBtes.srcTemp + value : hpgBtes.srcTemp - value;
    try {
      // on récupère les nouvelles données dont dépendaient srcTempDelta
      const body = {
        criticalPoints: Data.pumps.regimes.criticalPoints,
        physicalData: Data.pumps.regimes.physicalData,
        need,
        srcTempDelta: value
      };
      const { criticalPoints, physicalData } =
        await fetchRegimesDependsOnSrcTempDelta(ConfigSstID, body);

      // on set les nouvelles données
      Data.pumps.regimes.criticalPoints = { ...criticalPoints };
      Data.pumps.regimes.physicalData = [...physicalData];
    } catch (err) {
      error = true;
      console.error(err);
      openErrorToast(err);

      // on reset la config
      hpgBtes.srcTempDelta = oldSrcTempDelta;
      hpgBtes.srcTempOut = oldSrcTempOut;
    } finally {
      setConfig(() => ({ ...config }));
      onLoading(false, error);
    }
  };
  //#endregion

  //#region [render]
  const { services, heatpumps, pumps } = config.ConfigsSst[0].Data;
  const { types } = heatpumps.settings;
  const sortedNeeds = getSortedNeeds(services.needs);
  return (
    <Section
      title={i18n._('config.pumps.regimes.criticalPoints')}
      level={2}
      open
      className='critical-points-section'
    >
      <p className='bold'>
        {i18n._('config.pumps.regimes.criticalPoints.instructions1')}
      </p>
      <p>{i18n._('config.pumps.regimes.criticalPoints.instructions2')}</p>
      <div className='custom-table-wrapper'>
        <table className='critical-points-section-table'>
          <thead>
            <tr>
              <th></th>
              {types.includes(HP_TYPE.AERO) && (
                <th>{i18n._('config.pumps.regimes.criticalPoints.hpa')}</th>
              )}
              {types.includes(HP_TYPE.GEO) && (
                <th colSpan={3}>
                  {i18n._('config.pumps.regimes.criticalPoints.hpgBtes')}
                </th>
              )}
              <th colSpan={3}>
                {i18n._('config.pumps.regimes.criticalPoints.hpProd')}
              </th>
              <th colSpan={3}>
                {i18n._(
                  'config.pumps.regimes.criticalPoints.buildingDistribution'
                )}
              </th>
              <th colSpan={2}>
                {i18n._('config.pumps.regimes.criticalPoints.flowRates')}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{i18n._('config.needs')}</td>
              {types.includes(HP_TYPE.AERO) && (
                <td>
                  {i18n._('config.pumps.regimes.criticalPoints.outsideTemp')}
                </td>
              )}
              {types.includes(HP_TYPE.GEO) && (
                <>
                  <td>
                    {i18n._('config.pumps.regimes.criticalPoints.srcTemp')}
                  </td>
                  <td>
                    {i18n._('config.pumps.regimes.criticalPoints.srcTempDelta')}
                  </td>
                  <td>
                    {i18n._('config.pumps.regimes.criticalPoints.srcTempOut')}
                  </td>
                </>
              )}
              <td>{i18n._('config.pumps.regimes.criticalPoints.dstTempIn')}</td>
              <td>
                {i18n._('config.pumps.regimes.criticalPoints.dstTempDelta')}
              </td>
              <td>{i18n._('config.pumps.regimes.criticalPoints.dstTemp')}</td>
              <td>{i18n._('config.pumps.regimes.criticalPoints.temp')}</td>
              <td>{i18n._('config.pumps.regimes.criticalPoints.deltaT')}</td>
              <td>{i18n._('config.pumps.regimes.criticalPoints.tempBack')}</td>
              <td className='critical-points-col-flowRate'>
                {i18n._('config.pumps.regimes.criticalPoints.coldFlowRateMax')}
              </td>
              <td className='critical-points-col-flowRate'>
                {i18n._('config.pumps.regimes.criticalPoints.heatFlowRateMax')}
              </td>
            </tr>
            {sortedNeeds.map((need) => {
              const {
                hpa,
                hpgBtes,
                hpProd,
                buildingDistribution,
                flowRateMax
              } = pumps.regimes.criticalPoints[need];
              // PAC aéro/géo, côté production
              const { dstTempIn, dstTemp, dstTempDelta } = hpProd;
              // Distribution bâtiment
              const { temperature, temperatureBack } = buildingDistribution;
              // débits max
              const { coldSide, heatSide } = flowRateMax;
              return (
                <tr key={'critical_points_tr_' + need}>
                  <td>{i18n._(`need.${need}`)}</td>
                  {types.includes(HP_TYPE.AERO) && (
                    // PAC aéro : T° ext
                    <td>
                      <FormInput
                        param={HPA[need].outsideTemp}
                        unit
                        value={hpa.outsideTemp}
                        onBlur={async (value) =>
                          await handleOutsideTempChange(need, value)
                        }
                        type={INPUT_TYPE.NUMBER}
                        addError={addError}
                        removeError={() => removeErrors(1)}
                      />
                    </td>
                  )}
                  {types.includes(HP_TYPE.GEO) && (
                    // PAC géo, côté BTES
                    <>
                      <td>
                        <FormInput
                          param={BTES_HPG[need].srcTemp}
                          unit
                          value={hpgBtes.srcTemp}
                          onBlur={async (value) =>
                            await handleSrcTempChange(need, value)
                          }
                          type={INPUT_TYPE.NUMBER}
                          addError={addError}
                          removeError={() => removeErrors(1)}
                        />
                      </td>
                      <td>
                        <FormInput
                          param={BTES_HPG[need].srcTempDelta}
                          unit
                          value={hpgBtes.srcTempDelta}
                          onBlur={async (value) =>
                            await handleSrcTempDeltaChange(need, value)
                          }
                          type={INPUT_TYPE.NUMBER}
                          addError={addError}
                          removeError={() => removeErrors(1)}
                        />
                      </td>
                      <td>
                        {formatValue(hpgBtes.srcTempOut, UNIT.CELSIUS_DEGREE)}
                      </td>
                    </>
                  )}
                  {/*PAC aéro/géo, côté production*/}
                  <td>{formatValue(dstTempIn, UNIT.CELSIUS_DEGREE)}</td>
                  <td>{formatValue(dstTempDelta, UNIT.KELVIN)}</td>
                  <td>{formatValue(dstTemp, UNIT.CELSIUS_DEGREE)}</td>
                  {/*Distribution bâtiment*/}
                  <td>{formatValue(temperature, UNIT.CELSIUS_DEGREE)}</td>
                  <td>{formatValue(dstTempDelta, UNIT.KELVIN)}</td>
                  <td>{formatValue(temperatureBack, UNIT.CELSIUS_DEGREE)}</td>
                  {/*Débit max côté froid*/}
                  <td className='critical-points-col-flowRate'>
                    {formatValue(coldSide, UNIT.CUBIC_METER_PER_HOUR, 1)}
                  </td>
                  {/*Débit max côté chaud*/}
                  <td className='critical-points-col-flowRate'>
                    {formatValue(heatSide, UNIT.CUBIC_METER_PER_HOUR, 1)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Section>
  );
  //#endregion
};

export default CriticalPointsSection;
