import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Alert, Form } from 'react-bootstrap';
import {
  HOTWATER_PRODUCTION,
  getHeatProductionParams
} from '../../../../../../../server/models/design/description.model';
import ParamInput from '../../../../../components/ParamForm/ParamInput';
import Section from '../../../../../components/Section/Section';
import TileCheckbox from '../../../../../components/Tile/TileCheckbox';
import { INPUT_TYPE } from '../../../../../constants';
import PopupContext from '../../../../../contexts/PopupContext';
import gazImg from '../../../../../image/equipment-gaz.png';
import hpaImg from '../../../../../image/equipment-hpa.png';
import hpgImg from '../../../../../image/equipment-hpg.png';
import solarImg from '../../../../../image/equipment-solar.png';
import wcImg from '../../../../../image/weatherCompensation.png';
import { getDefaultValue } from '../../../../../utils/param.utils';
import './HeatProductionSection.css';

const HeatProductionSection = ({
  form,
  formIndex,
  onInputChange,
  onTemperatureHeatBackChange,
  onGasReleveChange,
  onGasAppointChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [render]
  const heatProductionParams = getHeatProductionParams(
    form.InitTemperatureHeat,
    form.InitWaterLawIsUsed
  );
  const InitAppointGasEnabled = form.InitGasEnabled && !form.InitReleveEnabled;
  const noEquipment =
    !form.InitHPGHeatingEnabled &&
    !form.InitHPAHeatingEnabled &&
    !form.InitGasEnabled &&
    !form.InitSolarThermalEnabled;
  return (
    <Section
      title={i18n._('description.heatProduction')}
      open
      className='description-production-section'
    >
      <div className='description-production-regimes'>
        <h2>{i18n._('description.heatProduction.regimes')}</h2>
        {form.InitStationHasHeatNeeds && (
          <>
            <div className='description-production-regimes-need-row'>
              <span className='param-input-label'>
                {i18n._('description.heatProduction.regimes.heat')}
              </span>
              <div>
                <ParamInput
                  value={form.InitTemperatureHeat}
                  onChange={(value) =>
                    onInputChange('InitTemperatureHeat', value)
                  }
                  param={heatProductionParams.InitTemperatureHeat}
                  unit
                  type={INPUT_TYPE.NUMBER}
                />
                <ParamInput
                  value={form.InitTemperatureHeatBack}
                  onChange={onTemperatureHeatBackChange}
                  param={heatProductionParams.InitTemperatureHeatBack}
                  unit
                  type={INPUT_TYPE.NUMBER}
                />
              </div>
              <span className='param-input-small-text'>
                {i18n._('description.heatProduction.regimes.heat.default', {
                  InitTemperatureHeat:
                    heatProductionParams.InitTemperatureHeat.default,
                  InitTemperatureHeatBack:
                    heatProductionParams.InitTemperatureHeat.default -
                    heatProductionParams.InitTemperatureHeatDelta.default
                })}
              </span>
            </div>
            <div className='heat-production-regimes-heat-wc'>
              <div className='heat-production-regimes-heat-wc-check'>
                <Form.Check
                  type='switch'
                  label={i18n._('description.InitWaterLawIsUsed')}
                  checked={form.InitWaterLawIsUsed}
                  onChange={(evt) =>
                    onInputChange('InitWaterLawIsUsed', evt.target.checked)
                  }
                  name='InitWaterLawIsUsed'
                  id={'InitWaterLawIsUsed_' + formIndex}
                />
                <FontAwesomeIcon
                  className='modal-info-icon'
                  icon='circle-question'
                  onClick={() =>
                    openInfoModal(
                      i18n._('description.heatProduction.regimes.wc'),
                      <img src={wcImg} alt='weather compensation image' />
                    )
                  }
                />
              </div>
              <div className='heat-production-regimes-heat-wc-inputs'>
                <ParamInput
                  label={i18n._('description.InitStationTemperatureSetPoint')}
                  value={form.InitStationTemperatureSetPoint}
                  param={heatProductionParams.InitStationTemperatureSetPoint}
                  onChange={(value) =>
                    onInputChange('InitStationTemperatureSetPoint', value)
                  }
                  unit
                  disabled={!form.InitWaterLawIsUsed}
                  type={INPUT_TYPE.NUMBER}
                  bottomText={getDefaultValue(
                    i18n,
                    heatProductionParams.InitStationTemperatureSetPoint
                  )}
                />
                <ParamInput
                  label={i18n._(
                    'description.InitStationWaterTankHotTemperatureMin'
                  )}
                  value={form.InitStationWaterTankHotTemperatureMin}
                  param={
                    heatProductionParams.InitStationWaterTankHotTemperatureMin
                  }
                  onChange={(value) =>
                    onInputChange(
                      'InitStationWaterTankHotTemperatureMin',
                      value
                    )
                  }
                  unit
                  disabled={!form.InitWaterLawIsUsed}
                  type={INPUT_TYPE.NUMBER}
                  bottomText={getDefaultValue(
                    i18n,
                    heatProductionParams.InitStationWaterTankHotTemperatureMin
                  )}
                />
              </div>
            </div>
          </>
        )}
        {form.InitStationHasHotWaterNeeds && (
          <div className='description-production-regimes-need-row'>
            <span className='param-input-label'>
              {i18n._('description.heatProduction.regimes.hotwater')}
            </span>
            <div>
              <ParamInput
                value={form.InitTemperatureHotWater}
                onChange={(value) =>
                  onInputChange('InitTemperatureHotWater', value)
                }
                param={HOTWATER_PRODUCTION.InitTemperatureHotWater}
                unit
                type={INPUT_TYPE.NUMBER}
              />
              <ParamInput
                value={form.InitTemperatureHotWaterBack}
                onChange={(value) =>
                  onInputChange('InitTemperatureHotWaterBack', value)
                }
                param={HOTWATER_PRODUCTION.InitTemperatureHotWaterBack}
                unit
                type={INPUT_TYPE.NUMBER}
              />
            </div>
            <span className='param-input-small-text'>
              {i18n._('description.heatProduction.regimes.hotwater.default', {
                InitTemperatureHotWater:
                  HOTWATER_PRODUCTION.InitTemperatureHotWater.default,
                InitTemperatureHotWaterBack:
                  HOTWATER_PRODUCTION.InitTemperatureHotWaterBack.default
              })}
            </span>
          </div>
        )}
      </div>
      <div>
        <h2>{i18n._('description.heatProduction.equipment')}</h2>
        <div className='description-production-equipment-row'>
          {form.InitBtesEnabled && (
            <TileCheckbox
              checked={form.InitHPGHeatingEnabled}
              image={hpgImg}
              onChange={(checked) =>
                onInputChange('InitHPGHeatingEnabled', checked)
              }
              title={i18n._('description.InitHPGHeatingEnabled')}
            />
          )}
          <TileCheckbox
            checked={form.InitHPAHeatingEnabled}
            image={hpaImg}
            onChange={(checked) =>
              onInputChange('InitHPAHeatingEnabled', checked)
            }
            title={i18n._('description.InitHPAHeatingEnabled')}
          />
          <TileCheckbox
            checked={form.InitReleveEnabled}
            image={gazImg}
            onChange={onGasReleveChange}
            title={i18n._('description.InitReleveEnabled')}
            details={i18n._('description.heatProduction.equipment.gas')}
          />
          <TileCheckbox
            checked={InitAppointGasEnabled}
            image={gazImg}
            onChange={onGasAppointChange}
            title={i18n._('description.InitAppointGasEnabled')}
            details={i18n._('description.heatProduction.equipment.gas')}
          />
          <TileCheckbox
            checked={form.InitSolarThermalEnabled}
            image={solarImg}
            onChange={(checked) =>
              onInputChange('InitSolarThermalEnabled', checked)
            }
            title={i18n._('description.InitSolarThermalEnabled')}
            details={i18n._('description.InitSolarThermalEnabled.details')}
          />
        </div>
        {noEquipment && (
          <Alert variant='danger' className='description-equipment-alert'>
            {i18n._('description.error.noEquipment')}
          </Alert>
        )}
        {form.InitBtesEnabled &&
          !form.InitHPGHeatingEnabled &&
          !form.InitHPGCoolingEnabled && (
            <Alert variant='danger' className='description-equipment-alert'>
              {i18n._('description.error.noHpgEquipment')}
            </Alert>
          )}
      </div>
    </Section>
  );
  //#endregion
};

export default HeatProductionSection;
