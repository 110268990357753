// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-services {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 80px;
    margin-top: 15px;
}

.settings-noNeeds {
    font-size: 14px;
    color: var(--danger-color);
    font-weight: 500;
    margin-top: 10px;
}

.settings-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 50px;
    margin-top: 15px;
}

.settings-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 15px;
}

.settings-distrib-inputs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 15px;
    margin-top: -15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/configs/buildingDescription/services/settings/SettingsPage.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,0BAA0B;IAC1B,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,4BAA4B;IAC5B,6BAA6B;QACzB,0BAA0B;YACtB,sBAAsB;IAC9B,SAAS;AACb;;AAEA;IACI,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,SAAS;IACT,iBAAiB;AACrB","sourcesContent":[".settings-services {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    gap: 80px;\n    margin-top: 15px;\n}\n\n.settings-noNeeds {\n    font-size: 14px;\n    color: var(--danger-color);\n    font-weight: 500;\n    margin-top: 10px;\n}\n\n.settings-row {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    gap: 50px;\n    margin-top: 15px;\n}\n\n.settings-column {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n        -ms-flex-direction: column;\n            flex-direction: column;\n    gap: 15px;\n}\n\n.settings-distrib-inputs {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    gap: 15px;\n    margin-top: -15px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
