import { STORAGE_KEYS } from '../../../server/constants';
import axios from '../conf/axios.conf';

//#region [GET]
export const fetchUserIdByResetPwdToken = async (token) => {
  try {
    const url = `/auth/resetPwd/${token}/user`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [POST]
export const login = async (email, pwd) => {
  try {
    const body = { email, pwd };
    const response = await axios.post('/auth/login', body);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const logout = async () => {
  try {
    const response = await axios.post(
      '/auth/logout',
      {},
      { withCredentials: true }
    );
    localStorage.removeItem(STORAGE_KEYS.ACCESS);
    localStorage.removeItem(STORAGE_KEYS.COMPANY);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [PUT]
export const sendMailToResetPwd = async (email, language) => {
  try {
    const body = { email, language };
    const response = await axios.put('/auth/resetPwd/token', body);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const resetUserPwd = async (userId, pwd, token) => {
  try {
    const body = { userId, pwd, token };
    const response = await axios.put('/auth/resetPwd/pwd', body);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion
