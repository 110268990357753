// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-cell-error .form-select {
    border-color: var(--danger-color);
}`, "",{"version":3,"sources":["webpack://./src/pages/configs/thermalProduction/heatpumps/selection/cells/StatusCellRenderer/StatusCellRenderer.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;AACrC","sourcesContent":[".status-cell-error .form-select {\n    border-color: var(--danger-color);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
