import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { EMPTY_USER, USER } from '../../../../../../server/models/user.model';
import { USER_SCHEMA } from '../../../../../../server/validation/user.validation';
import { createUser, isEmailAvailable } from '../../../../api/user.api';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import ParamSelect from '../../../../components/ParamForm/ParamSelect';
import { INPUT_TYPE } from '../../../../constants';
import AjvContext from '../../../../contexts/AjvContext';
import PopupContext from '../../../../contexts/PopupContext';
import { IS_DEV } from '../../../../utils/env.utils';
import './NewUserModal.css';

const NewUserModal = ({ isOpen, onClose, onSubmit }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { companyId } = useParams();
  //#endregion

  //#region [contexts]
  const { ajv } = useContext(AjvContext);
  const { openToast, openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [form, setForm] = useState(EMPTY_USER);
  const [emailError, setEmailError] = useState(false);
  //#endregion

  //#region [methods]
  const handleInputChange = (key, value) => {
    setForm((oldForm) => ({ ...oldForm, [key]: value }));
    setEmailError(() => false);
  };

  const handleEmailBlur = async (value) => {
    try {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(value)) return;
      setForm((oldForm) => ({
        ...oldForm,
        UserEmail: value
      }));
      const available = await isEmailAvailable(value);
      setEmailError(() => !available);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleFormSubmit = async () => {
    try {
      await createUser({
        ...form,
        UserCoID: companyId
      });
      onClose();
      await onSubmit();
      openToast(
        i18n._('users.new.success.title'),
        i18n._('users.new.success.body'),
        'success'
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const validate = ajv.compile(USER_SCHEMA);
  const isFormValid = validate(form) && !emailError;
  if (IS_DEV && validate.errors) console.log(validate.errors);
  return isOpen ? (
    <Modal
      show={isOpen}
      onHide={onClose}
      size='lg'
      className='custom-modal new-user-modal'
      animation={false}
    >
      <Modal.Header closeButton>
        <span>{i18n._('users.new')}</span>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <ParamInput
              param={USER.UserFirstName}
              label={i18n._('users.new.firstName')}
              value={form.UserFirstName ?? ''}
              onChange={(value) => handleInputChange('UserFirstName', value)}
              type={INPUT_TYPE.TEXT}
            />
          </Col>
          <Col>
            <ParamInput
              param={USER.UserLastName}
              label={i18n._('users.new.lastName')}
              value={form.UserLastName ?? ''}
              onChange={(value) => handleInputChange('UserLastName', value)}
              type={INPUT_TYPE.TEXT}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ParamInput
              param={USER.UserEmail}
              label={i18n._('users.new.email')}
              value={form.UserEmail ?? ''}
              onChange={(value) => handleInputChange('UserEmail', value)}
              onBlur={(value) => handleEmailBlur(value)}
              className={emailError ? 'input-email-not-available' : ''}
              type={INPUT_TYPE.EMAIL}
            />
          </Col>
          <Col>
            <ParamInput
              param={USER.UserPassword}
              label={i18n._('users.new.pwd')}
              value={form.UserPassword ?? ''}
              onChange={(value) => handleInputChange('UserPassword', value)}
              type={INPUT_TYPE.PASSWORD}
              showInfoIcon
              infoTooltip={i18n._('users.new.pwdModal.body')}
              infoIcon='fa-regular fa-circle-question'
            />
          </Col>
        </Row>
        {emailError && (
          <Row>
            <Col xs='6'>
              <span className='email-not-available'>
                <FontAwesomeIcon icon='xmark' />
                {i18n._('users.new.notAvailable')}
              </span>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <ParamInput
              param={USER.UserJobTitle}
              label={i18n._('users.new.job')}
              value={form.UserJobTitle ?? ''}
              onChange={(value) => handleInputChange('UserJobTitle', value)}
              type={INPUT_TYPE.TEXT}
            />
          </Col>
          <Col>
            <Form.Check
              type='checkbox'
              label={i18n._('users.new.admin')}
              value={form.IsSuperAdmin}
              onChange={(evt) =>
                handleInputChange('IsSuperAdmin', evt.target.checked)
              }
              name='isSuperAdmin'
              id='isSuperAdmin'
            />
          </Col>
        </Row>
        <Row>
          <Col xs='6'>
            <ParamSelect
              label={i18n._('users.new.country')}
              param={USER.UserCountry}
              value={form.UserCountry}
              onChange={(value) => handleInputChange('AhsCountry', value)}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          {i18n._('cancel')}
        </Button>
        <Button
          variant='primary'
          onClick={handleFormSubmit}
          disabled={!isFormValid}
        >
          {i18n._('save')}
        </Button>
      </Modal.Footer>
    </Modal>
  ) : null;
  //#endregion
};

export default NewUserModal;
