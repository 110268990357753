import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import React, { useContext } from 'react';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../contexts/PopupContext';

const DeleteCellRenderer = ({ data, onFormChange }) => {
  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const handleDeleteHpClick = () => {
    try {
      const newList = cloneDeep(config.ConfigsSst[0].Data.heatpumps.list);
      const index = newList.findIndex((hp) => hp.id === data.id);
      const deletedHp = { ...newList[index] };
      newList.splice(index, 1);
      newList.forEach((hp) => {
        if (hp.position > deletedHp.position) hp.position--;
      });
      config.ConfigsSst[0].Data.heatpumps.list = newList;
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  return (
    <FontAwesomeIcon
      icon='trash-alt'
      className='selected-hps-delete'
      onClick={handleDeleteHpClick}
    />
  );
  //#endregion
};

export default DeleteCellRenderer;
