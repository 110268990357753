// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-user-modal .row {
  margin-bottom: 10px;
}

.input-email-not-available input {
  border-color: var(--danger-color);
}

.input-email-not-available input:focus {
  border-color: var(--danger-color) !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  -webkit-box-shadow: 0 0 2px 2px rgba(193, 43, 43, 0.2) !important;
          box-shadow: 0 0 2px 2px rgba(193, 43, 43, 0.2) !important;
}

.email-not-available {
  margin-top: -5px;
  font-size: 14px;
  color: var(--danger-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/users/components/NewUserModal/NewUserModal.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,4CAA4C;EAC5C,mCAAmC;UAC3B,2BAA2B;EACnC,iEAAiE;UACzD,yDAAyD;AACnE;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,0BAA0B;EAC1B,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;EAC3B,QAAQ;AACV","sourcesContent":[".new-user-modal .row {\n  margin-bottom: 10px;\n}\n\n.input-email-not-available input {\n  border-color: var(--danger-color);\n}\n\n.input-email-not-available input:focus {\n  border-color: var(--danger-color) !important;\n  -webkit-box-shadow: none !important;\n          box-shadow: none !important;\n  -webkit-box-shadow: 0 0 2px 2px rgba(193, 43, 43, 0.2) !important;\n          box-shadow: 0 0 2px 2px rgba(193, 43, 43, 0.2) !important;\n}\n\n.email-not-available {\n  margin-top: -5px;\n  font-size: 14px;\n  color: var(--danger-color);\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  gap: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
