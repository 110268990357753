import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import AuthProvider from '../auth/Auth';
import ErrorFallback from '../components/ErrorFallback/ErrorFallback';
import ConfigsPage from '../pages/configs/ConfigsPage';
import HydraulicDiagramPage from '../pages/configs/deliverable/hydraulicDiagram/HydraulicDiagramPage';
import pages from '../pages/configs/pages';
import ComputePage from '../pages/design/compute/ComputePage';
import CostsPage from '../pages/design/costs/CostsPage';
import DescriptionsPage from '../pages/design/descriptions/DescriptionsPage';
import GeneralPage from '../pages/design/general/GeneralPage';
import GeologyPage from '../pages/design/geology/GeologyPage';
import PerformancePage from '../pages/design/performance/PerformancePage';
import ResultPage from '../pages/design/result/ResultPage';
import ResultsPage from '../pages/design/results/ResultsPage';
import LoginPage from '../pages/login/LoginPage';
import NewProjectPage from '../pages/newProject/NewProjectPage';
import NotFoundPage from '../pages/notFound/NotFoundPage';
import ProjectPage from '../pages/project/ProjectPage';
import ProjectsPage from '../pages/projects/ProjectsPage';
import ResetPwdPage from '../pages/resetPwd/ResetPwdPage';
import RootPage from '../pages/root/RootPage';
import UsersPage from '../pages/users/UsersPage';
import VersionPage from '../pages/version/VersionPage';
import RedirectToProjects from './RedirectToProjects';
import RequireSuperAdmin from './RequireAdmin';
import RequireAuth from './RequireAuth';

const Router = () => {
  //#region [render]
  return (
    <AuthProvider>
      <Routes>
        <Route
          path='/login'
          element={
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <LoginPage />
            </ErrorBoundary>
          }
        />
        <Route
          path='/reset-pwd'
          element={
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <ResetPwdPage />
            </ErrorBoundary>
          }
        />
        <Route
          path='/diagram/:configId'
          element={
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <RequireAuth redirectTo='/login'>
                <HydraulicDiagramPage />
              </RequireAuth>
            </ErrorBoundary>
          }
        />
        <Route
          path='/'
          element={
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <RequireAuth redirectTo='/login'>
                <RootPage />
              </RequireAuth>
            </ErrorBoundary>
          }
        >
          <Route index element={<RedirectToProjects />} />
          <Route
            path='version'
            element={
              <RequireSuperAdmin redirectTo='/not-found'>
                <VersionPage />
              </RequireSuperAdmin>
            }
          />
          <Route path='company/:companyId'>
            <Route path='projects' element={<ProjectsPage />} />
            <Route path='new-project' element={<NewProjectPage />} />
            <Route
              path='users'
              element={
                <RequireSuperAdmin redirectTo='/not-found'>
                  <UsersPage />
                </RequireSuperAdmin>
              }
            />
            <Route path='project/:projectId' element={<ProjectPage />}>
              <Route path='design'>
                <Route path='general' element={<GeneralPage />} />
                <Route path='compute' element={<ComputePage />} />
                <Route path='descriptions' element={<DescriptionsPage />} />
                <Route path='geology' element={<GeologyPage />} />
                <Route path='costs' element={<CostsPage />} />
                <Route path='performance' element={<PerformancePage />} />
                <Route path='results' element={<ResultsPage />} />
                <Route path='results/:resultId' element={<ResultPage />} />
              </Route>
              <Route path='configs' element={<ConfigsPage />}>
                {pages.map((page) => {
                  let Component = page.component;
                  return (
                    <Route
                      path={`:configId/${page.id}`}
                      element={<Component />}
                      key={'config_route_' + page.id}
                    />
                  );
                })}
              </Route>
            </Route>
          </Route>
        </Route>
        <Route
          path='*'
          element={
            <RequireAuth redirectTo='/login'>
              <NotFoundPage />
            </RequireAuth>
          }
        />
      </Routes>
    </AuthProvider>
  );
  //#endregion
};

export default Router;
