// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-noHp {
    font-size: 14px;
    color: var(--danger-color);
    font-weight: 500;
    margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/configs/thermalProduction/heatpumps/settings/SettingsPage.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,0BAA0B;IAC1B,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".settings-noHp {\n    font-size: 14px;\n    color: var(--danger-color);\n    font-weight: 500;\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
