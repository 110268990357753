// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-page {
  padding: 15px;
}

.project-header-wrapper {
  position: sticky;
  top: 0px;
  z-index: 101;
}
`, "",{"version":3,"sources":["webpack://./src/pages/project/ProjectPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,QAAQ;EACR,YAAY;AACd","sourcesContent":[".project-page {\n  padding: 15px;\n}\n\n.project-header-wrapper {\n  position: sticky;\n  top: 0px;\n  z-index: 101;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
