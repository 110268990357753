import { useLingui } from '@lingui/react';
import React from 'react';
import { NEED } from '../../../../../../../../server/constants';
import IconBadge from '../../../../../../components/IconBadge/IconBadge';

const icons = {
  [NEED.HEAT]: 'sun',
  [NEED.COLD]: 'snowflake',
  [NEED.DHW]: 'water'
};

const NeedBadge = ({ need }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return <IconBadge icon={icons[need]} text={i18n._(`need.${need}`)} />;
  //#endregion
};

export default NeedBadge;
