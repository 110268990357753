import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import {
  GOAL,
  OPTIONS_GOAL
} from '../../../../../../../../../server/models/design/calculationData.model';
import { Counter } from '../../../../../../../components/Counter/Counter';
import ParamInput from '../../../../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../../../../constants';
import './OptiGoalsSection.css';

const OptiGoalsSection = ({
  calculationData,
  onSelectChange,
  onConstraintChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  let selectedParam;
  if (!calculationData.constraints.inp.InitConstraintEnabled) {
    selectedParam = GOAL.InitConstraintNone;
  } else {
    const optionParam = OPTIONS_GOAL.filter(
      (param) => param.key !== GOAL.InitConstraintNone.key
    ).find((param) => calculationData.constraints.inp[param.key] !== 0);
    selectedParam = optionParam
      ? GOAL[optionParam.key]
      : GOAL.InitConstraintNone;
  }
  const selectedParamValue = calculationData.constraints.inp[selectedParam.key];
  return (
    <div className='opti-section opti-goals-section'>
      <h2>{i18n._('compute.opti.goals')}</h2>
      <Row>
        <Col xs={8}>
          <Form.Select
            onChange={onSelectChange}
            value={selectedParam.key}
            name='opti_goals_select'
          >
            {Object.values(OPTIONS_GOAL).map((param, index) => (
              <option
                value={param.key}
                key={'opti_goals_select_' + param.key + '_' + index}
              >
                {i18n._(`compute.opti.goals.${param.key}`)}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      {selectedParam.key !== GOAL.InitConstraintNone.key &&
        selectedParam.ref && (
          <Row>
            <Col className='opti-inputs-label'>
              {i18n._('compute.opti.goals.ref', {
                unit: i18n._(`unit.${selectedParam.unit}`)
              })}
            </Col>
            <Col>
              <ParamInput
                value={calculationData.constraints.inp.InitConstraintReference}
                param={GOAL.InitConstraintReference}
                onChange={(value) =>
                  onConstraintChange(
                    GOAL.InitConstraintReference,
                    value === '' ? value : +value
                  )
                }
                type={INPUT_TYPE.NUMBER}
                notDefaultValueHilighted={false}
              />
            </Col>
            <Col>
              <p className='opti-goals-section-caption'>
                {i18n._('compute.opti.goals.refCaption')}
              </p>
            </Col>
          </Row>
        )}
      {selectedParam.key !== GOAL.InitConstraintNone.key && (
        <Row>
          <Col className='opti-inputs-label'>
            {i18n._('compute.opti.goals.percentGoal')}
          </Col>
          <Col>
            <ParamInput
              value={selectedParamValue}
              param={selectedParam}
              onChange={(value) =>
                onConstraintChange(selectedParam, value === '' ? value : +value)
              }
              type={INPUT_TYPE.NUMBER}
              notDefaultValueHilighted={false}
            />
          </Col>
          <Col>
            <div className='opti-goals-counter'>
              <Counter
                value={calculationData.constraints.inp.InitConstraintNbOptim}
                min={1}
                max={5}
                onChange={(value) =>
                  onConstraintChange(GOAL.InitConstraintNbOptim, value)
                }
              />
              <FontAwesomeIcon
                icon='fa-regular fa-circle-question'
                data-tooltip-id='opti-counter-tooltip'
                data-tooltip-content={
                  selectedParam.key === GOAL.InitConstraintGasPart.key
                    ? i18n._('compute.opti.goals.goalCaption.gas')
                    : i18n._('compute.opti.goals.goalCaption')
                }
              />
              <Tooltip
                id='opti-counter-tooltip'
                place='bottom'
                className='info-tooltip'
                opacity={1}
              />
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
  //#endregion
};

export default React.memo(OptiGoalsSection);
