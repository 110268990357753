import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import { isNull } from '../../utils/data.utils';
import { getInputError } from '../../utils/error.utils';
import './ParamForm.css';

const ParamInput = ({
  className,
  value,
  disabled,
  param,
  label,
  bottomText,
  topText,
  unit,
  showInfoIcon,
  infoIcon,
  infoTooltip,
  type,
  notDefaultValueHilighted = true,
  onChange,
  onBlur,
  onInfoClick
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [methods]
  const getEvtTargetValue = (evt) => {
    return type === 'number' &&
      !isNull(evt.target.value) &&
      evt.target.value !== ''
      ? +evt.target.value
      : evt.target.value;
  };

  const handleValueChange = (evt) => {
    if (!onChange) return;
    onChange(getEvtTargetValue(evt));
  };

  const handleInputBlur = (evt) => {
    if (!onBlur) return;
    onBlur(getEvtTargetValue(evt));
  };
  //#endregion

  //#region [render]
  const inputId = `input_${param.key}_` + crypto.randomUUID(); // on s'assure que l'id est unique
  const inputError = disabled ? null : getInputError(i18n, value, param);
  let inputClassName = '';
  if (inputError) {
    inputClassName = 'param-input-error';
  } else if (!disabled && value != param.default && notDefaultValueHilighted) {
    inputClassName = 'param-input-changed';
  }
  return (
    <div className={className}>
      {label && (
        <span className='param-input-label'>
          {label}
          {showInfoIcon && (
            <>
              <FontAwesomeIcon
                icon={infoIcon ?? 'circle-question'}
                onClick={onInfoClick}
                data-tooltip-content={infoTooltip}
                data-tooltip-id={'info_' + inputId}
                className={infoTooltip ? '' : 'modal-info-icon'}
              />
              {infoTooltip && (
                <Tooltip
                  id={'info_' + inputId}
                  place='right'
                  className='info-tooltip'
                  opacity={1}
                />
              )}
            </>
          )}
        </span>
      )}
      <div className='param-input-body'>
        {topText && (
          <p className='param-input-small-text param-input-top-text'>
            {topText}
          </p>
        )}
        <InputGroup className='param-input'>
          <Form.Control
            className={inputClassName}
            type={type}
            disabled={disabled}
            onChange={handleValueChange}
            onBlur={handleInputBlur}
            onWheel={(e) => e.target.blur()}
            value={value}
            aria-describedby='form_input'
            data-tooltip-content={inputError}
            data-tooltip-id={'error_' + inputId}
            maxLength={param.maxLength}
            name={param.key}
          />
          {unit && param.unit && (
            <InputGroup.Text id='form_input' className='param-input-unit'>
              {i18n._(`unit.${param.unit}`)}
            </InputGroup.Text>
          )}
        </InputGroup>
        {bottomText && (
          <span className='param-input-small-text'>{bottomText}</span>
        )}
        {inputError && (
          <Tooltip
            id={'error_' + inputId}
            place='bottom'
            className='error-tooltip'
            arrowColor='#f04460'
            opacity={1}
          />
        )}
      </div>
    </div>
  );
  //#endregion
};

export default React.memo(ParamInput);
