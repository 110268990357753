import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { OPEX_TYPES } from '../../../../../../../../server/constants';
import LinesChart from '../../../../../../components/Chart/LinesChart';
import { CHART_MODE } from '../../../../../../constants';
import { OPEX_TYPES_CHART_COLORS } from '../../../../../../styles/colors';
import { isArrNullOrEmpty } from '../../../../../../utils/data.utils';

const DetailedOpexLinesChart = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    const opexObjects = OPEX_TYPES.filter(
      (type) => !isArrNullOrEmpty(result.ComputeResult.summary[type])
    ).map((type) => ({
      type,
      values: result.ComputeResult.summary[type]
    }));
    if (isArrNullOrEmpty(opexObjects)) return null;

    const { InitYearsSimulations } = result.ComputeResult.inp;
    const x = new Array(InitYearsSimulations + 1).fill(0).map((_, i) => i + 1);
    return opexObjects.map((opexObject) => ({
      x,
      y: opexObject.values,
      name: i18n._(`results.economicAnalysis.opexType.${opexObject.type}`),
      line: { color: OPEX_TYPES_CHART_COLORS[opexObject.type] },
      mode: CHART_MODE.LINES_MARKERS,
      hovertemplate: '%{y:.2s}'
    }));
  }, [result.ComputeID]);
  //#endregion

  //#region [render]
  return data ? (
    <LinesChart
      data={data}
      layout={{ xaxis: { title: i18n._('date.years') } }}
      title={i18n._('results.economicAnalysis.detailedOpexLinesChart.title')}
      filename={i18n._('plotly.filename', {
        projectName: result.ComputeResult.inp.description,
        title: i18n._('results.economicAnalysis.detailedOpexLinesChart.title')
      })}
    />
  ) : null;
  //#endregion
};

export default DetailedOpexLinesChart;
