import axios from '../conf/axios.conf';

//#region [GET]
export const fetchProjects = async (companyId, active) => {
  try {
    const url = `/company/${companyId}/projects?active=${active}`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchUsers = async (companyId) => {
  try {
    const url = `/company/${companyId}/users`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchProjectHeader = async (companyId, projectId) => {
  try {
    const url = `/company/${companyId}/project/${projectId}/header`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchNewProject = async (companyId) => {
  try {
    const response = await axios.get(`/company/${companyId}/newProject`);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion
