// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
  margin-top: 10px;
}

.results-btns button {
  font-size: 15px;
}

.results-btns button:disabled {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  opacity: 1;
}

.results-btns .btn-primary:focus {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.results-title {
  font-size: 17px;
  font-weight: bold;
  color: black;
  margin: 20px 0 10px 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/ResultsElements/ResultsElements.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;EAC3B,QAAQ;EACR,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,sCAAsC;EACtC,kCAAkC;EAClC,UAAU;AACZ;;AAEA;EACE,sCAAsC;EACtC,kCAAkC;AACpC;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,qBAAqB;AACvB","sourcesContent":[".results-btns {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  gap: 5px;\n  margin-top: 10px;\n}\n\n.results-btns button {\n  font-size: 15px;\n}\n\n.results-btns button:disabled {\n  background-color: var(--primary-color);\n  border-color: var(--primary-color);\n  opacity: 1;\n}\n\n.results-btns .btn-primary:focus {\n  background-color: var(--primary-color);\n  border-color: var(--primary-color);\n}\n\n.results-title {\n  font-size: 17px;\n  font-weight: bold;\n  color: black;\n  margin: 20px 0 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
