import { isNull } from './data.utils';

export const getInputError = (i18n, value, param) => {
  // le champ est vide et non-obligatoire : pas d'erreur
  if (
    !param.required &&
    (isNull(value) || value.toString().trim().length === 0)
  )
    return null;

  let error;
  if (
    param.required &&
    (isNull(value) || value.toString().trim().length === 0)
  ) {
    // le param est obligatoire mais la valeur est manquante
    error = i18n._('input.error.required');
  } else if (param.type === 'number') {
    // le param est un nombre
    if (isNaN(value)) {
      error = i18n._('input.error.notNumber');
    } else if (param.min !== null && +value < param.min) {
      error = i18n._('input.error.min', { min: param.min });
    } else if (param.max !== null && +value > param.max) {
      error = i18n._('input.error.max', { max: param.max });
    }
  } else if (param.type === 'string' || param.type === 'password') {
    value = value.toString();
    if (param.minLength && value.length < param.minLength) {
      error = i18n._('input.error.minLength', { min: param.minLength });
    } else if (param.maxLength && value.length > param.maxLength) {
      error = i18n._('input.error.maxLength', { max: param.maxLength });
    } else if (param.pattern && !param.pattern.test(value)) {
      error = i18n._('input.error.pattern');
    }
  }
  return error;
};
