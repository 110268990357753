import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PV_EQUIPMENT } from '../../../../../../../../../server/constants';
import {
  getParamsConstraints,
  getSstParamsConstraints
} from '../../../../../../../../../server/models/design/calculationData.model';
import { INPUT_TYPE } from '../../../../../../../constants';
import { isCalculationDataParamPresent } from '../../../../../../../utils/calculationData.utils';
import HPAPowerMaxModal from '../../../components/HPAPowerMaxModal';
import HPGColdBtesPowerMaxModal from '../../../components/HPGColdBtesPowerMaxModal';
import HPGHeatBtesPowerMaxModal from '../../../components/HPGHeatBtesPowerMaxModal';
import HPGPowerMaxPerSSTModal from '../../../components/HPGPowerMaxPerSSTModal';
import KilowattCretePVModal from '../../../components/KilowattCretePVModal';
import SimuInput from '../../components/SimuInput';
import SimuSelect from '../../components/SimuSelect';
import './SimuSizingSection.css';

const SimuSizingSection = ({
  project,
  calculationData,
  selectedProject,
  onConstraintChange,
  onHPGPowerMaxChange,
  onBtesLengthValueChange,
  onSTSurfaceValueChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const constraints = useMemo(
    () => getParamsConstraints(selectedProject),
    [selectedProject]
  );
  //#endregion

  //#region [render]
  return (
    <div className='simulation-section'>
      <h2>{i18n._('compute.simu.sizing')}</h2>
      {isCalculationDataParamPresent(
        constraints.BtesLength.key,
        calculationData
      ) && (
        <SimuInput
          label={i18n._('compute.BtesLength')}
          value={calculationData.constraints.params.BtesLength}
          param={constraints.BtesLength}
          onChange={(_, value) => onBtesLengthValueChange(value)}
        />
      )}
      {isCalculationDataParamPresent(
        constraints.BtesProbeDepth.key,
        calculationData
      ) && (
        <SimuSelect
          label={i18n._('compute.BtesProbeDepth')}
          value={calculationData.constraints.params.BtesProbeDepth}
          param={constraints.BtesProbeDepth}
          onChange={onConstraintChange}
        />
      )}
      {isCalculationDataParamPresent(
        constraints.BtesProbeDistance.key,
        calculationData
      ) && (
        <SimuSelect
          label={i18n._('compute.BtesProbeDistance')}
          value={calculationData.constraints.params.BtesProbeDistance}
          param={constraints.BtesProbeDistance}
          onChange={onConstraintChange}
        />
      )}
      {isCalculationDataParamPresent(
        constraints.T2final_Btes.key,
        calculationData
      ) && (
        <SimuInput
          label={i18n._('compute.T2final_Btes')}
          value={calculationData.constraints.params.T2final_Btes}
          param={constraints.T2final_Btes}
          onChange={onConstraintChange}
        />
      )}
      {isCalculationDataParamPresent(
        constraints.HPGHeatBtesPowerMax.key,
        calculationData
      ) && (
        <SimuInput
          label={i18n._('compute.HPGHeatBtesPowerMax')}
          value={calculationData.constraints.params.HPGHeatBtesPowerMax}
          param={constraints.HPGHeatBtesPowerMax}
          onChange={onConstraintChange}
          modalMaxInfo={{
            title: i18n._('compute.HPGHeatBtesPowerMax.info.title'),
            body: (
              <HPGHeatBtesPowerMaxModal
                max={constraints.HPGHeatBtesPowerMax.max}
              />
            )
          }}
        />
      )}
      {isCalculationDataParamPresent(
        constraints.HPGColdBtesPowerMax.key,
        calculationData
      ) && (
        <SimuInput
          label={i18n._('compute.HPGColdBtesPowerMax')}
          value={calculationData.constraints.params.HPGColdBtesPowerMax}
          param={constraints.HPGColdBtesPowerMax}
          onChange={onConstraintChange}
          modalMaxInfo={{
            title: i18n._('compute.HPGColdBtesPowerMax.info.title'),
            body: (
              <HPGColdBtesPowerMaxModal
                max={constraints.HPGColdBtesPowerMax.max}
              />
            )
          }}
        />
      )}
      {isCalculationDataParamPresent(
        constraints.HPAPowerMax.key,
        calculationData
      ) && (
        <SimuInput
          label={i18n._('compute.HPAPowerMax')}
          value={calculationData.constraints.params.HPAPowerMax}
          param={constraints.HPAPowerMax}
          onChange={onConstraintChange}
          modalMaxInfo={{
            title: i18n._('compute.HPAPowerMax.info.title'),
            body: <HPAPowerMaxModal max={constraints.HPAPowerMax.max} />
          }}
        />
      )}
      {isCalculationDataParamPresent(
        constraints.GasPowerMax.key,
        calculationData
      ) && (
        <SimuInput
          label={i18n._('compute.GasPowerMax')}
          value={calculationData.constraints.params.GasPowerMax}
          param={constraints.GasPowerMax}
          onChange={onConstraintChange}
        />
      )}
      {isCalculationDataParamPresent(
        constraints.SolarThermalSurface.key,
        calculationData
      ) && (
        <SimuInput
          label={i18n._('compute.SolarThermalSurface')}
          value={calculationData.constraints.params.SolarThermalSurface}
          param={constraints.SolarThermalSurface}
          onChange={(_, value) => onSTSurfaceValueChange(value)}
        />
      )}
      {isCalculationDataParamPresent(
        constraints.KiloWattCretePV.key,
        calculationData
      ) && (
        <SimuInput
          label={i18n._('compute.KiloWattCretePV')}
          value={calculationData.constraints.params.KiloWattCretePV}
          param={constraints.KiloWattCretePV}
          onChange={onConstraintChange}
          modalInfo={{
            title: i18n._('compute.KiloWattCretePV'),
            body: <KilowattCretePVModal />
          }}
          disabled={
            calculationData.constraints.params.SolarThermalSurface > 0 &&
            project.otherValues.InitPhotovoltaicModel ===
              PV_EQUIPMENT.DUALSUN.value
          }
        />
      )}
      {isCalculationDataParamPresent(
        constraints.QItesMax.key,
        calculationData
      ) && (
        <SimuInput
          label={i18n._('compute.QItesMax')}
          value={calculationData.constraints.params.QItesMax}
          param={constraints.QItesMax}
          onChange={onConstraintChange}
        />
      )}
      {isCalculationDataParamPresent('HPGPowerMaxPerSST', calculationData) && (
        <Row>
          <Col xs='7'>
            <span className='param-input-label'>
              {i18n._('compute.HPGPowerMaxPerSST')}
            </span>
          </Col>
          <Col xs='5'>
            <Row>
              {project.substations.map((sst, index) => (
                <Col key={'sst_inputs_col_label' + index} className='ps-0 pe-0'>
                  <span className='simu-sizing-sst-name'>
                    {sst.InitStationName}
                  </span>
                </Col>
              ))}
            </Row>
            <Row>
              {project.substations.map((sst, index) => {
                const sstConstraints = getSstParamsConstraints(sst);
                return (
                  <Col
                    key={'sst_inputs_col_value' + index}
                    className='ps-0 pe-0 simu-sizing-sst-col'
                  >
                    <SimuInput
                      value={
                        calculationData.constraints.params.HPGPowerMaxPerSST[
                          index
                        ]
                      }
                      param={sstConstraints.HPGPowerMaxPerSST}
                      onChange={(_, value) => onHPGPowerMaxChange(value, index)}
                      modalMaxInfo={{
                        title: i18n._('compute.HPGPowerMaxPerSST.info.title'),
                        body: (
                          <HPGPowerMaxPerSSTModal
                            max={sstConstraints.HPGPowerMaxPerSST.max}
                          />
                        )
                      }}
                      type={INPUT_TYPE.NUMBER}
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
  //#endregion
};

export default SimuSizingSection;
