import { useLingui } from '@lingui/react';
import React from 'react';
import { Alert } from 'react-bootstrap';
import ExpandableProperty from '../ExpandableProperty/ExpandableProperty';
import './RecursiveProperty.css';

const RecursiveProperty = ({ property, propertyName, rootProperty, level }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <>
      {property !== null && property !== undefined ? (
        typeof property === 'number' ||
        typeof property === 'string' ||
        typeof property === 'boolean' ? (
          <tr>
            <td style={{ paddingLeft: `${(level + 1) * 10}px` }}>
              {propertyName}
            </td>
            <td className='recursive-prop-value' title={property.toString()}>
              {property.toString()}
            </td>
          </tr>
        ) : (
          <ExpandableProperty
            title={propertyName}
            expanded={!!rootProperty}
            level={level}
          >
            {Object.values(property).map((newProperty, index) => {
              return (
                <RecursiveProperty
                  key={index}
                  property={newProperty}
                  propertyName={Object.getOwnPropertyNames(property)[index]}
                  level={level + 1}
                />
              );
            })}
          </ExpandableProperty>
        )
      ) : (
        <tr>
          <td colSpan={2}>
            <Alert variant='warning'>
              {i18n._('results.simulationParams.recursivePropNoData')}
            </Alert>
          </td>
        </tr>
      )}
    </>
  );
  //#endregion
};

export default React.memo(RecursiveProperty);
