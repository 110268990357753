import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import Section from '../../../../../../../components/Section/Section';
import TwoColsTemplateTable from '../../../../../../../components/TemplateTables/TwoColsTemplateTable/TwoColsTemplateTable';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import './HeadLossSummarySection.css';
import headlossTemplate from './templates';

const HeadLossSummarySection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [memos]
  const HTemplates = useMemo(() => {
    return headlossTemplate(i18n, config.ConfigsSst[0]);
  }, [config]);
  //#endregion

  //#region [render]
  return (
    <Section
      title={i18n._('config.geostorage.headLoss.summary')}
      level={2}
      open
      className='headLoss-summary-section'
    >
      <TwoColsTemplateTable template={HTemplates} />
    </Section>
  );
  //#endregion
};

export default HeadLossSummarySection;
