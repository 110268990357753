import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './SelectBtn.css';

const SelectBtn = ({ children, onClick, selected, disabled, color }) => {
  //#region [render]
  const btnStyle = !selected
    ? {
        color: 'black',
        backgroundColor: 'white',
        border: `1px solid rgb(238, 238, 238)`
      }
    : { color: 'white', backgroundColor: color, border: `1px solid ${color}` };
  const iconStyle = { color: !selected ? color : 'white' };
  return (
    <div
      className={'select-btn ' + (disabled ? 'cursor-auto' : 'cursor-pointer')}
      onClick={!disabled ? onClick : null}
      style={btnStyle}
      onMouseEnter={(evt) => {
        if (!selected) evt.currentTarget.style.border = `1px solid ${color}`;
      }}
      onMouseLeave={(evt) => {
        if (!selected)
          evt.currentTarget.style.border = `1px solid rgb(238, 238, 238)`;
      }}
    >
      <FontAwesomeIcon icon={selected ? 'check' : 'plus'} style={iconStyle} />
      {children}
    </div>
  );
  //#endregion
};

export default React.memo(SelectBtn);
