const { USAGE, UNIT } = require('../../constants');

const AhsID = Object.freeze({
  AhsID: {
    key: 'AhsID',
    type: 'string',
    pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
  },
  ParentAhsID: {
    key: 'ParentAhsID',
    type: 'string',
    pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
  },
  AhsCoID: {
    key: 'AhsCoID',
    type: 'string',
    pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
  }
});

const NAME = Object.freeze({
  description: {
    key: 'description',
    type: 'string',
    pattern: /^(?!\s)(?:\s*\S\s*){3,}$/,
    default: null,
    minLength: 3,
    maxLength: 255
  }
});

const NEEDS = Object.freeze({
  InitStationHasHeatNeeds: {
    key: 'InitStationHasHeatNeeds',
    type: 'boolean',
    default: true
  },
  InitStationHasColdNeeds: {
    key: 'InitStationHasColdNeeds',
    type: 'boolean',
    default: true
  },
  InitStationHasHotWaterNeeds: {
    key: 'InitStationHasHotWaterNeeds',
    type: 'boolean',
    default: true
  }
});

const ENERGY_STORAGE = Object.freeze({
  InitBtesEnabled: {
    key: 'InitBtesEnabled',
    type: 'boolean',
    default: true
  },
  ItesEnabled: {
    key: 'ItesEnabled',
    type: 'boolean',
    default: false
  }
});

const GEOSTORAGE = Object.freeze({
  InitLoopIsUsed: {
    key: 'InitLoopIsUsed',
    type: 'boolean',
    default: false
  },
  InitLoopWaterType: {
    key: 'InitLoopWaterType',
    default: 'B',
    values: ['W', 'B']
  },
  InitHPAInjectionEnabled: {
    key: 'InitHPAInjectionEnabled',
    type: 'boolean',
    default: false
  }
});

function getHeatProductionParams(heatTemperature = 0) {
  return {
    InitTemperatureHeat: {
      key: 'InitTemperatureHeat',
      min: 25,
      max: 80,
      type: 'number',
      default: 45,
      unit: UNIT.CELSIUS_DEGREE,
      required: true
    },
    InitTemperatureHeatBack: {
      key: 'InitTemperatureHeatBack',
      min: +heatTemperature - 40,
      max: +heatTemperature - 3,
      type: 'number',
      default: 40,
      unit: UNIT.CELSIUS_DEGREE,
      required: true
    },
    InitTemperatureHeatDelta: {
      type: 'number',
      key: 'InitTemperatureHeatDelta',
      default: 5,
      required: true
    },
    InitWaterLawIsUsed: {
      key: 'InitWaterLawIsUsed',
      type: 'boolean',
      default: false
    },
    InitStationTemperatureSetPoint: {
      key: 'InitStationTemperatureSetPoint',
      type: 'number',
      required: true,
      min: 10,
      max: 30,
      default: 19,
      unit: UNIT.CELSIUS_DEGREE
    },
    InitStationWaterTankHotTemperatureMin: {
      key: 'InitStationWaterTankHotTemperatureMin',
      type: 'number',
      required: true,
      min: 24,
      max: +heatTemperature,
      default: 30,
      unit: UNIT.CELSIUS_DEGREE
    },
    InitHPGHeatingEnabled: {
      key: 'InitHPGHeatingEnabled',
      type: 'boolean',
      default: ENERGY_STORAGE.InitBtesEnabled.default
    },
    InitHPAHeatingEnabled: {
      key: 'InitHPAHeatingEnabled',
      type: 'boolean',
      default: false
    },
    InitGasEnabled: {
      key: 'InitGasEnabled',
      type: 'boolean',
      default: false
    },
    InitReleveEnabled: {
      key: 'InitReleveEnabled',
      type: 'boolean',
      default: false
    },
    InitSolarThermalEnabled: {
      key: 'InitSolarThermalEnabled',
      type: 'boolean',
      default: false
    }
  };
}

function getColdProductionParams(coldTemperature = 0) {
  return {
    InitTemperatureCold: {
      key: 'InitTemperatureCold',
      min: -5,
      max: 20,
      type: 'number',
      required: true,
      default: 8,
      unit: UNIT.CELSIUS_DEGREE
    },
    InitTemperatureColdBack: {
      key: 'InitTemperatureColdBack',
      min: +coldTemperature + 3,
      max: +coldTemperature + 20,
      type: 'number',
      required: true,
      default: 12,
      unit: UNIT.CELSIUS_DEGREE
    },
    InitStationEvapTemperatureDelta: {
      key: 'InitStationEvapTemperatureDelta',
      default: 4,
      required: true
    },
    InitHPGCoolingEnabled: {
      key: 'InitHPGCoolingEnabled',
      type: 'boolean',
      default: ENERGY_STORAGE.InitBtesEnabled.default
    },
    InitHPACoolingEnabled: {
      key: 'InitHPACoolingEnabled',
      type: 'boolean',
      default: false
    }
  };
}

const HOTWATER_PRODUCTION = Object.freeze({
  InitTemperatureHotWater: {
    key: 'InitTemperatureHotWater',
    min: 40,
    max: 75,
    type: 'number',
    required: true,
    default: 60,
    unit: UNIT.CELSIUS_DEGREE
  },
  InitTemperatureHotWaterBack: {
    key: 'InitTemperatureHotWaterBack',
    min: 4,
    max: 40,
    type: 'number',
    required: true,
    default: 12,
    unit: UNIT.CELSIUS_DEGREE
  }
});

const SST = Object.freeze({
  AhsID: {
    key: 'AhsID',
    type: 'string',
    pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
  },
  InitStationID: {
    key: 'InitStationID',
    type: 'string',
    pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
  },
  ParentInitStationID: {
    key: 'ParentInitStationID',
    type: 'string',
    pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
  },
  InitBuildingSurface: {
    key: 'InitBuildingSurface',
    min: 100,
    max: 1000000,
    type: 'number',
    required: true,
    default: null,
    unit: UNIT.SQUARE_METER
  },
  InitStationName: {
    key: 'InitStationName',
    type: 'string',
    required: true,
    default: null,
    minLength: 3,
    maxLength: 255
  },
  InitStationUse: {
    key: 'InitStationUse',
    required: true,
    default: USAGE.TERTIARY,
    type: 'string',
    values: Object.values(USAGE),
    translateValuesIds: Object.values(USAGE).map((usage) => `usage.${usage}`) // TODO côté front
  },
  InitStationOrder: {
    key: 'InitStationOrder',
    type: 'number',
    default: 1,
    min: 0
  },
  StdFileID: {
    key: 'StdFileID',
    required: true,
    default: null,
    type: 'string',
    pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
  }
});

const ELECTRIC_PRODUCTION = Object.freeze({
  InitPhotovoltaicEnabled: {
    key: 'InitPhotovoltaicEnabled',
    type: 'boolean',
    default: false
  }
});

const WEATHER = Object.freeze({
  WeatherFileID: {
    key: 'WeatherFileID',
    required: true,
    default: null,
    type: 'string',
    pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
  }
});

function getDescriptionParams(heatTemperature, coldTemperature) {
  return [
    ...Object.values(AhsID),
    ...Object.values(NAME),
    ...Object.values(NEEDS),
    ...Object.values(ENERGY_STORAGE),
    ...Object.values(GEOSTORAGE),
    ...Object.values(getHeatProductionParams(heatTemperature)),
    ...Object.values(getColdProductionParams(coldTemperature)),
    ...Object.values(HOTWATER_PRODUCTION),
    ...Object.values(ELECTRIC_PRODUCTION),
    ...Object.values(WEATHER),
    ...Object.values(SST)
  ];
}

const STD_COLS = Object.freeze({
  TIME_CODE: {
    mandatory: true,
    unit: null,
    min: 1,
    max: 8760,
    isNeed: false,
    name: 'TIME_CODE',
    type: 'number'
  },
  HEAT_NEED: {
    mandatory: true,
    unit: UNIT.KILOWATT,
    range: null,
    isNeed: true,
    name: 'HEAT_NEED',
    min: 0,
    type: 'number'
  },
  COLD_NEED: {
    mandatory: true,
    unit: UNIT.KILOWATT,
    range: null,
    isNeed: true,
    name: 'COLD_NEED',
    min: 0,
    type: 'number'
  },
  HOTWATER_NEED: {
    mandatory: true,
    unit: UNIT.KILOWATT,
    range: null,
    isNeed: true,
    name: 'HOTWATER_NEED',
    min: 0,
    type: 'number'
  },
  ELECTRICITY_PRICE: {
    mandatory: true,
    unit: UNIT.EURO_PER_KILOWATT,
    range: null,
    isNeed: false,
    name: 'ELECTRICITY_PRICE',
    min: 0,
    type: 'number'
  },
  PHOTOVOLTAIC_PRODUCTION: {
    mandatory: false,
    unit: UNIT.KILOWATT_PER_SQUARE_METER,
    range: null,
    isNeed: false,
    name: 'PHOTOVOLTAIC_PRODUCTION',
    type: 'number'
  },
  SOLAR_THERMAL_PRODUCTION: {
    mandatory: false,
    unit: UNIT.KILOWATT_PER_SQUARE_METER,
    range: null,
    isNeed: false,
    name: 'SOLAR_THERMAL_PRODUCTION',
    type: 'number'
  },
  ELECTRICITY_CONSUMPTION: {
    mandatory: false,
    unit: UNIT.KILOWATT_HOUR_ELECTRIC,
    range: null,
    isNeed: false,
    name: 'ELECTRICITY_CONSUMPTION',
    type: 'number'
  },
  ELECTRICITY_PRICE_PV_PROJECT: {
    mandatory: false,
    unit: UNIT.EURO_PER_KILOWATT_HOUR_ELECTRIC,
    range: null,
    isNeed: false,
    name: 'ELECTRICITY_PRICE_PV_PROJECT',
    type: 'number'
  },
  ELECTRICITY_PRICE_PV_NETWORK: {
    mandatory: false,
    unit: UNIT.EURO_PER_KILOWATT_HOUR_ELECTRIC,
    range: null,
    isNeed: false,
    name: 'ELECTRICITY_PRICE_PV_NETWORK',
    type: 'number'
  }
});

const WEATHER_COLS = Object.freeze({
  HOUR: {
    mandatory: false,
    name: 'HOUR',
    min: 1,
    max: 24,
    type: 'number',
    name: 'HOUR'
  },
  STATION_ID: {
    mandatory: false,
    name: 'STATION_ID',
    error: 'errorStationId',
    type: 'string',
    name: 'STATION_ID'
  },
  OUTSIDE_TEMPERATURE: {
    mandatory: true,
    name: 'OUTSIDE_TEMPERATURE',
    unit: UNIT.CELSIUS_DEGREE,
    min: -70,
    max: 70,
    type: 'number',
    name: 'OUTSIDE_TEMPERATURE'
  },
  GLOBAL_RADIATION: {
    mandatory: false,
    name: 'GLOBAL_RADIATION',
    unit: UNIT.WATT_HOUR_PER_SQUARE_METER,
    min: 0,
    max: 2000,
    type: 'number',
    name: 'GLOBAL_RADIATION'
  },
  DIFFUSE_RADIATION: {
    mandatory: false,
    name: 'DIFFUSE_RADIATION',
    unit: UNIT.WATT_HOUR_PER_SQUARE_METER,
    min: 0,
    max: 850,
    accepted: ['E'],
    type: 'number',
    name: 'DIFFUSE_RADIATION'
  },
  NORMAL_DIRECT_RADIATION: {
    mandatory: false,
    name: 'NORMAL_DIRECT_RADIATION',
    unit: UNIT.WATT_HOUR_PER_SQUARE_METER,
    min: 0,
    max: 1700,
    accepted: ['E'],
    type: 'number',
    name: 'NORMAL_DIRECT_RADIATION'
  },
  SUNSHINE_DURATION: {
    mandatory: false,
    name: 'SUNSHINE_DURATION',
    unit: UNIT.MINUTES,
    min: 0,
    max: 1440,
    accepted: ['E'],
    type: 'number',
    name: 'SUNSHINE_DURATION'
  },
  RELATIVE_HUMIDITY: {
    mandatory: true,
    name: 'RELATIVE_HUMIDITY',
    unit: UNIT.PERCENT,
    min: 0,
    max: 110,
    accepted: ['E'],
    type: 'number',
    name: 'RELATIVE_HUMIDITY'
  },
  WIND_SPEED: {
    mandatory: true,
    name: 'WIND_SPEED',
    unit: UNIT.METER_PER_SECOND,
    min: 0,
    max: 40,
    type: 'number',
    name: 'WIND_SPEED'
  },
  MONTH: {
    mandatory: false,
    name: 'MONTH',
    min: 1,
    max: 12,
    type: 'number',
    name: 'MONTH'
  },
  DAY: {
    mandatory: false,
    name: 'DAY',
    min: 1,
    max: 31,
    type: 'number',
    name: 'DAY'
  }
});

module.exports = {
  AhsID,
  NAME,
  NEEDS,
  ENERGY_STORAGE,
  GEOSTORAGE,
  HOTWATER_PRODUCTION,
  ELECTRIC_PRODUCTION,
  SST,
  WEATHER,
  STD_COLS,
  WEATHER_COLS,
  getHeatProductionParams,
  getColdProductionParams,
  getDescriptionParams
};
