import { useLingui } from '@lingui/react';
import React from 'react';
import Bloc from '../../../../../components/Bloc/Bloc';
import TileRadio from '../../../../../components/Tile/TileRadio';
import btesImg from '../../../../../image/storage-btes.png';
import itesImg from '../../../../../image/storage-ites.png';
import noStorageImg from '../../../../../image/storage-noStorage.png';
import './EnergyStorageBloc.css';

const EnergyStorageBloc = ({
  form,
  onNoStorageChange,
  onBtesChange,
  onItesChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  const isBtesVisible =
    form.InitStationHasHeatNeeds ||
    form.InitStationHasHotWaterNeeds ||
    form.InitStationHasColdNeeds;
  const isItesVisible =
    !form.InitStationHasHeatNeeds &&
    !form.InitStationHasHotWaterNeeds &&
    form.InitStationHasColdNeeds;
  return (
    <Bloc title={i18n._('description.energyStorage')}>
      <div className='energy-storage-tiles-row'>
        <TileRadio
          title={i18n._('description.energyStorage.noStorage')}
          image={noStorageImg}
          checked={!form.InitBtesEnabled && !form.ItesEnabled}
          onChange={onNoStorageChange}
        />
        {isBtesVisible && (
          <TileRadio
            title={i18n._('description.energyStorage.btes')}
            image={btesImg}
            checked={form.InitBtesEnabled}
            onChange={onBtesChange}
          />
        )}
        {isItesVisible && (
          <TileRadio
            title={i18n._('description.energyStorage.ites')}
            image={itesImg}
            checked={form.ItesEnabled}
            onChange={onItesChange}
          />
        )}
      </div>
    </Bloc>
  );
  //#endregion
};

export default React.memo(EnergyStorageBloc);
