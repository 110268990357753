import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { INPUT_TYPE } from '../../constants';
import { getDefaultValue } from '../../utils/param.utils';
import ParamInput from '../ParamForm/ParamInput';
import './EditTable.css';

const EditTable = ({ titles, values, params, onChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const extendedParams = useMemo(() => {
    const newExtendedParams = {};
    params.forEach((param) => {
      newExtendedParams[param.key] = [];
      Object.entries(param.default).forEach(([key, value]) => {
        newExtendedParams[param.key].push({
          key: param.key + '_' + key,
          type: 'number',
          min: param.min,
          max: param.max,
          unit: param.unit,
          default: value,
          required: true
        });
      });
    });
    return newExtendedParams;
  }, [params]);
  //#endregion

  //#region [render]
  const nbRows = Object.values(values[0]).length;
  const nbCols = values.length;
  return (
    <table className='custom-table edit-table'>
      <thead>
        <tr>
          {titles.map((title) => (
            <th key={'edit_table_th_' + title}>{title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: nbRows }, (_, rowIndex) => (
          <tr key={'edit_table_tr_' + rowIndex}>
            {Array.from({ length: nbCols + 1 }, (_, colIndex) => {
              const rowKey = Object.keys(values[0])[rowIndex];
              return (
                <td key={'edit_table_td_' + rowIndex + '_' + colIndex}>
                  {colIndex === 0 ? (
                    rowKey
                  ) : (
                    <ParamInput
                      param={extendedParams[params[colIndex - 1].key][rowIndex]}
                      value={Object.values(values[colIndex - 1])[rowIndex]}
                      onChange={(val) => {
                        onChange(params[colIndex - 1].key, rowKey, val);
                      }}
                      bottomText={getDefaultValue(
                        i18n,
                        extendedParams[params[colIndex - 1].key][rowIndex]
                      )}
                      type={INPUT_TYPE.NUMBER}
                    />
                  )}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
  //#endregion
};

export default EditTable;
