import { useLingui } from '@lingui/react';
import { cloneDeep } from 'lodash';
import React, { useContext, useState } from 'react';
import { updateConfigSst } from '../../../../../api/configSst.api';
import Bloc from '../../../../../components/Bloc/Bloc';
import SaveButton from '../../../../../components/SaveButton/SaveButton';
import { FORM_STATUS } from '../../../../../constants';
import ConfigsContext from '../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../contexts/PopupContext';
import ConfigFormPage from '../../../components/ConfigFormPage/ConfigFormPage';
import './HydraulicCalculationsPage.css';
import CollectorsHeadLossSection from './sections/CollectorsHeadLossSection';
import DestinationsHeadLossSection from './sections/DestinationsHeadLossSection';
import HeadLossTotalSection from './sections/HeadLossTotalSection';
import HpsHeadLossSection from './sections/HpsHeadLossSection';
import HpsPipingHeadLossSection from './sections/HpsPipingHeadLossSection';

const HydraulicCalculationsPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config, setConfig, refreshConfig } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [formStatus, setFormStatus] = useState(FORM_STATUS.ORIGIN);
  //#endregion

  //#region [methods]
  const save = async () => {
    try {
      await updateConfigSst(config.ConfigsSst[0]);
      await refreshConfig();
    } catch (err) {
      throw err;
    }
  };

  const handleLoading = (loading) => {
    setFormStatus(() => (loading ? FORM_STATUS.LOADING : FORM_STATUS.DIRTY));
  };

  const handleFormChange = (conf) => {
    setConfig(() => cloneDeep(conf));
    setFormStatus(() => FORM_STATUS.DIRTY);
  };

  const handleSaveClick = async () => {
    try {
      await save();
      setFormStatus(() => FORM_STATUS.SAVED);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  return (
    <ConfigFormPage formStatus={formStatus} save={save}>
      <Bloc
        title={i18n._('config.pumps.headLoss.title')}
        className='pumps-calculations-bloc'
      >
        <HpsHeadLossSection />
        <HpsPipingHeadLossSection
          onFormChange={handleFormChange}
          onLoading={handleLoading}
        />
        <CollectorsHeadLossSection
          onFormChange={handleFormChange}
          onLoading={handleLoading}
        />
        <DestinationsHeadLossSection />
        <HeadLossTotalSection />
      </Bloc>
      <SaveButton formStatus={formStatus} onClick={handleSaveClick} />
    </ConfigFormPage>
  );
  //#endregion
};

export default HydraulicCalculationsPage;
