import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import { MODULE_TYPE } from '../../../../../../../../server/constants';
import {
  getPrimaryModuleParams,
  getSecondaryModuleParams
} from '../../../../../../../../server/models/config/buildingDescription/services/hydraulic.model';
import Section from '../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import HydraulicEquipmentTable from '../components/HydraulicEquipmentTable/HydraulicEquipmentTable';

const HydraulicEquipmentSection = ({ onFormChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const getTableData = (module, needs) => {
    return Object.entries(module).reduce((acc, [key, equipmentPerNeeds]) => {
      needs
        .filter((need) => !!equipmentPerNeeds[need])
        .forEach((need) => {
          if (!acc[key]) acc[key] = {};
          acc[key][need] = equipmentPerNeeds[need].value;
        });
      return acc;
    }, {});
  };
  //#endregion

  //#region [memos]
  const primaryModuleTableData = useMemo(() => {
    const { services } = config.ConfigsSst[0].Data;
    const data = getTableData(getPrimaryModuleParams(services), services.needs);
    const nbCols = Object.entries(data).length;
    return nbCols > 5
      ? [
          Object.entries(data).slice(0, 5),
          Object.entries(data).slice(5, nbCols)
        ]
      : [Object.entries(data).slice(0, nbCols), null];
  }, []);

  const secondaryModuleTableData = useMemo(() => {
    const { services } = config.ConfigsSst[0].Data;
    const data = getTableData(
      getSecondaryModuleParams(services),
      services.needs
    );
    return Object.entries(data).length > 0 ? Object.entries(data) : null;
  }, []);
  //#endregion

  //#region [render]
  return (
    <Section
      title={i18n._('config.services.hydraulic.equipment')}
      level={2}
      open
    >
      <h3>{i18n._('config.services.hydraulic.primaryModule')}</h3>
      <HydraulicEquipmentTable
        data={primaryModuleTableData[0]}
        moduleType={MODULE_TYPE.PRIMARY}
        onFormChange={onFormChange}
      />
      {primaryModuleTableData[1] && (
        <HydraulicEquipmentTable
          data={primaryModuleTableData[1]}
          moduleType={MODULE_TYPE.PRIMARY}
          className='hydraulic-page-primary-module-table'
          onFormChange={onFormChange}
        />
      )}
      {secondaryModuleTableData && (
        <>
          <h3 className='hydraulic-page-secondary-module'>
            {i18n._('config.services.hydraulic.secondaryModule')}
          </h3>
          <HydraulicEquipmentTable
            data={secondaryModuleTableData}
            moduleType={MODULE_TYPE.SECONDARY}
            onFormChange={onFormChange}
          />
        </>
      )}
    </Section>
  );
  //#endregion
};

export default HydraulicEquipmentSection;
