import { useLingui } from '@lingui/react';

const CompressorCellRenderer = ({ value }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return value <= 0 ? i18n._('notAvailable') : value;
  //#endregion
};

export default CompressorCellRenderer;
