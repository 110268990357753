import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import TwoWayTable from '../../../../../../../components/TwoWayTable/TwoWayTable';
import { formatValue } from '../../../../../../../utils/data.utils';

const PVEnergyTable = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    if (!result.ComputeResult.summary.PVRecapStruct) return null;
    const { PVRecapStruct } = result.ComputeResult.summary;
    const newHorizontalTitles = [];
    const newValues = [[]];
    newHorizontalTitles.push(
      i18n._('results.energyAndCarbon.pvEnergyTable.total')
    );
    newValues[0].push(formatValue(PVRecapStruct.Total));
    Object.keys(PVRecapStruct)
      .filter((key) => key !== 'Total')
      .forEach((key) => {
        newHorizontalTitles.push(i18n._(`pv.use.${key.toLowerCase()}`));
        newValues[0].push(formatValue(PVRecapStruct[key]));
      });
    return {
      verticalTitles: [i18n._('results.energyAndCarbon.pvEnergyTable.pvProd')],
      horizontalTitles: newHorizontalTitles,
      values: newValues
    };
  }, [result.ComputeID]);
  //#endregion

  //#region [render]
  return data ? (
    <TwoWayTable
      horizontalTitles={data.horizontalTitles}
      verticalTitles={data.verticalTitles}
      values={data.values}
    />
  ) : (
    <Alert variant='info'>{i18n._('results.noData')}</Alert>
  );
  //#endregion
};

export default PVEnergyTable;
