// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users-page {
  position: relative;
  width: 100%;
  padding: 15px 35px;
  height: calc(100vh - var(--header-footer-height));
}

.users-top-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
}

.users-company-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 6px;
  font-size: 16px;
  font-weight: 500;
  width: 300px;
  min-width: 300px;
}

.users-top-row select,
.users-top-row button {
  height: 40px;
}

.users-top-row button {
  margin-left: 15px;
}

.users-top-row button svg {
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/users/UsersPage.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,kBAAkB;EAClB,iDAAiD;AACnD;;AAEA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;EAC3B,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;EAC3B,QAAQ;EACR,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".users-page {\n  position: relative;\n  width: 100%;\n  padding: 15px 35px;\n  height: calc(100vh - var(--header-footer-height));\n}\n\n.users-top-row {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin-bottom: 20px;\n}\n\n.users-company-select {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  gap: 6px;\n  font-size: 16px;\n  font-weight: 500;\n  width: 300px;\n  min-width: 300px;\n}\n\n.users-top-row select,\n.users-top-row button {\n  height: 40px;\n}\n\n.users-top-row button {\n  margin-left: 15px;\n}\n\n.users-top-row button svg {\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
