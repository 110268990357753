import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { AHS_TYPE } from '../../../../../server/constants';
import { GENERAL } from '../../../../../server/models/design/general.model';
import { fetchImageFileAsObjectURL } from '../../../api/imageFile.api';
import Bloc from '../../../components/Bloc/Bloc';
import ParamInput from '../../../components/ParamForm/ParamInput';
import ParamSelect from '../../../components/ParamForm/ParamSelect';
import { INPUT_TYPE } from '../../../constants';

const GeneralBloc = ({ form, imageFile, onInputChange, onImageChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [imagePreview, setImagePreview] = useState(
    imageFile ? URL.createObjectURL(imageFile) : null
  );
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        if (!imageFile && form.ImageFileID) {
          const image = await fetchImageFileAsObjectURL(form.ImageFileID);
          setImagePreview(() => image);
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);
  //#endregion

  //#region [methods]
  const handleImageChange = (evt) => {
    if (!evt.target.files?.[0]) return;
    const file = evt.target.files[0];
    setImagePreview(URL.createObjectURL(file));
    onImageChange(file);
  };

  const removeImage = () => {
    onImageChange(null);
    setImagePreview(null);
  };
  //#endregion

  //#region [render]
  const descriptionLength = form.AhsDescription?.length || 0;
  const remaining = GENERAL.AhsDescription.maxLength - descriptionLength;
  const remainingStr =
    remaining === 0 || remaining === 1
      ? i18n._('general.remainingCharacter', { remaining })
      : i18n._('general.remainingCharacters', { remaining });
  return (
    <Bloc title={i18n._('general.general')}>
      <ParamInput
        label={i18n._('general.AhsName')}
        value={form.AhsName ?? ''}
        onChange={(value) => onInputChange('AhsName', value)}
        param={GENERAL.AhsName}
        type={INPUT_TYPE.TEXT}
        notDefaultValueHilighted={false}
      />
      <Form.Group>
        <Form.Label className='param-input-label' htmlFor='AhsDescription'>
          {i18n._('general.AhsDescription')}
        </Form.Label>
        <Form.Control
          rows={3}
          as='textarea'
          defaultValue={form.AhsDescription ?? ''}
          maxLength={GENERAL.AhsDescription.maxLength}
          onChange={(evt) => onInputChange('AhsDescription', evt.target.value)}
          name='AhsDescription'
          id='AhsDescription'
        />
      </Form.Group>
      <span className='general-remaining'>{remainingStr}</span>
      <div>
        <span className='param-input-label' style={{ display: 'block' }}>
          {i18n._('general.image')}
        </span>
        <div className='general-upload-img-wrapper'>
          <Button variant='outline-secondary'>
            <label htmlFor='imageFile' className='cursor-pointer'>
              {i18n._('general.selectImg')}
            </label>
            <input
              id='imageFile'
              className='hidden'
              type='file'
              accept='image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp'
              onChange={handleImageChange}
              onClick={(evt) => {
                evt.target.value = null;
              }}
            />
          </Button>
          {imagePreview && (
            <div>
              <img src={imagePreview} alt='Selected' />
              <FontAwesomeIcon
                icon='xmark'
                className='cursor-pointer ms-2'
                onClick={removeImage}
              />
            </div>
          )}
        </div>
      </div>
      <div className='general-radio-btns'>
        <span className='param-input-label'>{i18n._('general.AhsType')}</span>
        <div>
          <Form.Check
            name='ahsTypes'
            id='ahsTypes_1'
            type='radio'
            label={i18n._('general.construction')}
            checked={form.AhsType === AHS_TYPE.CONSTRUCTION}
            value={AHS_TYPE.CONSTRUCTION}
            onChange={(evt) => onInputChange('AhsType', evt.target.value)}
          />
          <Form.Check
            name='ahsTypes'
            id='ahsTypes_2'
            type='radio'
            label={i18n._('general.renovation')}
            checked={form.AhsType === AHS_TYPE.RENOVATION}
            value={AHS_TYPE.RENOVATION}
            onChange={(evt) => onInputChange('AhsType', evt.target.value)}
          />
        </div>
      </div>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('general.InitBuildingSurface')}
            value={form.InitBuildingSurface ?? ''}
            onChange={(value) => onInputChange('InitBuildingSurface', value)}
            param={GENERAL.InitBuildingSurface}
            unit
            type={INPUT_TYPE.NUMBER}
            notDefaultValueHilighted={false}
          />
        </Col>
        <Col>
          <ParamSelect
            label={i18n._('general.AhsMainUsage')}
            value={form.AhsMainUsage}
            onChange={(value) => onInputChange('AhsMainUsage', value)}
            param={GENERAL.AhsMainUsage}
            notDefaultValueHilighted={false}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default GeneralBloc;
