import axios from 'axios';
import { STORAGE_KEYS } from '../../../server/constants';

const instance = axios.create();

instance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem(STORAGE_KEYS.ACCESS);
  if (accessToken) config.headers['Authorization'] = 'Bearer ' + accessToken;
  if (!config.url.includes('/api-v1')) config.url = '/api-v1' + config.url;
  return config;
});

export default instance;
