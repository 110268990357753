import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import TwoWayTable from '../../../../../../components/TwoWayTable/TwoWayTable';
import { formatValue, getAverage } from '../../../../../../utils/data.utils';

const EnergyEvolsTable = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    const { InitGasPriceEvol, InitElecPriceEvol, InitYearsSimulations } =
      result.ComputeResult.inp;
    if (!InitGasPriceEvol || !InitElecPriceEvol) return null;

    const horizontalTitles = [i18n._('results.average')];
    for (let i = 0; i < InitYearsSimulations; ++i) {
      horizontalTitles.push(
        i18n._('results.year', {
          year: i + 1
        })
      );
    }
    // pour des projets + anciens, InitGasPriceEvol et InitElecPriceEvol étaient des nombres et non des tableaux
    // si ce sont des nombres alors on remplit deux tableaux de taille InitYearsSimulations avec ces nombres
    const gasEvols = !Array.isArray(InitGasPriceEvol)
      ? new Array(InitYearsSimulations).fill(InitGasPriceEvol)
      : InitGasPriceEvol;
    const elecEvols = !Array.isArray(InitElecPriceEvol)
      ? new Array(InitYearsSimulations).fill(InitElecPriceEvol)
      : InitElecPriceEvol;

    const values = [
      [formatValue(getAverage(gasEvols))],
      [formatValue(getAverage(elecEvols))]
    ];
    values[0] = values[0].concat(
      gasEvols.map((gasEvol) => formatValue(gasEvol))
    );
    values[1] = values[1].concat(
      elecEvols.map((elecEvol) => formatValue(elecEvol))
    );

    return {
      horizontalTitles,
      verticalTitles: [
        i18n._('results.simulationParams.inputData.energyPricing.gas'),
        i18n._('results.simulationParams.inputData.energyPricing.elec')
      ],
      values
    };
  }, [result.ComputeID]);
  //#endregion

  //#region [render]
  return data ? (
    <TwoWayTable
      horizontalTitles={data.horizontalTitles}
      verticalTitles={data.verticalTitles}
      values={data.values}
    />
  ) : (
    <Alert variant='info'>{i18n._('results.noData')}</Alert>
  );
  //#endregion
};

export default EnergyEvolsTable;
