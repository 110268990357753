const {
  PIPE_NOMINAL_DIAMETER,
  PIPE_MATERIAL,
  UNIT
} = require('../../../../constants');
const { getDefaultValues } = require('../../../../utils/param.utils');

const PIPING = Object.freeze({
  material: {
    key: 'material',
    default: PIPE_MATERIAL.STEEL,
    values: Object.values(PIPE_MATERIAL),
    translateValuesIds: Object.values(PIPE_MATERIAL).map(
      (mat) => `material.${mat}`
    ) // TODO côté front
  },
  distanceToCollector: {
    key: 'distanceToCollector',
    default: 1.5,
    unit: UNIT.METER,
    required: true
  },
  hpaExchangerHeadLoss: {
    key: 'hpaExchangerHeadLoss',
    default: 2,
    unit: UNIT.WATER_COLUMN_METER,
    required: true
  }
});

const getCollectorParams = (material) => {
  return {
    material: {
      key: 'material',
      default: PIPE_MATERIAL.STEEL,
      values: Object.values(PIPE_MATERIAL),
      translateValuesIds: Object.values(PIPE_MATERIAL).map(
        (mat) => `material.${mat}`
      ) // TODO côté front
    },
    coldSideNd: {
      key: 'coldSideNd',
      default: PIPE_NOMINAL_DIAMETER[material][0],
      values: PIPE_NOMINAL_DIAMETER[material]
    },
    heatSideNd: {
      key: 'heatSideNd',
      default: PIPE_NOMINAL_DIAMETER[material][0],
      values: PIPE_NOMINAL_DIAMETER[material]
    },
    distanceBetweenHps: {
      key: 'distanceBetweenHps',
      default: 2,
      min: 0,
      unit: UNIT.METER,
      required: true
    },
    distanceToDestination: {
      key: 'distanceToDestination',
      default: 3,
      min: 0,
      unit: UNIT.METER,
      required: true
    }
  };
};

const DESTINATIONS = Object.freeze({
  exchangerHeadLoss: {
    key: 'exchangerHeadLoss',
    default: 2,
    unit: UNIT.WATER_COLUMN_METER,
    required: true
  },
  waterTankHeadLoss: {
    key: 'waterTankHeadLoss',
    default: 1,
    unit: UNIT.WATER_COLUMN_METER,
    required: true
  }
});

const SOURCES = Object.freeze({
  captureHeatExchangerHeadLoss: {
    key: 'captureHeatExchangerHeadLoss',
    default: 2,
    unit: UNIT.WATER_COLUMN_METER,
    required: true
  },
  injectionExchangerHeadLoss: {
    key: 'injectionExchangerHeadLoss',
    default: 2,
    unit: UNIT.WATER_COLUMN_METER,
    required: true
  }
});

const DEFAULT_HEATING_PLANT_SETTINGS = {
  piping: { ...getDefaultValues(PIPING) },
  collectors: {
    ...getDefaultValues(getCollectorParams(PIPE_MATERIAL.STEEL))
  },
  destinations: { ...getDefaultValues(DESTINATIONS) },
  sources: { ...getDefaultValues(SOURCES) }
};

module.exports = {
  PIPING,
  DEFAULT_HEATING_PLANT_SETTINGS,
  DESTINATIONS,
  SOURCES,
  getCollectorParams
};
