// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal .modal-header {
  font-size: 18px;
  font-weight: 500;
}

.custom-modal .modal-header svg {
  margin-left: 8px;
  cursor: auto;
}

.custom-modal .modal-body {
  white-space: pre-wrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".custom-modal .modal-header {\n  font-size: 18px;\n  font-weight: 500;\n}\n\n.custom-modal .modal-header svg {\n  margin-left: 8px;\n  cursor: auto;\n}\n\n.custom-modal .modal-body {\n  white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
