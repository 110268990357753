import { useLingui } from '@lingui/react';
import React from 'react';
import Section from '../../../../../../components/Section/Section';
import ResultsTable from '../../../../../../components/TemplateTables/ResultsTable/ResultsTable';
import { sstTemplates } from '../templates';

const SstTable = ({ sstName, sstIndex, comparedResults }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Section
      level={2}
      title={i18n._('results.sizing.sst', {
        sst: sstName,
        index: sstIndex + 1
      })}
    >
      {sstTemplates(i18n, comparedResults, sstName).map((template, index) => (
        <ResultsTable
          template={template}
          key={'sizing_sst_template_' + index}
          level={3}
          comparedResults={comparedResults}
        />
      ))}
    </Section>
  );
  //#endregion
};

export default React.memo(SstTable);
