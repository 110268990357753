import axios from '../conf/axios.conf';

//#region [GET]
export const fetchImageFileAsObjectURL = async (imageFileId) => {
  try {
    const response = await axios.get(`/imageFile/${imageFileId}`, {
      responseType: 'arraybuffer'
    });
    const contentType = response.headers['content-type'];
    const blob = new Blob([response.data], { type: contentType });
    return URL.createObjectURL(blob);
  } catch (err) {
    throw err;
  }
};
//#endregion
