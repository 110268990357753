import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { ITERATIONS } from '../../../../../../../../../server/models/design/calculationData.model';
import ParamInput from '../../../../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../../../../constants';
import './OptiIterationsSection.css';

const OptiIterationsSection = ({ calculationData, onIterationsChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  const { FuncEvaluations } = calculationData.constraints.inp;
  let radioIndex, configurable;
  if (FuncEvaluations === 1000) {
    radioIndex = 0;
    configurable = 3000;
  } else if (FuncEvaluations === 2500) {
    radioIndex = 1;
    configurable = 3000;
  } else {
    radioIndex = 2;
    configurable = FuncEvaluations;
  }
  return (
    <div className='opti-section'>
      <h2>{i18n._('compute.opti.optiType')}</h2>
      <Form.Group className='opti-type-section-group'>
        <Row>
          <Col xs={4}>
            <Form.Check
              type='radio'
              name='iterationsRadio'
              id='iterationsRadio_0'
              onChange={() => onIterationsChange(1000)}
              checked={radioIndex === 0}
              label={i18n._('compute.opti.iterations', {
                iterations: 1000
              })}
              className='opti-inputs-label'
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Form.Check
              type='radio'
              name='iterationsRadio'
              id='iterationsRadio_1'
              onChange={() => onIterationsChange(2500)}
              checked={radioIndex === 1}
              label={i18n._('compute.opti.iterations', {
                iterations: 2500
              })}
              className='opti-inputs-label'
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Form.Check
              type='radio'
              name='iterationsRadio'
              id='iterationsRadio_2'
              onChange={() => onIterationsChange(configurable)}
              checked={radioIndex === 2}
              label={i18n._('compute.opti.configurable')}
              className='opti-inputs-label'
            />
          </Col>
          <Col xs={4}>
            <ParamInput
              param={ITERATIONS.ConfigurableIterations}
              value={configurable}
              onChange={onIterationsChange}
              disabled={radioIndex === 0 || radioIndex === 1}
              type={INPUT_TYPE.NUMBER}
              notDefaultValueHilighted={false}
            />
          </Col>
        </Row>
      </Form.Group>
    </div>
    //#endregion
  );
};

export default React.memo(OptiIterationsSection);
