import { NEED } from '../../../server/constants';
import { STD_COLS } from '../../../server/models/design/description.model';
import { isArrNullOrEmpty } from './data.utils';

export const getSummaryData = (fileRows) => {
  let maxHeat = 0,
    maxCold = 0,
    maxHotwater = 0,
    sumHeat = 0,
    sumCold = 0,
    sumHotwater = 0;
  for (const row of fileRows) {
    maxHeat = Math.max(maxHeat, +row[STD_COLS.HEAT_NEED.name]);
    maxCold = Math.max(maxCold, +row[STD_COLS.COLD_NEED.name]);
    maxHotwater = Math.max(maxHotwater, +row[STD_COLS.HOTWATER_NEED.name]);
    sumHeat += +row[STD_COLS.HEAT_NEED.name];
    sumCold += +row[STD_COLS.COLD_NEED.name];
    sumHotwater += +row[STD_COLS.HOTWATER_NEED.name];
  }
  return { maxHeat, maxCold, maxHotwater, sumHeat, sumCold, sumHotwater };
};

const getMissingStdNeed = (form, descStdForms) => {
  if (
    form.InitStationHasHeatNeeds &&
    descStdForms.every((stdForm) => stdForm.summaryData.maxHeat === 0)
  ) {
    return NEED.HEAT;
  }
  if (
    form.InitStationHasColdNeeds &&
    descStdForms.every((stdForm) => stdForm.summaryData.maxCold === 0)
  ) {
    return NEED.COLD;
  }
  if (
    form.InitStationHasHotWaterNeeds &&
    descStdForms.every((stdForm) => stdForm.summaryData.maxHotwater === 0)
  ) {
    return NEED.DHW;
  }
  return null;
};

export const getStdFilesError = (i18n, form, formIndex, descStdForms) => {
  if (!form || isArrNullOrEmpty(descStdForms)) return null;
  const missingStdNeed = getMissingStdNeed(form, descStdForms);
  if (missingStdNeed) {
    return i18n._('description.error.missingNeedInStdFiles', {
      need: i18n._(`need.${missingStdNeed}`),
      descIndex: formIndex + 1
    });
  }
  return null;
};
