// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heat-production-regimes-heat-wc {
  margin: 25px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
}

.heat-production-regimes-heat-wc-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.heat-production-regimes-heat-wc-check > svg {
  font-size: 17px;
  margin-top: 3px;
}

.heat-production-regimes-heat-wc-inputs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 40px;
}

@media (max-width: 768px) {
  .heat-production-regimes-heat-wc-inputs {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    gap: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/containers/DescriptionsTabs/DescriptionTab/sections/HeatProductionSection/HeatProductionSection.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,4BAA4B;EAC5B,6BAA6B;MACzB,0BAA0B;UACtB,sBAAsB;EAC9B,SAAS;AACX;;AAEA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;EAC3B,SAAS;AACX;;AAEA;EACE;IACE,4BAA4B;IAC5B,6BAA6B;QACzB,0BAA0B;YACtB,sBAAsB;IAC9B,wBAAwB;QACpB,qBAAqB;YACjB,uBAAuB;IAC/B,SAAS;EACX;AACF","sourcesContent":[".heat-production-regimes-heat-wc {\n  margin: 25px 0;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  gap: 10px;\n}\n\n.heat-production-regimes-heat-wc-check {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n\n.heat-production-regimes-heat-wc-check > svg {\n  font-size: 17px;\n  margin-top: 3px;\n}\n\n.heat-production-regimes-heat-wc-inputs {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  gap: 40px;\n}\n\n@media (max-width: 768px) {\n  .heat-production-regimes-heat-wc-inputs {\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n        -ms-flex-direction: column;\n            flex-direction: column;\n    -webkit-box-align: start;\n        -ms-flex-align: start;\n            align-items: flex-start;\n    gap: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
