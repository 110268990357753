import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import Pagination from '../../../../components/Pagination/Pagination';
import './ProjectsTable.css';

const columnsStyles = {
  ProjectImg: { width: '60px' },
  AhsName: { width: '20%', cursor: 'pointer' },
  apps: { width: '190px' },
  AhsDescription: { width: '25%' },
  lastUpdate: { width: 'auto', cursor: 'pointer' },
  lastUpdater: { width: 'auto' },
  actions: { width: '60px' }
};

const ProjectsTable = ({
  projects,
  columns,
  hiddenColumns,
  smallSizeHiddenColumns
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [react-table]
  const {
    headerGroups,
    page,
    state,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    setHiddenColumns,
    getTableProps,
    getTableBodyProps,
    prepareRow
  } = useTable(
    {
      columns,
      data: projects,
      initialState: {
        sortBy: [
          {
            id: 'lastUpdate',
            desc: false
          }
        ]
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 50);
  //#endregion

  //#region [states]
  const [searchValue, setSearchValue] = useState(state.globalFilter);
  //#endregion

  //#region [effects]
  useEffect(() => {
    setHiddenColumns(
      window.innerWidth < 1007
        ? [...(hiddenColumns ?? []), ...(smallSizeHiddenColumns ?? [])]
        : [...(hiddenColumns ?? [])]
    );
  }, []);

  useEffect(() => {
    const debounce = (fn, ms) => {
      let timer;
      return () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          timer = null;
          fn.apply(this, arguments);
        }, ms);
      };
    };

    const debounceHandleResize = debounce(function handleResize() {
      const pageSize = parseInt((window.innerHeight - 290) / 60);
      setHiddenColumns(
        window.innerWidth < 1007
          ? [...(hiddenColumns ?? []), ...(smallSizeHiddenColumns ?? [])]
          : [...(hiddenColumns ?? [])]
      );
      setPageSize(pageSize < 1 ? 1 : pageSize);
    }, 50);

    window.addEventListener('resize', debounceHandleResize);
    return () => window.removeEventListener('resize', debounceHandleResize);
  }, []);

  useEffect(() => {
    const pageSize = parseInt((window.innerHeight - 290) / 60);
    setPageSize(pageSize < 1 ? 1 : pageSize);
  }, []);
  //#endregion

  //#region [render]
  return (
    <div>
      <div className='projects-input-body'>
        <FontAwesomeIcon icon='magnifying-glass' />
        <Form.Control
          type='text'
          placeholder={i18n._('projects.search')}
          value={searchValue ?? ''}
          onChange={(evt) => {
            setSearchValue(evt.target.value);
            onChange(evt.target.value);
          }}
          name='search'
        />
      </div>
      <table className='projects-table' {...getTableProps()} border='1'>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={columnsStyles[column.id]}
                >
                  {column.render('Header')}
                  {column.isSorted && (
                    <FontAwesomeIcon
                      icon={column.isSortedDesc ? 'arrow-up' : 'arrow-down'}
                    />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {pageOptions.length > 1 && (
        <Pagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          nbPages={pageOptions.length}
          pageIndex={state.pageIndex}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
        />
      )}
    </div>
  );
  //#endregion
};

export default ProjectsTable;
