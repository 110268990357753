// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cost-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 15px;
  width: 70%;
}

.cost-page .col {
  padding-left: 0;
}

.cost-page .param-input-body:not(.edit-table .param-input-body) {
  max-width: 350px;
  min-width: 200px;
  width: 25vw;
}

.cost-page .edit-table {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@media (max-width: 1007px) {
  .cost-page {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/costs/CostsPage.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,4BAA4B;EAC5B,6BAA6B;MACzB,0BAA0B;UACtB,sBAAsB;EAC9B,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,0BAA0B;EAC1B,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".cost-page {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  gap: 15px;\n  width: 70%;\n}\n\n.cost-page .col {\n  padding-left: 0;\n}\n\n.cost-page .param-input-body:not(.edit-table .param-input-body) {\n  max-width: 350px;\n  min-width: 200px;\n  width: 25vw;\n}\n\n.cost-page .edit-table {\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n}\n\n@media (max-width: 1007px) {\n  .cost-page {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
