import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { Fragment, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import './FittingsModal.css';

const FittingsModal = ({
  isOpen,
  defaultFittings,
  initialFittings,
  title,
  subtitle,
  onClose,
  onValidate
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [fittings, setFittings] = useState(initialFittings);
  //#endregion

  //#region [methods]
  const handleCounterValueChange = (key, value) => {
    if (value < 0) return;
    setFittings({ ...fittings, [key]: value });
  };
  //#endregion

  //#region [render]
  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <div className='fitting-modal-header'>
          <h1>{title || i18n._('fittingsModal.title')}</h1>
          {subtitle && <p>{subtitle}</p>}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='fitting-modal-body'>
          {Object.entries(fittings).map(([key, value]) => {
            const fittingId = 'fitting_' + key;
            const edited = value !== defaultFittings[key];
            return (
              <Fragment key={fittingId}>
                <span
                  className={`fitting-modal-key ${edited && 'fitting-edited'}`}
                  data-tooltip-id={'tooltip_' + fittingId}
                  data-tooltip-content={i18n._('geostorage.fitting.edited')}
                >
                  {i18n._(`geostorage.fitting.${key}`)}
                </span>
                <FontAwesomeIcon
                  icon='circle-minus'
                  onClick={() => handleCounterValueChange(key, value - 1)}
                  className={value === 0 ? 'disabled' : ''}
                />
                <span
                  className={`fitting-modal-counter-value ${
                    edited && 'fitting-edited'
                  }`}
                >
                  {value}
                </span>
                <FontAwesomeIcon
                  icon='circle-plus'
                  onClick={() => handleCounterValueChange(key, value + 1)}
                />
                {edited && (
                  <Tooltip
                    id={'tooltip_' + fittingId}
                    place='left'
                    opacity={1}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='outline-secondary'
          onClick={() => setFittings(() => defaultFittings)}
        >
          {i18n._('fittingsModal.reset')}
        </Button>
        <Button onClick={async () => await onValidate(fittings)}>
          {i18n._('validate')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
  //#endregion
};

export default FittingsModal;
