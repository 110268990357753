// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.descriptions-table th:nth-of-type(2) {
  border-top-left-radius: 5px !important;
}

.descriptions-table th:last-of-type {
  border-top-right-radius: 5px;
}

.descriptions-table th:nth-of-type(2n) {
  background-color: var(--primary-color);
}

.descriptions-table th:nth-of-type(2n + 1):not(:first-of-type) {
  background-color: #d5dae3;
  color: rgb(39, 39, 39);
}

.descriptions-table th,
.descriptions-table td {
  font-size: 14px;
  word-break: break-word;
}

.descriptions-table th {
  color: white;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.descriptions-table-value {
  text-align: center;
  padding: 5px;
  white-space: pre-wrap;
}

.descriptions-table tbody tr td {
  border-left: 1px solid var(--border-color);
}

.descriptions-table > tbody > tr:first-of-type > td {
  border-top: 1px solid var(--border-color);
}

.descriptions-table > tbody > tr:last-of-type > td {
  border-bottom: 1px solid var(--border-color);
}

.descriptions-table > tbody > tr > td:last-of-type {
  border-right: 1px solid var(--border-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/ProjectSummary/sections/description/components/DescriptionsTable/DescriptionsTable.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;;EAEE,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":[".descriptions-table th:nth-of-type(2) {\n  border-top-left-radius: 5px !important;\n}\n\n.descriptions-table th:last-of-type {\n  border-top-right-radius: 5px;\n}\n\n.descriptions-table th:nth-of-type(2n) {\n  background-color: var(--primary-color);\n}\n\n.descriptions-table th:nth-of-type(2n + 1):not(:first-of-type) {\n  background-color: #d5dae3;\n  color: rgb(39, 39, 39);\n}\n\n.descriptions-table th,\n.descriptions-table td {\n  font-size: 14px;\n  word-break: break-word;\n}\n\n.descriptions-table th {\n  color: white;\n  font-weight: 500;\n  padding: 5px;\n  text-align: center;\n}\n\n.descriptions-table-value {\n  text-align: center;\n  padding: 5px;\n  white-space: pre-wrap;\n}\n\n.descriptions-table tbody tr td {\n  border-left: 1px solid var(--border-color);\n}\n\n.descriptions-table > tbody > tr:first-of-type > td {\n  border-top: 1px solid var(--border-color);\n}\n\n.descriptions-table > tbody > tr:last-of-type > td {\n  border-bottom: 1px solid var(--border-color);\n}\n\n.descriptions-table > tbody > tr > td:last-of-type {\n  border-right: 1px solid var(--border-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
