import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { UNIT } from '../../../../../../../../server/constants';
import Card from '../../../../../../components/Card/Card';
import LinesChart from '../../../../../../components/Chart/LinesChart';
import { CHART_MODE, CHART_TYPE } from '../../../../../../constants';
import { BASIC_COLORS } from '../../../../../../styles/colors';
import { formatValueWithUnit } from '../../../../../../utils/data.utils';
import { getProjectIndex } from '../../../../../../utils/project.utils';

const Dataviz = ({ calculation, project }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    const newData = [];
    let tempBestCost;
    let tempLastX;
    calculation.descriptions.forEach((desc, descIndex) => {
      const descName =
        desc.name ??
        i18n._('description', {
          index: getProjectIndex(project, desc.projectId) + 1
        });
      desc.computes
        .filter((compute) => compute.Dataviz)
        .forEach((compute, compIndex) => {
          const { x, y } = compute.Dataviz;
          const name = [descName];
          if (compute.GoalPart?.value) {
            name.push(
              i18n._(`compute.goal.${compute.GoalPart.key}`, {
                value: formatValueWithUnit(
                  i18n,
                  compute.GoalPart.value * 100,
                  UNIT.PERCENT
                )
              })
            );
          }
          const lastX = Math.max(...x);
          if (!tempLastX || tempLastX < lastX) {
            tempLastX = lastX;
          }
          const bestCost = Math.min(...y);
          if (!tempBestCost || tempBestCost > bestCost) {
            tempBestCost = bestCost;
          }
          newData.push({
            x,
            y,
            name: name.join(', '),
            type: CHART_TYPE.SCATTER,
            mode: CHART_MODE.MARKERS,
            marker: {
              color: BASIC_COLORS[descIndex * desc.computes.length + compIndex]
            },
            showlegend: true,
            hovertemplate: '%{y:.3s}'
          });
        });
    });

    const bestCostData = {
      x: [0, tempLastX],
      y: [tempBestCost, tempBestCost],
      mode: CHART_MODE.LINES,
      line: {
        dash: 'dot',
        width: 1,
        color: 'orange'
      },
      name: '',
      showlegend: false
    };
    newData.push(bestCostData);
    return newData;
  }, [calculation]);
  //#endregion

  //#region [render]
  return (
    <Card>
      <LinesChart
        data={data}
        layout={{
          title: '',
          autosize: true,
          margin: { l: 100, r: 0, b: 70, t: 50, pad: 0 },
          xaxis: {
            title: {
              text: i18n._('dataviz.nbSimu'),
              standoff: 20
            },
            titlefont: {
              size: 18
            },
            tickfont: {
              size: 10
            }
          },
          yaxis: {
            title: {
              text: i18n._('dataviz.totalCost'),
              standoff: 30
            },
            titlefont: {
              size: 18
            },
            tickfont: {
              size: 10
            },
            tickangle: 25,
            nticks: 8
          },
          legend: {
            x: 1,
            y: 0.5,
            font: {
              size: 11
            }
          }
        }}
      />
    </Card>
  );
  //#endregion
};
export default Dataviz;
