import { isNull } from './data.utils';

export const bufferToArray = (i18n, buffer, modelCols, delimiter = ';') => {
  try {
    // on convertit le buffer en string
    const csvString = buffer.toString();

    // on découpe le contenu du fichier en lignes
    const csvArray = csvString.split(/\r?\n/);

    // on récupère les en-têtes du fichier
    const headers = csvArray[0].split(delimiter);

    // on vérifie que les colonnes obligatoires sont présentes
    const mandatoryHeaders = modelCols
      .filter((col) => col.mandatory)
      .map((col) => col.name);
    const missingHeaders = mandatoryHeaders.filter(
      (header) => !headers.includes(header)
    );
    if (missingHeaders.length > 0) {
      throw new Error(
        i18n._('file.error.missingHeaders', {
          columns: missingHeaders.join(', ')
        })
      );
    }

    const rows = csvArray
      .slice(1)
      .filter((row) => row?.trim() !== '')
      .map((row, index) => {
        const rowValues = row.split(delimiter);

        // on vérifie que le nombre de valeurs correspond au nombre d'en-têtes
        if (rowValues.length !== headers.length) {
          throw new Error(
            i18n._('file.error.incorrectValuesCount', {
              row: index + 1,
              count: rowValues.length,
              expected: headers.length
            })
          );
        }

        const newRow = {};
        headers.forEach((header, index) => {
          let value = rowValues[index];
          const modelCol = Object.values(modelCols).find(
            (col) => col.name === header
          );
          // on vérifie que la valeur n'est pas nulle
          if (isNull(value) || value.trim() === '') {
            if (modelCol.mandatory) {
              throw new Error(
                i18n._('file.error.null', {
                  row: index + 1,
                  header
                })
              );
            } else {
              value = null;
            }
          } else if (modelCol.type === 'number') {
            if (isFinite(value)) {
              // on vérifie que la valeur n'est pas supérieure ou inférieure aux limites
              if (modelCol.max && value > modelCol.max) {
                throw new Error(
                  i18n._('file.error.max', {
                    header,
                    row: index + 1,
                    max: modelCol.max,
                    value
                  })
                );
              } else if (modelCol.min && value < modelCol.min) {
                throw new Error(
                  i18n._('file.error.min', {
                    header,
                    row: index + 1,
                    min: modelCol.min,
                    value
                  })
                );
              }
            } else if (
              !modelCol.accepted ||
              (modelCol.accepted && !modelCol.accepted.includes(value))
            ) {
              throw new Error(
                i18n._('file.error.invalid', {
                  header,
                  row: index + 1,
                  value
                })
              );
            }
          }
          newRow[header] = value;
        });
        return newRow;
      });
    return rows;
  } catch (error) {
    throw error;
  }
};
