import { useLingui } from '@lingui/react';
import { merge } from 'lodash';
import React from 'react';
import Plot from 'react-plotly.js';
import './Chart.css';

const DoughnutPieChart = ({ data, layout, title, filename }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  if (!data) return null;
  const defaultLayout = {
    margin: {
      l: 0,
      r: 0,
      b: 10,
      t: 50,
      pad: 0
    },
    autosize: true,
    height: 400,
    responsive: true,
    annotations: [
      {
        font: { size: 16 },
        showarrow: false,
        text: layout.text ?? ''
      }
    ],
    legend: {
      orientation: 'h',
      xanchor: 'center',
      y: -0.1,
      x: 0.5
    }
  };
  const pieChartLayout = layout
    ? merge({}, defaultLayout, layout)
    : defaultLayout;
  const pieChartData = [
    {
      values: data.values,
      labels: data.labels,
      marker: { colors: data.colors },
      hole: 0.7,
      type: 'pie',
      textinfo: 'percent',
      textposition: 'outside',
      sort: false,
      textfont: {
        size: 14
      }
    }
  ];
  return (
    <div className='chart'>
      {title && <p>{title}</p>}
      <Plot
        style={{ width: '100%', height: '100%' }}
        useResizeHandler={true}
        data={pieChartData}
        layout={pieChartLayout}
        config={{
          displaylogo: false,
          displayModeBar: true,
          modeBarButtons: [
            [
              {
                name: i18n._('plotly.dl'),
                icon: Plotly.Icons.camera,
                click: (gd) => {
                  gd.data.hole = 0.8;
                  gd.layout.title = {
                    font: { size: 16 },
                    text: title ?? ''
                  };
                  Plotly.downloadImage(gd, {
                    filename: filename ?? title,
                    format: 'png',
                    width: 500,
                    height: 500
                  });
                }
              }
            ]
          ]
        }}
      />
    </div>
  );
  //#endregion;
};

export default DoughnutPieChart;
