import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { UNIT } from '../../../../../../../../server/constants';
import { COLLECTORS } from '../../../../../../../../server/models/config/thermalProduction/geostorage/settings.model';
import FormInput from '../../../../../../components/Form/FormInput';
import FormSelect from '../../../../../../components/Form/FormSelect';
import Section from '../../../../../../components/Section/Section';
import { INPUT_TYPE } from '../../../../../../constants';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';

const CollectorsSection = ({ onFormChange, addError, removeErrors }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const handleParamChange = (param, value) => {
    config.ConfigsSst[0].Data.geostorage.settings.collectors[param.key] = value;
    onFormChange(config);
  };
  //#endregion

  //#region [render]
  const { hdpeNd, fluidPathDistance } =
    config.ConfigsSst[0].Data.geostorage.settings.collectors;
  return (
    <Section
      title={i18n._('config.geostorage.settings.collectors')}
      level={2}
      open
    >
      <div className='config-form-row'>
        <FormSelect
          label={i18n._('config.geostorage.settings.hdpeNd')}
          param={COLLECTORS.hdpeNd}
          value={hdpeNd}
          onChange={(value) => handleParamChange(COLLECTORS.hdpeNd, value)}
          className='config-form-input'
        />
        <FormInput
          label={i18n._('config.geostorage.settings.fluidPathDistance')}
          param={COLLECTORS.fluidPathDistance}
          type={INPUT_TYPE.NUMBER}
          unit={UNIT.METER}
          value={fluidPathDistance}
          onChange={(value) =>
            handleParamChange(COLLECTORS.fluidPathDistance, value)
          }
          addError={addError}
          removeError={() => removeErrors(1)}
          className='config-form-input'
        />
      </div>
    </Section>
  );
  //#endregion
};

export default CollectorsSection;
