import React, { useEffect, useState } from 'react';
import { fetchImageFileAsObjectURL } from '../../../api/imageFile.api';
import defaultProjectImg from '../../../image/default_project.png';

const ProjectImgCell = ({ row }) => {
  //#region [states]
  const [image, setImage] = useState();
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        if (row.original.ImageFileID) {
          const image = await fetchImageFileAsObjectURL(
            row.original.ImageFileID
          );
          setImage(() => image);
        } else {
          setImage(defaultProjectImg);
        }
      } catch (err) {
        console.error(err);
        setImage(defaultProjectImg);
      }
    })();
  }, [row.original.ImageFileID]);
  //#endregion

  //#region [render]
  return image ? (
    <img
      className={`projects-table-img ${
        row.original.ImageFileID ? 'projects-table-rounded-img' : ''
      } `}
      src={image}
      alt='projectImg'
    />
  ) : null;
  //#endregion
};

export default ProjectImgCell;
