import { useLingui } from '@lingui/react';
import React from 'react';
import ResultsExpandableSection from '../../../../../components/ExpandableSection/ResultsExpandableSection';
import ResultsCards from '../../../../../components/ResultsCards/ResultsCards';
import ResultsElements from '../../../../../components/ResultsElements/ResultsElements.jsx';
import Section from '../../../../../components/Section/Section';
import ResultsTable from '../../../../../components/TemplateTables/ResultsTable/ResultsTable';
import ConstraintsTable from './components/ConstraintsTable';
import DetailedResults from './components/DetailedResults/DetailedResults';
import DlJsonResultBtn from './components/DlJsonResultBtn.jsx';
import EnergyEvolsTable from './components/EnergyEvolsTable';
import OtherPerSubTable from './components/OtherPerSubTable/OtherPerSubTable';
import InputsParamsTable from './components/RecursiveTable/InputsParamsTable';
import {
  detailedParamsTemplate,
  inputDataTemplates,
  simuTemplate
} from './templates.js';

const SimulationParamsSection = ({ comparedResults, loadDetails }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Section title={i18n._('results.simulationParams')} open>
      <h2>{i18n._('results.simulationParams.inputData')}</h2>
      {inputDataTemplates(i18n, comparedResults).map((template, index) => (
        <ResultsTable
          level={3}
          template={template}
          comparedResults={comparedResults}
          key={'simulationParams_inputData_template_' + index}
        />
      ))}
      <ResultsExpandableSection level={3} comparedResults={comparedResults}>
        <ResultsCards
          element={EnergyEvolsTable}
          comparedResults={comparedResults}
          title={i18n._(
            'results.simulationParams.inputData.energyPricing.energyEvols'
          )}
        />
      </ResultsExpandableSection>
      <ResultsExpandableSection
        level={2}
        comparedResults={comparedResults}
        title={i18n._('results.simulationParams.constraints')}
      >
        <ResultsCards
          element={ConstraintsTable}
          comparedResults={comparedResults}
          title={i18n._('results.simulationParams.constraints.table.title')}
        />
      </ResultsExpandableSection>
      <h2>{i18n._('results.simulationParams.simulation')}</h2>
      <ResultsElements
        element={DlJsonResultBtn}
        comparedResults={comparedResults}
      />
      <ResultsTable
        comparedResults={comparedResults}
        template={simuTemplate(i18n, comparedResults)}
      />
      <ResultsExpandableSection
        level={2}
        comparedResults={comparedResults}
        title={i18n._('results.simulationParams.detailed')}
      >
        <ResultsCards
          element={DetailedResults}
          comparedResults={comparedResults}
          title={i18n._('results.simulationParams.detailed.title')}
          onOpen={loadDetails}
        />
      </ResultsExpandableSection>
      <ResultsTable
        level={2}
        comparedResults={comparedResults}
        template={detailedParamsTemplate(i18n, comparedResults)}
      />
      <ResultsExpandableSection
        level={2}
        comparedResults={comparedResults}
        title={i18n._('results.simulationParams.otherParams')}
      >
        <ResultsCards
          element={OtherPerSubTable}
          comparedResults={comparedResults}
          title={i18n._(
            'results.simulationParams.otherParams.perSubParams.table.title'
          )}
        />
        <ResultsCards
          element={InputsParamsTable}
          comparedResults={comparedResults}
          title={i18n._('results.inputsParams')}
        />
      </ResultsExpandableSection>
    </Section>
  );
  //#endregion
};

export default React.memo(SimulationParamsSection);
