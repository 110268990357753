import { useLingui } from '@lingui/react';
import React from 'react';
import { NAME } from '../../../../../../server/models/design/description.model';
import { INPUT_TYPE } from '../../../../constants';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';

const DescriptionNameBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('description.description')}>
      <ParamInput
        value={form.description ?? ''}
        onChange={(value) => onInputChange('description', value)}
        param={NAME.description}
        type={INPUT_TYPE.TEXT}
        notDefaultValueHilighted={false}
      />
    </Bloc>
  );
  //#endregion
};

export default DescriptionNameBloc;
