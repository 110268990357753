import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import './Breadcrumb.css';

const Breadcrumb = ({ companyName, projectName }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const location = useLocation();
  const { companyId, projectId } = useParams();
  //#endregion

  //#region [memos]
  const links = useMemo(() => {
    if (!location || !companyName) return null;
    const paths = location.pathname.split('/');
    const companyLink = {
      to: '/',
      name: i18n._('breadcrumb.company', {
        company: companyName
      }),
      state: { companyId },
      icon: 'house'
    };

    // la page actuelle est une page de config
    if (paths.includes('configs')) {
      return [
        companyLink,
        {
          to: null,
          name: i18n._('page.project.configs', {
            project: projectName
          })
        }
      ];
    }

    const path = [
      'compute',
      'results',
      'general',
      'descriptions',
      'geology',
      'costs',
      'performance',
      'new-project'
    ].find((path) => paths.includes(path));
    if (!path) return null;
    if (path === 'compute' || path === 'results') {
      // la page actuelle est une page de design : compute ou results
      return [
        companyLink,
        {
          to: null,
          name: i18n._('page.project.design', {
            project: projectName
          })
        },
        {
          to: null,
          name: i18n._(`page.${path}`)
        }
      ];
    } else if (path === 'new-project') {
      // la page actuelle est la page pour créer un nouvel utilisateur
      return [
        companyLink,
        {
          to: null,
          name: i18n._('projects.new')
        }
      ];
    } else {
      // la page actuelle est une page de design : general, description, geology, costs, performance
      return [
        companyLink,
        {
          to: `/company/${companyId}/project/${projectId}/design/compute?type=opti`,
          name: i18n._('page.project.design', {
            project: projectName
          })
        },
        {
          to: null,
          name: i18n._(`page.${path}`)
        }
      ];
    }
  }, [location, companyName, projectName]);
  //#endregion

  //#region [render]
  return links ? (
    <div className='breadcrumb'>
      {links.map((link, index) => {
        if (!link.to || index === links.length - 1) {
          return (
            <span
              key={'breadcrumbspan_' + index}
              data-content={i18n._('breadcrumb.separator')}
            >
              {link.icon && <FontAwesomeIcon icon={link.icon} />} {link.name}
            </span>
          );
        } else {
          return (
            <Link
              to={link.to}
              state={link.state}
              key={'breadcrumbLink_' + index}
              data-content={i18n._('breadcrumb.separator')}
            >
              {link.icon && <FontAwesomeIcon icon={link.icon} />} {link.name}
            </Link>
          );
        }
      })}
    </div>
  ) : null;
  //#endregion
};

export default Breadcrumb;
