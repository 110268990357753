import { createElement } from 'react';
import { createRoot } from 'react-dom';

export const render = (comp, el) => createRoot(el).render(comp);

export function h(type, config, children) {
  if (config) {
    if (!config.key)
      try {
        // on s'assure que la key est unique
        if (!config.id) {
          const configValues = Object.values(config);
          if (configValues.length === 0) {
            config.key = '' + Math.random() + new Date().getTime();
          } else {
            let randomIndex = Math.floor(Math.random() * configValues.length);
            config.key = '' + configValues[randomIndex] + Math.random();
          }
        } else {
          config.key = config.id;
        }
      } catch (e) {}
    if (typeof config.style == 'string') {
      config.style = Object.fromEntries(
        config.style.split(/;\s*/).map((s) => s.split(':').map((s) => s.trim()))
      );
    }
    if (typeof config.style == 'object') {
      let s = {};
      for (let k in config.style) {
        const k1 = k.replace(/-([a-z])/g, (m) => m[1].toUpperCase());
        s[k1] = config.style[k];
      }
      config.style = s;
    }
    if (config.for) {
      config.htmlFor = config.for;
      delete config.for;
    }
    if ('value' in config && !config.onChange) config.readOnly = true;
    if ('checked' in config && !config.onChange) config.readOnly = true;
    const hprops = Object.keys(config).filter((k) => /-([a-z])/g.test(k));
    if (hprops.length > 0) {
      for (let k of hprops) {
        const k1 = k.replace(/-([a-z])/g, (m) => m[1].toUpperCase());
        config[k1] = config[k];
        delete config[k];
      }
    }
  } else {
    config = { key: Math.random() };
  }
  const args = Array.from(arguments);
  if (args.length > 3) {
    children = args.slice(2);
  }
  return createElement(type, config, children);
}
