export const CHART_MODE = Object.freeze({
  LINES: 'lines',
  LINES_MARKERS: 'lines+markers',
  MARKERS: 'markers',
  NONE: 'none'
});

export const CHART_TYPE = Object.freeze({
  SCATTER: 'scatter'
});

export const FORM_STATUS = Object.freeze({
  ORIGIN: 1,
  LOADING: 2,
  DIRTY: 3,
  SAVING: 4,
  SAVED: 5
});

export const INPUT_TYPE = Object.freeze({
  EMAIL: 'email',
  NUMBER: 'number',
  PASSWORD: 'password',
  TEXT: 'text'
});
