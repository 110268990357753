import { UNIT } from '../../../../../../../../server/constants';
import {
  HOTWATER_PRODUCTION,
  SST,
  getColdProductionParams,
  getHeatProductionParams
} from '../../../../../../../../server/models/design/description.model';

export const descriptionsTemplate = (i18n, projectAndChildren) => {
  const heatProductionParams = getHeatProductionParams();
  const coldProductionParams = getColdProductionParams();
  return {
    descriptions: projectAndChildren.map(
      (project) => project.otherValues.description
    ),
    rows: [
      {
        label: i18n._('description.InitTemperatureHeat'),
        values: projectAndChildren.map(
          (project) => project.otherValues.InitTemperatureHeat
        ),
        unit: heatProductionParams.InitTemperatureHeat.unit
      },
      {
        label: i18n._('description.InitWaterLawIsUsed'),
        values: projectAndChildren.map((project) =>
          project.otherValues.InitWaterLawIsUsed ? i18n._('yes') : i18n._('no')
        )
      },
      {
        label: i18n._('description.InitStationTemperatureSetPoint'),
        values: projectAndChildren.map((project) =>
          project.otherValues.InitWaterLawIsUsed
            ? project.otherValues.InitStationTemperatureSetPoint
            : null
        ),
        unit: heatProductionParams.InitStationTemperatureSetPoint.unit
      },
      {
        label: i18n._('description.InitStationWaterTankHotTemperatureMin'),
        values: projectAndChildren.map((project) =>
          project.otherValues.InitWaterLawIsUsed
            ? project.otherValues.InitStationWaterTankHotTemperatureMin
            : null
        ),
        unit: heatProductionParams.InitStationWaterTankHotTemperatureMin.unit
      },
      {
        label: i18n._('description.InitTemperatureCold'),
        values: projectAndChildren.map(
          (project) => project.otherValues.InitTemperatureCold
        ),
        unit: coldProductionParams.InitTemperatureCold.unit
      },
      {
        label: i18n._('description.InitTemperatureHotWater'),
        values: projectAndChildren.map(
          (project) => project.otherValues.InitTemperatureHotWater
        ),
        unit: HOTWATER_PRODUCTION.InitTemperatureHotWater.unit
      },
      {
        label: i18n._('description.InitLoopIsUsed'),
        values: projectAndChildren.map((project) =>
          project.otherValues.InitLoopIsUsed ? i18n._('yes') : i18n._('no')
        )
      },
      {
        label: i18n._('description.heatProductionSolutions'),
        values: projectAndChildren.map((project) => {
          const {
            InitGasEnabled,
            InitReleveEnabled,
            InitHPGHeatingEnabled,
            InitHPAHeatingEnabled,
            InitSolarThermalEnabled
          } = project.otherValues;
          if (
            !InitHPGHeatingEnabled &&
            !InitHPAHeatingEnabled &&
            !InitGasEnabled
          ) {
            return i18n._('compute.none');
          }
          const translateValueIds = [];
          if (project.otherValues.InitHPGHeatingEnabled) {
            translateValueIds.push(
              i18n._('description.InitHPGHeatingEnabled.short')
            );
          }
          if (project.otherValues.InitHPAHeatingEnabled) {
            translateValueIds.push(
              i18n._('description.InitHPAHeatingEnabled.shot')
            );
          }
          if (InitGasEnabled) {
            if (InitReleveEnabled) {
              translateValueIds.push(i18n._('description.InitReleveEnabled'));
            } else {
              translateValueIds.push(
                i18n._('description.InitAppointGasEnabled')
              );
            }
          }
          if (InitSolarThermalEnabled) {
            translateValueIds.push(
              i18n._('description.InitSolarThermalEnabled')
            );
          }
          return translateValueIds.join('\n');
        })
      },
      {
        label: i18n._('description.coldProductionSolutions'),
        values: projectAndChildren.map((project) => {
          const translateValueIds = [];
          const { InitHPGCoolingEnabled, InitHPACoolingEnabled, ItesEnabled } =
            project.otherValues;
          if (
            !InitHPGCoolingEnabled &&
            !InitHPACoolingEnabled &&
            !ItesEnabled
          ) {
            return i18n._('compute.none');
          }
          if (InitHPGCoolingEnabled) {
            translateValueIds.push(
              i18n._('description.InitHPGCoolingEnabled.short')
            );
          }
          if (InitHPACoolingEnabled) {
            translateValueIds.push(
              i18n._('description.InitHPACoolingEnabled.short')
            );
          }
          if (ItesEnabled) {
            translateValueIds.push(i18n._('description.ItesEnabled'));
          }
          return translateValueIds.join('\n');
        })
      },
      {
        label: i18n._('description.InitHPAInjectionEnabled'),
        values: projectAndChildren.map((project) =>
          project.otherValues.InitHPAInjectionEnabled
            ? i18n._('yes')
            : i18n._('no')
        )
      },
      {
        label: i18n._('description.InitPhotovoltaicEnabled'),
        values: projectAndChildren.map((project) =>
          project.otherValues.InitPhotovoltaicEnabled
            ? i18n._('yes')
            : i18n._('no')
        )
      },
      {
        label: i18n._('description.weatherFile'),
        values: projectAndChildren.map((project) => project.weatherFilename)
      }
    ]
  };
};

export const sstTemplate = (i18n, substation) => {
  const { InitStationName, InitStationUse, InitBuildingSurface } = substation;
  return {
    title: InitStationName,
    rows: [
      {
        label: i18n._('description.InitStationUse'),
        value: i18n._(`usage.${InitStationUse}`)
      },
      {
        label: i18n._('description.InitBuildingSurface'),
        value: InitBuildingSurface,
        unit: SST.InitBuildingSurface.unit
      }
    ]
  };
};

export const stdTemplate = (i18n, projectAndChildren, sstIndex) => ({
  descriptions: projectAndChildren.map(
    (project) => project.otherValues.description
  ),
  files: projectAndChildren.map(
    (project) => project.substations[sstIndex].stdFilename
  ),
  rows: [
    {
      label: i18n._('std.heat.sum'),
      values: projectAndChildren.map(
        (project) => project.substations[sstIndex].summaryData.sumHeat
      ),
      unit: UNIT.KILOWATT_HOUR_PER_YEAR
    },
    {
      label: i18n._('std.heat.max'),
      values: projectAndChildren.map(
        (project) => project.substations[sstIndex].summaryData.maxHeat
      ),
      unit: UNIT.KILOWATT
    },
    {
      label: i18n._('std.cold.sum'),
      values: projectAndChildren.map(
        (project) => project.substations[sstIndex].summaryData.sumCold
      ),
      unit: UNIT.KILOWATT_HOUR_PER_YEAR
    },
    {
      label: i18n._('std.cold.max'),
      values: projectAndChildren.map(
        (project) => project.substations[sstIndex].summaryData.maxCold
      ),
      unit: UNIT.KILOWATT
    },
    {
      label: i18n._('std.hotwater.sum'),
      values: projectAndChildren.map(
        (project) => project.substations[sstIndex].summaryData.sumHotwater
      ),
      unit: UNIT.KILOWATT_HOUR_PER_YEAR
    },
    {
      label: i18n._('std.hotwater.max'),
      values: projectAndChildren.map(
        (project) => project.substations[sstIndex].summaryData.maxHotwater
      ),
      unit: UNIT.KILOWATT
    }
  ]
});
