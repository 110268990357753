import React from 'react';
import './OpaqueLayer.css';

const OpaqueLayer = ({ children, visible, className }) => {
  return (
    <div
      className={
        (visible ? 'opaque-layer' : 'hidden') +
        (className ? ` ${className}` : '')
      }
    >
      {children}
    </div>
  );
};

export default OpaqueLayer;
