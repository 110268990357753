// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-comment-input-group {
  margin-bottom: 10px;
}

.results-comment-input-group input:disabled {
  color: rgb(108, 108, 108);
}

.results-comment-input-group .btn {
  z-index: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/result/sections/general/components/CommentInput/CommentInput.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".results-comment-input-group {\n  margin-bottom: 10px;\n}\n\n.results-comment-input-group input:disabled {\n  color: rgb(108, 108, 108);\n}\n\n.results-comment-input-group .btn {\n  z-index: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
