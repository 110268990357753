import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { INJECTION } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../constants';
import { getDefaultValue } from '../../../../utils/param.utils';

const InjectionBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('cost.injection')} level={2}>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitInjectionExchangerCostA')}
            value={form.InitInjectionExchangerCostA}
            param={INJECTION.InitInjectionExchangerCostA}
            onChange={(value) =>
              onInputChange('InitInjectionExchangerCostA', value)
            }
            unit
            bottomText={getDefaultValue(
              i18n,
              INJECTION.InitInjectionExchangerCostA
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitInjectionExchangerCostB')}
            value={form.InitInjectionExchangerCostB}
            param={INJECTION.InitInjectionExchangerCostB}
            onChange={(value) =>
              onInputChange('InitInjectionExchangerCostB', value)
            }
            unit
            bottomText={getDefaultValue(
              i18n,
              INJECTION.InitInjectionExchangerCostB
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitInjectionPumpCostA')}
            value={form.InitInjectionPumpCostA}
            param={INJECTION.InitInjectionPumpCostA}
            onChange={(value) => onInputChange('InitInjectionPumpCostA', value)}
            unit
            bottomText={getDefaultValue(i18n, INJECTION.InitInjectionPumpCostA)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitInjectionPumpCostB')}
            value={form.InitInjectionPumpCostB}
            param={INJECTION.InitInjectionPumpCostB}
            onChange={(value) => onInputChange('InitInjectionPumpCostB', value)}
            unit
            bottomText={getDefaultValue(i18n, INJECTION.InitInjectionPumpCostB)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default InjectionBloc;
