import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import TwoWayTable from '../../../../../../components/TwoWayTable/TwoWayTable';
import { formatValue } from '../../../../../../utils/data.utils';

const BtesEnergyBalanceTable = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    if (!result.ComputeResult.summary.QBtesPerYear) return null;

    const { QBtesPerYear, QBtesPerYearAverage } = result.ComputeResult.summary;
    const { InitYearsSimulations } = result.ComputeResult.inp;
    const horizontalTitles = new Array(InitYearsSimulations + 1);
    horizontalTitles[0] = i18n._('results.average');
    const values = [new Array(InitYearsSimulations + 1)];
    values[0][0] = formatValue(QBtesPerYearAverage);
    for (let i = 1; i <= InitYearsSimulations; ++i) {
      horizontalTitles[i] = i18n._('results.year', { year: i });
      values[0][i] = formatValue(QBtesPerYear[i - 1]);
    }
    return {
      horizontalTitles,
      verticalTitles: [
        i18n._('results.geomodeling.btesEnergybalance.table.QBtes')
      ],
      values
    };
  }, [result.ComputeID]);
  //#endregion

  //#region [render]
  return data ? (
    <TwoWayTable
      horizontalTitles={data.horizontalTitles}
      verticalTitles={data.verticalTitles}
      values={data.values}
    />
  ) : (
    <Alert variant='info'>{i18n._('results.noData')}</Alert>
  );
  //#endregion
};

export default BtesEnergyBalanceTable;
