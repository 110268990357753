import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { EXCHANGER } from '../../../../../../server/models/design/performance.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../constants';
import { getDefaultValue } from '../../../../utils/param.utils';

const ExchangerBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('performance.exchangers')}>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.InitLoopExchangerPinch')}
            value={form.InitLoopExchangerPinch}
            param={EXCHANGER.InitLoopExchangerPinch}
            onChange={(value) => onInputChange('InitLoopExchangerPinch', value)}
            bottomText={getDefaultValue(i18n, EXCHANGER.InitLoopExchangerPinch)}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('performance.InitLoopExchangerU')}
            value={form.InitLoopExchangerU}
            param={EXCHANGER.InitLoopExchangerU}
            onChange={(value) => onInputChange('InitLoopExchangerU', value)}
            bottomText={getDefaultValue(i18n, EXCHANGER.InitLoopExchangerU)}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.InitLoopExchangerAirPinch')}
            value={form.InitLoopExchangerAirPinch}
            param={EXCHANGER.InitLoopExchangerAirPinch}
            onChange={(value) =>
              onInputChange('InitLoopExchangerAirPinch', value)
            }
            bottomText={getDefaultValue(
              i18n,
              EXCHANGER.InitLoopExchangerAirPinch
            )}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('performance.InitLoopExchangerAirU')}
            value={form.InitLoopExchangerAirU}
            param={EXCHANGER.InitLoopExchangerAirU}
            onChange={(value) => onInputChange('InitLoopExchangerAirU', value)}
            bottomText={getDefaultValue(i18n, EXCHANGER.InitLoopExchangerAirU)}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.InitStationExchangerPinch')}
            value={form.InitStationExchangerPinch}
            param={EXCHANGER.InitStationExchangerPinch}
            onChange={(value) =>
              onInputChange('InitStationExchangerPinch', value)
            }
            bottomText={getDefaultValue(
              i18n,
              EXCHANGER.InitStationExchangerPinch
            )}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('performance.InitStationColdExchangerPinch')}
            value={form.InitStationColdExchangerPinch}
            param={EXCHANGER.InitStationColdExchangerPinch}
            onChange={(value) =>
              onInputChange('InitStationColdExchangerPinch', value)
            }
            bottomText={getDefaultValue(
              i18n,
              EXCHANGER.InitStationColdExchangerPinch
            )}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <ParamInput
        label={i18n._('performance.InitStationHotwaterExchangerPinch')}
        value={form.InitStationHotwaterExchangerPinch}
        param={EXCHANGER.InitStationHotwaterExchangerPinch}
        onChange={(value) =>
          onInputChange('InitStationHotwaterExchangerPinch', value)
        }
        bottomText={getDefaultValue(
          i18n,
          EXCHANGER.InitStationHotwaterExchangerPinch
        )}
        unit
        type={INPUT_TYPE.NUMBER}
      />
    </Bloc>
  );
  //#endregion
};

export default ExchangerBloc;
