import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { GENERAL_SCHEMA } from '../../../../../server/validation/general.validation';
import { fetchGeneral, updateGeneral } from '../../../api/project.api';
import GeneralForm from '../../../containers/GeneralForm/GeneralForm';
import AjvContext from '../../../contexts/AjvContext';
import PopupContext from '../../../contexts/PopupContext';
import { IS_DEV } from '../../../utils/env.utils';

const GeneralPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  const { companyId, projectId } = useParams();
  //#endregion

  //#region [contexts]
  const { ajv } = useContext(AjvContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [form, setForm] = useState();
  const [imageFile, setImageFile] = useState();
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        const form = await fetchGeneral(projectId);
        setForm(() => form);
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, []);
  //#endregion

  //#region [methods]
  const handleInputChange = (key, value) => {
    setForm((form) => ({ ...form, [key]: value }));
  };

  const handleImageChange = (file) => {
    setImageFile(file);
    setForm((oldForm) => ({
      ...oldForm,
      ImageFileID: file ? crypto.randomUUID() : null
    }));
  };

  const handleSubmitClick = async () => {
    try {
      const formData = new FormData();
      if (imageFile) {
        formData.append('imageFile', imageFile, imageFile.name);
      }
      formData.append('form', JSON.stringify(form));
      await updateGeneral(projectId, formData);
      navigate(
        `/company/${companyId}/project/${projectId}/design/compute?type=opti`
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  if (!form) return null;
  const validate = ajv.compile(GENERAL_SCHEMA);
  const isFormValid = validate(form);
  if (IS_DEV && validate.errors) console.log(validate.errors);
  return (
    <div className='general-page'>
      <GeneralForm
        form={form}
        imageFile={imageFile}
        onInputChange={handleInputChange}
        onImageChange={handleImageChange}
      />
      <div className='bottom-page-btns'>
        <Button
          variant='primary'
          onClick={handleSubmitClick}
          disabled={!isFormValid}
        >
          {i18n._('save')}
        </Button>
      </div>
    </div>
  );
  //#endregion
};

export default GeneralPage;
