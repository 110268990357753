import { useLingui } from '@lingui/react';
import React from 'react';
import { NEED } from '../../../../../../../../server/constants';
import ResultsExpandableSection from '../../../../../../components/ExpandableSection/ResultsExpandableSection';
import ResultsCards from '../../../../../../components/ResultsCards/ResultsCards';
import Section from '../../../../../../components/Section/Section';
import ResultsExpandableTable from '../../../../../../components/TemplateTables/ResultsExpandableTable/ResultsExpandableTable';
import ResultsTable from '../../../../../../components/TemplateTables/ResultsTable/ResultsTable';
import {
  sstColdTemplate,
  sstHeatTemplate,
  sstHotwaterTemplate,
  stdTemplate
} from '../templates';
import PowerLoadCurveSst from './PowerLoadCurve/PowerLoadCurveSst';
import SubstationNeedsChart from './SubstationNeedsChart';
import COPEERPerYearLinesChart from './heatpumps/COPEERPerYearLinesChart';
import COPEERPerYearTable from './heatpumps/COPEERPerYearTable';
import COPsDistributionTable from './heatpumps/COPsDistributionTable';
import EnergyConsumedDistributionTable from './heatpumps/EnergyConsumedDistributionTable';
import EnergyProducedDistributionPieChart from './heatpumps/EnergyProducedDistributionPieChart/EnergyProducedDistributionPieChart';
import EnergyProducedDistributionTable from './heatpumps/EnergyProducedDistributionTable';

const EnergyCarbonSubstationTable = ({
  sstName,
  sstIndex,
  comparedResults,
  loadDetails
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Section
      level={2}
      title={i18n._('results.energyAndCarbon.sst', {
        index: '' + (sstIndex + 1),
        substation: sstName
      })}
    >
      <ResultsExpandableTable
        level={3}
        template={stdTemplate(i18n, comparedResults, sstIndex)}
        comparedResults={comparedResults}
      />
      <ResultsExpandableSection comparedResults={comparedResults}>
        <ResultsCards
          title={i18n._('results.energyAndCarbon.sst.needsChart.title')}
          element={SubstationNeedsChart}
          elementProps={{ sstName }}
          comparedResults={comparedResults}
          onOpen={loadDetails}
        />
      </ResultsExpandableSection>
      <ResultsTable
        level={3}
        comparedResults={comparedResults}
        template={sstHeatTemplate(i18n, comparedResults, sstName)}
      />
      <ResultsExpandableSection comparedResults={comparedResults}>
        <ResultsCards
          element={PowerLoadCurveSst}
          elementProps={{ sstName, need: NEED.HEAT, sorted: true }}
          comparedResults={comparedResults}
          title={i18n._('results.energyAndCarbon.powerLoadCurve')}
          onOpen={loadDetails}
        />
        <ResultsCards
          element={PowerLoadCurveSst}
          elementProps={{ sstName, need: NEED.HEAT }}
          comparedResults={comparedResults}
          title={i18n._('results.energyAndCarbon.yearNeedsCoverageCurve')}
          onOpen={loadDetails}
        />
      </ResultsExpandableSection>
      <ResultsTable
        level={3}
        comparedResults={comparedResults}
        template={sstColdTemplate(i18n, comparedResults, sstName)}
      />
      <ResultsExpandableSection comparedResults={comparedResults}>
        <ResultsCards
          element={PowerLoadCurveSst}
          elementProps={{ sstName, need: NEED.COLD, sorted: true }}
          comparedResults={comparedResults}
          title={i18n._('results.energyAndCarbon.powerLoadCurve')}
          onOpen={loadDetails}
        />
        <ResultsCards
          element={PowerLoadCurveSst}
          elementProps={{ sstName, need: NEED.COLD }}
          comparedResults={comparedResults}
          title={i18n._('results.energyAndCarbon.yearNeedsCoverageCurve')}
          onOpen={loadDetails}
        />
      </ResultsExpandableSection>
      <ResultsTable
        level={3}
        comparedResults={comparedResults}
        template={sstHotwaterTemplate(i18n, comparedResults, sstName)}
      />
      <ResultsExpandableSection comparedResults={comparedResults}>
        <ResultsCards
          element={PowerLoadCurveSst}
          elementProps={{ sstName, need: NEED.DHW, sorted: true }}
          comparedResults={comparedResults}
          title={i18n._('results.energyAndCarbon.powerLoadCurve')}
          onOpen={loadDetails}
        />
        <ResultsCards
          element={PowerLoadCurveSst}
          elementProps={{ sstName, need: NEED.DHW }}
          comparedResults={comparedResults}
          title={i18n._('results.energyAndCarbon.yearNeedsCoverageCurve')}
          onOpen={loadDetails}
        />
      </ResultsExpandableSection>
      <ResultsExpandableSection
        level={3}
        comparedResults={comparedResults}
        title={i18n._('results.energyAndCarbon.energyBalance')}
      >
        <ResultsCards
          element={EnergyProducedDistributionPieChart}
          elementProps={{ sstName }}
          comparedResults={comparedResults}
          title={i18n._(
            'results.energyAndCarbon.energyBalance.energyProducedDistributionPieChart.title'
          )}
        />
        <ResultsCards
          element={EnergyProducedDistributionTable}
          elementProps={{ sstName }}
          comparedResults={comparedResults}
          title={i18n._(
            'results.energyAndCarbon.energyBalance.productionsTable.title'
          )}
        />
        <ResultsCards
          element={EnergyConsumedDistributionTable}
          elementProps={{ sstName }}
          comparedResults={comparedResults}
          title={i18n._(
            'results.energyAndCarbon.energyBalance.consumptionsTable.title'
          )}
        />
        <ResultsCards
          element={COPsDistributionTable}
          elementProps={{ sstName }}
          comparedResults={comparedResults}
          title={i18n._(
            'results.energyAndCarbon.energyBalance.copsTable.title'
          )}
        />
        <ResultsCards
          element={COPEERPerYearTable}
          elementProps={{ sstName }}
          comparedResults={comparedResults}
          title={i18n._(
            'results.energyAndCarbon.energyBalance.copEerPerYearTable.title'
          )}
        />
        <ResultsCards
          element={COPEERPerYearLinesChart}
          elementProps={{ sstName }}
          comparedResults={comparedResults}
          title={i18n._(
            'results.energyAndCarbon.energyBalance.copEerPerYearLinesChart.title'
          )}
        />
      </ResultsExpandableSection>
    </Section>
  );
  //#endregion
};

export default React.memo(EnergyCarbonSubstationTable);
