import React from 'react';

const ProjectNameCell = ({ row }) => {
  //#region [render]
  return (
    <div
      className='projects-table-name two-lines-max'
      title={row.original.AhsName}
    >
      {row.original.AhsName}
    </div>
  );
  //#endregion
};

export default React.memo(ProjectNameCell);
