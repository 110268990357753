import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import {
  HOURS_PER_YEAR,
  MILLISECONDS_PER_HOUR
} from '../../../../../../../../server/constants';
import { DETAILED_RESULTS_SST_KEYS } from '../../../../../../../../server/models/design/result.model';
import LinesChart from '../../../../../../components/Chart/LinesChart';
import { CHART_MODE, CHART_TYPE } from '../../../../../../constants';
import { DETAILED_RESULTS_SST_COLORS } from '../../../../../../styles/colors';
import { isArrNullOrEmpty } from '../../../../../../utils/data.utils';

const SubstationNeedsChart = ({ result, sstName }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const chart = useMemo(() => {
    if (!result.details || !sstName) return null;
    const data = [];
    const keys = Object.assign(
      {},
      ...Object.values(DETAILED_RESULTS_SST_KEYS).map((obj) => obj.total)
    );
    Object.keys(keys)
      .filter((key) => {
        const sstKey = `${sstName}_${key}`;
        const sstNeedsPerKey = result.details[sstKey];
        return (
          !isArrNullOrEmpty(sstNeedsPerKey) &&
          !sstNeedsPerKey.every((need) => need == 0)
        );
      })
      .forEach((key) => {
        const x = new Array(HOURS_PER_YEAR);
        const y = new Array(HOURS_PER_YEAR);
        const sstKey = `${sstName}_${key}`;
        const sstNeedsPerKey = result.details[sstKey];
        let timestamp = new Date(new Date().getFullYear(), 0, 0).getTime();
        for (let i = 0; i < HOURS_PER_YEAR; ++i) {
          timestamp += MILLISECONDS_PER_HOUR;
          x[i] = new Date(timestamp).toISOString();
          y[i] = sstNeedsPerKey[i];
        }
        data.push({
          x,
          y,
          line: { color: DETAILED_RESULTS_SST_COLORS[key] },
          name: i18n._(`detailed.key.${key}`),
          mode: CHART_MODE.LINES,
          type: CHART_TYPE.SCATTER
        });
      });
    return {
      data,
      layout: {
        xaxis: {
          tickformat: '%b',
          dtick: 'M1'
        },
        yaxis: {
          title: {
            text: i18n._('projectResults.substation.needsChart.yaxis.title')
          }
        },
        showlegend: true
      }
    };
  }, [result.details, sstName]);
  //#endregion

  //#region [render]
  return chart ? (
    <LinesChart
      data={chart.data}
      layout={chart.layout}
      title={i18n._('projectResults.substation.needsChart.dynamicThermalSimu', {
        substation: sstName
      })}
      filename={i18n._('plotly.filename', {
        projectName:
          result.descriptionName ??
          i18n._('description', { index: result.descriptionIndex + 1 }),
        title: i18n._(
          'projectResults.substation.needsChart.dynamicThermalSimu',
          {
            substation: sstName
          }
        )
      })}
    />
  ) : null;
  //#endregion
};

export default SubstationNeedsChart;
