import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import LinesChart from '../../../../../../../components/Chart/LinesChart';
import { CHART_MODE } from '../../../../../../../constants';
import { COP_EER_CHART_COLORS } from '../../../../../../../styles/colors';

const COPEERPerYearLinesChart = ({ result, sstName }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    const copEerPerYear = sstName
      ? result.ComputeResult.summary.PerSub.CopEerPerYear?.[sstName]
      : result.ComputeResult.summary.COPEERPerYear;
    if (!copEerPerYear) return null;

    const { InitYearsSimulations } = result.ComputeResult.inp;
    const x = new Array(InitYearsSimulations + 1).fill(0).map((_, i) => i + 1);
    return Object.entries(copEerPerYear).map(([copEerType, values]) => ({
      x,
      y: values,
      name: i18n._(`results.energyAndCarbon.copEerPerYearTable.${copEerType}`),
      line: { color: COP_EER_CHART_COLORS[copEerType] },
      mode: CHART_MODE.LINES_MARKERS
    }));
  }, [result.ComputeID, sstName]);
  //#endregion

  //#region [render]
  return data ? (
    <LinesChart
      data={data}
      layout={{ xaxis: { title: i18n._('date.years') } }}
      title={i18n._(
        'results.energyAndCarbon.energyBalance.copEerPerYearLinesChart.title',
        { years: result.ComputeResult.inp.InitYearsSimulations }
      )}
      filename={i18n._('plotly.filename', {
        projectName: result.ComputeResult.inp.description,
        title: i18n._(
          'results.energyAndCarbon.energyBalance.copEerPerYearLinesChart.title'
        )
      })}
    />
  ) : null;
  //#endregion
};

export default COPEERPerYearLinesChart;
