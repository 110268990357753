import { useLingui } from '@lingui/react';
import React from 'react';
import { Alert } from 'react-bootstrap';
import {
  HOURS_PER_YEAR,
  MILLISECONDS_PER_HOUR,
  SOURCE
} from '../../../../../../../../../server/constants';
import { DETAILED_RESULTS_SST_KEYS } from '../../../../../../../../../server/models/design/result.model';
import LinesChart from '../../../../../../../components/Chart/LinesChart';
import { CHART_MODE } from '../../../../../../../constants';
import {
  DETAILED_RESULTS_SST_COLORS,
  SOURCES_CHART_COLORS
} from '../../../../../../../styles/colors';
import { getResultFullName } from '../../../../../../../utils/compute.utils';
import {
  formatValue,
  isArrNullOrEmpty,
  isNull
} from '../../../../../../../utils/data.utils';

const PowerLoadCurveTotal = ({ result, need, sorted }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  if (!result?.details) return null;
  try {
    const data = [];
    // on récupère les clés des différentes sources
    const srcKeys = Object.keys(DETAILED_RESULTS_SST_KEYS[need].sources).filter(
      (srcKey) =>
        Object.keys(result.details).some((key) => key.includes(srcKey))
    );
    if (isArrNullOrEmpty(srcKeys)) {
      return (
        <Alert variant='warning'>
          {i18n._('results.energyAndCarbon.noPowerLoadCurve')}
        </Alert>
      );
    }

    // on récupère la clé du total
    const totalKey = Object.keys(DETAILED_RESULTS_SST_KEYS[need].total).find(
      (srcKey) =>
        Object.keys(result.details).some((key) => key.includes(srcKey))
    );
    if (!totalKey) {
      return (
        <Alert variant='warning'>
          {i18n._('results.energyAndCarbon.noPowerLoadCurve')}
        </Alert>
      );
    }

    // l'abcisse = nombre d'heures par année
    const x = new Array(HOURS_PER_YEAR).fill(0).map((_, index) => index);

    // utils
    const getSrc = (srcKey) => {
      return Object.values(SOURCE).find((source) =>
        srcKey.toLowerCase().includes(source)
      );
    };

    const getArea = (y, srcY, src, fill) => {
      if (!sorted) {
        let timestamp = new Date(new Date().getFullYear(), 0, 0).getTime();
        for (let i = 0; i < HOURS_PER_YEAR; ++i) {
          timestamp += MILLISECONDS_PER_HOUR;
          x[i] = new Date(timestamp).toISOString();
        }
      }
      return {
        x,
        y,
        mode: CHART_MODE.NONE,
        fill,
        fillcolor: SOURCES_CHART_COLORS[src],
        text: formatValue(srcY, 2),
        hovertemplate: '%{text}',
        name: i18n._('results.energyAndCarbon.needs.legend', {
          src: i18n._(`source.${src}`),
          unit: sorted ? i18n._('unit.kilowatt') : i18n._('unit.kilowattHour')
        })
      };
    };

    // la courbe représentant la couverture des besoins
    const totalValues = new Array(HOURS_PER_YEAR);
    result.ComputeResult.inp.substations.forEach((sst) => {
      for (let i = 0; i < totalValues.length; ++i) {
        if (isNull(totalValues[i])) totalValues[i] = { total: 0, hour: i };
        const fullTotalKey = `${sst.InitStationName}_${totalKey}`;
        if (!result.details[fullTotalKey]) {
          throw new Error(
            i18n._('results.powerLoadCurve.error', { missing: fullTotalKey })
          );
        }
        totalValues[i].total += result.details[fullTotalKey][i];
      }
    });

    // si c'est une monotone alors on la trie par valeurs décroissantes
    if (sorted) totalValues.sort((a, b) => b.total - a.total);

    const totalY = totalValues.map((value) => value.total);
    const name = sorted
      ? i18n._('results.energyAndCarbon.powerLoadCurve.name')
      : i18n._('results.energyAndCarbon.yearNeedsCurve.total.name', {
          need: i18n._(`need.${need}`)
        });
    data.push({
      x,
      y: totalY,
      mode: CHART_MODE.LINES,
      name,
      line: {
        color: DETAILED_RESULTS_SST_COLORS[totalKey],
        width: sorted ? 2 : 0.5
      }
    });

    /**
     * les autres courbes
     * ordre de somme des aires
        - Chauffage : (TFP + HPG) → HPSolaireThermique→ HPA → Gaz
        - ECS : (TFP + HPG) → HPSolaireThermique→ HPA → Gaz
        - Climatisation : (TFP + HPG) → HPA → ITES
     */
    let tempTotalValuesY;
    const tfpSrcKey = srcKeys.find(
      (sstSrcKey) => getSrc(sstSrcKey) === SOURCE.TFP
    );
    srcKeys
      .filter((sstSrcKey) => getSrc(sstSrcKey) !== SOURCE.TFP)
      .forEach((srcKey, i) => {
        const source = getSrc(srcKey);
        const valuesY = new Array(totalValues.length);
        const srcY = new Array(totalValues.length);
        if (source === SOURCE.HPG && tfpSrcKey) {
          // TFP est compris dans HPG
          totalValues.forEach((value, j) => {
            let sum = 0;
            result.ComputeResult.inp.substations.forEach((sst) => {
              const fullSrcKey = `${sst.InitStationName}_${srcKey}`;
              const fullTfpSrcKey = `${sst.InitStationName}_${tfpSrcKey}`;
              const detailsSrcKey = result.details[fullSrcKey];
              const detailsTfpSrcKey = result.details[fullTfpSrcKey];
              if (!detailsSrcKey || !detailsTfpSrcKey) {
                throw new Error(
                  i18n._('results.powerLoadCurve.error', {
                    missing: detailsSrcKey ? fullSrcKey : fullTfpSrcKey
                  })
                );
              }
              sum +=
                result.details[fullSrcKey][value.hour] +
                result.details[fullTfpSrcKey][value.hour];
            });
            // le total temporaire de toutes les valeurs à l'heure "value.hour"
            valuesY[j] = (tempTotalValuesY ? tempTotalValuesY[j] : 0) + sum;

            // la vraie valeur à l'heure "value.hour"
            srcY[j] = sum;
          });
        } else {
          totalValues.forEach((value, j) => {
            let sum = 0;
            result.ComputeResult.inp.substations.forEach((sst) => {
              sum +=
                result.details[`${sst.InitStationName}_${srcKey}`][value.hour];
            });
            // le total temporaire de toutes les valeurs à l'heure "value.hour"
            valuesY[j] = (tempTotalValuesY ? tempTotalValuesY[j] : 0) + sum;

            // la vraie valeur à l'heure "value.hour"
            srcY[j] = sum;
          });
        }
        tempTotalValuesY = [...valuesY];
        data.push(
          getArea(valuesY, srcY, source, i === 0 ? 'tozeroy' : 'tonexty')
        );
      });
    let title;
    let layout = {
      margin: { b: 70 },
      xaxis: undefined,
      yaxis: {
        title: {
          text: i18n._('results.energyAndCarbon.powerLoadCurve.yaxis'),
          standoff: 30,
          showlegend: true
        }
      }
    };
    if (sorted) {
      title = i18n._('results.energyAndCarbon.powerLoadCurve.need', {
        need: i18n._(`need.${need}`).toLowerCase()
      });
      layout.xaxis = {
        title: i18n._('results.energyAndCarbon.powerLoadCurve.xaxis')
      };
    } else {
      title = i18n._('results.energyAndCarbon.yearNeedsCoverageCurve.need', {
        need: i18n._(`need.${need}`).toLowerCase()
      });
      layout.xaxis = {
        tickformat: '%b',
        dtick: 'M1'
      };
    }
    return (
      <LinesChart
        data={data}
        layout={layout}
        title={title}
        filename={i18n._(
          'results.energyAndCarbon.powerLoadCurveTotal.filename',
          {
            result: getResultFullName(i18n, result),
            title
          }
        )}
      />
    );
  } catch (err) {
    console.error(err);
    return <Alert variant='danger'>{'' + err}</Alert>;
  }
  //#endregion
};

export default React.memo(PowerLoadCurveTotal);
