import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import TwoWayTable from '../../../../../../components/TwoWayTable/TwoWayTable';
import { formatValue } from '../../../../../../utils/data.utils';

const OpexTable = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    if (!result.ComputeResult.summary.OpexPerYear) return null;
    const { OpexPerYear } = result.ComputeResult.summary;
    const { InitYearsSimulations } = result.ComputeResult.inp;
    const horizontalTitles = [i18n._('results.average')];
    const values = [[0], [0]];
    let acc = 0;

    for (let i = 0; i < InitYearsSimulations; ++i) {
      horizontalTitles.push(
        i18n._('results.year', {
          year: i + 1
        })
      );
      const formattedValue = formatValue(OpexPerYear[i]);
      values[0].push(formattedValue);
      acc += OpexPerYear[i];
      values[1].push(formatValue(acc));
    }
    values[0][0] = formatValue(acc / InitYearsSimulations);
    values[1][0] = i18n._('noValue');

    return {
      horizontalTitles,
      verticalTitles: [
        i18n._('results.economicAnalysis.opexTable.opex'),
        i18n._('results.economicAnalysis.opexTable.cumulativeOpex')
      ],
      values
    };
  }, [result.ComputeID]);
  //#endregion

  //#region [render]
  return data ? (
    <TwoWayTable
      horizontalTitles={data.horizontalTitles}
      verticalTitles={data.verticalTitles}
      values={data.values}
    />
  ) : (
    <Alert variant='info'>{i18n._('results.noData')}</Alert>
  );
};

export default OpexTable;
