import React from 'react';

const defaultState = {
  showPipeNames: false,
  showFrames: true,
  showBlockNames: true
};

const SchemaContext = React.createContext(defaultState);
export default SchemaContext;
