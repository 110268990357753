import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { getCommonConstraints } from '../../../../../../../../../server/models/design/calculationData.model';
import SimuInput from '../../components/SimuInput';
import './SimuGeneralSection.css';

const SimuGeneralSection = ({
  project,
  calculationData,
  onConstraintChange,
  onSelectedProjectChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  const { InitYearsSimulations } = calculationData.constraints.inp;
  const commonConstraints = getCommonConstraints(InitYearsSimulations);
  const projectAndChildren = [project, ...project.children];
  return (
    <div className='simulation-section'>
      <h2>{i18n._('compute.simu.general')}</h2>
      <SimuInput
        label={i18n._('compute.InitYearsSimulations')}
        value={calculationData.constraints.inp.InitYearsSimulations}
        param={commonConstraints.InitYearsSimulations}
        onChange={onConstraintChange}
      />
      <SimuInput
        label={i18n._('compute.InitBalanceDuration')}
        value={calculationData.constraints.inp.InitBalanceDuration}
        param={commonConstraints.InitBalanceDuration}
        onChange={onConstraintChange}
      />
      <Row>
        <Col xs='7'>
          <span className='param-input-label'>
            {projectAndChildren.length === 1
              ? i18n._('compute.description')
              : i18n._('compute.selectDescription')}
          </span>
        </Col>
        <Col xs='5'>
          <div className='simu-general-descriptions'>
            {projectAndChildren.map((proj, projIndex) => (
              <Form.Check
                key={'simu_check_' + projIndex}
                label={
                  proj.otherValues.description ||
                  i18n._('description', { index: projIndex + 1 })
                }
                onChange={() => onSelectedProjectChange(projIndex)}
                value={projIndex}
                checked={calculationData.selectedDescriptionsIndexes.includes(
                  projIndex
                )}
                disabled={calculationData.selectedDescriptionsIndexes.includes(
                  projIndex
                )}
                name={'simu_check_' + projIndex}
                id={'simu_check_' + projIndex}
              />
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
  //#endregion
};

export default React.memo(SimuGeneralSection);
