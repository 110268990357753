// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-chart-selects {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.results-chart-selects label {
  font-weight: 500;
  font-size: 15px;
}

.results-chart-selects select {
  max-width: 350px;
  font-size: 15px;
}

.results-chart-trash {
  position: absolute;
  right: 15px;
  top: 93px;
  z-index: 5;
  color: var(--danger-color);
  font-size: 17px;
  cursor: pointer;
}

.results-chart-trash:hover {
  color: rgb(214, 24, 24);
}

.results-chart-tooltip .tooltip-inner {
  background-color: rgb(214, 24, 24);
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/result/sections/summary/components/ResultsChart/ResultsChart.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;EAC3B,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,UAAU;EACV,0BAA0B;EAC1B,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".results-chart-selects {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  gap: 20px;\n  margin-bottom: 20px;\n}\n\n.results-chart-selects label {\n  font-weight: 500;\n  font-size: 15px;\n}\n\n.results-chart-selects select {\n  max-width: 350px;\n  font-size: 15px;\n}\n\n.results-chart-trash {\n  position: absolute;\n  right: 15px;\n  top: 93px;\n  z-index: 5;\n  color: var(--danger-color);\n  font-size: 17px;\n  cursor: pointer;\n}\n\n.results-chart-trash:hover {\n  color: rgb(214, 24, 24);\n}\n\n.results-chart-tooltip .tooltip-inner {\n  background-color: rgb(214, 24, 24);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
