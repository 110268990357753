// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-btn {
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  padding: 5px 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  -webkit-box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/components/SelectBtn/SelectBtn.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,QAAQ;EACR,iBAAiB;EACjB,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;EAC3B,eAAe;EACf,wDAAwD;EACxD,gDAAgD;AAClD","sourcesContent":[".select-btn {\n  border-radius: 5px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  gap: 5px;\n  padding: 5px 10px;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  font-size: 14px;\n  -webkit-box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);\n  box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
