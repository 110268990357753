import axios from '../conf/axios.conf';

//#region [GET]
export const fetchWeatherFile = async (weatherFileId) => {
  try {
    const response = await axios.get(`/weatherFile/${weatherFileId}`);
    const weatherFile = response.data;
    const filename = decodeURI(
      response.headers['content-disposition'].split('filename=')[1]
    );
    const csvType = { type: 'text/csv' };
    const blob = new Blob([weatherFile], csvType);
    return new File([blob], filename, csvType);
  } catch (err) {
    throw err;
  }
};
//#endregion
