import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './IconBadge.css';

const IconBadge = ({ icon, text }) => {
  //#region [render]
  return (
    <div className='icon-badge'>
      <FontAwesomeIcon icon={icon} />
      <span>{text}</span>
    </div>
  );
  //#endregion
};

export default IconBadge;
