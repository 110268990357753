import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { Form } from 'react-bootstrap';
import './TextFilter.css';

export default forwardRef((props, ref) => {
  //#region [states]
  const [filterText, setFilterText] = useState(null);
  //#endregion

  //#region [imperative handle]
  useImperativeHandle(ref, () => {
    // expose AG Grid Filter Lifecycle callbacks
    return {
      doesFilterPass(params) {
        const { api, colDef, column, columnApi, context } = props;
        const { node } = params;

        // make sure each word passes separately, ie search for firstname, lastname
        let passed = true;
        filterText
          .toLowerCase()
          .split(' ')
          .forEach((filterWord) => {
            const value = props.valueGetter({
              api,
              colDef,
              column,
              columnApi,
              context,
              data: node.data,
              getValue: (field) => node.data[field],
              node
            });

            if (value.toString().toLowerCase().indexOf(filterWord) < 0) {
              passed = false;
            }
          });

        return passed;
      },
      isFilterActive() {
        return filterText != null && filterText !== '';
      },
      getModel() {
        return !this.isFilterActive() ? null : { value: filterText };
      },
      setModel(model) {
        setFilterText(model == null ? null : model.value);
      }
    };
  });
  //#endregion

  //#region [methods]
  const handleTextChange = (event) => {
    setFilterText(event.target.value);
  };
  //#endregion

  //#region [effects]
  useEffect(() => {
    props.filterChangedCallback();
  }, [filterText]);
  //#endregion

  //#region [render]
  return (
    <div className='text-filter'>
      <FontAwesomeIcon icon='magnifying-glass' />
      <Form.Control
        type='text'
        placeholder={props.filterPlaceholder}
        onChange={handleTextChange}
      />
    </div>
  );
  //#endregion
});
