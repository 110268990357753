const reactSelectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? '#042264' : 'rgb(193, 197, 218)',
    boxShadow:
      state.isFocused || state.isVisited
        ? '0 0 2px 2px rgba(13, 17, 151, 0.2)'
        : 'none',
    outline: 'none',
    '&:hover': {}
  }),
  singleValue: (defaultStyles) => ({
    ...defaultStyles,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '20px',
    fontWeight: 500,
    justifyContent: 'space-between'
  }),
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    backgroundColor:
      state.isFocused || state.isSelected ? 'rgba(226,223,235, 0.4)' : 'white',
    color: 'black',
    ':active': {
      ...defaultStyles[':active'],
      backgroundColor: 'rgba(226,223,235, 0.7)'
    }
  }),
  groupHeading: () => ({
    flex: '1',
    textOverflow: 'ellipsis',
    whiteSpace: 'noWrap',
    overflow: 'hidden',
    fontSize: '14px',
    color: '#6C757D',
    textTransform: 'uppercase'
  }),
  group: () => ({ marginBottom: '6px' })
};

export default reactSelectStyles;
