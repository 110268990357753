import { useLingui } from '@lingui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import Section from '../../../../../../components/Section/Section';
import DescriptionsTable from './components/DescriptionsTable/DescriptionsTable';
import TwoColsTemplateTable from '../../../../../../components/TemplateTables/TwoColsTemplateTable/TwoColsTemplateTable';
import StdTable from './components/StdTable/StdTable';
import { descriptionsTemplate, sstTemplate, stdTemplate } from './templates';

const DescriptionSection = ({ project }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { companyId, projectId } = useParams();
  //#endregion

  //#region [render]
  const projectAndChildren = [project, ...project.children];
  return (
    <Section
      title={
        projectAndChildren.length === 1
          ? i18n._('page.description')
          : i18n._('page.descriptions')
      }
      to={`/company/${companyId}/project/${projectId}/design/descriptions`}
      open
    >
      <DescriptionsTable
        template={descriptionsTemplate(i18n, projectAndChildren)}
      />
      {project.substations.map((sst, sstIndex) => (
        <div key={'desc_sst_table_' + sstIndex}>
          <TwoColsTemplateTable template={sstTemplate(i18n, sst)} level={2} />
          <StdTable
            template={stdTemplate(i18n, projectAndChildren, sstIndex)}
          />
        </div>
      ))}
    </Section>
  );
  //#endregion
};

export default React.memo(DescriptionSection);
