import { useLingui } from '@lingui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import {
  SOURCE,
  UNIT,
  USE
} from '../../../../../../../../../../server/constants.js';
import DoughnutPieChart from '../../../../../../../../components/Chart/DoughnutPieChart.jsx';
import { SOURCES_CHART_COLORS } from '../../../../../../../../styles/colors';
import {
  formatValueWithUnit,
  roundNumber
} from '../../../../../../../../utils/data.utils';
import './EnergyProducedDistributionPieChart.css';

const EnergyProducedDistributionPieChart = ({ result, sstName }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [uses, setUses] = useState();
  const [sources, setSources] = useState();
  //#endregion

  //#region [memos]
  const productions = useMemo(() => {
    return sstName
      ? result.ComputeResult.summary.PerSub.Production?.[sstName]
      : result.ComputeResult.summary.Productions;
  }, [result.ComputeID, sstName]);

  const pieChart = useMemo(() => {
    if (!productions || !uses || !sources) return null;
    try {
      const { InitYearsSimulations } = result.ComputeResult.inp;
      const newValues = {};

      const checkedUses = Object.keys(uses).filter((use) => uses[use]);
      const checkedSources = Object.keys(sources).filter((src) => sources[src]);

      checkedUses.forEach((use) => {
        const useSources = productions[use];
        for (const [src, value] of Object.entries(useSources)) {
          if (checkedSources.includes(src)) {
            if (!newValues[src]) newValues[src] = 0;
            newValues[src] += value / InitYearsSimulations;
          }
        }
      });

      const pieChartSources = Object.keys(newValues);
      const piechartValues = Object.values(newValues);
      const roundedValues = piechartValues.map((value) =>
        roundNumber(value, 0)
      );
      const newLabels = pieChartSources.map((source) => {
        return i18n._(
          'results.energyAndCarbon.energyProducedDistributionPieChart.label',
          {
            src: i18n._(`source.${source.toLowerCase()}`),
            unit: i18n._('unit.kilowattHour')
          }
        );
      });

      const newColors = pieChartSources.map(
        (source) => SOURCES_CHART_COLORS[source.toLowerCase()]
      );
      const newTotal = formatValueWithUnit(
        i18n,
        Math.round(piechartValues.reduce((acc, current) => acc + current, 0)),
        UNIT.KILOWATT_HOUR
      );

      return {
        data: {
          values: roundedValues,
          labels: newLabels,
          colors: newColors
        },
        layout: {
          text: newTotal,
          separators: ', '
        }
      };
    } catch (err) {
      console.error(err);
      return null;
    }
  }, [result.ComputeID, productions, uses, sources]);
  //#endregion

  //#region [effects]
  useEffect(() => {
    if (!productions) return;
    const newUses = {};
    Object.keys(productions)
      .filter((use) => use.toLowerCase() !== USE.SUM)
      .forEach((use) => {
        newUses[use] = use.toLowerCase() !== USE.INJECTION;
      });
    setUses(newUses);
  }, [productions]);

  useEffect(() => {
    if (!uses) return;
    const newSources = {};
    Object.keys(uses)
      .filter((use) => uses[use])
      .forEach((use) => {
        Object.keys(productions[use])
          .filter((src) => src.toLowerCase() !== SOURCE.SUM)
          .forEach((src) => {
            newSources[src] = true;
          });
      });
    setSources(() => newSources);
  }, [uses]);
  //#endregion

  //#region [methods]
  const handleUseCheckboxChange = (evt, use) => {
    setUses((uses) => ({ ...uses, [use]: evt.target.checked }));
  };

  const handleSrcCheckboxChange = (evt, source) => {
    setSources((sources) => ({ ...sources, [source]: evt.target.checked }));
  };
  //#endregion

  //#region [render]
  if (!productions || !uses || !sources || !pieChart) return null;
  const atLeastOneUse = Object.values(uses).some((checked) => checked);
  const atLeastOneSrc = Object.values(sources).some((checked) => checked);
  return (
    <div className='energyProdPieChart'>
      <p>
        {i18n._(
          'results.energyAndCarbon.energyProducedDistributionPieChart.uses'
        )}
      </p>
      {Object.entries(uses).map(([use, checked], index) => (
        <Form.Check
          inline
          name='uses'
          type='checkbox'
          label={i18n._(`use.${use.toLowerCase()}`)}
          onChange={(evt) => handleUseCheckboxChange(evt, use)}
          id={use + '_' + index}
          checked={checked}
          key={result.ComputeID + '_' + use + '_' + index + '_' + checked}
        />
      ))}
      <p className='energyProdPieChart-sources'>
        {i18n._(
          'results.energyAndCarbon.energyProducedDistributionPieChart.sources'
        )}
      </p>
      {Object.entries(sources).map(([source, checked], index) => (
        <Form.Check
          inline
          name='sources'
          type='checkbox'
          label={i18n._(`source.${source.toLowerCase()}`)}
          onChange={(evt) => handleSrcCheckboxChange(evt, source)}
          id={source + '_' + index}
          checked={checked}
          key={result.ComputeID + '_' + source + '_' + index + '_' + checked}
        />
      ))}
      {!atLeastOneUse || !atLeastOneSrc ? (
        <Alert variant='warning'>
          {i18n._(
            'results.energyAndCarbon.energyProducedDistributionPieChart.noData'
          )}
        </Alert>
      ) : (
        <DoughnutPieChart
          data={pieChart.data}
          layout={pieChart.layout}
          title={i18n._(
            'results.energyAndCarbon.energyBalance.energyProducedDistributionPieChart.title'
          )}
          filename={i18n._('plotly.filename', {
            projectName: result.ComputeResult.inp.description,
            title: i18n._(
              'results.energyAndCarbon.energyBalance.energyProducedDistributionPieChart.title'
            )
          })}
        />
      )}
    </div>
  );
  //#endregion
};

export default EnergyProducedDistributionPieChart;
