import React from 'react';
import './Card.css';

const Card = ({ title, tooltip, className, children }) => {
  //#region [render]
  return (
    <div
      title={tooltip}
      className={`card-wrapper${className ? ' ' + className : ''}`}
    >
      {title && <p className='card-title'>{title}</p>}
      <div className='card-content'>{children}</div>
    </div>
  );
  //#endregion
};

export default React.memo(Card);
