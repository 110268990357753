import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PV_EQUIPMENT, ST_EQUIPMENT } from '../../../../../../server/constants';
import {
  getPVParams,
  getSTParams
} from '../../../../../../server/models/design/performance.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import ParamSelect from '../../../../components/ParamForm/ParamSelect';
import { INPUT_TYPE } from '../../../../constants';
import PopupContext from '../../../../contexts/PopupContext';
import azimuthImg from '../../../../image/azimuth.png';
import { roundNumber } from '../../../../utils/data.utils';
import { getDefaultValue } from '../../../../utils/param.utils';

const PhotovoltaicBloc = ({ form, onInputChange, onPVModelChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [render]
  const pvModel = Object.values(PV_EQUIPMENT).find(
    (model) => model.value === form.InitPhotovoltaicModel
  );
  const stModel = Object.values(ST_EQUIPMENT).find(
    (model) => model.value === form.InitSolarThermalModel
  );
  const { isCombined } = pvModel;
  const PV = getPVParams(pvModel);
  const ST = getSTParams(stModel);
  const disabledOptions = Object.values(PV_EQUIPMENT)
    .filter((pv) => pv.disabled)
    .map((pv) => pv.value);
  return (
    <Bloc title={i18n._('performance.photovoltaic')}>
      <div className='performance-row'>
        <ParamSelect
          label={i18n._('performance.InitPhotovoltaicModel')}
          value={form.InitPhotovoltaicModel}
          param={PV.InitPhotovoltaicModel}
          onChange={onPVModelChange}
          disabledOptions={disabledOptions}
        />
        {isCombined && (
          <span className='performance-combined'>
            <FontAwesomeIcon icon='exclamation-circle' />
            {i18n._('performance.combined.pv')}
          </span>
        )}
      </div>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.InitPVEfficiency')}
            value={roundNumber(form.InitPVEfficiency * 100, 4)}
            param={PV.InitPVEfficiency}
            onChange={(value) => onInputChange('InitPVEfficiency', value / 100)}
            topText={i18n._('performance.topInitPVEfficiency')}
            bottomText={i18n._('param.defaultValueWithUnit', {
              value: roundNumber(PV.InitPVEfficiency.default * 100, 4),
              unit: i18n._(`unit.${PV.InitPVEfficiency.unit}`)
            })}
            unit
            disabled={form.InitPhotovoltaicModel !== PV_EQUIPMENT.CUSTOM.value}
            type={INPUT_TYPE.NUMBER}
            className={
              form.InitPVEfficiency === PV.InitPVEfficiency.default
                ? 'default-value'
                : ''
            }
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('performance.InitPVTemperatureCoefGammaModule')}
            value={roundNumber(form.InitPVTemperatureCoefGammaModule * 100, 4)}
            param={PV.InitPVTemperatureCoefGammaModule}
            onChange={(value) =>
              onInputChange('InitPVTemperatureCoefGammaModule', value / 100)
            }
            bottomText={i18n._('param.defaultValueWithUnit', {
              value: roundNumber(
                PV.InitPVTemperatureCoefGammaModule.default * 100,
                4
              ),
              unit: i18n._(`unit.${PV.InitPVTemperatureCoefGammaModule.unit}`)
            })}
            unit
            disabled={form.InitPhotovoltaicModel !== PV_EQUIPMENT.CUSTOM.value}
            type={INPUT_TYPE.NUMBER}
            className={
              form.InitPVTemperatureCoefGammaModule ===
              PV.InitPVTemperatureCoefGammaModule.default
                ? 'default-value'
                : ''
            }
          />
        </Col>
      </Row>
      <ParamInput
        label={i18n._('performance.InitPVDCLossesAgeModule')}
        value={roundNumber(form.InitPVDCLossesAgeModule * 100, 4)}
        param={PV.InitPVDCLossesAgeModule}
        onChange={(value) =>
          onInputChange('InitPVDCLossesAgeModule', value / 100)
        }
        topText={i18n._('performance.topInitPVDCLossesAgeModule')}
        bottomText={i18n._('param.defaultValueWithUnit', {
          value: roundNumber(PV.InitPVDCLossesAgeModule.default * 100, 4),
          unit: i18n._(`unit.${PV.InitPVDCLossesAgeModule.unit}`)
        })}
        unit
        type={INPUT_TYPE.NUMBER}
        className={
          form.InitPVDCLossesAgeModule === PV.InitPVDCLossesAgeModule.default
            ? 'default-value'
            : ''
        }
      />
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.InitPVInverterMaxPowerPerWc')}
            value={form.InitPVInverterMaxPowerPerWc}
            param={PV.InitPVInverterMaxPowerPerWc}
            onChange={(value) =>
              onInputChange('InitPVInverterMaxPowerPerWc', value)
            }
            bottomText={getDefaultValue(i18n, PV.InitPVInverterMaxPowerPerWc)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('performance.InitPVInverterNominalEfficiency')}
            value={roundNumber(form.InitPVInverterNominalEfficiency * 100, 4)}
            param={PV.InitPVInverterNominalEfficiency}
            onChange={(value) =>
              onInputChange('InitPVInverterNominalEfficiency', value / 100)
            }
            bottomText={i18n._('param.defaultValueWithUnit', {
              value: roundNumber(
                PV.InitPVInverterNominalEfficiency.default * 100,
                4
              ),
              unit: i18n._(`unit.${PV.InitPVInverterNominalEfficiency.unit}`)
            })}
            unit
            type={INPUT_TYPE.NUMBER}
            className={
              form.InitPVInverterNominalEfficiency ===
              PV.InitPVInverterNominalEfficiency.default
                ? 'default-value'
                : ''
            }
          />
        </Col>
      </Row>
      <ParamSelect
        label={i18n._('performance.InitPVMountingType')}
        value={form.InitPVMountingType}
        param={PV.InitPVMountingType}
        onChange={(value) => onInputChange('InitPVMountingType', value)}
      />
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.InitSolarSurfaceTilt')}
            value={form.InitSolarSurfaceTilt}
            param={ST.InitSolarSurfaceTilt}
            onChange={(value) => onInputChange('InitSolarSurfaceTilt', value)}
            topText={i18n._('performance.topPVInitSolarSurfaceTilt')}
            bottomText={getDefaultValue(i18n, ST.InitSolarSurfaceTilt)}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col className='performance-azimuth-col'>
          <ParamInput
            label={i18n._('performance.InitSolarSurfaceAzimuth')}
            value={form.InitSolarSurfaceAzimuth}
            param={ST.InitSolarSurfaceAzimuth}
            onChange={(value) =>
              onInputChange('InitSolarSurfaceAzimuth', value)
            }
            topText={i18n._('performance.topPVInitSolarSurfaceAzimuth')}
            bottomText={getDefaultValue(i18n, ST.InitSolarSurfaceAzimuth)}
            unit
            showInfoIcon
            onInfoClick={() =>
              openInfoModal(
                i18n._('performance.azimuthModalTitle'),
                <img
                  src={azimuthImg}
                  alt='azimuthImg'
                  style={{ width: '90%', height: '90%' }}
                />
              )
            }
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default PhotovoltaicBloc;
