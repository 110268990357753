// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.two-cols-table tbody tr .table-label {
  border-left: 1px solid var(--border-color);
}

.two-cols-table > tbody > tr:first-of-type > td {
  border-top: 1px solid var(--border-color);
}

.two-cols-table > tbody > tr:last-of-type > td {
  border-bottom: 1px solid var(--border-color);
}

.two-cols-table > tbody > tr > td:last-of-type {
  border-right: 1px solid var(--border-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/TemplateTables/TwoColsTemplateTable/TwoColsTemplateTable.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;AAC5C;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":[".two-cols-table tbody tr .table-label {\n  border-left: 1px solid var(--border-color);\n}\n\n.two-cols-table > tbody > tr:first-of-type > td {\n  border-top: 1px solid var(--border-color);\n}\n\n.two-cols-table > tbody > tr:last-of-type > td {\n  border-bottom: 1px solid var(--border-color);\n}\n\n.two-cols-table > tbody > tr > td:last-of-type {\n  border-right: 1px solid var(--border-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
