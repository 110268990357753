import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { STORAGE_KEYS } from '../../../server/constants';
import useAuth from '../hooks/useAuth';

const RedirectToProjects = () => {
  //#region [router]
  const location = useLocation();
  //#endregion

  //#region [auth]
  const { user } = useAuth();
  //#endregion

  //#region [render]
  const companyId =
    location?.state?.companyId ??
    localStorage.getItem(STORAGE_KEYS.COMPANY) ??
    user.UserCoID;
  return <Navigate to={`company/${companyId}/projects?active=true`} replace />;
  //#endregion
};

export default RedirectToProjects;
