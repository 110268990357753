// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  width: 100%;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/result/sections/general/components/Tags/Tags.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,SAAS;EACT,WAAW;EACX,mBAAmB;AACrB","sourcesContent":[".results-tags {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  gap: 15px;\n  width: 100%;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
