import React from 'react';
import './ProjectSummary.css';
import CostSection from './sections/cost/CostSection';
import DescriptionSection from './sections/description/DescriptionSection';
import GeologySection from './sections/geology/GeologySection';
import PerformanceSection from './sections/performance/PerformanceSection';

const ProjectSummary = ({ project }) => {
  //#region [render]
  return (
    <div className='project-summary'>
      <DescriptionSection project={project} />
      <GeologySection project={project} />
      <CostSection project={project} />
      <PerformanceSection project={project} />
    </div>
  );
  //#endregion
};

export default React.memo(ProjectSummary);
