// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ps-sidebar-container {
    background-color: white !important;
    border-right: 1px solid var(--light-grey);
}

.ps-sidebar-container h1 {
    font-size: 17px;
    font-weight: 600;
    margin-left: 10px;
}

.ps-sidebar-container span,
.ps-sidebar-container h1,
.ps-sidebar-container h2,
.ps-sidebar-container p {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.ps-sidebar-container hr {
    border: 1px solid var(--medium-grey);
}

.ps-menu-icon {
    margin-right: 5px !important;
}

.ps-menu-button {
    padding-left: 10px !important;
    font-size: 15px;
    height: 40px !important;
}

.ps-menu-button:hover {
    background-color: rgb(244, 246, 248) !important;
}

.ps-submenu-expand-icon>span {
    width: 7px;
    height: 7px;
    margin: 0 0 2px 5px;
}

.ps-submenu-content .ps-menu-label {
    padding-left: 46px;
}`, "",{"version":3,"sources":["webpack://./src/pages/configs/components/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,yCAAyC;AAC7C;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;;;;IAII,yBAAyB;OACtB,sBAAsB;QACrB,qBAAqB;YACjB,iBAAiB;AAC7B;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,+CAA+C;AACnD;;AAEA;IACI,UAAU;IACV,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".ps-sidebar-container {\n    background-color: white !important;\n    border-right: 1px solid var(--light-grey);\n}\n\n.ps-sidebar-container h1 {\n    font-size: 17px;\n    font-weight: 600;\n    margin-left: 10px;\n}\n\n.ps-sidebar-container span,\n.ps-sidebar-container h1,\n.ps-sidebar-container h2,\n.ps-sidebar-container p {\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n}\n\n.ps-sidebar-container hr {\n    border: 1px solid var(--medium-grey);\n}\n\n.ps-menu-icon {\n    margin-right: 5px !important;\n}\n\n.ps-menu-button {\n    padding-left: 10px !important;\n    font-size: 15px;\n    height: 40px !important;\n}\n\n.ps-menu-button:hover {\n    background-color: rgb(244, 246, 248) !important;\n}\n\n.ps-submenu-expand-icon>span {\n    width: 7px;\n    height: 7px;\n    margin: 0 0 2px 5px;\n}\n\n.ps-submenu-content .ps-menu-label {\n    padding-left: 46px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
