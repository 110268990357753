import { UNIT } from '../../../../../../../server/constants';
import { getSafeData } from '../../../../../utils/data.utils';

export const generalTemplate = (i18n, results) => {
  const template = {
    title: i18n._('results.general.info'),
    rows: [
      {
        label: i18n._('results.general.user'),
        values: results.map((result) =>
          i18n._('results.general.username', {
            firstName: result.UserFirstName,
            lastName: result.UserLastName
          })
        )
      },

      {
        label: i18n._('results.general.FuncEvaluations'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.FuncEvaluations)
        )
      },
      {
        label: i18n._('results.general.yearsSimulations'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.inp.InitYearsSimulations)
        ),
        unit: UNIT.YEAR
      },
      {
        label: i18n._('results.general.balanceDuration'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.inp.InitBalanceDuration)
        ),
        unit: UNIT.YEAR
      }
    ]
  };
  return template;
};

export const physicalConstraintsTemplate = (i18n, results) => {
  try {
    let rows = [
      {
        label: i18n._('results.general.penalties.unmetNeeds'),
        values: results.map((result) =>
          getSafeData(
            () => result.ComputeResult.summary.Penalties.ProductionError
          )
        ),
        unit: UNIT.PERCENT
      },
      {
        label: i18n._('results.general.penalties.btesFinalT2NotReached'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.Penalties.T2final)
        ),
        unit: UNIT.PERCENT
      },
      {
        label: i18n._('results.general.penalties.minEvaporatorTempExceeded'),
        values: results.map((result) =>
          getSafeData(
            () => result.ComputeResult.summary.Penalties.TEvapHotwaterOverflow
          )
        ),
        unit: UNIT.PERCENT
      },
      {
        label: i18n._('results.general.penalties.btesPressureDrops'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.Penalties.BtesHeadloss)
        ),
        unit: UNIT.PERCENT
      },
      {
        label: i18n._('results.general.penalties.tempWaterLoopPressureDrops'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.Penalties.LoopHeadLoss)
        ),
        unit: UNIT.PERCENT
      },
      {
        label: i18n._('results.general.penalties.btesTempExceeded'),
        values: results.map((result) =>
          getSafeData(
            () => result.ComputeResult.summary.Penalties.BtesTempInOut
          )
        ),
        unit: UNIT.PERCENT
      }
    ];

    if (results.length === 1) rows = rows.filter((row) => row.values[0]);
    return {
      rows: rows.sort((a, b) => a.values[0] - b.values[0])
    };
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const nonComplianceUserPenaltiesTemplate = (i18n, results) => {
  try {
    return {
      rows: [
        {
          label: i18n._('results.general.penalties.objectiveNotAchieved'),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.Penalties
                  .ConstraintAdditionnalPenalty
            )
          ),
          unit: UNIT.PERCENT
        }
      ]
    };
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const penaltiesTemplate = (i18n, results) => {
  try {
    return {
      title: i18n._('results.general.penalties.total'),
      rows: [
        {
          label: i18n._('results.general.penalties.unitPenalty'),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.Penalties.UnitPenalty
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.general.penalties.physicalConstraintsPenalties'
          ),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.Penalties
                  .TotalPhysicalConstraintsPenalties
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.general.penalties.nonComplianceObjUserPenalties'
          ),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.Penalties
                  .TotalNonComplianceObjUserPenalties
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._('results.general.penalties.totalPenalties'),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.Penalties.TotalPenalty
            )
          ),
          unit: UNIT.EURO
        }
      ]
    };
  } catch (err) {
    console.error(err);
    return null;
  }
};
