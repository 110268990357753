const {
  SRC_EXCHANGE,
  SRC_TYPE,
  GEOSTORAGE_EXCHANGER,
  GEOSTORAGE_HE
} = require('../../../../constants');

const getEx4x0Params = (srcType) => {
  return {
    Ex4x0: {
      [SRC_EXCHANGE.CAPTURE]: {
        value: GEOSTORAGE_EXCHANGER.EX400,
        default: srcType !== SRC_TYPE.BTES_GLYCOL_WATER
      },
      [SRC_EXCHANGE.INJECTION]: {
        value: GEOSTORAGE_EXCHANGER.EX410,
        default: srcType !== SRC_TYPE.BTES_CLEAN_WATER
      }
    }
  };
};

const getExchangersParams = (Ex400, Ex410) => {
  return {
    Pr4x1: {
      [SRC_EXCHANGE.CAPTURE]: {
        value: GEOSTORAGE_EXCHANGER.PR401,
        default: Ex400
      },
      [SRC_EXCHANGE.INJECTION]: {
        value: GEOSTORAGE_EXCHANGER.PR411,
        default: Ex410
      }
    },
    Pr4x2: {
      [SRC_EXCHANGE.CAPTURE]: {
        value: GEOSTORAGE_EXCHANGER.PR402,
        default: Ex400
      },
      [SRC_EXCHANGE.INJECTION]: {
        value: GEOSTORAGE_EXCHANGER.PR412,
        default: Ex410
      }
    },
    Pr4x3: {
      [SRC_EXCHANGE.CAPTURE]: {
        value: GEOSTORAGE_EXCHANGER.PR403,
        default: Ex400
      },
      [SRC_EXCHANGE.INJECTION]: {
        value: GEOSTORAGE_EXCHANGER.PR413,
        default: Ex410
      }
    },
    Pr4x4: {
      [SRC_EXCHANGE.CAPTURE]: {
        value: GEOSTORAGE_EXCHANGER.PR404,
        default: Ex400
      },
      [SRC_EXCHANGE.INJECTION]: {
        value: GEOSTORAGE_EXCHANGER.PR414,
        default: Ex410
      }
    }
  };
};

const getHydraulicEquipmentParams = (Ex400, Ex410, type) => {
  return {
    Sx00: {
      [SRC_EXCHANGE.CAPTURE]: {
        value: GEOSTORAGE_HE.S400,
        default: true
      }
    },
    Etx00: {
      [SRC_EXCHANGE.CAPTURE]: {
        value: GEOSTORAGE_HE.ET400,
        default: true
      }
    },
    Puxx0: {
      [SRC_EXCHANGE.CAPTURE]: {
        value: GEOSTORAGE_HE.PU400,
        default:
          Ex400 &&
          (type === SRC_TYPE.BTES_CLEAN_WATER || type === SRC_TYPE.GROUNDWATER)
      },
      [SRC_EXCHANGE.INJECTION]: {
        value: GEOSTORAGE_HE.PU410,
        default:
          Ex410 &&
          (type === SRC_TYPE.BTES_GLYCOL_WATER || type === SRC_TYPE.GROUNDWATER)
      }
    },
    Vmxx0: {
      [SRC_EXCHANGE.CAPTURE]: {
        value: GEOSTORAGE_HE.VM400,
        default:
          Ex400 &&
          (type === SRC_TYPE.GLYCOOL_LOOP ||
            type === SRC_TYPE.CLEAN_WATER_LOOP ||
            type === SRC_TYPE.GROUNDWATER)
      },
      [SRC_EXCHANGE.INJECTION]: {
        value: GEOSTORAGE_HE.VM410,
        default:
          Ex410 &&
          (type === SRC_TYPE.GLYCOOL_LOOP ||
            type === SRC_TYPE.CLEAN_WATER_LOOP ||
            type === SRC_TYPE.GROUNDWATER)
      }
    }
  };
};

const getDefaultValues = (params) => {
  return Object.values(params).reduce((acc, equipmentType) => {
    Object.values(equipmentType).forEach((equipment) => {
      acc[equipment.value] = equipment.default;
    });
    return acc;
  }, {});
};

const getDefaultGeostorageHydraulicConfigValues = (type) => {
  const Ex4x0 = getDefaultValues(getEx4x0Params(type));
  const otherExchangers = getDefaultValues(
    getExchangersParams(
      Ex4x0[GEOSTORAGE_EXCHANGER.EX400],
      Ex4x0[GEOSTORAGE_EXCHANGER.EX410]
    )
  );
  const hydraulicEquipment = getDefaultValues(
    getHydraulicEquipmentParams(
      Ex4x0[GEOSTORAGE_EXCHANGER.EX400],
      Ex4x0[GEOSTORAGE_EXCHANGER.EX410],
      type
    )
  );
  return { exchangers: { ...Ex4x0, ...otherExchangers }, hydraulicEquipment };
};

module.exports = {
  getEx4x0Params,
  getExchangersParams,
  getHydraulicEquipmentParams,
  getDefaultGeostorageHydraulicConfigValues
};
