import { useLingui } from '@lingui/react';
import React, { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  BTES_TEMP_KEYS,
  HOURS_PER_YEAR
} from '../../../../../../../../server/constants';
import LinesChart from '../../../../../../components/Chart/LinesChart';
import { CHART_MODE } from '../../../../../../constants';
import { DETAILED_RESULTS_CHART_COLORS } from '../../../../../../styles/colors';

const BtesTemperatureLinesChartPerYear = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [selectedYear, setSelectedYear] = useState(0);
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    if (!result.details) return null;
    const start = selectedYear * HOURS_PER_YEAR;
    const end = (selectedYear + 1) * HOURS_PER_YEAR;
    const oneYearArr = new Array(HOURS_PER_YEAR).fill(0);
    return BTES_TEMP_KEYS.map((key) => ({
      x: oneYearArr.map((_, i) => selectedYear * HOURS_PER_YEAR + i),
      y: result.details[key].slice(start, end),
      name: i18n._(`btes.${key}`),
      line: { color: DETAILED_RESULTS_CHART_COLORS[key] },
      mode: CHART_MODE.LINES,
      hovertemplate: '%{y:.4s}'
    }));
  }, [result.details, selectedYear]);
  //#endregion

  //#region [render]
  const { InitYearsSimulations } = result.ComputeResult.inp;
  return data ? (
    <div>
      <Form.Select
        value={selectedYear}
        onChange={(evt) => setSelectedYear(evt.target.value)}
        style={{ width: '160px', marginTop: '15px' }}
      >
        {new Array(InitYearsSimulations).fill(0).map((_, index) => (
          <option key={'btesTemperatureLinesChart_' + index} value={index}>
            {i18n._('results.year', { year: index + 1 })}
          </option>
        ))}
      </Form.Select>
      <LinesChart
        data={data}
        layout={{
          xaxis: { title: i18n._('date.hours') }
        }}
        title={i18n._('results.geomodeling.btesTempPerYear.title', {
          year: parseInt(selectedYear) + 1
        })}
      />
    </div>
  ) : null;
  //#endregion
};

export default BtesTemperatureLinesChartPerYear;
