import { useLingui } from '@lingui/react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchProjects } from '../../../api/company.api';
import PopupContext from '../../../contexts/PopupContext';
import useAuth from '../../../hooks/useAuth';
import ActiveProjectActionsCell from './ActiveProjectActionsCell';
import ProjectAppsCell from './ProjectAppsCell';
import ProjectDescriptionCell from './ProjectDescriptionCell';
import ProjectImgCell from './ProjectImgCell';
import ProjectLastUpdateCell from './ProjectLastUpdateCell';
import ProjectLastUpdaterCell from './ProjectLastUpdaterCell';
import ProjectNameCell from './ProjectNameCell';
import ProjectsTable from './ProjectsTable/ProjectsTable';

const ActiveProjects = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  const { companyId } = useParams();
  //#endregion

  //#region [auth]
  const { user } = useAuth();
  //#endregion

  //#region [contexts]
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [activeProjects, setActiveProjects] = useState([]);
  //#endregion

  //#region [callbacks]
  const getProjects = useCallback(async () => {
    try {
      const projects = await fetchProjects(companyId, true);
      setActiveProjects(projects);
    } catch (err) {
      throw err;
    }
  }, [companyId]);
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        await getProjects();
      } catch (err) {
        console.error(err);
        if (err.response?.status === 404) {
          navigate('/notfound');
        } else {
          openErrorToast(err);
        }
      }
    })();
  }, [companyId, getProjects]);
  //#endregion

  //#region [memos]
  const columns = useMemo(
    () => [
      {
        id: 'ProjectImg',
        Cell: ProjectImgCell
      },
      {
        id: 'AhsName',
        accessor: 'AhsName',
        Header: () => <span>{i18n._('projects.header.name')}</span>,
        Cell: ProjectNameCell
      },
      {
        id: 'apps',
        Header: () => <span>{i18n._('projects.header.apps')}</span>,
        Cell: ProjectAppsCell
      },
      {
        id: 'AhsDescription',
        Header: () => <span>{i18n._('projects.header.description')}</span>,
        Cell: ProjectDescriptionCell,
        isSorted: false
      },
      {
        id: 'lastUpdate',
        accessor: 'lastUpdate',
        Header: () => <span>{i18n._('projects.header.lastUpdate')}</span>,
        Cell: ProjectLastUpdateCell,
        sortType: (a, b) =>
          new Date(b.original.lastUpdate) - new Date(a.original.lastUpdate)
      },
      {
        id: 'lastUpdater',
        Header: () => <span>{i18n._('projects.header.lastUpdater')}</span>,
        Cell: ProjectLastUpdaterCell,
        isSorted: false
      },
      {
        id: 'actions',
        Cell: ActiveProjectActionsCell,
        onProjectDelete: getProjects,
        isSorted: false
      }
    ],
    [companyId]
  );
  //#endregion

  //#region [render]
  return (
    <ProjectsTable
      projects={activeProjects}
      columns={columns}
      hiddenColumns={user.IsSuperAdmin ? [] : ['actions']}
      smallSizeHiddenColumns={['ProjectImg', 'AhsDescription', 'lastCompute']}
    />
  );
  //#endregion
};

export default ActiveProjects;
