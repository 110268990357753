import {
  BTES,
  BTES_TEST,
  SOIL
} from '../../../../../../../../server/models/design/geology.model';

export const geoTemplates = (i18n, project) => [
  {
    title: i18n._('geology.trt'),
    rows: [
      {
        label: i18n._('geology.InitTrtChecked'),
        value: project.otherValues.InitTrtChecked ? i18n._('yes') : i18n._('no')
      },
      {
        label: i18n._('geology.InitBtesTestProbeLength'),
        value: project.otherValues.InitBtesTestProbeLength,
        unit: BTES_TEST.InitBtesTestProbeLength.unit
      }
    ]
  },
  {
    title: i18n._('geology.soil'),
    rows: [
      {
        label: i18n._('geology.InitSoilThermalConductivity'),
        value: project.otherValues.InitSoilThermalConductivity,
        unit: SOIL.InitSoilThermalConductivity.unit
      },
      {
        label: i18n._('geology.InitSoilVolumeHeatCapacity'),
        value: project.otherValues.InitSoilVolumeHeatCapacity,
        unit: SOIL.InitSoilVolumeHeatCapacity.unit
      },
      {
        label: i18n._('geology.InitSoilTemperature10m'),
        value: project.otherValues.InitSoilTemperature10m,
        unit: SOIL.InitSoilTemperature10m.unit
      }
    ]
  },
  {
    title: i18n._('geology.btes'),
    rows: [
      {
        label: i18n._('geology.InitBtesTemperatureMin'),
        value: project.otherValues.InitBtesTemperatureMin,
        unit: BTES.InitBtesTemperatureMin.unit
      },
      {
        label: i18n._('geology.InitBtesTemperatureMax'),
        value: project.otherValues.InitBtesTemperatureMax,
        unit: BTES.InitBtesTemperatureMax.unit
      },
      {
        label: i18n._('geology.InitBtesTemperatureDelta'),
        value: project.otherValues.InitBtesTemperatureDelta,
        unit: BTES.InitBtesTemperatureDelta.unit
      },
      {
        label: i18n._('geology.YearsT2Final'),
        value: project.otherValues.YearsT2Final,
        unit: BTES.YearsT2Final.unit
      },
      {
        label: i18n._('geology.InitBtesMaxWidth'),
        value: project.otherValues.InitBtesMaxWidth,
        unit: BTES.InitBtesMaxWidth.unit
      },
      {
        label: i18n._('geology.InitBtesProbeResistance'),
        value: project.otherValues.InitBtesProbeResistance,
        unit: BTES.InitBtesProbeResistance.unit,
        precision: 2
      },
      {
        label: i18n._('geology.InitBtesProbeInternalDiameter'),
        value: project.otherValues.InitBtesProbeInternalDiameter,
        unit: BTES.InitBtesProbeInternalDiameter.unit,
        precision: 4
      }
    ]
  }
];
