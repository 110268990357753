// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expandable-section {
  margin: 10px 0;
}

.expandable-section-children {
  border: 1px solid var(--border-color);
  border-radius: 5px;
}

.expandable-section .expandable-section-child {
  padding: 0 10px;
}

.expandable-section .expandable-section-child {
  padding-top: 5px;
}

.expandable-section .expandable-section-child {
  padding-bottom: 5px;
}

.expandable-section .expandable-section-child:nth-of-type(2n) {
  background-color: rgb(245, 245, 245) !important;
}

.expandable-section-child-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  padding: 5px 0;
  font-weight: 450;
}

.expandable-section-child-title svg {
  font-size: 18px;
  color: var(--primary-color);
}

.expandable-section-child-title .spinner-border {
  width: 20px;
  height: 20px;
}

.expandable-section > div {
  width: 100%;
}

.expandable-section-child-content > :first-child {
  width: 100%;
}

.expandable-section-child-content .card-wrapper {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/ExpandableSection/ExpandableSection.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qCAAqC;EACrC,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;EAC3B,SAAS;EACT,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":[".expandable-section {\n  margin: 10px 0;\n}\n\n.expandable-section-children {\n  border: 1px solid var(--border-color);\n  border-radius: 5px;\n}\n\n.expandable-section .expandable-section-child {\n  padding: 0 10px;\n}\n\n.expandable-section .expandable-section-child {\n  padding-top: 5px;\n}\n\n.expandable-section .expandable-section-child {\n  padding-bottom: 5px;\n}\n\n.expandable-section .expandable-section-child:nth-of-type(2n) {\n  background-color: rgb(245, 245, 245) !important;\n}\n\n.expandable-section-child-title {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  gap: 10px;\n  padding: 5px 0;\n  font-weight: 450;\n}\n\n.expandable-section-child-title svg {\n  font-size: 18px;\n  color: var(--primary-color);\n}\n\n.expandable-section-child-title .spinner-border {\n  width: 20px;\n  height: 20px;\n}\n\n.expandable-section > div {\n  width: 100%;\n}\n\n.expandable-section-child-content > :first-child {\n  width: 100%;\n}\n\n.expandable-section-child-content .card-wrapper {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
