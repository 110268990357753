import axios from '../conf/axios.conf';

//#region [GET]
export const fetchUser = async () => {
  try {
    const response = await axios.get('/user/me');
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const isEmailAvailable = async (email) => {
  try {
    const response = await axios.post('/user/available', { email });
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [POST]
export const createUser = async (user) => {
  try {
    const response = await axios.post('/user', user);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion
