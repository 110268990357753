import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import {
  SOCKET_MSG_TYPE,
  SOCKET_ROOM_VERSION
} from '../../../../server/constants';
import { getVersion } from '../../api/redis.api';
import PopupContext from '../../contexts/PopupContext';
import ws from '../../tools/WebSocketClient';
import './VersionPage.css';

const VersionPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [info, setInfo] = useState();
  const [error, setError] = useState();
  const [isWsReady, setIsWsReady] = useState();
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        // callback quand le websocket est connecté
        const openHandler = () => setIsWsReady(() => true);

        // callback quand le websocket est déconnecté
        const closeHandler = () => setIsWsReady(() => false);

        // callback pour gérer les messages reçus par le websocket
        const messageHandler = (data) => {
          try {
            if (data.type === SOCKET_MSG_TYPE.VERSION) {
              setInfo(() => ({
                buildDate: data.msg.buildDate,
                commit: data.msg.commit,
                computer: data.msg.computer,
                version: data.msg.version
              }));
              ws.disconnect(SOCKET_ROOM_VERSION);
            } else if (data.type === SOCKET_MSG_TYPE.ERROR) {
              setError(data.msg);
            }
          } catch (err) {
            console.error(err);
            openErrorToast(err);
          }
        };

        // on se connecte au websocket
        ws.connect(
          SOCKET_ROOM_VERSION,
          openHandler,
          closeHandler,
          messageHandler
        );

        // on demande la version
        await getVersion();
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
    return () => ws.disconnect(SOCKET_ROOM_VERSION);
  }, []);
  //#endregion

  //#region [render]
  return (
    <div className='version-page'>
      {error ? <Alert variant='danger'>{error}</Alert> : null}
      {isWsReady === false && (
        <Alert variant='danger'>{i18n._('error.wsUnavailable')}</Alert>
      )}
      {info &&
        Object.keys(info).map((key) => (
          <div key={key}>
            <span className='bold'>{key}</span> - {info[key]}
          </div>
        ))}
    </div>
  );
  //#endregion
};

export default VersionPage;
