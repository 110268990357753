import React from 'react';
import './Table.css';

const Table = ({ header, cols, className }) => {
  //#region [render]
  if (!cols || cols.length === 0) return null;
  const values = [];
  for (let i = 0; i < cols[0].length; ++i) {
    values[i] = [];
    cols.forEach((colValues) => {
      values[i].push(colValues[i]);
    });
  }
  return (
    <table className={`custom-table${className ? ' ' + className : ''}`}>
      <thead>
        <tr>
          {header.map((colName) => (
            <th key={'table_th_' + colName}>{colName}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {values.map((rowValues, rowIndex) => (
          <tr key={'table_tr_' + rowValues[0][0] + '_' + rowIndex}>
            {rowValues.map((value, colIndex) => (
              <td key={'table_td_' + value + '_' + colIndex}>{value}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
  //#endregion
};

export default React.memo(Table);
