import { useLingui } from '@lingui/react';
import React from 'react';
import { formatDateAndHour } from '../../../utils/date.utils';

const ProjectLastUpdateCell = ({ row }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return <div>{formatDateAndHour(i18n, row.original.lastUpdate)}</div>;
  //#endregion
};

export default React.memo(ProjectLastUpdateCell);
