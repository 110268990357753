import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { Fragment } from 'react';
import { Tooltip } from 'react-tooltip';
import { UNIT } from '../../../../../server/constants';
import { getResultFullName } from '../../../utils/compute.utils';
import {
  formatValueWithUnit,
  getPercentsDifference,
  isNull
} from '../../../utils/data.utils';
import './AccentaVsRefTable.css';

const AccentaVsRefTable = ({ template, comparedResults }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <table className='template-table accenta-ref-table results-table'>
      <thead>
        <tr>
          <th
            style={{ width: comparedResults.length > 2 ? '17%' : '35%' }}
          ></th>
          {comparedResults.map((result, i) => (
            <Fragment key={'th_accenta_ref_' + i}>
              <th className='result-table-title'>
                {i18n._('reference', { index: i + 1 })}
              </th>
              <th title={getResultFullName(i18n, result)}>
                <p className='result-table-title'>
                  {i18n._('result', { index: i + 1 })}
                </p>
                {result.Comment && (
                  <p className='result-table-details ellipsis'>
                    {result.Comment}
                  </p>
                )}
              </th>
            </Fragment>
          ))}
        </tr>
      </thead>
      <tbody>
        {template.rows.map((row, rowIndex) => (
          <tr key={'accenta_ref_row_' + rowIndex}>
            <td className='table-label'>
              {row.label}
              {row.tooltipInfo && (
                <>
                  <FontAwesomeIcon
                    icon='fa-regular fa-circle-question'
                    data-tooltip-id={'accenta_ref_tooltip_' + rowIndex}
                    data-tooltip-content={row.tooltipInfo}
                  />
                  <Tooltip
                    id={'accenta_ref_tooltip_' + rowIndex}
                    place='right'
                    className='info-tooltip'
                    opacity={1}
                  />
                </>
              )}
            </td>
            {row.refValues.map((refValue, index) => {
              const refValueWithUnit = !isNull(refValue)
                ? formatValueWithUnit(i18n, refValue, row.unit, row.precision)
                : i18n._('noValue');

              const accentaValue = row.accentaValues[index];
              let accentaValueWithUnit = !isNull(accentaValue)
                ? formatValueWithUnit(
                    i18n,
                    accentaValue,
                    row.unit,
                    row.precision
                  )
                : i18n._('noValue');

              let comparisonValue;
              let comparisonPercent;
              if (typeof refValue === 'string') refValue = Number(refValue);
              if (refValue && !isNull(accentaValue)) {
                comparisonValue = getPercentsDifference(accentaValue, refValue);
                comparisonPercent = formatValueWithUnit(
                  i18n,
                  comparisonValue,
                  UNIT.PERCENT,
                  row.precision
                );
                if (comparisonValue > 0) {
                  comparisonPercent = i18n._('plusValue', {
                    value: comparisonPercent
                  });
                }
              }
              return (
                <Fragment key={'accenta_ref_values_' + index}>
                  <td className='ref-td' title={refValueWithUnit}>
                    <p>{refValueWithUnit}</p>
                    {comparisonPercent && (
                      <p>
                        {i18n._('accentaVsRef', {
                          percent: comparisonPercent
                        })}
                      </p>
                    )}
                  </td>
                  <td className='accenta-td' title={accentaValueWithUnit}>
                    <p>{accentaValueWithUnit}</p>
                    {comparisonPercent && (
                      <p>
                        {i18n._('accentaVsRef', {
                          percent: comparisonPercent
                        })}
                      </p>
                    )}
                  </td>
                </Fragment>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
  //#endregion
};

export default React.memo(AccentaVsRefTable);
