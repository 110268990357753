import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SOLAR } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../constants';
import { getDefaultValue } from '../../../../utils/param.utils';

const SolarThermalBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('cost.solarThermal')} level={2}>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitSolarThermalPanelCostA')}
            value={form.InitSolarThermalPanelCostA}
            param={SOLAR.InitSolarThermalPanelCostA}
            onChange={(value) =>
              onInputChange('InitSolarThermalPanelCostA', value)
            }
            unit
            bottomText={getDefaultValue(i18n, SOLAR.InitSolarThermalPanelCostA)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitSolarThermalVanneCost')}
            value={form.InitSolarThermalVanneCost}
            param={SOLAR.InitSolarThermalVanneCost}
            onChange={(value) =>
              onInputChange('InitSolarThermalVanneCost', value)
            }
            unit
            bottomText={getDefaultValue(i18n, SOLAR.InitSolarThermalVanneCost)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitSolarThermalPumpCostA')}
            value={form.InitSolarThermalPumpCostA}
            param={SOLAR.InitSolarThermalPumpCostA}
            onChange={(value) =>
              onInputChange('InitSolarThermalPumpCostA', value)
            }
            unit
            bottomText={getDefaultValue(i18n, SOLAR.InitSolarThermalPumpCostA)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitSolarThermalPumpCostB')}
            value={form.InitSolarThermalPumpCostB}
            param={SOLAR.InitSolarThermalPumpCostB}
            onChange={(value) =>
              onInputChange('InitSolarThermalPumpCostB', value)
            }
            unit
            bottomText={getDefaultValue(i18n, SOLAR.InitSolarThermalPumpCostB)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default SolarThermalBloc;
