import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { updateInputs } from '../../../../../../../api/project.api';
import PopupContext from '../../../../../../../contexts/PopupContext';
import RecursiveProperty from '../RecursiveProperty/RecursiveProperty';
import './InputsParamsTable.css';

const InputsParamsTable = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { projectId } = useParams();
  //#endregion

  //#region [contexts]
  const { openErrorToast, openToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const handleUpdateInputsClick = async () => {
    try {
      await updateInputs(projectId, result.ComputeID);
      openToast(
        i18n._('results.inputsParams.inputsUpdated.title'),
        i18n._('results.inputsParams.inputsUpdated.body'),
        'success'
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  return (
    <div>
      <Button
        variant='primary'
        onClick={handleUpdateInputsClick}
        className='results-params-inputs-btn'
      >
        {i18n._('results.inputsParams.inputsReturn')}
      </Button>
      <table className='results-params-inputs-table'>
        <tbody>
          <RecursiveProperty
            property={result.ComputeResult.inp}
            propertyName={i18n._('results.inputsParams.table.title')}
            rootProperty
            level={0}
          />
        </tbody>
      </table>
    </div>
  );
  //#endregion
};

export default InputsParamsTable;
