import { useLingui } from '@lingui/react';
import React from 'react';
import ResultsTable from '../../../../../../components/TemplateTables/ResultsTable/ResultsTable';
import { capexBySstTemplates } from '../templates';

const CapexBySSTTable = ({ sstName, comparedResults }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <div>
      <h2>
        {i18n._('results.economicAnalysis.capexBySst', {
          sst: sstName
        })}
      </h2>
      {capexBySstTemplates(i18n, comparedResults, sstName).map(
        (template, index) => (
          <ResultsTable
            level={3}
            template={template}
            comparedResults={comparedResults}
            key={'economicAnalysis_capexBySst_template_' + index}
          />
        )
      )}
    </div>
  );
  //#endregion
};

export default React.memo(CapexBySSTTable);
