// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.opti-goals-section .col-8,
.opti-goals-section .col {
  padding-right: 0;
}

.opti-goals-section-caption {
  font-size: 11px;
  color: var(--medium-grey);
  margin-bottom: 0;
}

.opti-goals-counter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/ComputeForm/optimization/sections/OptiGoalsSection/OptiGoalsSection.css"],"names":[],"mappings":"AAAA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,SAAS;EACT,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;AAC7B","sourcesContent":[".opti-goals-section .col-8,\n.opti-goals-section .col {\n  padding-right: 0;\n}\n\n.opti-goals-section-caption {\n  font-size: 11px;\n  color: var(--medium-grey);\n  margin-bottom: 0;\n}\n\n.opti-goals-counter {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  gap: 10px;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
