// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.count-cell .fa-circle-minus:not(.disabled),
.count-cell .fa-circle-plus:not(.disabled) {
    cursor: pointer;
    color: var(--primary-color);
}

.count-cell>span {
    font-size: 14px;
    margin: 0 6px;
}`, "",{"version":3,"sources":["webpack://./src/pages/configs/thermalProduction/heatpumps/selection/cells/CountCellRenderer/CountCellRenderer.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,2BAA2B;AAC/B;;AAEA;IACI,eAAe;IACf,aAAa;AACjB","sourcesContent":[".count-cell .fa-circle-minus:not(.disabled),\n.count-cell .fa-circle-plus:not(.disabled) {\n    cursor: pointer;\n    color: var(--primary-color);\n}\n\n.count-cell>span {\n    font-size: 14px;\n    margin: 0 6px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
