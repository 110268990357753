import React from 'react';

const defaultState = {
  config: undefined,
  savedConfig: undefined,
  setConfig: () => {},
  refreshConfig: async () => {}
};

const ConfigsContext = React.createContext(defaultState);
export default ConfigsContext;
