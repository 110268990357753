import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { restoreProject } from '../../../api/project.api';
import PopupContext from '../../../contexts/PopupContext';

const DeletedProjectActionsCell = ({ row, column }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openConfirmModal, openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const handleRestoreProject = async () => {
    try {
      await restoreProject(row.original.AhsID);
      await column.onProjectRestore();
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  return (
    <FontAwesomeIcon
      icon='trash-can-arrow-up'
      className='projects-table-actions-restore'
      onClick={() =>
        openConfirmModal(
          i18n._('projects.restoreProject.title'),
          i18n._('projects.restoreProject.body'),
          'info',
          handleRestoreProject
        )
      }
      title={i18n._('projects.action.restore')}
    />
  );
  //#endregion
};

export default DeletedProjectActionsCell;
