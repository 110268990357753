// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.param-input-label {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.param-input-small-text {
  font-size: 13px;
  color: var(--medium-grey);
}

.param-input-top-text {
  margin: 0;
  padding: 0;
  font-style: italic;
}

.param-input-label,
.param-input-small-text {
  white-space: pre-wrap;
  max-width: 500px;
}

.param-input-label svg {
  margin-left: 8px;
}

.param-input-unit {
  font-size: 14px;
}

.param-input {
  width: 100%;
}

.param-input-error {
  border-color: var(--danger-color) !important;
}

.param-input-error:focus {
  border-color: var(--danger-color) !important;
  -webkit-box-shadow: 0 0 2px 2px rgba(193, 43, 43, 0.2) !important;
          box-shadow: 0 0 2px 2px rgba(193, 43, 43, 0.2) !important;
}

.param-input-changed {
  border: 1px solid rgba(15, 40, 132, 0.7) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/ParamForm/ParamForm.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,SAAS;EACT,UAAU;EACV,kBAAkB;AACpB;;AAEA;;EAEE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,4CAA4C;EAC5C,iEAAiE;UACzD,yDAAyD;AACnE;;AAEA;EACE,mDAAmD;AACrD","sourcesContent":[".param-input-label {\n  font-size: 16px;\n  font-weight: 500;\n  margin-bottom: 5px;\n}\n\n.param-input-small-text {\n  font-size: 13px;\n  color: var(--medium-grey);\n}\n\n.param-input-top-text {\n  margin: 0;\n  padding: 0;\n  font-style: italic;\n}\n\n.param-input-label,\n.param-input-small-text {\n  white-space: pre-wrap;\n  max-width: 500px;\n}\n\n.param-input-label svg {\n  margin-left: 8px;\n}\n\n.param-input-unit {\n  font-size: 14px;\n}\n\n.param-input {\n  width: 100%;\n}\n\n.param-input-error {\n  border-color: var(--danger-color) !important;\n}\n\n.param-input-error:focus {\n  border-color: var(--danger-color) !important;\n  -webkit-box-shadow: 0 0 2px 2px rgba(193, 43, 43, 0.2) !important;\n          box-shadow: 0 0 2px 2px rgba(193, 43, 43, 0.2) !important;\n}\n\n.param-input-changed {\n  border: 1px solid rgba(15, 40, 132, 0.7) !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
