import { UNIT } from '../../../../../../../server/constants';
import { getSafeData } from '../../../../../utils/data.utils';

export const btesEnergybalanceTemplate = (i18n, results) => {
  return {
    rows: [
      {
        label: i18n._('results.geomodeling.btesEnergybalance.selfDischarge'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.AutoDecharge)
        ),
        unit: UNIT.KILOWATT_HOUR
      },
      {
        label: i18n._(
          'results.geomodeling.btesEnergybalance.QBtesPerYearAverage'
        ),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.QBtesPerYearAverage)
        ),
        unit: UNIT.KILOWATT_HOUR
      },
      {
        label: i18n._(
          'results.geomodeling.btesEnergybalance.injectedHeatForT2Final'
        ),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.QExtraSurinjAverage)
        ),
        unit: UNIT.KILOWATT_HOUR
      },
      {
        label: i18n._(
          'results.geomodeling.btesEnergybalance.QBtesExtractMaxPerMeter'
        ),
        values: results.map((result) =>
          getSafeData(
            () => result.ComputeResult.summary.QBtesExtractMaxPerMeter
          )
        ),
        unit: UNIT.WATT_PER_METER
      },
      {
        label: i18n._(
          'results.geomodeling.btesEnergybalance.QBtesInjectMaxPerMeter'
        ),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.QBtesInjectMaxPerMeter)
        ),
        unit: UNIT.WATT_PER_METER
      },
      {
        label: i18n._(
          'results.geomodeling.btesEnergybalance.QBtesExtractTotal'
        ),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.QBtesExtractTotal)
        ),
        unit: UNIT.KILOWATT_HOUR
      },
      {
        label: i18n._(
          'results.geomodeling.btesEnergybalance.QBtesExtractTotalPerMeter'
        ),
        values: results.map((result) =>
          getSafeData(
            () => result.ComputeResult.summary.QBtesExtractTotalPerMeter
          )
        ),
        unit: UNIT.KILOWATT_HOUR_PER_METER
      },
      {
        label: i18n._('results.geomodeling.btesEnergybalance.QBtesInjectTotal'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.QBtesInjectTotal)
        ),
        unit: UNIT.KILOWATT_HOUR
      },
      {
        label: i18n._(
          'results.geomodeling.btesEnergybalance.QBtesInjectTotalPerMeter'
        ),
        values: results.map((result) =>
          getSafeData(
            () => result.ComputeResult.summary.QBtesInjectTotalPerMeter
          )
        ),
        unit: UNIT.KILOWATT_HOUR_PER_METER
      }
    ]
  };
};

export const btesEvolutionTemplate = (i18n, results) => {
  return {
    title: i18n._('results.geomodeling.btesEvolution'),
    rows: [
      {
        label: i18n._('results.geomodeling.btesEvolution.minWaterTemperature'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.TEauInBtesSimuMin)
        ),
        unit: UNIT.CELSIUS_DEGREE
      },
      {
        label: i18n._('results.geomodeling.btesEvolution.maxWaterTemperature'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.TEauInBtesSimuMax)
        ),
        unit: UNIT.CELSIUS_DEGREE
      }
    ]
  };
};

export const btesParametersTemplate = (i18n, results) => {
  return {
    title: i18n._('results.geomodeling.btesParameters'),
    rows: [
      {
        label: i18n._('results.geomodeling.btesParameters.mc1'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.MC1)
        ),
        unit: UNIT.JOULE_PER_KELVIN_PER_CUBIC_METER
      },
      {
        label: i18n._('results.geomodeling.btesParameters.mc2'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.MC2)
        ),
        unit: UNIT.JOULE_PER_KELVIN_PER_CUBIC_METER
      },
      {
        label: i18n._('results.geomodeling.btesParameters.A'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.A)
        ),
        precision: 2,
        unit: UNIT.WATT_PER_METER_KELVIN
      },
      {
        label: i18n._('results.geomodeling.btesParameters.B'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.B)
        ),
        precision: 2,
        unit: UNIT.WATT_PER_METER_KELVIN
      },
      {
        label: i18n._('results.geomodeling.btesParameters.R'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.R)
        ),
        precision: 2,
        unit: UNIT.KELVIN_METER_PER_WATT
      }
    ]
  };
};
