// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.counter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 4px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  -moz-user-select: none;
       user-select: none; /* Standard syntax */
}

.counter > span {
  font-size: 16px;
}

.counter svg {
  color: var(--light-grey);
  font-size: 15px;
}

.counter svg.active {
  color: var(--primary-color);
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Counter/Counter.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,QAAQ;EACR,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;EAC3B,yBAAyB,EAAE,WAAW;EACtC,qBAAqB,EAAE,oBAAoB;EAC3C,sBAAsB;OACjB,iBAAiB,EAAE,oBAAoB;AAC9C;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,eAAe;AACjB;;AAEA;EACE,2BAA2B;EAC3B,eAAe;AACjB","sourcesContent":[".counter {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  gap: 4px;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-user-select: none; /* Safari */\n  -ms-user-select: none; /* IE 10 and IE 11 */\n  -moz-user-select: none;\n       user-select: none; /* Standard syntax */\n}\n\n.counter > span {\n  font-size: 16px;\n}\n\n.counter svg {\n  color: var(--light-grey);\n  font-size: 15px;\n}\n\n.counter svg.active {\n  color: var(--primary-color);\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
