import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { STORAGE_KEYS } from '../../../../server/constants';
import { fetchUsers } from '../../api/company.api';
import PopupContext from '../../contexts/PopupContext';
import useAuth from '../../hooks/useAuth';
import './UsersPage.css';
import NewUserModal from './components/NewUserModal/NewUserModal';
import UsersTable from './components/UsersTable/UsersTable';

const UsersPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { companyId } = useParams();
  const navigate = useNavigate();
  //#endregion

  //#region [auth]
  const { user } = useAuth();
  //#endregion

  //#region [contexts]
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  //#endregion

  //#region [callbacks]
  const setUsersList = useCallback(async () => {
    try {
      const users = await fetchUsers(companyId);
      setUsers(users);
    } catch (err) {
      console.error(err);
      if (err.response?.status === 404) {
        navigate('/notfound');
      } else {
        openErrorToast(err);
      }
    }
  }, [companyId]);
  ///#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      await setUsersList();
    })();
  }, [companyId]);
  //#endregion

  //#region [methods]
  const handleCompanyChange = (evt) => {
    localStorage.setItem(STORAGE_KEYS.COMPANY, evt.target.value);
    navigate(`/company/${evt.target.value}/users`);
  };
  //#endregion

  //#region [render]
  return (
    <div className='users-page'>
      <div className='users-top-row'>
        <div className='users-company-select'>
          <label htmlFor='users_company_select'>{i18n._('company')}</label>
          <Form.Select
            aria-label='select company'
            onChange={handleCompanyChange}
            value={companyId}
            name='users_company_select'
          >
            {user.companies.map((company) => (
              <option key={'company_' + company.CoID} value={company.CoID}>
                {company.CoName}
              </option>
            ))}
          </Form.Select>
        </div>
        <Button variant='primary' onClick={() => setIsModalOpen(true)}>
          <FontAwesomeIcon icon='circle-plus' />
          {i18n._('newUser')}
        </Button>
      </div>
      {users && <UsersTable users={users} />}
      {isModalOpen && (
        <NewUserModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={setUsersList}
        />
      )}
    </div>
  );
  //#endregion
};

export default UsersPage;
