import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './Counter.css';

export const Counter = ({ min, max, value, onChange }) => {
  //#region [methods]
  const handleMinusClick = () => {
    if (value === min) return;
    onChange(value - 1);
  };

  const handlePlusClick = () => {
    if (value === max) return;
    onChange(value + 1);
  };
  //#endregion

  //#region [render]
  return (
    <div className='counter'>
      <FontAwesomeIcon
        icon='circle-minus'
        onClick={handleMinusClick}
        className={value === min ? '' : 'active'}
      />
      <span>{value}</span>
      <FontAwesomeIcon
        icon='circle-plus'
        onClick={handlePlusClick}
        className={value === max ? '' : 'active'}
      />
    </div>
  );
  //#endregion
};
