import { useLingui } from '@lingui/react';
import React from 'react';
import ResultsElements from '../../../../../components/ResultsElements/ResultsElements';
import Section from '../../../../../components/Section/Section';
import ResultsTable from '../../../../../components/TemplateTables/ResultsTable/ResultsTable';
import CommentInput from './components/CommentInput/CommentInput';
import PenaltiesTable from './components/PenaltiesTable/PenaltiesTable';
import Tags from './components/Tags/Tags';
import WarningsAlerts from './components/WarningsAlerts';
import { generalTemplate } from './templates';

const GeneralSection = ({
  comparedResults,
  onCommentSave,
  onTagAdd,
  onTagRemove,
  onTagReplace
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Section title={i18n._('results.general')} open>
      <ResultsTable
        level={2}
        template={generalTemplate(i18n, comparedResults)}
        comparedResults={comparedResults}
      />
      <h2>{i18n._('results.general.comment')}</h2>
      <ResultsElements
        element={CommentInput}
        elementProps={{ onCommentSave }}
        comparedResults={comparedResults}
      />
      <h2>{i18n._('results.general.tags')}</h2>
      <ResultsElements
        element={Tags}
        elementProps={{ onTagAdd, onTagRemove, onTagReplace }}
        comparedResults={comparedResults}
      />
      <h2>{i18n._('results.general.warnings')}</h2>
      <ResultsElements
        element={WarningsAlerts}
        comparedResults={comparedResults}
      />
      <PenaltiesTable comparedResults={comparedResults} />
    </Section>
  );
  //#endregion
};

export default React.memo(GeneralSection);
