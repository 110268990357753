// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-table th,
.edit-table td {
    min-width: 200px;
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
}

.edit-table tr td:first-of-type {
    text-align: center;
}

.edit-table th,
.edit-table tr td:not(:first-of-type) {
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/EditTable/EditTable.css"],"names":[],"mappings":"AAAA;;IAEI,gBAAgB;IAChB,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;;IAEI,YAAY;AAChB","sourcesContent":[".edit-table th,\n.edit-table td {\n    min-width: 200px;\n    max-width: 200px;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n    font-size: 14px;\n}\n\n.edit-table tr td:first-of-type {\n    text-align: center;\n}\n\n.edit-table th,\n.edit-table tr td:not(:first-of-type) {\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
