import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { SOURCES } from '../../../../../../../../server/models/config/hydraulicEquipment/pumps/heatingPlant.model';
import FormInput from '../../../../../../components/Form/FormInput';
import Section from '../../../../../../components/Section/Section';
import { INPUT_TYPE } from '../../../../../../constants';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import { getDefaultValue } from '../../../../../../utils/param.utils';

const SourcesSection = ({ onFormChange, addError, removeErrors }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const handleParamChange = (param, value) => {
    config.ConfigsSst[0].Data.pumps.heatingPlant.sources[param.key] = value;
    onFormChange(config);
  };
  //#endregion

  //#region [render]
  const { captureHeatExchangerHeadLoss, injectionExchangerHeadLoss } =
    config.ConfigsSst[0].Data.pumps.heatingPlant.sources;
  return (
    <>
      <Section
        title={i18n._('config.pumps.heatingPlant.sources.title')}
        level={2}
        open
      >
        <div className='config-form-row'>
          <FormInput
            label={i18n._(
              'config.pumps.heatingPlant.sources.captureHeatExchangerHeadLoss'
            )}
            value={captureHeatExchangerHeadLoss}
            param={SOURCES.captureHeatExchangerHeadLoss}
            type={INPUT_TYPE.NUMBER}
            unit
            bottomText={getDefaultValue(
              i18n,
              SOURCES.captureHeatExchangerHeadLoss
            )}
            onChange={(value) =>
              handleParamChange(SOURCES.captureHeatExchangerHeadLoss, value)
            }
            addError={addError}
            removeError={() => removeErrors(1)}
            className='config-form-input'
          />
          <FormInput
            label={i18n._(
              'config.pumps.heatingPlant.sources.injectionExchangerHeadLoss'
            )}
            value={injectionExchangerHeadLoss}
            param={SOURCES.injectionExchangerHeadLoss}
            type={INPUT_TYPE.NUMBER}
            unit
            bottomText={getDefaultValue(
              i18n,
              SOURCES.injectionExchangerHeadLoss
            )}
            onChange={(value) =>
              handleParamChange(SOURCES.injectionExchangerHeadLoss, value)
            }
            addError={addError}
            removeError={() => removeErrors(1)}
            className='config-form-input'
          />
        </div>
      </Section>
    </>
  );
  //#endregion
};

export default SourcesSection;
