import { GEOLOGY_A_MAX, GEOLOGY_A_MIN } from '../../../server/constants';

export const getProjectIndex = (projectParent, projectId) => {
  return [projectParent, ...projectParent.children].findIndex(
    (project) => project.AhsID === projectId
  );
};

export const getAlphaError = (thermalConductivity, volumeHeatCapacity) => {
  if (!volumeHeatCapacity) return true;
  const alpha = (thermalConductivity || 0) / volumeHeatCapacity;
  return alpha < GEOLOGY_A_MIN || alpha > GEOLOGY_A_MAX;
};
