// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headLoss-summary-section {
  width: 600px;
}

.headLoss-summary-section .template-table {
  border-radius: 10px;
  border: 1px solid rgb(236, 237, 236);
  -webkit-box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  margin-bottom: 10px;
}

.headLoss-summary-section .template-table tr {
  height: 40px;
}

.headLoss-summary-detail {
  padding-left: 20px !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/configs/thermalProduction/geostorage/calculations/sections/HeadLossSummarySection/HeadLossSummarySection.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,oCAAoC;EACpC,wDAAwD;EACxD,gDAAgD;EAChD,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".headLoss-summary-section {\n  width: 600px;\n}\n\n.headLoss-summary-section .template-table {\n  border-radius: 10px;\n  border: 1px solid rgb(236, 237, 236);\n  -webkit-box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);\n  box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.headLoss-summary-section .template-table tr {\n  height: 40px;\n}\n\n.headLoss-summary-detail {\n  padding-left: 20px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
