import { useLingui } from '@lingui/react';
import React from 'react';
import { HYDRAULIC } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../constants';
import { roundNumber } from '../../../../utils/data.utils';

const HydraulicLotBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('cost.hydraulicLot')} level={2}>
      <ParamInput
        label={i18n._('cost.InitCapexHydraulicMargin')}
        value={roundNumber(form.InitCapexHydraulicMargin * 100, 4)}
        param={HYDRAULIC.InitCapexHydraulicMargin}
        onChange={(value) =>
          onInputChange('InitCapexHydraulicMargin', value / 100)
        }
        unit
        bottomText={i18n._('param.defaultValueWithUnit', {
          value: roundNumber(
            HYDRAULIC.InitCapexHydraulicMargin.default * 100,
            4
          ),
          unit: i18n._(`unit.${HYDRAULIC.InitCapexHydraulicMargin.unit}`)
        })}
        showInfoIcon
        infoIcon='fa-regular fa-circle-question'
        infoTooltip={i18n._('cost.hydraulicLot.help')}
        type={INPUT_TYPE.NUMBER}
        className={
          form.InitCapexHydraulicMargin ===
          HYDRAULIC.InitCapexHydraulicMargin.default
            ? 'default-value'
            : ''
        }
      />
    </Bloc>
  );
  //#endregion
};

export default HydraulicLotBloc;
