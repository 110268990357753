// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.two-way-table-wrapper {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  margin: 2px 0 5px 0;
  border-radius: 5px;
  -webkit-box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);
  border: 1px solid rgb(236, 237, 236);
}

.two-way-table {
  overflow-x: hidden;
  border-collapse: collapse;
  width: 100%;
  white-space: nowrap;
}

.two-way-table th {
  background-color: var(--primary-color);
  font-weight: 500;
  text-align: center;
  color: white;
}

.two-way-table th:not(:first-of-type) {
  border-left: 1px solid white;
}

.two-way-table tbody tr {
  background-color: white;
}

.two-way-table tr:not(:last-of-type) {
  border-bottom: 1px solid rgba(213, 210, 210, 0.7);
}

.two-way-table th,
.two-way-table td {
  padding: 5px 15px;
  font-size: 14px;
}

.two-way-table th,
.two-way-table tr td:not(:first-of-type) {
  white-space: nowrap;
}

.two-way-table tr td:first-of-type {
  font-weight: 500;
  overflow: hidden;
}

.two-way-table tr td:not(:first-of-type) {
  text-align: right;
  border-left: 1px solid rgba(213, 210, 210, 0.7);
}
`, "",{"version":3,"sources":["webpack://./src/components/TwoWayTable/TwoWayTable.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,wDAAwD;EACxD,gDAAgD;EAChD,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,sCAAsC;EACtC,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,iDAAiD;AACnD;;AAEA;;EAEE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,+CAA+C;AACjD","sourcesContent":[".two-way-table-wrapper {\n  width: 100%;\n  max-width: 100%;\n  overflow-x: auto;\n  margin: 2px 0 5px 0;\n  border-radius: 5px;\n  -webkit-box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);\n  box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);\n  border: 1px solid rgb(236, 237, 236);\n}\n\n.two-way-table {\n  overflow-x: hidden;\n  border-collapse: collapse;\n  width: 100%;\n  white-space: nowrap;\n}\n\n.two-way-table th {\n  background-color: var(--primary-color);\n  font-weight: 500;\n  text-align: center;\n  color: white;\n}\n\n.two-way-table th:not(:first-of-type) {\n  border-left: 1px solid white;\n}\n\n.two-way-table tbody tr {\n  background-color: white;\n}\n\n.two-way-table tr:not(:last-of-type) {\n  border-bottom: 1px solid rgba(213, 210, 210, 0.7);\n}\n\n.two-way-table th,\n.two-way-table td {\n  padding: 5px 15px;\n  font-size: 14px;\n}\n\n.two-way-table th,\n.two-way-table tr td:not(:first-of-type) {\n  white-space: nowrap;\n}\n\n.two-way-table tr td:first-of-type {\n  font-weight: 500;\n  overflow: hidden;\n}\n\n.two-way-table tr td:not(:first-of-type) {\n  text-align: right;\n  border-left: 1px solid rgba(213, 210, 210, 0.7);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
