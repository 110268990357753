import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { WATERTANK_HOTWATER } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../constants';
import { getDefaultValue } from '../../../../utils/param.utils';

const WaterTankHotwaterBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('cost.waterTankHotwater')} level={2}>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitWaterTankHotwaterCostA')}
            value={form.InitWaterTankHotwaterCostA}
            param={WATERTANK_HOTWATER.InitWaterTankHotwaterCostA}
            onChange={(value) =>
              onInputChange('InitWaterTankHotwaterCostA', value)
            }
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_HOTWATER.InitWaterTankHotwaterCostA
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitWaterTankHotwaterCostB')}
            value={form.InitWaterTankHotwaterCostB}
            param={WATERTANK_HOTWATER.InitWaterTankHotwaterCostB}
            onChange={(value) =>
              onInputChange('InitWaterTankHotwaterCostB', value)
            }
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_HOTWATER.InitWaterTankHotwaterCostB
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitWaterTankHotwaterExchangerCostA')}
            value={form.InitWaterTankHotwaterExchangerCostA}
            param={WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostA}
            onChange={(value) =>
              onInputChange('InitWaterTankHotwaterExchangerCostA', value)
            }
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostA
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitWaterTankHotwaterExchangerCostB')}
            value={form.InitWaterTankHotwaterExchangerCostB}
            param={WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostB}
            onChange={(value) =>
              onInputChange('InitWaterTankHotwaterExchangerCostB', value)
            }
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostB
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitWaterTankHotwaterPumpCostA')}
            value={form.InitWaterTankHotwaterPumpCostA}
            param={WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostA}
            onChange={(value) =>
              onInputChange('InitWaterTankHotwaterPumpCostA', value)
            }
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostA
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitWaterTankHotwaterPumpCostB')}
            value={form.InitWaterTankHotwaterPumpCostB}
            param={WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostB}
            onChange={(value) =>
              onInputChange('InitWaterTankHotwaterPumpCostB', value)
            }
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostB
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default WaterTankHotwaterBloc;
