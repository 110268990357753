// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.opti-sizing-section-header {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
}

.opti-input-max-icon {
  background-color: white;
  cursor: pointer;
  font-size: 14px;
}

.opti-input-warning-icon {
  color: var(--warning-color);
}

.minLessMax-error {
  margin-top: 7px;
  font-size: 14px;
  color: var(--danger-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
}

.min-max-col {
  padding-right: 0;
}

.opti-inputs-label {
  font-size: 15px;
  font-weight: 500;
  padding-top: 5px;
  vertical-align: middle;
}

.opti-inputs-badges {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  margin-left: 8px;
}

.opti-inputs-badges .number-badge:not(:first-of-type) {
  margin-left: -4px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/ComputeForm/optimization/sections/OptiSizingSection/OptiSizingSection.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,eAAe;EACf,eAAe;EACf,0BAA0B;EAC1B,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;EAC3B,QAAQ;AACV;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,2BAA2B;EAC3B,2BAA2B;EAC3B,oBAAoB;EACpB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".opti-sizing-section-header {\n  font-weight: 500;\n  font-size: 15px;\n  text-align: center;\n}\n\n.opti-input-max-icon {\n  background-color: white;\n  cursor: pointer;\n  font-size: 14px;\n}\n\n.opti-input-warning-icon {\n  color: var(--warning-color);\n}\n\n.minLessMax-error {\n  margin-top: 7px;\n  font-size: 14px;\n  color: var(--danger-color);\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  gap: 5px;\n}\n\n.min-max-col {\n  padding-right: 0;\n}\n\n.opti-inputs-label {\n  font-size: 15px;\n  font-weight: 500;\n  padding-top: 5px;\n  vertical-align: middle;\n}\n\n.opti-inputs-badges {\n  display: -webkit-inline-box;\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n  vertical-align: middle;\n  margin-left: 8px;\n}\n\n.opti-inputs-badges .number-badge:not(:first-of-type) {\n  margin-left: -4px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
