import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import {
  HP_TYPE,
  NEED,
  UNIT
} from '../../../../../../../../../server/constants';
import {
  REGIMES_HPA,
  REGIMES_HPG_BTES
} from '../../../../../../../../../server/models/config/thermalProduction/heatpumps/settings.model';
import FormInput from '../../../../../../../components/Form/FormInput';
import Section from '../../../../../../../components/Section/Section';
import { INPUT_TYPE } from '../../../../../../../constants';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import { getSortedNeeds } from '../../../../../../../utils/config.utils';
import {
  formatValueWithUnit,
  isNull
} from '../../../../../../../utils/data.utils';
import './RegimesSection.css';

const RegimesSection = ({ onFormChange, addError, removeError }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const formatValue = (value, unit, precision) =>
    !isNull(value)
      ? formatValueWithUnit(i18n, value, unit, precision)
      : i18n._('noValue');

  const handleOutsideTempChange = (value, need) => {
    config.ConfigsSst[0].Data.heatpumps.settings.regimes[need][
      HP_TYPE.AERO
    ].outsideTemp = value;
    onFormChange(config);
  };

  const handleSrcTempChange = (value, need) => {
    const { regimes } = config.ConfigsSst[0].Data.heatpumps.settings;
    regimes[need][HP_TYPE.GEO].srcTemp = value;
    regimes[need][HP_TYPE.GEO].srcTempOut =
      need === NEED.COLD
        ? value + regimes[need][HP_TYPE.GEO].srcTempDelta
        : value - regimes[need][HP_TYPE.GEO].srcTempDelta;
    onFormChange(config);
  };

  const handleSrcTempDeltaChange = (value, need) => {
    const { regimes } = config.ConfigsSst[0].Data.heatpumps.settings;
    regimes[need][HP_TYPE.GEO].srcTempDelta = value;
    regimes[need][HP_TYPE.GEO].srcTempOut =
      need === NEED.COLD
        ? regimes[need][HP_TYPE.GEO].srcTemp + value
        : regimes[need][HP_TYPE.GEO].srcTemp - value;
    onFormChange(config);
  };
  //#endregion

  //#region [render]
  const { services, heatpumps } = config.ConfigsSst[0].Data;
  const { types, regimes } = heatpumps.settings;
  const sortedNeeds = getSortedNeeds(services.needs);
  return (
    <Section
      title={i18n._('config.hps.regimes')}
      level={2}
      open
      className='hps-regimes-section'
    >
      <p className='bold'>{i18n._('config.hps.regimes.instructions1')}</p>
      <p>{i18n._('config.hps.regimes.instructions2')}</p>
      <p className='italic'>{i18n._('config.hps.regimes.instructions3')}</p>
      <div className='custom-table-wrapper'>
        <table className='hps-regimes-section-table'>
          <thead>
            <tr>
              <th></th>
              {types.includes(HP_TYPE.AERO) && (
                <th>{i18n._('config.hps.regimes.hpa')}</th>
              )}
              {types.includes(HP_TYPE.GEO) && (
                <th colSpan={3}>{i18n._('config.hps.regimes.hpgBtes')}</th>
              )}
              <th colSpan={3}>{i18n._('config.hps.regimes.hpProd')}</th>
              <th colSpan={3}>
                {i18n._('config.hps.regimes.buildingDistribution')}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              {types.includes(HP_TYPE.AERO) && (
                <td>{i18n._('config.hps.regimes.outsideTemp')}</td>
              )}
              {types.includes(HP_TYPE.GEO) && (
                <>
                  <td>{i18n._('config.hps.regimes.srcTemp')}</td>
                  <td>{i18n._('config.hps.regimes.srcTempDelta')}</td>
                  <td>{i18n._('config.hps.regimes.srcTempOut')}</td>
                </>
              )}
              <td>{i18n._('config.hps.regimes.dstTempIn')}</td>
              <td>{i18n._('config.hps.regimes.dstTempDelta')}</td>
              <td>{i18n._('config.hps.regimes.dstTemp')}</td>
              <td>{i18n._('config.hps.regimes.temp')}</td>
              <td>{i18n._('config.hps.regimes.deltaT')}</td>
              <td>{i18n._('config.hps.regimes.tempBack')}</td>
            </tr>
            {sortedNeeds.map((need) => {
              const { hpProd, buildingDistribution } = regimes[need];
              // PAC aéro/géo, côté production
              const { dstTempIn, dstTemp, dstTempDelta } = hpProd;
              // Distribution bâtiment
              const { temperature, temperatureBack } = buildingDistribution;
              return (
                <tr key={'regime_section_tr_' + need}>
                  <td>{i18n._(`need.${need}`)}</td>
                  {types.includes(HP_TYPE.AERO) && (
                    // PAC aéro : T° ext
                    <td>
                      <FormInput
                        value={regimes[need][HP_TYPE.AERO].outsideTemp}
                        onChange={(value) =>
                          handleOutsideTempChange(value, need)
                        }
                        param={REGIMES_HPA[need].outsideTemp}
                        type={INPUT_TYPE.NUMBER}
                        addError={() => addError(HP_TYPE.AERO)}
                        removeError={() => removeError(HP_TYPE.AERO)}
                        unit
                      />
                    </td>
                  )}
                  {types.includes(HP_TYPE.GEO) && (
                    // PAC géo, côté BTES
                    <>
                      <td>
                        <FormInput
                          value={regimes[need][HP_TYPE.GEO].srcTemp}
                          onChange={(value) => handleSrcTempChange(value, need)}
                          param={REGIMES_HPG_BTES[need].srcTemp}
                          type={INPUT_TYPE.NUMBER}
                          addError={() => addError(HP_TYPE.GEO)}
                          removeError={() => removeError(HP_TYPE.GEO)}
                          unit
                        />
                      </td>
                      <td>
                        <FormInput
                          value={regimes[need][HP_TYPE.GEO].srcTempDelta}
                          onChange={(value) =>
                            handleSrcTempDeltaChange(value, need)
                          }
                          param={REGIMES_HPG_BTES[need].srcTempDelta}
                          type={INPUT_TYPE.NUMBER}
                          addError={() => addError(HP_TYPE.GEO)}
                          removeError={() => removeError(HP_TYPE.GEO)}
                          unit
                        />
                      </td>
                      <td>
                        {formatValue(
                          regimes[need][HP_TYPE.GEO].srcTempOut,
                          UNIT.CELSIUS_DEGREE
                        )}
                      </td>
                    </>
                  )}
                  {/* Pac aéro/géo, côté production */}
                  <td>{formatValue(dstTempIn, UNIT.CELSIUS_DEGREE)}</td>
                  <td>{formatValue(dstTempDelta, UNIT.KELVIN)}</td>
                  <td>{formatValue(dstTemp, UNIT.CELSIUS_DEGREE)}</td>
                  {/* Distribution bâtiment */}
                  <td>{formatValue(temperature, UNIT.CELSIUS_DEGREE)}</td>
                  <td>{formatValue(dstTempDelta, UNIT.KELVIN)}</td>
                  <td>{formatValue(temperatureBack, UNIT.CELSIUS_DEGREE)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Section>
  );
  //#endregion
};

export default RegimesSection;
