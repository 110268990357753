// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hpa-section-check {
    -ms-flex-item-align: center;
        align-self: center;
}

@media (max-width: 1007px) {
    .hpa-section-check {
        -ms-flex-item-align: auto;
            align-self: auto;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/configs/thermalProduction/heatpumps/settings/sections/HpaSection/HpaSection.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;QACvB,kBAAkB;AAC1B;;AAEA;IACI;QACI,yBAAyB;YACrB,gBAAgB;IACxB;AACJ","sourcesContent":[".hpa-section-check {\n    -ms-flex-item-align: center;\n        align-self: center;\n}\n\n@media (max-width: 1007px) {\n    .hpa-section-check {\n        -ms-flex-item-align: auto;\n            align-self: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
