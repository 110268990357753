import { useLingui } from '@lingui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import Section from '../../../../../../components/Section/Section';
import TwoColsTemplateTable from '../../../../../../components/TemplateTables/TwoColsTemplateTable/TwoColsTemplateTable';
import { geoTemplates } from './templates';

const GeologySection = ({ project }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { companyId, projectId } = useParams();
  //#endregion

  //#region [render]
  return (
    <Section
      title={i18n._('page.geology')}
      to={`/company/${companyId}/project/${projectId}/design/geology`}
    >
      {geoTemplates(i18n, project).map((template, index) => (
        <TwoColsTemplateTable
          key={'geology_table_' + index}
          template={template}
          level={2}
        />
      ))}
    </Section>
  );
  //#endregion
};

export default React.memo(GeologySection);
