import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { STORAGE_KEYS } from '../../../server/constants';
import { login, logout } from '../api/auth.api';
import { fetchUser } from '../api/user.api';
import AuthContext from '../contexts/AuthContext';

const AuthProvider = ({ children }) => {
  //#region [router]
  const navigate = useNavigate();
  //#endregion

  //#region [states]
  const [user, setUser] = useState();
  //#endregion

  //#region [methods]
  const handleUserChange = async () => {
    try {
      const { user } = await fetchUser();
      setUser(() => ({
        ...user,
        companies: user.companies.sort((a, b) =>
          a.CoName.toLowerCase().localeCompare(b.CoName.toLowerCase())
        )
      }));
    } catch (err) {
      throw err;
    }
  };

  const handleLogin = async (email, pwd) => {
    try {
      const { accessToken } = await login(email, pwd);
      localStorage.setItem(STORAGE_KEYS.ACCESS, accessToken);
      navigate('/');
    } catch (err) {
      localStorage.removeItem(STORAGE_KEYS.ACCESS);
      localStorage.removeItem(STORAGE_KEYS.COMPANY);
      setUser(() => undefined);
      throw err;
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      setUser(() => undefined);
      navigate('/login');
    } catch (err) {
      console.error(err);
      navigate('/login');
    }
  };
  //#endregion

  return (
    <AuthContext.Provider
      value={{
        user,
        handleLogin,
        handleLogout,
        handleUserChange
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
