import { useLingui } from '@lingui/react';
import React, { useContext, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { updateHpsHydraulicConfig } from '../../../../../api/configSst.api';
import Bloc from '../../../../../components/Bloc/Bloc';
import SaveButton from '../../../../../components/SaveButton/SaveButton';
import { FORM_STATUS } from '../../../../../constants';
import ConfigsContext from '../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../contexts/PopupContext';
import ConfigFormPage from '../../../components/ConfigFormPage/ConfigFormPage';
import './HydraulicPage.css';
import ExchangersSection from './sections/ExchangersSection';
import HydraulicEquipmentSection from './sections/HydraulicEquipmentSection';

const HydraulicPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config, setConfig, refreshConfig } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [formStatus, setFormStatus] = useState(FORM_STATUS.ORIGIN);
  //#endregion

  //#region [methods]
  const save = async () => {
    try {
      await updateHpsHydraulicConfig(config.ConfigsSst[0]);
      await refreshConfig();
    } catch (err) {
      throw err;
    }
  };

  const handleFormChange = (conf) => {
    setConfig(() => ({ ...conf }));
    setFormStatus(() => FORM_STATUS.DIRTY);
  };

  const handleSaveClick = async () => {
    try {
      setFormStatus(() => FORM_STATUS.SAVING);
      await save();
      setFormStatus(() => FORM_STATUS.SAVED);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
      setFormStatus(() => FORM_STATUS.DIRTY);
    }
  };
  //#endregion

  //#region [render]
  const { pumps } = config.ConfigsSst[0].Data;
  return (
    <ConfigFormPage formStatus={formStatus} save={save}>
      <Bloc
        title={i18n._('config.hps.hydraulic.title')}
        className='hps-hydraulic-bloc'
      >
        <HydraulicEquipmentSection onFormChange={handleFormChange} />
        <ExchangersSection onFormChange={handleFormChange} />
      </Bloc>
      {pumps?.exchangersHeadLossData && formStatus === FORM_STATUS.DIRTY && (
        <Alert variant='warning' className='hps-hydraulic-warning'>
          {i18n._('config.resetHydraulicCalculationsWarning')}
        </Alert>
      )}
      <SaveButton formStatus={formStatus} onClick={handleSaveClick} />
    </ConfigFormPage>
  );
  //#endregion
};

export default HydraulicPage;
