// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.building-needs-row {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}

.building-need-wrapper {
  -webkit-box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);
  border: 1px solid rgb(230, 230, 230);
  border-radius: 10px;
  padding: 5px 10px;
}

.building-need-wrapper.checked {
  border: 2px solid var(--primary-color);
}

.building-need-wrapper .form-check {
  font-size: 16px;
}

.building-need-wrapper.checked .form-check-label {
  font-weight: 500;
}

.building-need-wrapper .form-check-label svg {
  margin-left: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/containers/DescriptionsTabs/DescriptionTab/blocs/BuildingNeedsBloc/BuildingNeedsBloc.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;EAC3B,SAAS;AACX;;AAEA;EACE,wDAAwD;EACxD,gDAAgD;EAChD,oCAAoC;EACpC,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".building-needs-row {\n  margin-top: 10px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  gap: 20px;\n}\n\n.building-need-wrapper {\n  -webkit-box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);\n  box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);\n  border: 1px solid rgb(230, 230, 230);\n  border-radius: 10px;\n  padding: 5px 10px;\n}\n\n.building-need-wrapper.checked {\n  border: 2px solid var(--primary-color);\n}\n\n.building-need-wrapper .form-check {\n  font-size: 16px;\n}\n\n.building-need-wrapper.checked .form-check-label {\n  font-weight: 500;\n}\n\n.building-need-wrapper .form-check-label svg {\n  margin-left: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
