// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.descriptions-tabs .dropdown-toggle {
  background-color: transparent !important;
  color: black !important;
}

.descriptions-tabs .param-input-body {
  max-width: 350px;
  min-width: 200px;
  width: 25vw;
}

.descriptions-tabs .alert {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/containers/DescriptionsTabs/DescriptionsTabs.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,0BAA0B;EAC1B,uBAAuB;EACvB,kBAAkB;AACpB","sourcesContent":[".descriptions-tabs .dropdown-toggle {\n  background-color: transparent !important;\n  color: black !important;\n}\n\n.descriptions-tabs .param-input-body {\n  max-width: 350px;\n  min-width: 200px;\n  width: 25vw;\n}\n\n.descriptions-tabs .alert {\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
