import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ELEC_CAPEX } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../constants';
import { getDefaultValue } from '../../../../utils/param.utils';

const ElecLotBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('cost.elecLot')} level={2}>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitCapexLotElecA')}
            value={form.InitCapexLotElecA}
            param={ELEC_CAPEX.InitCapexLotElecA}
            onChange={(value) => onInputChange('InitCapexLotElecA', value)}
            unit
            bottomText={getDefaultValue(i18n, ELEC_CAPEX.InitCapexLotElecA)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitCapexLotElecB')}
            value={form.InitCapexLotElecB}
            param={ELEC_CAPEX.InitCapexLotElecB}
            onChange={(value) => onInputChange('InitCapexLotElecB', value)}
            unit
            bottomText={getDefaultValue(i18n, ELEC_CAPEX.InitCapexLotElecB)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default ElecLotBloc;
