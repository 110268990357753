const { DHW_TYPE, NEED, UNIT } = require('../../../../constants');
const { getDefaultValues } = require('../../../../utils/param.utils');

const SERVICES = Object.freeze({
  needs: {
    key: 'needs',
    default: []
  },
  modes: {
    key: 'modes',
    default: []
  }
});

const getHeatParams = (
  combinedHotColdWaterTank = false,
  secondaryControl = false
) => ({
  pMax: {
    key: 'pMax',
    required: true,
    default: null,
    unit: UNIT.KILOWATT
  },
  temperature: {
    key: 'temperature',
    required: true,
    default: 44,
    unit: UNIT.CELSIUS_DEGREE
  },
  temperatureBack: {
    key: 'temperatureBack',
    required: true,
    default: 40,
    unit: UNIT.CELSIUS_DEGREE
  },
  exchanger: {
    key: 'exchanger',
    default: false
  },
  combinedHotColdWaterTank: {
    key: 'combinedHotColdWaterTank',
    default: combinedHotColdWaterTank
  },
  secondaryControl: {
    key: 'secondaryControl',
    default: combinedHotColdWaterTank ? secondaryControl : false
  },
  position: {
    key: 'position',
    default: 1,
    required: true,
    min: 1,
    max: 8
  }
});

const getColdParams = (
  combinedHotColdWaterTank = false,
  secondaryControl = false
) => ({
  pMax: {
    key: 'pMax',
    required: true,
    default: null,
    unit: UNIT.KILOWATT
  },
  temperature: {
    key: 'temperature',
    required: true,
    default: 7,
    unit: UNIT.CELSIUS_DEGREE
  },
  temperatureBack: {
    key: 'temperatureBack',
    required: true,
    default: 12,
    unit: UNIT.CELSIUS_DEGREE
  },
  exchanger: {
    key: 'exchanger',
    default: true
  },
  combinedHotColdWaterTank: {
    key: 'combinedHotColdWaterTank',
    default: combinedHotColdWaterTank
  },
  secondaryControl: {
    key: 'secondaryControl',
    default: combinedHotColdWaterTank ? secondaryControl : false
  },
  position: {
    key: 'position',
    default: 1,
    required: true,
    min: 1,
    max: 8
  }
});

const DHW = Object.freeze({
  pMax: {
    key: 'pMax',
    required: true,
    default: null,
    unit: UNIT.KILOWATT
  },
  temperature: {
    key: 'temperature',
    required: true,
    default: 57,
    unit: UNIT.CELSIUS_DEGREE
  },
  temperatureBack: {
    key: 'temperatureBack',
    required: true,
    default: 12,
    unit: UNIT.CELSIUS_DEGREE
  },
  secondaryControl: {
    key: 'secondaryControl',
    default: false
  },
  dhwType: {
    key: 'dhwType',
    required: true,
    default: DHW_TYPE.SEMI,
    values: Object.values(DHW_TYPE),
    translateValuesIds: Object.values(DHW_TYPE).map(
      (type) => `dhwType.${type.toLowerCase()}`
    ) // TODO côté front
  },
  position: {
    key: 'position',
    default: 2,
    required: true,
    min: 1,
    max: 8
  }
});

const getDefaultHeatValues = (combinedHotColdWaterTank, secondaryControl) =>
  getDefaultValues(getHeatParams(combinedHotColdWaterTank, secondaryControl));

const getDefaultColdValues = (combinedHotColdWaterTank, secondaryControl) =>
  getDefaultValues(getColdParams(combinedHotColdWaterTank, secondaryControl));

const getDefaultDhwValues = () => getDefaultValues(DHW);

const NEED_PARAMS = {
  [NEED.HEAT]: getHeatParams(false, false),
  [NEED.COLD]: getColdParams(false, false),
  [NEED.DHW]: DHW
};

module.exports = {
  SERVICES,
  DHW,
  NEED_PARAMS,
  getHeatParams,
  getColdParams,
  getDefaultHeatValues,
  getDefaultColdValues,
  getDefaultDhwValues
};
