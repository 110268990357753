import { useLingui } from '@lingui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import Section from '../../../../../../components/Section/Section';
import TwoColsTemplateTable from '../../../../../../components/TemplateTables/TwoColsTemplateTable/TwoColsTemplateTable';
import { perfTemplates } from './templates';

const PerformanceSection = ({ project }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { companyId, projectId } = useParams();
  //#endregion

  //#region [render]
  return (
    <Section
      title={i18n._('page.performance')}
      to={`/company/${companyId}/project/${projectId}/design/performance`}
    >
      {perfTemplates(i18n, project).map((template, index) => (
        <TwoColsTemplateTable
          template={template}
          key={'performance_table_' + index}
          level={2}
        />
      ))}
    </Section>
  );
  //#endregion
};

export default React.memo(PerformanceSection);
