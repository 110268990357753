import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { isArrNullOrEmpty } from '../../utils/data.utils';
import SectionTitle from '../SectionTitle';
import './ExpandableSection.css';

const ExpandableSection = ({ title, level, children }) => {
  if (!children) children = [];
  else if (!Array.isArray(children)) children = [children];

  //#region [states]
  const [visibilities, setVisibilities] = useState(
    children.map((child) => !!child.props.open)
  );
  //#endregion

  //#region [methods]
  const handleTitleClick = async (index) => {
    setVisibilities((visibilities) => {
      visibilities[index] = !visibilities[index];
      return [].concat(visibilities);
    });
  };
  //#endregion

  //#region [render]
  return isArrNullOrEmpty(children) ? null : (
    <div className='expandable-section'>
      {title && <SectionTitle level={level}>{title}</SectionTitle>}
      <div className='expandable-section-children'>
        {children.map((child, index) =>
          !child ? null : (
            <div
              key={'expandable_row_' + index}
              className='expandable-section-child'
            >
              <div
                onClick={async () => await handleTitleClick(index)}
                className='expandable-section-child-title cursor-pointer'
              >
                <FontAwesomeIcon
                  icon={
                    visibilities[index]
                      ? 'caret-square-down'
                      : 'caret-square-right'
                  }
                />
                {child.props.title}
              </div>
              {visibilities[index] && (
                <div className='expandable-section-child-content'>{child}</div>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
  //#endregion
};

export default ExpandableSection;
