import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import { isArrNullOrEmpty } from '../../../../../../utils/data.utils';

const WarningsAlerts = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const warnings = useMemo(() => {
    const { Errors } = result.ComputeResult.summary;
    const warnings = !Errors ? [] : [...Errors];
    const { HPGFilename, HPAFilename } = result.ComputeResult.inp;
    if (HPGFilename?.toLowerCase().includes('extended')) {
      warnings.push(i18n._('results.general.warnings.hpg'));
    }
    if (HPAFilename?.toLowerCase().includes('extended')) {
      warnings.push(i18n._('results.general.warnings.hpa'));
    }
    return warnings;
  }, [result.ComputeID]);
  //#endregion

  //#region [render]
  return !isArrNullOrEmpty(warnings) ? (
    warnings.map((warning, index) => (
      <Alert variant='warning' key={warning + '_' + index} className='mb-3'>
        {warning}
      </Alert>
    ))
  ) : (
    <Alert variant='info'>{i18n._('results.general.noWarning')}</Alert>
  );
  //#endregion
};

export default WarningsAlerts;
