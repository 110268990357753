import { UNIT } from '../../../../../../../../../server/constants';
import { roundUp } from '../../../../../../../utils/data.utils';

const headlossTemplate = (i18n, configSst) => {
  const { summary } = configSst.Data.geostorage.headLossData;
  const { fluid, flowRateMax, totalVolumes } = summary;
  const linearHeadLossTotal = roundUp(summary.linearHeadLossTotal, 1);
  const singularHeadLossTotal = roundUp(summary.singularHeadLossTotal, 1);
  const headLossTotal = roundUp(linearHeadLossTotal + singularHeadLossTotal, 1);
  return {
    rows: [
      {
        label: i18n._('config.geostorage.headLoss.fluid'),
        value: i18n._(`heatTransferFluid.${fluid}`)
      },
      {
        label: i18n._('config.geostorage.headLoss.flowRateMax'),
        value: flowRateMax,
        unit: UNIT.CUBIC_METER_PER_HOUR,
        precision: 0,
        labelClassName: 'bold',
        valueClassName: 'bold'
      },
      {
        label: i18n._('config.geostorage.headLoss.headLossTotal'),
        value: headLossTotal,
        unit: UNIT.WATER_COLUMN_METER,
        labelClassName: 'bold',
        valueClassName: 'bold',
        round: false
      },
      {
        label: i18n._('config.geostorage.headLoss.linearHeadLossTotal'),
        value: linearHeadLossTotal,
        unit: UNIT.WATER_COLUMN_METER,
        labelClassName: 'headLoss-summary-detail',
        round: false
      },
      {
        label: i18n._('config.geostorage.headLoss.singularHeadLossTotal'),
        value: singularHeadLossTotal,
        unit: UNIT.WATER_COLUMN_METER,
        labelClassName: 'headLoss-summary-detail',
        round: false
      },
      {
        label: i18n._('config.geostorage.headLoss.totalVolumes'),
        value: totalVolumes,
        unit: UNIT.CUBIC_METER,
        precision: 2
      }
    ]
  };
};

export default headlossTemplate;
