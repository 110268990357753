import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import { isNull } from '../../utils/data.utils';
import './Tile.css';

const TileCheckbox = ({
  title,
  details,
  image,
  checked,
  disabled,
  onChange
}) => {
  //#region [render]
  const tileId = `tile_checkbox_${title}_` + crypto.randomUUID(); // on s'assure que l'id est unique
  return (
    <div
      className={`tile tile-checkbox ${checked ? 'active' : ''}${
        disabled ? ' disabled' : ''
      }`}
      onClick={disabled ? null : () => onChange(!checked)}
    >
      <img src={image} alt={'tile ' + title} />
      <Row>
        <Col xs={10}>
          <span>
            <span className='tile-title'>{title}</span>
            {details && (
              <>
                <FontAwesomeIcon
                  icon='fa-regular fa-circle-question'
                  data-tooltip-id={'tile_checkbox_tooltip_' + title}
                  data-tooltip-content={details}
                />
                <Tooltip
                  id={'tile_checkbox_tooltip_' + title}
                  place='bottom'
                  className='info-tooltip'
                  opacity={1}
                />
              </>
            )}
          </span>
        </Col>
        <Col xs={2} className='ps-2'>
          <Form.Check
            type='checkbox'
            checked={isNull(checked) ? false : checked}
            disabled={disabled}
            onChange={(evt) => onChange(evt.target.checked)}
            id={tileId}
            name={tileId}
          />
        </Col>
      </Row>
    </div>
  );
  //#endregion
};

export default React.memo(TileCheckbox);
