import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useRef } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { fetchWeatherFile } from '../../../../../api/weatherFile.api';
import PopupContext from '../../../../../contexts/PopupContext';
import { downloadFile } from '../../../../../utils/file.utils';
import './WeatherBloc.css';
import WeatherModal from './components/WeatherModal';

const WeatherBloc = ({ weatherForm, onFileChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openInfoModal, openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [refs]
  const inputOpenFileRef = useRef();
  //#endregion

  //#region [methods]
  const handleDlClick = async () => {
    try {
      const file =
        weatherForm.file ?? (await fetchWeatherFile(weatherForm.fileId));
      downloadFile(file.name, new Blob([file], { type: 'text/csv' }));
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [states]
  return (
    <div className='description-file-bloc'>
      <h1>{i18n._('description.meteoForStd')}</h1>
      <div className='weather-section'>
        <p className='param-input-label description-file-label'>
          {i18n._('description.weatherFile')}
          <FontAwesomeIcon
            icon='circle-question'
            className='modal-info-icon'
            onClick={() =>
              openInfoModal(
                i18n._('description.weatherFileExample.modalTitle'),
                <WeatherModal />
              )
            }
          />
        </p>
        <div className='description-file-btns'>
          <input
            ref={inputOpenFileRef}
            type='file'
            accept='.csv'
            style={{ display: 'none' }}
            onChange={onFileChange}
          />
          <Button
            variant='primary'
            onClick={() => inputOpenFileRef.current.click()}
          >
            <FontAwesomeIcon icon='upload' />
            {i18n._('browse')}
          </Button>
          {weatherForm.fileId && (
            <Button variant='outline-secondary' onClick={handleDlClick}>
              <FontAwesomeIcon icon='download' />
              {i18n._('download')}
            </Button>
          )}
        </div>
        <div className='description-file-csv-body'>
          {!weatherForm.fileId ? (
            <Alert variant='danger'>
              {i18n._('description.noWeatherFile')}
            </Alert>
          ) : (
            <span className='csv-file'>
              <FontAwesomeIcon icon='file-csv' />
              {weatherForm.filename}
            </span>
          )}
        </div>
      </div>
    </div>
  );
  //#endregion
};

export default WeatherBloc;
