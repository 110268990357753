'use strict';
import { h } from './RR.js';
import {
  Junction,
  Pump,
  Valve,
  Car,
  HpFan,
  Translate,
  Pipe,
  Pr,
  CRect,
  CText
} from '../components/MiscComponents.js';
import {
  VerticalPipesStrokeInfos,
  PacBgRect,
  HasLeftVertPipes
} from '../components/PacG1.js';

function PacR(props) {
  const { x, y, X, on, scale } = Object.assign({ scale: 1 }, props),
    transform = `translate(${x || 0} ${y || 0})`;
  return h('g', { transform }, [
    h('g', { transform: `scale(${scale})` }, [
      h('rect', {
        x: -30,
        y: -15,
        width: 60,
        height: 30,
        stroke: '#888',
        fill: '#ddd',
        rx: 3
      }),
      h(HpFan, { on, x: -15, scale: 0.8 }),
      Array(4)
        .fill(0)
        .map((_, i) =>
          h('rect', {
            x: 1,
            y: -11 + 6 * i,
            width: 25,
            height: 4,
            rx: 2,
            stroke: '#888'
          })
        )
    ])
  ]);
}

export function PacR1({
  X,
  scope,
  position,
  npacs,
  pacbefore,
  svcOn,
  showAll,
  showBlockNames
}) {
  const present = (x) => showAll || scope[`Cxx_syst_${x}_Presente`] > 0;
  const on = svcOn['Hp0' + X],
    { onLeft, onRight, invertedLeft, invertedRight, gc, HeatNeed, ColdNeed } =
      VerticalPipesStrokeInfos({ scope, svcOn, X, position });
  const gch = svcOn.GeoCooling && position == scope['Cxx_syst_PositionFroid'];
  const Paired = X < 8 && scope[`Cxx_syst_Pair_Hp0${X}_Hp0${X + 1}`] > 0;
  const hotColor = svcOn.ECS ? 'red' : 'orange';
  const xPac = 25;
  const dbf = scope[`Cxx_syst_Hp0${X}_ConnectDirectBallonFroid`] > 0;

  return !present(`Hp0${X}`)
    ? ''
    : h('g', { transform: `translate(200 ${position * (100 + 50) - 50})` }, [
        PacBgRect({ showAll }),
        showBlockNames
          ? h(
              'text',
              { x: -150, y: -100 + 20, className: 'block-label' },
              'PAC R1'
            )
          : '',
        h(PacR, { x: xPac, y: -80, X, on }),
        h(
          'text',
          {
            x: xPac + 50,
            y: -80,
            'text-anchor': 'middle',
            fill: on ? '#0C0' : '#000'
          },
          'Pac ' + X
        ),

        h('g', { className: 'hline1', transform: `translate(0 -25)` }, [
          h(Pipe, {
            name: 'LV1',
            d: `M -175 150 v -150`,
            stroke: onLeft ? 'blue' : 'black',
            invertedFlow: invertedLeft
          }),
          h(Pipe, {
            name: 'LV0',
            d: `M ${xPac - 15} 0 v -40`,
            stroke: on || gch ? (HeatNeed ? 'blue' : 'red') : 'black'
          }),

          Paired
            ? ''
            : h(Pipe, {
                name: 'LH1',
                d:
                  'M -175 0' +
                  (dbf ? ' M -130 0' : pacbefore ? 'h15 m 10 0' : 'h 25') +
                  ` L ${xPac - 15} 0`,
                stroke: (on && ColdNeed) || gch ? 'red' : 'black'
              }),
          h(Pipe, {
            name: 'RV0',
            d: `M ${xPac + 15} -40 v 40 `,
            stroke: on || gch ? (ColdNeed ? '#0aa' : hotColor) : 'black'
          }),
          h(Pipe, {
            name: 'RH1',
            d: `M ${xPac + 15} 0 h 10 L 150 0 ${
              pacbefore ? 'm 10 0 h 15' : 'h 25'
            }`,
            stroke: on && HeatNeed ? hotColor : 'black'
          }),
          h(Pipe, {
            name: 'RV1',
            d: `M 175 0 v 150`,
            stroke: onRight ? hotColor : 'black',
            invertedFlow: invertedRight
          }),
          !present(`PuE${X}`)
            ? ''
            : h(Pump, {
                X,
                scope,
                label: 'PuE' + X,
                rotate: 180,
                double: scope[`Cxx_regu_PuE${X}_Double`] > 0,
                x: -100,
                on: on && ColdNeed
              }),
          ...[xPac - 15, xPac + 15].map((x) => h(Junction, { x })),
          h('use', { href: '#arrowrightred', x: 52 }),
          Pr(scope, showAll, false)([-70, `Hp0${X}_Pr01`]),
          h(Junction, { x: 175 }),
          Paired
            ? [
                h(Pipe, {
                  name: 'P1',
                  d: `M -15 150 l -15 -15 v -120 l 15 -15`,
                  stroke: on ? hotColor : 'black'
                })
              ]
            : [
                //h('path',{d:`M -140 0 l -5 -3 v 6 z`,stroke:'blue',fill:'blue'}),
                h('use', { href: '#arrowrightblue', x: -145 + (dbf ? 25 : 0) }),
                h(Junction, { x: -175 + (dbf ? 45 : 0) }),
                dbf
                  ? h(CText, { x: -130, y: 25, style: 'stroke-width:1' }, 'F')
                  : '',
                present(`Ve${X}`)
                  ? h(Valve, {
                      X,
                      scope,
                      rotate: -90,
                      label: 'Ve' + X,
                      x: -25,
                      on: (on && ColdNeed) || gch
                    })
                  : npacs <= 1
                  ? ''
                  : h(Car, { X, scope, rotate: 180, x: -75 })
              ],
          present(`Ve${X}bis`)
            ? h(Valve, {
                X,
                scope,
                rotate: -90,
                label: 'Ve' + X,
                x: -25,
                y: 50,
                on: (on && ColdNeed) || gch
              })
            : ''
        ]),
        h('g', { className: 'hline2', transform: `translate(0 25)` }, [
          h(Pipe, {
            name: 'LV2',
            d: `M -155 0 v 150`,
            stroke: onLeft || gc ? (gc ? 'red' : '#0aa') : 'black',
            invertedFlow: invertedLeft
          }),
          h(Pipe, {
            name: 'RH2',
            d: `M 155 0 h -25 v -25 h ${xPac - 110} ${
              Paired ? 'h -10' : 'm-10 0'
            } h -25 v -25`,
            stroke: on && HeatNeed ? 'blue' : 'black'
          }),

          h(Pipe, {
            name: 'RV2',
            d: `M 155 150 v -150`,
            stroke: onRight ? 'blue' : 'black',
            invertedFlow: invertedRight
          }),
          Pr(scope, showAll, true)([-70, `Hp0${X}_Pr02`]),
          Paired
            ? [
                h(Pipe, {
                  name: 'P2',
                  d: `M 15 -50 l -15 15 v 5 m 0 10 v 105 l 15 15`,
                  stroke: on ? 'red' : 'black'
                })
              ]
            : [
                h(Pipe, {
                  name: 'LH2',
                  d: `M ${xPac + 15} -50 v 50 L ${dbf ? -130 : -155} 0`,
                  stroke:
                    (on && ColdNeed) || gch ? (gch ? 'red' : '#0aa') : 'black'
                }),
                h('use', { href: '#arrowleftblue', x: -145 + (dbf ? 25 : 0) }),
                h(Junction, { x: -155 + (dbf ? 25 : 0) }),
                h(Car, { X, scope, rotate: 180, x: -80 }),
                showAll
                  ? h(
                      'g',
                      { className: 'sde', transform: `translate(-100 0)` },
                      [
                        h('path', {
                          d: `M -5 10 l 5 -20 l 5 20 z`,
                          fill: 'violet'
                        }),
                        h('text', { 'text-anchor': 'middle', y: 20 }, 'Sde' + X)
                      ]
                    )
                  : '',
                h(Junction, { x: 155 })
              ]
        ]),
        h('g', { className: 'hline0' }, [
          Paired
            ? []
            : [
                h('use', { href: '#arrowleftblue', x: 50 }),
                present(`Vc${X}`)
                  ? h(Valve, {
                      X,
                      scope,
                      rotate: 90,
                      label: 'Vc' + X,
                      on: on && HeatNeed,
                      x: 75
                    })
                  : npacs <= 1
                  ? ''
                  : h(Car, { X, scope, rotate: 0, x: 75 }),
                present(`Vc${X}bis`)
                  ? h(Valve, {
                      X,
                      scope,
                      rotate: -90,
                      label: 'Vc' + X,
                      on: on && HeatNeed,
                      x: 75,
                      y: -25
                    })
                  : ''
              ]
        ]),

        !present(`PuC${X}`)
          ? ''
          : h(Pump, {
              X,
              scope,
              label: 'PuC' + X,
              rotate: 90,
              double: scope[`Cxx_regu_PuC${X}_Double`] > 0,
              x: xPac - 15,
              y: -40,
              on,
              text: { 'text-anchor': 'end', x: -3 }
            }),
        !present(`Vdc${X}`)
          ? ''
          : h(Valve, {
              X,
              scope,
              rotate: 0,
              label: 'Vdc' + X,
              x: 175,
              y: 50,
              on: onRight
            }),

        !showAll && +!present(`ExHp0${X}`)
          ? ''
          : h('rect', {
              x: -60,
              y: -25 - 10,
              width: 20,
              height: 10 + 50 + 10,
              className: 'exch',
              rx: 2,
              fill: 'url(#GradEch)'
            }),
        !showAll && !present(`Hp0${X}_Pr01`)
          ? ''
          : !showAll
          ? ''
          : h(
              'g',
              { className: 'sdc', transform: `translate(${xPac + 15} -50)` },
              [
                h('path', { d: `M 10 -5 l -20 5 l 20 5 z`, fill: 'violet' }),
                h(
                  'text',
                  {
                    'dominant-baseline': 'middle',
                    'text-anchor': 'start',
                    x: 20
                  },
                  'Sdc' + X
                )
              ]
            ),
        !present(`Vde${X}`)
          ? ''
          : h(Valve, {
              X,
              scope,
              rotate: 180,
              label: 'Vde' + X,
              x: -175,
              y: 37.5,
              on: onLeft
            })
      ]);
}

export function PacR2(props) {
  const {
    X,
    scope,
    position,
    npacs,
    pacbefore,
    svcOn,
    showAll,
    showBlockNames
  } = props;
  const present = (x) => showAll || scope[`Cxx_syst_${x}_Presente`] > 0;
  const on = svcOn['Hp0' + X],
    { onLeft, onRight, invertedLeft, invertedRight, gc, HeatNeed, ColdNeed } =
      VerticalPipesStrokeInfos({ scope, svcOn, X, position });
  const gch = svcOn.GeoCooling && position == scope['Cxx_syst_PositionFroid'];
  const onLeftRightTop = onLeft || gc;
  const Paired = X < 8 && scope[`Cxx_syst_Pair_Hp0${X}_Hp0${X + 1}`] > 0;
  const hotColor = svcOn.ECS ? 'red' : 'orange';
  const dbf = scope[`Cxx_syst_Hp0${X}_ConnectDirectBallonFroid`] > 0;

  return !present(`Hp0${X}`)
    ? ''
    : h('g', { transform: `translate(200 ${position * (100 + 50) - 50})` }, [
        h(PacBgRect, { showAll }),
        showBlockNames
          ? h(
              'text',
              { x: -150, y: -100 + 20, className: 'block-label' },
              'PAC R2'
            )
          : '',
        h(PacR, { x: -50, y: -80, X, on }),
        h(
          'text',
          { x: 0, y: -80, 'text-anchor': 'middle', fill: on ? '#0C0' : '#000' },
          'Pac ' + X
        ),
        h('g', { className: 'hline1' }, [
          h(Translate, { y: -25 }, [
            h(Pipe, {
              name: 'LV1',
              d: `M -175 150 v -150`,
              stroke: onLeft ? 'blue' : 'black',
              invertedFlow: invertedLeft
            }),
            Paired
              ? ''
              : h(Pipe, {
                  name: 'LH1',
                  d:
                    'M -175 0 ' +
                    (dbf ? 'm 50 0' : pacbefore ? 'h 15 m 10 0' : 'h 25') +
                    ' L -65 0',
                  stroke: (on && ColdNeed) || gch ? 'red' : 'black'
                }),
            h(Pipe, {
              name: 'LV0',
              d: `M -65 0 v -40`,
              stroke: on || gch ? (HeatNeed ? 'blue' : 'red') : 'black'
            }),
            h(Pipe, {
              name: 'RV0',
              d: `M -35 -40 v40`,
              stroke: on || gch ? (ColdNeed ? '#0aa' : hotColor) : 'black'
            }),
            h(Pipe, {
              name: 'RH1',
              d: `M -35 0 h 185 ${pacbefore ? 'm 10 0' : 'h 10'} h 15`,
              stroke: on && HeatNeed ? hotColor : 'black'
            }),
            h(Pipe, {
              name: 'RV1',
              d: `M 175 0 v 150`,
              stroke: onRight ? hotColor : 'black',
              invertedFlow: invertedRight
            }),
            Paired
              ? h('g', { className: 'paired' }, [
                  h(Pipe, {
                    name: 'P1',
                    d: `M -65 150 l -15 -15 v -120 l 15 -15`,
                    stroke: on ? 'red' : 'black'
                  })
                ])
              : h('g', { className: 'notPaired' }, [
                  Junction({ x: -175 + (dbf ? 50 : 0) }),
                  h('use', {
                    href: '#arrowrightblue',
                    x: -140 + (dbf ? 25 : 0)
                  })
                  //h(Car,{X,scope,rotate:180,x:-95}),
                ]),
            ...[-65, -35, 175].map((x) => h(Junction, { x })),
            !present(`Ve${X}`)
              ? ''
              : h(Valve, {
                  X,
                  scope,
                  rotate: -90,
                  x: -100,
                  label: 'Ve' + X,
                  on: (on && ColdNeed) || gch
                }),
            !present(`Ve${X}bis`)
              ? ''
              : h(Valve, {
                  X,
                  scope,
                  rotate: -90,
                  x: -100,
                  y: 25,
                  label: 'Ve' + X,
                  on: (on && ColdNeed) || gch
                }),

            !present(`PuC${X}`)
              ? ''
              : h(Pump, {
                  X,
                  label: 'PuC' + X,
                  rotate: 90,
                  x: -65,
                  y: -16,
                  double: scope[`Cxx_regu_PuC${X}_Double`] > 0,
                  on,
                  text: { 'text-anchor': 'end', x: -3 }
                }),
            !showAll
              ? ''
              : h('g', { className: 'sde', transform: `translate(-35 -20)` }, [
                  h('path', { d: `M 10 -5 l -20 5 l 20 5 z`, fill: 'violet' }),
                  h(
                    'text',
                    {
                      'dominant-baseline': 'middle',
                      'text-anchor': 'start',
                      x: 20
                    },
                    'Sde' + X
                  )
                ]),
            Pr(scope, showAll)([65, `Hp0${X}_Pr01`]),
            npacs <= 1 ? '' : h(Car, { rotate: 0, x: 95 }),
            !showAll
              ? ''
              : h('g', { className: 'sdc', transform: `translate(120 0)` }, [
                  h('path', { d: `M -5 -10 l 5 20 l 5 -20 z`, fill: 'violet' }),
                  h('text', { 'text-anchor': 'middle', y: -20 }, 'Sdc' + X)
                ]),
            ...[-20, 135].map((x) => h('use', { href: '#arrowrightred', x }))
          ])
        ]),
        h('g', { className: 'hline2' }, [
          h(Pipe, {
            name: 'LV2',
            d: `M -155 25 v 150`,
            stroke: onLeftRightTop ? (gc ? 'red' : '#0aa') : 'black',
            invertedFlow: invertedLeft
          }),
          Paired
            ? ''
            : h(Pipe, {
                name: 'LH2',
                d:
                  'M -35 -25 L -35 0 ' +
                  (dbf ? 'L -125 0' : 'L -130 0 L -130 25 L -155 25'),
                stroke:
                  (on && ColdNeed) || gch ? (gch ? 'red' : '#0aa') : 'black'
              }),
          h(Pipe, {
            name: 'RH2',
            d: `M 155 25 h -220 v -20 ${Paired ? 'v' : 'm 0'} -10 v -20`,
            stroke: on && HeatNeed ? 'blue' : 'black'
          }),
          h(Pipe, {
            name: 'RV2',
            d: `M 155 175 v -150`,
            stroke: onRight ? 'blue' : 'black',
            invertedFlow: invertedRight
          }),

          present(`Vc${X}`)
            ? h(Valve, {
                X,
                scope,
                rotate: 90,
                label: 'Vc' + X,
                y: 25,
                on: on && HeatNeed
              })
            : npacs <= 1
            ? ''
            : h(
                'g',
                { transform: `translate(0 25)` },
                h(Car, { X, scope, rotate: 0 })
              ),
          present(`Vc${X}bis`)
            ? h(Valve, {
                X,
                scope,
                rotate: -90,
                label: 'Vc' + X,
                y: -25,
                on: on && HeatNeed
              })
            : '',
          h('g', { transform: `translate(0,25)` }, [
            Pr(scope, showAll, true)([65, `Hp0${X}_Pr02`]),
            !present(`PuE${X}`)
              ? ''
              : h(Pump, {
                  X,
                  showAll,
                  label: 'PuE' + X,
                  rotate: 0,
                  double: scope[`Cxx_regu_PuE${X}_Double`] > 0,
                  x: 115,
                  on: on && HeatNeed
                }),
            ...[-20, 135].map((x) => h('use', { href: '#arrowleftblue', x }))
          ]),
          Paired
            ? [
                h(Pipe, {
                  name: 'P2',
                  d: `M -35 -25 l -15 15 v 30 m 0 10 v 80 l 15 15`,
                  stroke: on ? 'red' : 'black'
                })
              ]
            : [
                h('use', {
                  href: '#arrowleftblue',
                  ...(dbf ? { x: -115, y: 0 } : { x: -140, y: 25 })
                }),
                h(Junction, dbf ? { x: -125, y: 0 } : { x: -155, y: 25 }),
                dbf
                  ? h(
                      CText,
                      { x: -125, y: -12.5 + 1, style: 'stroke-width:1' },
                      'F'
                    )
                  : ''
              ]
        ]),

        !present(`Vdc${X}`)
          ? ''
          : h(
              'g',
              { transform: `translate(175,50)` },
              h(Valve, { X, scope, rotate: 0, label: 'Vdc' + X, on: onRight })
            ),
        !showAll && +!present(`ExHp0${X}`)
          ? ''
          : h('rect', {
              x: 30,
              y: -25 - 10,
              width: 20,
              height: 10 + 50 + 10,
              className: 'exch',
              rx: 2,
              fill: 'url(#GradEch)'
            }),
        !present(`Vde${X}`)
          ? ''
          : h(
              'g',
              { transform: `translate(-175,37.5)` },
              h(Valve, { X, scope, rotate: 180, label: 'Vde' + X, on: onLeft })
            )
      ]);
}

export function PacF1(props) {
  const {
    X,
    scope,
    position,
    npacs,
    pacbefore,
    svcOn,
    showAll,
    showBlockNames
  } = props;
  const present = (x) => showAll || scope[`Cxx_syst_${x}_Presente`] > 0;
  const on = svcOn['Hp0' + X],
    { onLeft, onRight, invertedLeft, invertedRight, gc } =
      VerticalPipesStrokeInfos({ scope, svcOn, X, position });
  const hotColor = svcOn.ECS ? 'red' : 'orange';
  const dbf = scope[`Cxx_syst_Hp0${X}_ConnectDirectBallonFroid`] > 0;

  return !present(`Hp0${X}`)
    ? ''
    : h('g', { transform: `translate(200 ${position * (100 + 50) - 50})` }, [
        PacBgRect({ showAll }),
        showBlockNames
          ? h(
              'text',
              { x: -150, y: -100 + 20, className: 'block-label' },
              'PAC F1'
            )
          : '',
        h(PacR, { X, on, scale: 2, x: 35 }),
        h(
          'text',
          {
            x: 35,
            y: -40,
            'text-anchor': 'middle',
            fill: on ? '#0C0' : '#000'
          },
          'Pac ' + X
        ),
        h('g', { className: 'evap' }, [
          h('g', { className: 'in', transform: `translate(0 -25)` }, [
            h(Pipe, {
              name: 'LV1',
              d: `M -175 150 v -150`,
              stroke: onLeft ? 'blue' : 'black',
              invertedFlow: invertedLeft
            }),
            h(Pipe, {
              name: 'LH1',
              d:
                `M -175 0 ` +
                (dbf ? 'M -135 0' : pacbefore ? 'h 15 m 10 0 ' : 'h 25 ') +
                'L -25 0',
              stroke: on ? 'red' : 'black'
            }),
            h(Pipe, {
              name: 'RV1',
              d: `M 175 0 v 150`,
              stroke: onRight ? hotColor : 'black',
              invertedFlow: invertedRight
            }),
            ...[-175 + (dbf ? 40 : 0)].map((x) => Junction({ x })),
            ...[-50].map((x) => h('use', { href: '#arrowrightred', x })),
            present(`PuC${X}`)
              ? h(
                  'g',
                  { transform: `translate(-100,0)` },
                  h(Pump, {
                    label: 'PuC' + X,
                    rotate: 180,
                    double: scope[`Cxx_regu_PuE${X}_Double`] > 0,
                    on
                  })
                )
              : ''
          ]),
          h('g', { className: 'out', transform: `translate(0 25)` }, [
            h(Pipe, {
              name: 'LV2',
              d: `M -155 0 v 150`,
              stroke: onLeft || gc ? '#0aa' : 'black',
              invertedFlow: invertedLeft
            }),
            h(Pipe, {
              name: 'LH2',
              d: `M -25 0 L ${-155 + (dbf ? 20 : 0)} 0`,
              stroke: on ? '#0aa' : 'black'
            }),
            h(Pipe, {
              name: 'RV2',
              d: `M 155 150 v -150`,
              stroke: onRight ? 'blue' : 'black',
              invertedFlow: invertedRight
            }),
            ...[-155 + (dbf ? 20 : 0)].map((x) => Junction({ x })),
            ...[-50].map((x) => h('use', { href: '#arrowleftblue', x })),
            npacs > 1
              ? h(
                  'g',
                  { transform: `translate(-100,0)` },
                  h(Car, { rotate: 180 })
                )
              : ''
          ]),
          dbf ? h(CText, { x: -135, y: 1, style: 'stroke-width:1' }, 'F') : '',
          present(`Vde${X}`)
            ? h(
                'g',
                { transform: `translate(-175,37.5)` },
                h(Valve, { label: 'Vde' + X, rotate: 180, on: onLeft })
              )
            : '',
          present(`Vdc${X}`)
            ? h(
                'g',
                { transform: `translate(175,50)` },
                h(Valve, { label: 'Vdc' + X, rotate: 0, on: onRight })
              )
            : ''
        ])
      ]);
}

export function PacC1(props) {
  const {
    X,
    scope,
    position,
    npacs,
    pacbefore,
    svcOn,
    showAll,
    showBlockNames
  } = props;
  const present = (x) => showAll || scope[`Cxx_syst_${x}_Presente`] > 0;
  const on = svcOn['Hp0' + X],
    { onLeft, onRight, invertedLeft, invertedRight, gc } =
      VerticalPipesStrokeInfos({ scope, svcOn, X, position });
  const hotColor = svcOn.ECS ? 'red' : 'orange';
  return !present(`Hp0${X}`)
    ? ''
    : h('g', { transform: `translate(200 ${position * (100 + 50) - 50})` }, [
        PacBgRect({ showAll }),
        showBlockNames
          ? h(
              'text',
              { x: -150, y: -100 + 20, className: 'block-label' },
              'PAC C1'
            )
          : '',
        h(PacR, { X, on, scale: 2, x: -35 }),
        h(
          'text',
          {
            x: -35,
            y: -40,
            'text-anchor': 'middle',
            fill: on ? '#0C0' : '#000'
          },
          'Pac ' + X
        ),

        h('g', { className: 'cond' }, [
          h('g', { className: 'out', transform: `translate(0 -25)` }, [
            h(Pipe, {
              name: 'LV1',
              d: `M -175 150 v -150`,
              stroke: onLeft ? 'blue' : 'black',
              invertedFlow: invertedLeft
            }),
            h(Pipe, {
              name: 'RH1',
              d: `M 25 0 h 125 ${pacbefore ? 'm 10 0' : 'h 10'} h 15`,
              stroke: on ? hotColor : 'black'
            }),
            h(Pipe, {
              name: 'RV1',
              d: `M 175 0 v 150`,
              stroke: onRight ? hotColor : 'black',
              invertedFlow: invertedRight
            }),
            ...[175].map((x) => Junction({ x })),
            ...[50].map((x) => h('use', { href: '#arrowrightred', x })),
            npacs > 1
              ? h('g', { transform: `translate(50,0)` }, h(Car, { rotate: 0 }))
              : ''
          ]),
          h('g', { className: 'in', transform: `translate(0 25)` }, [
            h(Pipe, {
              name: 'LV2',
              d: `M -155 0 v 150`,
              stroke: onLeft ? 'blue' : 'black',
              invertedFlow: invertedLeft
            }),
            h(Pipe, {
              name: 'RH2',
              d: `M 155 0 h -130`,
              stroke: on ? 'blue' : 'black'
            }),
            h(Pipe, {
              name: 'RV2',
              d: `M 155 150 v -150`,
              stroke: onRight ? 'blue' : 'black',
              invertedFlow: invertedRight
            }),
            ...[155].map((x) => Junction({ x })),
            ...[50].map((x) => h('use', { href: '#arrowleftblue', x })),
            present(`PuC${X}`)
              ? h(
                  'g',
                  { transform: `translate(50,0)` },
                  h(Pump, {
                    label: 'PuC' + X,
                    rotate: 0,
                    double: scope[`Cxx_regu_PuC${X}_Double`] > 0,
                    on
                  })
                )
              : ''
          ]),
          present(`Vde${X}`)
            ? h(
                'g',
                { transform: `translate(-175,37.5)` },
                h(Valve, { label: 'Vde' + X, rotate: 180, on: onLeft })
              )
            : '',
          present(`Vdc${X}`)
            ? h(
                'g',
                { transform: `translate(175,50)` },
                h(Valve, { label: 'Vdc' + X, rotate: 0, on: onRight })
              )
            : '',
          present(`Vc${X}`)
            ? h(Valve, { label: 'Vc' + X, rotate: -90, on, x: 100, y: -25 })
            : '',
          present(`Vc${X}bis`)
            ? h(Valve, { label: 'Vc' + X, rotate: -90, on, x: 100, y: 25 })
            : ''
        ])
      ]);
}

export function PacM1(props) {
  const {
    X,
    scope,
    position,
    npacs,
    pacbefore,
    svcOn,
    showAll,
    showBlockNames
  } = props;
  const present = (x) => showAll || scope[`Cxx_syst_${x}_Presente`] > 0;
  if (!present(`Hp0${X}`)) return '';
  const on = svcOn['Hp0' + X],
    { onLeft, onRight, invertedLeft, invertedRight, HeatNeed, ColdNeed, gc } =
      VerticalPipesStrokeInfos({ scope, svcOn, X, position });
  const hotColor = svcOn.ECS ? 'red' : 'orange';
  const dbf = scope[`Cxx_syst_Hp0${X}_ConnectDirectBallonFroid`] > 0;

  const leftPipes = HasLeftVertPipes({ X, scope, position });
  const xPac = -25;
  return h('g', { transform: `translate(200 ${position * (100 + 50) - 50})` }, [
    PacBgRect({ showAll }),
    showBlockNames
      ? h('text', { x: -150, y: -100 + 20, className: 'block-label' }, 'PAC M1')
      : '',
    h(PacR, { X, on, scale: 2, x: xPac }),
    h(
      'text',
      { x: xPac, y: -40, 'text-anchor': 'middle', fill: on ? '#0C0' : '#000' },
      'Pac ' + X
    ),

    h('g', { className: 'cond' }, [
      h('g', { className: 'out', transform: `translate(0 -25)` }, [
        h(Pipe, {
          name: 'RH1',
          d: `M ${xPac + 60} 0 L 135 0`,
          stroke: on ? (ColdNeed ? '#0aa' : hotColor) : 'black'
        }),
        h(Pipe, {
          name: 'RH12',
          d: `M 135 0 L 150 0 ${pacbefore ? 'M 160 0' : 'L 160 0'} L 175 0`,
          stroke: on && HeatNeed ? hotColor : 'black'
        }),
        h(Pipe, {
          name: 'RV1',
          d: `M 175 0 v 150`,
          stroke: onRight ? hotColor : 'black',
          invertedFlow: invertedRight
        }),

        h('circle', { cx: 175, r: 3, fill: '#000' }),
        npacs > 1
          ? h('g', { transform: `translate(50,0)` }, h(Car, { rotate: 0 }))
          : ''
      ]),
      h('g', { className: 'in', transform: `translate(0 25)` }, [
        h(Pipe, {
          name: 'RH22',
          d: `M 155 0 L 120 0`,
          stroke: on || HeatNeed ? 'blue' : 'black'
        }),
        h(Pipe, {
          name: 'RH21',
          d: `M 120 0 L ${xPac + 60} 0`,
          stroke: on ? 'blue' : 'black'
        }),
        h(Pipe, {
          name: 'RV2',
          d: `M 155 150 v -150`,
          stroke: onRight ? 'blue' : 'black',
          invertedFlow: invertedRight
        }),

        h('circle', { cx: 155, r: 3, fill: '#000' }),
        present(`PuC${X}`)
          ? h(Pump, {
              label: 'PuC' + X,
              rotate: 0,
              double: scope[`Cxx_regu_PuC${X}_Double`] > 0,
              on,
              x: 60
            })
          : '',
        present(`Vc${X}`)
          ? h(Valve, { label: 'Vc' + X, rotate: -90, on, x: 100 })
          : '',
        present(`Vc${X}bis`)
          ? h(Valve, { label: 'Vc' + X, rotate: -90, on, x: 100, y: -50 })
          : ''
      ]),
      present(`Ve${X}`)
        ? h('g', {}, [
            h(Pipe, {
              name: 'LH2',
              d: `M 135 -25 v -50 h -240 v 100 h ${dbf ? -30 : -50}`,
              stroke: on && ColdNeed ? '#0aa' : 'black'
            }),
            h(Pipe, {
              name: 'LH1',
              d:
                `M 115 25 L 115 -20 M 115 -30 L 115 -55 L -90 -55 L -95 -55  L -95 -25 L -100 -25 M -110 -25` +
                (dbf
                  ? 'L -135 -25'
                  : `h -40 ${pacbefore ? 'm -10 0 h -15' : 'h -25'}`),
              stroke: on && ColdNeed ? 'blue' : 'black'
            }),

            [-175 + (dbf ? 40 : 0), 135].map((x) => h(Junction, { x, y: -25 })),
            [-155 + (dbf ? 20 : 0), 115].map((x) => h(Junction, { x, y: 25 })),
            dbf ? h(CText, { x: -135, style: 'stroke-width:1' }, 'F') : ''
          ])
        : '',
      leftPipes &&
        h(Pipe, {
          name: 'LV1',
          d: `M -175 125 v -150`,
          stroke: onLeft ? 'blue' : 'black'
        }),
      leftPipes &&
        h(Pipe, {
          name: 'LV2',
          d: `M -155 25 v 150`,
          stroke: onLeft || gc ? '#0aa' : 'black'
        }),
      showAll || present(`Ve${X}`)
        ? h(Valve, { label: 'Ve' + X, rotate: -90, on, x: -120, y: 25 })
        : '',
      showAll || present(`Ve${X}bis`)
        ? h(Valve, { label: 'Ve' + X, rotate: -90, on, x: -120, y: -25 })
        : '',
      present(`Vde${X}`)
        ? h(
            'g',
            { transform: `translate(-175,37.5)` },
            h(Valve, { label: 'Vde' + X, rotate: 180, on: onLeft })
          )
        : '',
      present(`Vdc${X}`)
        ? h(
            'g',
            { transform: `translate(175,50)` },
            h(Valve, { label: 'Vdc' + X, rotate: 0, on: onRight })
          )
        : ''
    ])
  ]);
}
