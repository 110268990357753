// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 5px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 10px;
}

.text-filter input {
    width: 180px;
    height: 30px;
    font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/components/TextFilter/TextFilter.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,QAAQ;IACR,yBAAyB;QACrB,sBAAsB;YAClB,mBAAmB;IAC3B,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".text-filter {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    gap: 5px;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n    margin: 10px;\n}\n\n.text-filter input {\n    width: 180px;\n    height: 30px;\n    font-size: 13px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
