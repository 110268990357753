import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { HEADLOSS } from '../../../../../../server/models/design/performance.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../constants';
import { getDefaultValue } from '../../../../utils/param.utils';

const HeadlossBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('performance.headloss')}>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.InitPumpEfficiency')}
            value={form.InitPumpEfficiency}
            param={HEADLOSS.InitPumpEfficiency}
            onChange={(value) => onInputChange('InitPumpEfficiency', value)}
            bottomText={getDefaultValue(i18n, HEADLOSS.InitPumpEfficiency)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('performance.InitExchangerHeadlossMceMax')}
            value={form.InitExchangerHeadlossMceMax}
            param={HEADLOSS.InitExchangerHeadlossMceMax}
            onChange={(value) =>
              onInputChange('InitExchangerHeadlossMceMax', value)
            }
            bottomText={getDefaultValue(
              i18n,
              HEADLOSS.InitExchangerHeadlossMceMax
            )}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.InitBtesHeadlossLimit')}
            value={form.InitBtesHeadlossLimit}
            param={HEADLOSS.InitBtesHeadlossLimit}
            onChange={(value) => onInputChange('InitBtesHeadlossLimit', value)}
            bottomText={getDefaultValue(i18n, HEADLOSS.InitBtesHeadlossLimit)}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('performance.InitLoopConnectionLength')}
            value={form.InitLoopConnectionLength}
            param={HEADLOSS.InitLoopConnectionLength}
            onChange={(value) =>
              onInputChange('InitLoopConnectionLength', value)
            }
            bottomText={getDefaultValue(
              i18n,
              HEADLOSS.InitLoopConnectionLength
            )}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default HeadlossBloc;
