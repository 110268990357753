import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { UNIT } from '../../../../../../../../../server/constants';
import {
  HORIZONTAL_CONNECTIONS,
  getConnectionsHdpeNdParam
} from '../../../../../../../../../server/models/config/thermalProduction/geostorage/settings.model';
import FormInput from '../../../../../../../components/Form/FormInput';
import FormSelect from '../../../../../../../components/Form/FormSelect';
import Section from '../../../../../../../components/Section/Section';
import { INPUT_TYPE } from '../../../../../../../constants';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import { getDefaultValue } from '../../../../../../../utils/param.utils';
import './HorizontalConnectionsSection.css';

const HorizontalConnectionsSection = ({
  onFormChange,
  addError,
  removeErrors
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const handleParamChange = (param, value) => {
    config.ConfigsSst[0].Data.geostorage.settings.horizontalConnections[
      param.key
    ] = value;
    onFormChange(config);
  };
  //#endregion

  //#region [render]
  const { probes, horizontalConnections } =
    config.ConfigsSst[0].Data.geostorage.settings;
  const { probesPerConnection, hdpeNd, fluidPathDistance } =
    horizontalConnections;
  const HDPE = getConnectionsHdpeNdParam(probes.hdpeNd, probesPerConnection);
  return (
    <>
      <Section
        title={i18n._('config.geostorage.settings.horizontalConnections')}
        level={2}
        open
        className='horizontal-connections-section'
      >
        <div className='config-form-row'>
          <FormSelect
            label={i18n._('config.geostorage.settings.probesPerConnection')}
            param={HORIZONTAL_CONNECTIONS.probesPerConnection}
            bottomText={getDefaultValue(
              i18n,
              HORIZONTAL_CONNECTIONS.probesPerConnection
            )}
            value={probesPerConnection}
            onChange={(value) =>
              handleParamChange(
                HORIZONTAL_CONNECTIONS.probesPerConnection,
                value
              )
            }
            className='config-form-input'
          />
          <FormSelect
            label={i18n._('config.geostorage.settings.hdpeNd')}
            param={HDPE.hdpeNd}
            bottomText={i18n._('param.geostorage.settings.defaultDn', {
              value: HDPE.hdpeNd.default
            })}
            value={hdpeNd}
            onChange={(value) => handleParamChange(HDPE.hdpeNd, value)}
            showInfoIcon
            infoTooltip={i18n._(
              'config.geostorage.settings.connectionsHdpeNd.info'
            )}
            className='config-form-input'
          />
          <div>
            <FormInput
              label={i18n._('config.geostorage.settings.fluidPathDistance')}
              param={HORIZONTAL_CONNECTIONS.fluidPathDistance}
              type={INPUT_TYPE.NUMBER}
              unit={UNIT.METER}
              value={fluidPathDistance}
              onChange={(value) =>
                handleParamChange(
                  HORIZONTAL_CONNECTIONS.fluidPathDistance,
                  value
                )
              }
              addError={addError}
              removeError={() => removeErrors(1)}
              className='config-form-input'
            />
            {/*👇 hack pour aligner les trois inputs 👇*/}
            <span className='form-input-small-text'> </span>
          </div>
        </div>
      </Section>
    </>
  );
  //#endregion
};

export default HorizontalConnectionsSection;
