import { useLingui } from '@lingui/react';
import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
  COMPUTE_STATUS,
  COMPUTE_TYPE,
  SOCKET_MSG_TYPE
} from '../../../../../../../../server/constants';
import { fetchCalculation } from '../../../../../../api/compute.api';
import {
  cancelCalculation,
  fetchCurrentCalculation,
  fetchLastCalculationError,
  startCalculation
} from '../../../../../../api/project.api';
import OpaqueLayer from '../../../../../../components/OpaqueLayer/OpaqueLayer';
import PopupContext from '../../../../../../contexts/PopupContext';
import wsClient from '../../../../../../tools/WebSocketClient';
import { isNull } from '../../../../../../utils/data.utils';
import { IS_DEV } from '../../../../../../utils/env.utils';
import CalculationCard from '../CalculationCard/CalculationCard';
import EngineErrorCard from '../ErrorCard/ErrorCard';
import './CalculationForm.css';

const CalculationForm = ({
  project,
  calculationData,
  refreshResults,
  className,
  isFormValid,
  children
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { projectId } = useParams();
  //#endregion

  //#region [contexts]
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [calculation, setCalculation] = useState();
  const [isWsReady, setIsWsReady] = useState(false);
  const [isCalculating, setIsCalculating] = useState(); // peut être soit null, true ou false. Prend la valeur null ssi chargement de CalculationForm
  const [engineError, setEngineError] = useState(project.LastCalculationError);
  //#endregion

  //#region [methods]
  const handleComputeError = async () => {
    try {
      const error = await fetchLastCalculationError(projectId);
      setEngineError(() => error);
      const calc = calculationData.resultId
        ? await fetchCalculation(calculationData.resultId)
        : null;
      setCalculation(() => calc);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    } finally {
      setIsCalculating(() => false);
    }
  };

  const handleComputeProgress = ({
    ComputeID,
    Type,
    ProjectID,
    GoalPart,
    message,
    progressData
  }) => {
    setCalculation((calc) => {
      if (!calc) return calc;
      const calcCopy = cloneDeep(calc);
      const { descriptions } = calcCopy;

      const descIndex = descriptions.findIndex(
        (desc) => desc.projectId === ProjectID
      );
      if (descIndex === -1) return calc;
      const compIndex = descriptions[descIndex].computes.findIndex(
        (comp) => comp.ComputeID === ComputeID
      );
      if (compIndex === -1) return calc;

      calcCopy.descriptions[descIndex].computes[compIndex].Type = Type;
      calcCopy.descriptions[descIndex].computes[compIndex].Status =
        COMPUTE_STATUS.IN_PROGRESS;
      calcCopy.descriptions[descIndex].computes[compIndex].message = message;
      if (Type === COMPUTE_TYPE.SIMU) return calcCopy;

      if (GoalPart) {
        calcCopy.descriptions[descIndex].computes[compIndex].GoalPart =
          GoalPart;
      }
      if (progressData) {
        const { Dataviz, Percent } = progressData;
        calcCopy.descriptions[descIndex].computes[compIndex].Dataviz = Dataviz;
        calcCopy.descriptions[descIndex].computes[compIndex].Percent = Percent;
      }

      return calcCopy;
    });
    setIsCalculating(() => true);
  };

  const connectWebSocket = () => {
    // callback quand le websocket est connecté
    const openHandler = () => setIsWsReady(() => true);

    // callback quand le websocket est déconnecté
    const closeHandler = () => setIsWsReady(() => false);

    // callback pour gérer les messages reçus par le websocket
    const messageHandler = async (data) => {
      try {
        if (!data?.type) return;
        if (IS_DEV) console.info('socket message type:', data.type);
        switch (data.type) {
          case SOCKET_MSG_TYPE.COMPUTE_IDS:
            // on récupère le calculation en cours
            const calc = await fetchCurrentCalculation(project.AhsID);
            setCalculation(() => calc);
            break;
          case SOCKET_MSG_TYPE.COMPUTE_PROGRESS:
            handleComputeProgress(data.msg);
            break;
          case SOCKET_MSG_TYPE.COMPUTE_RESULT:
            await refreshResults();
            break;
          case SOCKET_MSG_TYPE.COMPUTE_FINISHED:
            await refreshResults();
            break;
          case SOCKET_MSG_TYPE.ERROR:
          case SOCKET_MSG_TYPE.CANCELED:
            await handleComputeError();
            break;
          default:
            console.warn('unknown socket message type:', data.type);
        }
      } catch (err) {
        console.error(err);
        openErrorToast(err);
        setIsCalculating(() => false);
      }
    };

    // on se connecte au websocket
    const room = `ws_room_${project.AhsID}`;
    wsClient.connect(room, openHandler, closeHandler, messageHandler);
  };

  const handleStartClick = async () => {
    try {
      setIsCalculating(() => true);
      if (engineError) setEngineError(() => null);
      if (calculation) setCalculation(() => null);
      await startCalculation(project.AhsID, calculationData);
    } catch (err) {
      console.error(err);
      openErrorToast(
        err.response?.status === 503 ? i18n._('error.redisUnavailable') : err
      );
      await handleComputeError();
    }
  };

  const handleCancelClick = async () => {
    try {
      await cancelCalculation(project.AhsID);
      await refreshResults();
    } catch (err) {
      console.error(err);
      openErrorToast(
        err.response?.status === 503 ? i18n._('error.redisUnavailable') : err
      );
      await handleComputeError();
    }
  };
  //#endregion

  //#region [effects]
  useEffect(() => {
    connectWebSocket();
    return () => wsClient.disconnect(`ws_room_${project.AhsID}`);
  }, [project.AhsID]);

  useEffect(() => {
    (async () => {
      try {
        // on récupère le calculation en cours
        let calc = await fetchCurrentCalculation(project.AhsID);

        if (calc) {
          // un calculation est en cours
          setIsCalculating(() => true);
        } else {
          // pas de calculation en cours
          if (calculationData.resultId) {
            // il y a au moins un résultat
            calc = await fetchCalculation(calculationData.resultId);
          }
          setIsCalculating(() => false);
        }
        setCalculation(() => calc);
      } catch (err) {
        console.error(err);
        openErrorToast(err);
        setIsCalculating(() => false);
      }
    })();
  }, [project.AhsID, calculationData.resultId]);
  //#endregion

  //#region [render]
  if (isNull(isCalculating)) return null;
  const btnText = isCalculating
    ? i18n._('calcBtn.status.inProgress')
    : i18n._('calcBtn.status.start');
  return (
    <div className='calculation-form-wrapper'>
      <div className='calculation-form'>
        <OpaqueLayer visible={isCalculating}>
          <Alert animation='border' variant='primary'>
            {i18n._('calcBtn.status.inProgress')}
            <Spinner variant='light' />
          </Alert>
        </OpaqueLayer>
        <div className={className}>{children}</div>
      </div>
      {!isWsReady && (
        <Alert variant='danger'>{i18n._('error.wsUnavailable')}</Alert>
      )}
      <div className='calculation-form-btns'>
        <Button
          variant='primary'
          className='calculation-form-btn'
          onClick={handleStartClick}
          disabled={isCalculating || !isFormValid || !isWsReady}
        >
          {btnText}
        </Button>
        <Button
          variant='outline-secondary'
          className='calculation-form-btn'
          disabled={!isCalculating}
          onClick={handleCancelClick}
        >
          {i18n._('cancelBtn.stop')}
        </Button>
      </div>
      {engineError && <EngineErrorCard error={engineError} />}
      {calculation && (
        <CalculationCard calculation={calculation} project={project} />
      )}
    </div>
  );
  //#endregion
};

export default CalculationForm;
