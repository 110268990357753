import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { WATERTANK_HOT } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../constants';
import { getDefaultValue } from '../../../../utils/param.utils';

const WaterTankHotBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('cost.waterTankHot')} level={2}>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitWaterTankHotCostA')}
            value={form.InitWaterTankHotCostA}
            param={WATERTANK_HOT.InitWaterTankHotCostA}
            onChange={(value) => onInputChange('InitWaterTankHotCostA', value)}
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_HOT.InitWaterTankHotCostA
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitWaterTankHotCostB')}
            value={form.InitWaterTankHotCostB}
            param={WATERTANK_HOT.InitWaterTankHotCostB}
            onChange={(value) => onInputChange('InitWaterTankHotCostB', value)}
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_HOT.InitWaterTankHotCostB
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default WaterTankHotBloc;
