import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { PV } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../constants';
import { getDefaultValue } from '../../../../utils/param.utils';

const PhotovoltaicBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('cost.photovoltaic')} level={2}>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitPVModuleCostA')}
            value={form.InitPVModuleCostA}
            param={PV.InitPVModuleCostA}
            onChange={(value) => onInputChange('InitPVModuleCostA', value)}
            unit
            bottomText={getDefaultValue(i18n, PV.InitPVModuleCostA)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitPVModuleCostB')}
            value={form.InitPVModuleCostB}
            param={PV.InitPVModuleCostB}
            onChange={(value) => onInputChange('InitPVModuleCostB', value)}
            unit
            bottomText={getDefaultValue(i18n, PV.InitPVModuleCostB)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitPVModuleSupportCostA')}
            value={form.InitPVModuleSupportCostA}
            param={PV.InitPVModuleSupportCostA}
            onChange={(value) =>
              onInputChange('InitPVModuleSupportCostA', value)
            }
            unit
            bottomText={getDefaultValue(i18n, PV.InitPVModuleSupportCostA)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitPVModuleSupportCostB')}
            value={form.InitPVModuleSupportCostB}
            param={PV.InitPVModuleSupportCostB}
            onChange={(value) =>
              onInputChange('InitPVModuleSupportCostB', value)
            }
            unit
            bottomText={getDefaultValue(i18n, PV.InitPVModuleSupportCostB)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitPVInverterCostA')}
            value={form.InitPVInverterCostA}
            param={PV.InitPVInverterCostA}
            onChange={(value) => onInputChange('InitPVInverterCostA', value)}
            unit
            bottomText={getDefaultValue(i18n, PV.InitPVInverterCostA)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitPVInverterCostB')}
            value={form.InitPVInverterCostB}
            param={PV.InitPVInverterCostB}
            onChange={(value) => onInputChange('InitPVInverterCostB', value)}
            unit
            bottomText={getDefaultValue(i18n, PV.InitPVInverterCostB)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default PhotovoltaicBloc;
