import React from 'react';
import './GeneralForm.css';
import GeneralBloc from './blocs/GeneralBloc';
import LocationBloc from './blocs/LocationBloc';

const GeneralForm = ({ form, imageFile, onInputChange, onImageChange }) => {
  //#region [render]
  return (
    <div className='general-form'>
      <GeneralBloc
        form={form}
        imageFile={imageFile}
        onInputChange={onInputChange}
        onImageChange={onImageChange}
      />
      <LocationBloc form={form} onInputChange={onInputChange} />
    </div>
  );
  //#endregion
};

export default GeneralForm;
