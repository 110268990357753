import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import './SetFilter.css';

export default forwardRef((props, ref) => {
  //#region [states]
  const [filterSet, setFilterSet] = useState([]);
  //#endregion

  //#region [imperative handle]
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        return filterSet.includes(params.data[props.colDef.field]);
      },
      isFilterActive() {
        return filterSet.length > 0;
      },
      getModel() {
        return !this.isFilterActive() ? [] : { values: filterSet };
      },
      setModel(model) {
        setFilterSet(!model ? [] : model.values.map((val) => val.raw));
      }
    };
  });
  //#endregion

  //#region [effects]
  useEffect(() => {
    props.filterChangedCallback();
  }, [filterSet]);
  //#endregion

  //#region [methods]
  const handleFilterChange = (evt) => {
    const { value } = evt.target;
    setFilterSet((set) => {
      const index = set.findIndex((val) => val === value);
      index !== -1 ? set.splice(index, 1) : set.push(value);
      return [].concat(set);
    });
  };
  //#endregion

  //#region [render]
  return (
    <div className='set-filter'>
      {props.values.map((value, index) => {
        const { raw, formatted } = value;
        return (
          <div className='form-check' key={'set_filter_' + raw + index}>
            <input
              type='checkbox'
              id={'set_filter_' + raw + index}
              checked={filterSet.includes(raw)}
              onChange={handleFilterChange}
              value={raw}
              className='form-check-input'
            />
            <label
              htmlFor={'set_filter_' + raw + index}
              className='form-check-label'
            >
              {formatted ?? raw}
            </label>
          </div>
        );
      })}
    </div>
  );
  //#endregion
});
