const { USER } = require('../models/user.model');

const USER_SCHEMA = {
  type: 'object',
  properties: {
    [USER.UserFirstName.key]: {
      type: USER.UserFirstName.type,
      pattern: USER.UserFirstName.pattern.toString().slice(1, -1)
    },
    [USER.UserLastName.key]: {
      type: USER.UserLastName.type,
      pattern: USER.UserLastName.pattern.toString().slice(1, -1)
    },
    [USER.UserEmail.key]: {
      type: USER.UserEmail.type,
      pattern: USER.UserEmail.pattern.toString().slice(1, -1)
    },
    [USER.UserPassword.key]: {
      type: USER.UserPassword.type,
      pattern: USER.UserPassword.pattern.toString().slice(1, -1)
    },
    [USER.UserJobTitle.key]: {
      type: USER.UserJobTitle.type,
      minLength: 1,
      maxLength: USER.UserJobTitle.maxLength,
      isNotEmpty: true
    },
    [USER.UserCountry.key]: {
      type: USER.UserCountry.type,
      enum: USER.UserCountry.values
    },
    [USER.IsSuperAdmin.key]: {
      type: USER.IsSuperAdmin.type
    }
  },
  required: [
    USER.UserFirstName.key,
    USER.UserLastName.key,
    USER.UserEmail.key,
    USER.UserPassword.key,
    USER.UserJobTitle.key,
    USER.UserCountry.key,
    USER.IsSuperAdmin.key
  ],
  additionalProperties: false
};

module.exports = { USER_SCHEMA };
