// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services-hydraulic-bloc .custom-table tr {
  height: 45px;
}

.services-hydraulic-bloc .custom-table thead th:first-of-type,
.services-hydraulic-bloc .custom-table tr td:first-of-type {
  width: 100px;
  min-width: 100px;
  font-weight: 600;
  text-align: center;
}

.hydraulic-page-primary-module-table {
  margin-top: 30px;
}

.hydraulic-page-secondary-module {
  margin-top: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/configs/buildingDescription/services/hydraulic/HydraulicPage.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;;EAEE,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".services-hydraulic-bloc .custom-table tr {\n  height: 45px;\n}\n\n.services-hydraulic-bloc .custom-table thead th:first-of-type,\n.services-hydraulic-bloc .custom-table tr td:first-of-type {\n  width: 100px;\n  min-width: 100px;\n  font-weight: 600;\n  text-align: center;\n}\n\n.hydraulic-page-primary-module-table {\n  margin-top: 30px;\n}\n\n.hydraulic-page-secondary-module {\n  margin-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
