import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { OTHER_CAPEX } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../constants';
import { getDefaultValue } from '../../../../utils/param.utils';

const OtherCapexBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('cost.otherCapex')} level={2}>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitMOEBtesMargin')}
            value={form.InitMOEBtesMargin}
            param={OTHER_CAPEX.InitMOEBtesMargin}
            onChange={(value) => onInputChange('InitMOEBtesMargin', value)}
            unit
            bottomText={getDefaultValue(i18n, OTHER_CAPEX.InitMOEBtesMargin)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitMOEMargin')}
            value={form.InitMOEMargin}
            param={OTHER_CAPEX.InitMOEMargin}
            onChange={(value) => onInputChange('InitMOEMargin', value)}
            unit
            bottomText={getDefaultValue(i18n, OTHER_CAPEX.InitMOEMargin)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitIntegrationBtesMargin')}
            value={form.InitIntegrationBtesMargin}
            param={OTHER_CAPEX.InitIntegrationBtesMargin}
            onChange={(value) =>
              onInputChange('InitIntegrationBtesMargin', value)
            }
            unit
            bottomText={getDefaultValue(
              i18n,
              OTHER_CAPEX.InitIntegrationBtesMargin
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitIntegrationMargin')}
            value={form.InitIntegrationMargin}
            param={OTHER_CAPEX.InitIntegrationMargin}
            onChange={(value) => onInputChange('InitIntegrationMargin', value)}
            unit
            bottomText={getDefaultValue(
              i18n,
              OTHER_CAPEX.InitIntegrationMargin
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <ParamInput
        label={i18n._('cost.InitCapexOther')}
        value={form.InitCapexOther}
        param={OTHER_CAPEX.InitCapexOther}
        onChange={(value) => onInputChange('InitCapexOther', value)}
        unit
        bottomText={getDefaultValue(i18n, OTHER_CAPEX.InitCapexOther)}
        type={INPUT_TYPE.NUMBER}
      />
    </Bloc>
  );
  //#endregion
};

export default OtherCapexBloc;
