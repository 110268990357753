import { useLingui } from '@lingui/react';
import React from 'react';
import Section from '../../../../../components/Section/Section.jsx';
import ResultsTable from '../../../../../components/TemplateTables/ResultsTable/ResultsTable.jsx';
import SstTable from './components/SstTable.jsx';
import { btesSolarTotalTemplates } from './templates.js';

const SizingSection = ({ selectedResult, comparedResults }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  const { substations } = selectedResult.ComputeResult.inp;
  if (!substations) return null;
  return (
    <Section title={i18n._('results.sizing')} open>
      {substations.length > 1 &&
        substations.map((sst, sstIndex) => (
          <SstTable
            key={'sizing_section_sst_' + sstIndex}
            sstName={sst.InitStationName}
            sstIndex={sstIndex}
            comparedResults={comparedResults}
          />
        ))}
      <Section
        title={
          substations.length > 1
            ? i18n._('totalProject')
            : i18n._('totalProjectBySst', {
                sst: substations[0].InitStationName
              })
        }
        level={2}
        open
      >
        {btesSolarTotalTemplates(i18n, comparedResults).map(
          (template, index) => (
            <ResultsTable
              level={3}
              template={template}
              comparedResults={comparedResults}
              key={'sizing_btes_template_' + index}
            />
          )
        )}
      </Section>
    </Section>
  );
  //#endregion
};

export default React.memo(SizingSection);
