import React from 'react';
import './NumberBadge.css';

const styles = [
  {
    color: 'white',
    backgroundColor: 'rgb(120 145 179)'
  },
  { color: 'white', backgroundColor: '#82AFF2' },
  { color: 'white', backgroundColor: '#3078CD' },
  { color: 'white', backgroundColor: '#25456B' },
  { color: 'white', backgroundColor: 'rgb(4 22 41)' }
];

const NumberBadge = ({ number, title }) => {
  //#region [render]
  return (
    <span className='number-badge' style={styles[number]} title={title}>
      {number + 1}
    </span>
  );
  //#endregion
};

export default NumberBadge;
