// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simu-sizing-sst-name {
  display: block;
  width: 100%;
  text-align: center;
  padding-left: 10px;
}

.simu-sizing-sst-col .col-12 {
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/ComputeForm/simulation/sections/SimuSizingSection/SimuSizingSection.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;EACX,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".simu-sizing-sst-name {\n  display: block;\n  width: 100%;\n  text-align: center;\n  padding-left: 10px;\n}\n\n.simu-sizing-sst-col .col-12 {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
