import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import ResultsChart from '../ResultsChart/ResultsChart';
import { DEFAULT_OPTIONS_KEYS } from '../ResultsChart/options';
import './ResultsCharts.css';

const ResultsCharts = ({ comparedResults }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [charts, setCharts] = useState([DEFAULT_OPTIONS_KEYS]);
  //#endregion

  //#region [methods]
  const handleYLeftChange = (index, optnKey) => {
    setCharts((charts) => {
      charts[index].yLeftAxis = optnKey;
      return [...charts];
    });
  };

  const handleXChange = (index, optnKey) => {
    setCharts((charts) => {
      charts[index].xAxis = optnKey;
      return [...charts];
    });
  };

  const handleYRightChange = (index, optnKey) => {
    setCharts((charts) => {
      charts[index].yRightAxis = optnKey;
      return [...charts];
    });
  };

  const handleAddChartClick = () => {
    setCharts((charts) => [...charts, { ...DEFAULT_OPTIONS_KEYS }]);
  };

  const handleDeleteChart = (index) => {
    setCharts((charts) => {
      charts.splice(index, 1);
      return [...charts];
    });
  };
  //#endregion

  //#region [render]
  return (
    <div>
      <h2>{i18n._('results.summary.interactiveComparison')}</h2>
      <div className='results-charts'>
        {charts.map((chart, index) => (
          <ResultsChart
            key={'compare_results_chart_' + index}
            comparedResults={comparedResults}
            yLeftAxis={chart.yLeftAxis}
            xAxis={chart.xAxis}
            yRightAxis={chart.yRightAxis}
            onYLeftChange={(optnKey) => handleYLeftChange(index, optnKey)}
            onXChange={(optnKey) => handleXChange(index, optnKey)}
            onYRightChange={(optnKey) => handleYRightChange(index, optnKey)}
            onDelete={
              charts.length === 1 ? null : () => handleDeleteChart(index)
            }
          />
        ))}
        <Button disabled={charts.length > 2} onClick={handleAddChartClick}>
          <FontAwesomeIcon icon='circle-plus' />
          {i18n._('results.summary.charts.add')}
        </Button>
      </div>
    </div>
  );
  //#endregion
};

export default ResultsCharts;
