import { useLingui } from '@lingui/react';
import React from 'react';
import { Alert } from 'react-bootstrap';
import {
  HOURS_PER_YEAR,
  MILLISECONDS_PER_HOUR,
  SOURCE
} from '../../../../../../../../../server/constants';
import { DETAILED_RESULTS_SST_KEYS } from '../../../../../../../../../server/models/design/result.model';
import LinesChart from '../../../../../../../components/Chart/LinesChart';
import { CHART_MODE } from '../../../../../../../constants';
import {
  DETAILED_RESULTS_SST_COLORS,
  SOURCES_CHART_COLORS
} from '../../../../../../../styles/colors';
import { getResultFullName } from '../../../../../../../utils/compute.utils';
import {
  formatValue,
  isArrNullOrEmpty
} from '../../../../../../../utils/data.utils';

const PowerLoadCurveSst = ({ result, sstName, need, sorted }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  if (!result?.details) return null;
  try {
    const data = [];
    // on récupère les clés des différentes sources
    const sstSrcKeys = Object.keys(DETAILED_RESULTS_SST_KEYS[need].sources)
      .filter((key) => result.details[`${sstName}_${key}`])
      .map((key) => `${sstName}_${key}`);
    if (isArrNullOrEmpty(sstSrcKeys)) {
      return (
        <Alert variant='warning'>
          {i18n._('results.energyAndCarbon.noPowerLoadCurve')}
        </Alert>
      );
    }

    // on récupère la clé du total
    const totalKey = Object.keys(DETAILED_RESULTS_SST_KEYS[need].total).find(
      (key) => result.details[`${sstName}_${key}`]
    );
    if (!totalKey) {
      return (
        <Alert variant='warning'>
          {i18n._('results.energyAndCarbon.noPowerLoadCurve')}
        </Alert>
      );
    }

    // l'abcisse = nombre d'heures par année
    const x = new Array(HOURS_PER_YEAR).fill(0).map((_, index) => index);

    // utils
    const getSrc = (sstSrcKey) => {
      return Object.values(SOURCE).find((source) =>
        sstSrcKey
          .substring(sstName.length, sstSrcKey.length)
          .toLowerCase()
          .includes(source)
      );
    };

    const getArea = (y, srcY, src, fill) => {
      if (!sorted) {
        let timestamp = new Date(new Date().getFullYear(), 0, 0).getTime();
        for (let i = 0; i < HOURS_PER_YEAR; ++i) {
          timestamp += MILLISECONDS_PER_HOUR;
          x[i] = new Date(timestamp).toISOString();
        }
      }
      return {
        x,
        y,
        mode: CHART_MODE.NONE,
        fill,
        fillcolor: SOURCES_CHART_COLORS[src],
        text: formatValue(srcY, 2),
        hovertemplate: '%{text}',
        name: i18n._('results.energyAndCarbon.needs.legend', {
          src: i18n._(`source.${src}`),
          unit: sorted ? i18n._('unit.kilowatt') : i18n._('unit.kilowattHour')
        })
      };
    };

    // la courbe représentant la couverture des besoins
    let totalValues = result.details[`${sstName}_${totalKey}`]
      .splice(0, HOURS_PER_YEAR)
      .map((total, hour) => ({ total, hour }));

    // si c'est une monotone alors on la trie par valeurs décroissantes
    if (sorted) totalValues = totalValues.sort((a, b) => b.total - a.total);

    const totalY = totalValues.map((value) => value.total);
    const name = sorted
      ? i18n._('results.energyAndCarbon.powerLoadCurve.name')
      : i18n._('results.energyAndCarbon.yearNeedsCurve.total.name', {
          need: i18n._(`need.${need}`)
        });

    data.push({
      x,
      y: totalY,
      mode: CHART_MODE.LINES,
      name,
      line: {
        color: DETAILED_RESULTS_SST_COLORS[totalKey],
        width: sorted ? 2 : 0.5
      }
    });

    /**
     * les autres courbes
     * ordre de somme des aires
     * - Chauffage : (TFP + HPG) → HPSolaireThermique→ HPA → Gaz
     * - ECS : (TFP + HPG) → HPSolaireThermique→ HPA → Gaz
     * - Climatisation : (TFP + HPG) → HPA → ITES
     */
    let tempTotalValuesY;
    const tfpSstSrcKey = sstSrcKeys.find(
      (sstSrcKey) => getSrc(sstSrcKey) === SOURCE.TFP
    );
    sstSrcKeys
      .filter((sstSrcKey) => getSrc(sstSrcKey) !== SOURCE.TFP)
      .forEach((sstSrcKey, i) => {
        const source = getSrc(sstSrcKey);
        const valuesY = new Array(totalValues.length);
        const srcY = new Array(totalValues.length);
        if (source === SOURCE.HPG && tfpSstSrcKey) {
          // TFP est compris dans HPG
          totalValues.forEach((value, j) => {
            const detailsSrcKey = result.details[sstSrcKey];
            const detailsTfpSrcKey = result.details[tfpSstSrcKey];
            if (!detailsSrcKey || !detailsTfpSrcKey) {
              throw new Error(
                i18n._('results.powerLoadCurve.error', {
                  missing: detailsSrcKey ? sstSrcKey : tfpSstSrcKey
                })
              );
            }

            // le total temporaire de toutes les valeurs à l'heure "value.hour"
            valuesY[j] =
              (tempTotalValuesY ? tempTotalValuesY[j] : 0) +
              result.details[sstSrcKey][value.hour] +
              result.details[tfpSstSrcKey][value.hour];

            // la vraie valeur à l'heure "value.hour"
            srcY[j] =
              result.details[sstSrcKey][value.hour] +
              result.details[tfpSstSrcKey][value.hour];
          });
        } else {
          totalValues.forEach((value, j) => {
            // le total temporaire de toutes les valeurs à l'heure "value.hour"
            valuesY[j] =
              (tempTotalValuesY ? tempTotalValuesY[j] : 0) +
              result.details[sstSrcKey][value.hour];

            // la vraie valeur à l'heure "value.hour"
            srcY[j] = result.details[sstSrcKey][value.hour];
          });
        }
        tempTotalValuesY = [...valuesY];
        data.push(
          getArea(valuesY, srcY, source, i === 0 ? 'tozeroy' : 'tonexty')
        );
      });

    let titleId;
    let layout = {
      margin: { b: 70 },
      xaxis: undefined,
      yaxis: {
        title: {
          text: i18n._('results.energyAndCarbon.powerLoadCurve.yaxis'),
          standoff: 30,
          showlegend: true
        }
      }
    };

    if (sorted) {
      titleId = 'results.energyAndCarbon.powerLoadCurve.need';
      layout.xaxis = {
        title: i18n._('results.energyAndCarbon.powerLoadCurve.xaxis')
      };
    } else {
      titleId = 'results.energyAndCarbon.yearNeedsCoverageCurve.need';
      layout.xaxis = {
        tickformat: '%b',
        dtick: 'M1'
      };
    }
    const title = i18n._(titleId, {
      need: i18n._(`need.${need}`).toLowerCase()
    });

    return (
      <LinesChart
        data={data}
        layout={layout}
        title={title}
        filename={i18n._('results.energyAndCarbon.powerLoadCurveSst.filename', {
          result: getResultFullName(i18n, result),
          substation: sstName,
          title
        })}
      />
    );
  } catch (err) {
    console.error(err);
    return <Alert variant='danger'>{'' + err}</Alert>;
  }
  //#endregion
};

export default React.memo(PowerLoadCurveSst);
