import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const RequireSuperAdmin = ({ children, redirectTo }) => {
  //#region [auth]
  const { user } = useAuth();
  //#endregion

  //#region [render]
  return user.IsSuperAdmin ? children : <Navigate to={redirectTo} />;
  //#endregion
};

export default RequireSuperAdmin;
