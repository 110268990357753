import React from 'react';

const SectionTitle = ({ className, level, onClick, children }) => {
  //#region [render]
  className = `section-title${className ? ' ' + className : ''}`;
  if (!level || level === 1)
    return (
      <h1 className={className} onClick={onClick}>
        {children}
      </h1>
    );
  if (level === 2)
    return (
      <h2 className={className} onClick={onClick}>
        {children}
      </h2>
    );
  if (level === 3)
    return (
      <h3 className={className} onClick={onClick}>
        {children}
      </h3>
    );
  //#endregion
};

export default React.memo(SectionTitle);
