import React, { useMemo } from 'react';
import { PER_SUB_OTHER_KEYS } from '../../../../../../../../../server/constants';
import Table from '../../../../../../../components/Table/Table';
import {
  formatValue,
  getSafeData,
  isObjNullOrEmpty
} from '../../../../../../../utils/data.utils';
import './OtherPerSubTable.css';

const OtherPerSubTable = ({ result }) => {
  //#region [memos]
  const data = useMemo(() => {
    const { PerSub } = result.ComputeResult.summary;
    const otherPerSubKeys = PER_SUB_OTHER_KEYS.filter(
      (key) => !isObjNullOrEmpty(PerSub[key])
    );
    const cols = [otherPerSubKeys];
    const substations = result.ComputeResult.inp.substations.map(
      (sst) => sst.InitStationName
    );
    substations.forEach((sst) => {
      const newCol = new Array(otherPerSubKeys.length);
      otherPerSubKeys.forEach((key, index) => {
        newCol[index] = formatValue(getSafeData(() => PerSub[key][sst]));
      });
      cols.push(newCol);
    });
    return {
      titles: ['Variables', ...substations],
      cols
    };
  }, [result.ComputeID]);
  //#endregion

  //#region [render]
  return (
    <Table
      header={data.titles}
      cols={data.cols}
      className='other-persub-table'
    />
  );
  //#endregion
};

export default OtherPerSubTable;
