import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { isArrNullOrEmpty } from '../../utils/data.utils';
import SectionTitle from '../SectionTitle';

const ResultsExpandableSection = ({
  title,
  level,
  comparedResults,
  children
}) => {
  if (!children) children = [];
  else if (!Array.isArray(children)) children = [children];

  //#region [states]
  const [visibilities, setVisibilities] = useState(
    children.map((child) => !!child.props.open)
  );
  const [loaders, setLoaders] = useState(children.map((_) => false));
  //#endregion

  //#region [methods]
  const handleChildOpen = async (index) => {
    if (!children[index].props.onOpen) return;
    setLoaders((loaders) => {
      loaders[index] = true;
      return [].concat(loaders);
    });
    await children[index].props.onOpen();
    setLoaders((loaders) => {
      loaders[index] = false;
      return [].concat(loaders);
    });
  };

  const handleTitleClick = async (index) => {
    if (loaders[index]) return;
    if (!visibilities[index]) await handleChildOpen(index);
    setVisibilities((visibilities) => {
      visibilities[index] = !visibilities[index];
      return [].concat(visibilities);
    });
  };
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      for (let i = 0; i < children.length; ++i) {
        if (visibilities[i]) await handleChildOpen(i);
      }
    })();
  }, [
    JSON.stringify(
      comparedResults.map((r) => r.ComputeID).sort((a, b) => a - b)
    )
  ]);
  //#endregion

  //#region [render]
  return isArrNullOrEmpty(children) ? null : (
    <div className='expandable-section'>
      {title && <SectionTitle level={level}>{title}</SectionTitle>}
      <div className='expandable-section-children'>
        {children.map((child, index) => (
          <div
            key={'expandable_row_' + index}
            className='expandable-section-child'
          >
            <div
              onClick={async () => await handleTitleClick(index)}
              className='expandable-section-child-title cursor-pointer'
            >
              <FontAwesomeIcon
                icon={
                  visibilities[index]
                    ? 'caret-square-down'
                    : 'caret-square-right'
                }
              />
              {child.props.title}
              {loaders[index] && <Spinner animation='border' />}
            </div>
            <div className='expandable-section-child-content'>
              {visibilities[index] && child}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
  //#endregion
};

export default ResultsExpandableSection;
