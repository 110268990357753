import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { MAX_RESULTS_FOR_COMPARE } from '../../../../../server/constants';
import {
  addComputeTag,
  deleteResults,
  fetchListOfComputesDetails,
  fetchListOfResults,
  fetchResult,
  removeComputeTag,
  replaceComputeTag,
  updateComputeComment
} from '../../../api/compute.api';
import { fetchResults } from '../../../api/project.api';
import PopupContext from '../../../contexts/PopupContext';
import useScrollPosition from '../../../hooks/useScrollPosition';
import { getGroupHeadingName } from '../../../utils/compute.utils';
import { hasUniqueElements } from '../../../utils/data.utils';
import './ResultPage.css';
import ComparedResultsSelect from './components/ComparedResultsSelect/ComparedResultsSelect';
import Sidebar from './components/Sidebar/Sidebar';
import EconomicAnalysSection from './sections/economicAnalysis/EconomicAnalysisSection';
import EnergyCarbonSection from './sections/energyCarbon/EnergyCarbonSection';
import GeneralSection from './sections/general/GeneralSection';
import GeomodelingSection from './sections/geomodeling/GeomodelingSection';
import SimulationParamsSection from './sections/simulationParams/SimulationParamsSection';
import SizingSection from './sections/sizing/SizingSection';
import SummarySection from './sections/summary/SummarySection';

const SCROLL_MARGIN_TOP_SECTION = 135;
const SECTIONS = [
  'summary',
  'general',
  'sizing',
  'energyAndCarbon',
  'economicAnalysis',
  'geomodeling',
  'simulationParams'
];
const PAGE_WIDTHS = ['65', '70', '78', '86', '94', '100'];
const ResultPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  const { companyId, projectId, resultId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  //#endregion

  //#region [contexts]
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [groupedOptions, setGroupedOptions] = useState([]);
  const [selectedResult, setSelectedResult] = useState();
  const [compareResults, setCompareResults] = useState([]);
  const [activeSectionId, setActiveSectionId] = useState(
    () => window.location.hash.replace('#results_section_', '') || 'summary'
  );
  const [isSelectCollapsed, setIsSelectCollapsed] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia('(max-width: 1007px)').matches
  );
  //#endregion

  //#region [refs]
  const sectionsRefs = SECTIONS.reduce((acc, section) => {
    acc[section] = useRef();
    return acc;
  }, {});
  //#endregion

  //#region [custom hooks]
  useScrollPosition(({ prevPos, currPos }) => {
    if (prevPos.y === currPos.y) return;
    if (currPos.y === 0) setIsSelectCollapsed(false);
    else if (!isSelectCollapsed) setIsSelectCollapsed(true);
    const fromTop = -(currPos.y - SCROLL_MARGIN_TOP_SECTION);
    let nextSection = null;
    for (let i = 0; i < SECTIONS.length; i++) {
      const section = SECTIONS[i];
      if (!sectionsRefs[section]?.current) continue;
      const sectionTop = sectionsRefs[section].current.offsetTop;
      const sectionMiddle =
        sectionTop + sectionsRefs[section].current.offsetHeight - 100;
      if (fromTop >= sectionTop && fromTop < sectionMiddle) {
        setActiveSectionId(section);
        break;
      } else if (fromTop >= sectionMiddle) {
        nextSection = SECTIONS[i + 1];
      }
    }
    if (nextSection) {
      setActiveSectionId(nextSection);
    }
  }, []);
  //#endregion

  //#region [methods]
  const getResultIndexes = (computeId) => {
    for (let i = 0; i < groupedOptions.length; ++i) {
      for (let j = 0; j < groupedOptions[i].options.length; ++j) {
        if (groupedOptions[i].options[j].value === computeId) {
          return [i, j];
        }
      }
    }
    return [-1, -1];
  };

  const handleResultCheck = async (resultId, checked) => {
    try {
      setGroupedOptions((groups) => {
        const indexes = getResultIndexes(resultId);
        if (indexes[0] === -1) return groups;
        groups[indexes[0]].options[indexes[1]].checked = checked;
        return [...groups];
      });

      let newCompareResults;
      if (checked) {
        const result = await fetchResult(resultId);
        newCompareResults = [...compareResults, result];
      } else {
        newCompareResults = compareResults.filter(
          (result) => result.ComputeID !== resultId
        );
      }
      setCompareResults(() => newCompareResults);

      const newSearchParams = new URLSearchParams();
      newCompareResults
        .map((result) => result.ComputeID)
        .forEach((id) => {
          newSearchParams.append('compare', id);
        });
      setSearchParams(newSearchParams);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleResultsDelete = async (idsToDelete) => {
    try {
      await deleteResults(idsToDelete);
      if (
        idsToDelete.includes(resultId) ||
        compareResults.some((result) => idsToDelete.includes(result.ComputeID))
      ) {
        navigate(`/company/${companyId}/project/${projectId}/design/results`);
      } else {
        window.location.reload(false);
      }
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleCommentSave = async (resultId, comment) => {
    try {
      await updateComputeComment(resultId, comment);
      if (selectedResult.ComputeID === resultId) {
        setSelectedResult((res) => ({
          ...res,
          Comment: comment
        }));
      } else {
        setCompareResults((results) => {
          const index = results.findIndex((res) => res.ComputeID === resultId);
          if (index === -1) return results;
          results[index].Comment = comment;
          return [...results];
        });
      }
      setGroupedOptions((groups) => {
        const indexes = getResultIndexes(resultId);
        if (indexes[0] === -1) return groups;
        groups[indexes[0]].options[indexes[1]].comment = comment;
        return [...groups];
      });
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleResultsCompare = async (ids) => {
    try {
      const results = await fetchListOfResults(ids);
      setCompareResults(() => results);
      const newSearchParams = new URLSearchParams();
      results.forEach((result) => {
        newSearchParams.append('compare', result.ComputeID);
      });
      setSearchParams(newSearchParams);
      setGroupedOptions((groups) => {
        return groups.map((group) => {
          return {
            label: group.label,
            options: group.options.map((option) => {
              option.checked = ids.includes(option.value);
              return option;
            })
          };
        });
      });
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const changeTag = async (resultId, tagKey) => {
    if (selectedResult.ComputeID === resultId) {
      setSelectedResult((result) => {
        result.tags[tagKey] = !result.tags[tagKey];
        return { ...result };
      });
    } else {
      setCompareResults((results) => {
        const index = results.findIndex((res) => res.ComputeID === resultId);
        if (index === -1) return results;
        results[index].tags[tagKey] = !results[index].tags[tagKey];
        return [...results];
      });
    }
    setGroupedOptions((groups) => {
      const indexes = getResultIndexes(resultId);
      if (indexes[0] === -1) return groups;
      groups[indexes[0]].options[indexes[1]].tags[tagKey] =
        !groups[indexes[0]].options[indexes[1]].tags[tagKey];
      return [...groups];
    });
  };

  const handleTagAdd = async (resultId, tag) => {
    try {
      await addComputeTag(resultId, tag.value);
      changeTag(resultId, tag.key);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleTagRemove = async (resultId, tag) => {
    try {
      await removeComputeTag(resultId, tag.value);
      changeTag(resultId, tag.key);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleTagReplace = async (resultId, computeIdForReplacingTag, tag) => {
    try {
      await replaceComputeTag(resultId, computeIdForReplacingTag, tag.value);
      if (
        selectedResult.ComputeID === resultId ||
        selectedResult.ComputeID === computeIdForReplacingTag
      ) {
        setSelectedResult((result) => {
          result.tags[tag.key] = !result.tags[tag.key];
          return { ...result };
        });
      }
      setCompareResults((results) => {
        let index = results.findIndex((res) => res.ComputeID === resultId);
        if (index !== -1) {
          results[index].tags[tag.key] = !results[index].tags[tag.key];
        }
        index = results.findIndex(
          (res) => res.ComputeID === computeIdForReplacingTag
        );
        if (index !== -1) {
          results[index].tags[tag.key] = !results[index].tags[tag.key];
        }
        return [...results];
      });
      setGroupedOptions((groups) => {
        let indexes = getResultIndexes(resultId);
        groups[indexes[0]].options[indexes[1]].tags[tag.key] =
          !groups[indexes[0]].options[indexes[1]].tags[tag.key];
        indexes = getResultIndexes(computeIdForReplacingTag);
        groups[indexes[0]].options[indexes[1]].tags[tag.key] =
          !groups[indexes[0]].options[indexes[1]].tags[tag.key];
        return [...groups];
      });
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const loadDetails = async () => {
    try {
      const results = [selectedResult, ...compareResults];
      const ids = results
        .filter((result) => !result.details)
        .map((result) => result.ComputeID);
      if (ids.length === 0) return;
      const computes = await fetchListOfComputesDetails(ids);
      setSelectedResult((result) => {
        const compute = computes.find((c) => c.ComputeID === result.ComputeID);
        return !compute ? result : { ...result, details: compute.details };
      });
      setCompareResults((results) => {
        return results.map((result) => {
          const compute = computes.find(
            (c) => c.ComputeID === result.ComputeID
          );
          return !compute ? result : { ...result, details: compute.details };
        });
      });
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        const compareIds = searchParams.getAll('compare');
        if (
          compareIds.length > 0 &&
          (compareIds.length > MAX_RESULTS_FOR_COMPARE ||
            !hasUniqueElements(compareIds))
        ) {
          navigate('/notfound');
          return;
        }

        const groups = await fetchResults(projectId);
        setGroupedOptions(() =>
          groups.map((group, groupIndex) => ({
            label: getGroupHeadingName(i18n, group),
            options: group.results.map((result, resultIndex) => ({
              value: result.ComputeID,
              goalPart: result.GoalPart,
              comment: result.Comment,
              groupLabel: getGroupHeadingName(i18n, group),
              tags: result.tags,
              indexes: [groupIndex, resultIndex],
              checked: compareIds.find((id) => id === result.ComputeID)
            }))
          }))
        );
        const ids = [resultId, ...compareIds];
        const results = await fetchListOfResults(ids);
        if (compareIds.length > 0) {
          setCompareResults(
            results.filter((result) => compareIds.includes(result.ComputeID))
          );
        } else {
          setCompareResults([]);
        }
        setSelectedResult(
          results.find((result) => result.ComputeID === resultId)
        );
      } catch (err) {
        console.error(err);
        if (err.response?.status === 404) {
          navigate('/not-found');
        } else {
          openErrorToast(err);
        }
      }
    })();
  }, [projectId, resultId]);

  useEffect(() => {
    window
      .matchMedia('(max-width: 1007px)')
      .addEventListener('change', (e) => setMatches(e.matches));
    return () => {
      window.removeEventListener('change', setMatches);
    };
  }, []);

  useEffect(() => {
    if (!selectedResult?.ComputeID || !window.location.hash) return;
    const section = window.location.hash.replace('#results_section_', '');
    if (sectionsRefs[section]) sectionsRefs[section].current.scrollIntoView();
  }, [selectedResult?.ComputeID]);
  //#endregion

  //#region [render]
  if (!selectedResult || groupedOptions.length === 0) return null;
  const comparedResults = [selectedResult, ...compareResults];
  return (
    <div>
      <ComparedResultsSelect
        isCollapsed={isSelectCollapsed}
        selectedResultId={selectedResult.ComputeID}
        groupedOptions={groupedOptions}
        compareResultsIds={compareResults.map((result) => result.ComputeID)}
        onResultCheck={handleResultCheck}
        onResultsDelete={handleResultsDelete}
        onResultsCompare={handleResultsCompare}
      />
      <Sidebar
        sectionsIds={SECTIONS}
        activeSectionId={activeSectionId}
        onSectionClick={(sectionId) => setActiveSectionId(sectionId)}
      />
      <div
        className='result-body'
        style={{
          width: `${matches ? 100 : PAGE_WIDTHS[comparedResults.length - 1]}%`
        }}
      >
        <div ref={sectionsRefs.summary} id='results_section_summary'>
          <SummarySection comparedResults={comparedResults} />
        </div>
        <div ref={sectionsRefs.general} id='results_section_general'>
          <GeneralSection
            comparedResults={comparedResults}
            onCommentSave={handleCommentSave}
            onTagAdd={handleTagAdd}
            onTagRemove={handleTagRemove}
            onTagReplace={handleTagReplace}
          />
        </div>
        <div ref={sectionsRefs.sizing} id='results_section_sizing'>
          <SizingSection
            selectedResult={selectedResult}
            comparedResults={comparedResults}
          />
        </div>
        <div
          ref={sectionsRefs.energyAndCarbon}
          id='results_section_energyAndCarbon'
        >
          <EnergyCarbonSection
            selectedResult={selectedResult}
            comparedResults={comparedResults}
            loadDetails={loadDetails}
          />
        </div>
        <div
          ref={sectionsRefs.economicAnalysis}
          id='results_section_economicAnalysis'
        >
          <EconomicAnalysSection
            selectedResult={selectedResult}
            comparedResults={comparedResults}
          />
        </div>
        <div ref={sectionsRefs.geomodeling} id='results_section_geomodeling'>
          <GeomodelingSection
            comparedResults={comparedResults}
            loadDetails={loadDetails}
          />
        </div>
        <div
          ref={sectionsRefs.simulationParams}
          id='results_section_simulationParams'
        >
          <SimulationParamsSection
            comparedResults={comparedResults}
            loadDetails={loadDetails}
          />
        </div>
      </div>
    </div>
  );
  //#endregion
};

export default ResultPage;
