import { UNIT } from '../../../../../../../server/constants';
import { getSafeData } from '../../../../../utils/data.utils';
import { getShortHPName } from '../../../../../utils/heatpump.utils';

export const btesSolarTotalTemplates = (i18n, results) => {
  const { inp } = results[0].ComputeResult;
  let gasBoilerLabel = '';
  if (!inp.InitGasEnabled) {
    gasBoilerLabel = i18n._('results.sizing.hpa.gasPThMax.gasBoilerPower');
  } else {
    gasBoilerLabel = inp.InitReleveEnabled
      ? i18n._('results.sizing.hpa.gasPThMax.backupGasBoilerPower')
      : i18n._('results.sizing.hpa.gasPThMax.auxiliaryGasBoilerPower');
  }
  return [
    {
      title: i18n._('results.sizing.interSeasonalStorage'),
      rows: [
        {
          label: i18n._('results.sizing.interSeasonalStorage.btesSurface'),
          values: results.map(
            (result) => result.ComputeResult.summary.BtesSurface
          ),
          unit: UNIT.SQUARE_METER
        },
        {
          label: i18n._('results.sizing.interSeasonalStorage.btesLength'),
          values: results.map(
            (result) => result.ComputeResult.params.BtesLength
          ),
          unit: UNIT.METER
        },
        {
          label: i18n._('results.sizing.interSeasonalStorage.probesNumber'),
          values: results.map(
            (result) => result.ComputeResult.summary.ProbesNumber
          )
        },
        {
          label: i18n._('results.sizing.interSeasonalStorage.btesProbeDepth'),
          values: results.map(
            (result) => result.ComputeResult.params.BtesProbeDepth
          ),
          unit: UNIT.METER
        },
        {
          label: i18n._(
            'results.sizing.interSeasonalStorage.btesProbeDistance'
          ),
          values: results.map(
            (result) => result.ComputeResult.params.BtesProbeDistance
          ),
          unit: UNIT.METER
        },
        {
          label: i18n._(
            'results.sizing.interSeasonalStorage.btesFinalTemperature'
          ),
          values: results.map(
            (result) => result.ComputeResult.summary.BtesT2Final
          ),
          unit: UNIT.CELSIUS_DEGREE
        },
        {
          label: i18n._('results.sizing.interSeasonalStorage.qHeadLossMax'),
          values: results.map(
            (result) => result.ComputeResult.summary.QHeadLossMax
          ),
          unit: UNIT.WATER_COLUMN_METER
        },
        {
          label: i18n._('results.sizing.interSeasonalStorage.btesFlowMax'),
          values: results.map(
            (result) => result.ComputeResult.summary.BtesFlowMax
          ),
          unit: UNIT.CUBIC_METER_PER_HOUR
        }
      ]
    },
    {
      title: i18n._('results.sizing.btesJoining'),
      rows: [
        {
          label: i18n._('results.sizing.btesJoining.connectionLength'),
          values: results.map(
            (result) => result.ComputeResult.inp.InitLoopConnectionLength
          ),
          unit: UNIT.METER
        },
        {
          label: i18n._('results.sizing.btesJoining.connectionDiameter'),
          values: results.map(
            (result) => result.ComputeResult.summary.ConnectionDiameter
          ),
          unit: UNIT.DN_OD
        }
      ]
    },
    {
      title: i18n._('results.sizing.hpg'),
      rows: [
        {
          label: i18n._('results.sizing.selectedHp'),
          values: results.map((result) =>
            getShortHPName(result.ComputeResult.inp.HPGFilename)
          )
        },
        {
          label: i18n._('results.sizing.hpg.hpgPthHeat'),
          values: results.map(
            (result) => result.ComputeResult.summary.TotalHPGPthHeatNormMax
          ),
          unit: UNIT.KILOWATT
        },
        {
          label: i18n._('results.sizing.hpg.hpgPthCold'),
          values: results.map(
            (result) => result.ComputeResult.summary.TotalHPGPthColdNormMax
          ),
          unit: UNIT.KILOWATT
        },
        {
          label: i18n._('results.sizing.hpg.hpgPthHeatMaxB0W35'),
          values: results.map(
            (result) => result.ComputeResult.summary.TotalHPGPthHeatMaxB0W35
          ),
          unit: UNIT.KILOWATT
        },
        {
          label: i18n._('results.sizing.hpg.hpBtesPThMax'),
          values: results.map(
            (result) => result.ComputeResult.summary.TotalHPBtesPThMax
          ),
          unit: UNIT.KILOWATT
        },
        {
          label: i18n._('results.sizing.hpg.hpSolarPThMax'),
          values: results.map(
            (result) => result.ComputeResult.summary.TotalHPSolarPThMax
          ),
          unit: UNIT.KILOWATT
        },
        {
          label: i18n._('results.sizing.hpg.hpSolarPthENMax'),
          values: results.map(
            (result) => result.ComputeResult.summary.TotalHPSolarPthENMax
          ),
          unit: UNIT.KILOWATT
        }
      ]
    },
    {
      title: i18n._('results.sizing.hpa'),
      rows: [
        {
          label: i18n._('results.sizing.selectedHp'),
          values: results.map((result) =>
            getShortHPName(result.ComputeResult.inp.HPAFilename)
          )
        },
        {
          label: i18n._('results.sizing.hpa.hpaPthHeat'),
          values: results.map(
            (result) => result.ComputeResult.summary.TotalHPAPthHeatNormMax
          ),
          unit: UNIT.KILOWATT
        },
        {
          label: i18n._('results.sizing.hpa.hpaPthCold'),
          values: results.map(
            (result) => result.ComputeResult.summary.TotalHPAPthColdNormMax
          ),
          unit: UNIT.KILOWATT
        },
        {
          label: i18n._('results.sizing.hpa.hpaPthHeatMaxA0W35'),
          values: results.map(
            (result) => result.ComputeResult.summary.TotalHPAPthHeatMaxA0W35
          ),
          unit: UNIT.KILOWATT
        }
      ]
    },
    {
      title: i18n._('results.sizing.boiler'),
      rows: [
        {
          label: gasBoilerLabel,
          values: results.map(
            (result) => result.ComputeResult.summary.TotalGasPThMax
          ),
          unit: UNIT.KILOWATT
        }
      ]
    },
    {
      title: i18n._('results.sizing.solarThermal'),
      rows: [
        {
          label: i18n._('results.sizing.solarThermal.solarThermalSurface'),
          values: results.map(
            (result) => result.ComputeResult.params.SolarThermalSurface
          ),
          unit: UNIT.SQUARE_METER
        }
      ]
    },
    {
      title: i18n._('results.sizing.solarPhotovoltaic'),
      rows: [
        {
          label: i18n._('results.sizing.solarPhotovoltaic.pvSurface'),
          values: results.map(
            (result) => result.ComputeResult.summary.PVSurface
          ),
          unit: UNIT.SQUARE_METER
        },
        {
          label: i18n._('results.sizing.solarPhotovoltaic.kiloWattCretePV'),
          values: results.map(
            (result) => result.ComputeResult.params.KiloWattCretePV
          ),
          unit: UNIT.KILOWATT_PEAK
        },
        {
          label: i18n._('results.sizing.solarPhotovoltaic.pvInverterPower'),
          values: results.map(
            (result) => result.ComputeResult.summary.PVInverterPower
          ),
          unit: UNIT.KILOWATT
        }
      ]
    },
    {
      title: i18n._('results.sizing.coldStorage'),
      rows: [
        {
          label: i18n._('results.sizing.coldStorage.qItesMax'),
          values: results.map(
            (result) => result.ComputeResult.summary.TotalQItesMax
          ),
          unit: UNIT.KILOWATT_HOUR
        }
      ]
    },
    {
      title: i18n._('results.sizing.total.powerTotal'),
      rows: [
        {
          label: i18n._('results.sizing.total.pTotalElecMax'),
          values: results.map(
            (result) => result.ComputeResult.summary.PTotalElecMax
          ),
          unit: UNIT.KILOWATT
        }
      ]
    }
  ];
};

export const sstTemplates = (i18n, results, sstName) => {
  const { inp } = results[0].ComputeResult;
  let gasBoilerLabel = '';
  if (!inp.InitGasEnabled) {
    gasBoilerLabel = i18n._('results.sizing.hpa.gasPThMax.gasBoilerPower');
  } else {
    gasBoilerLabel = inp.InitReleveEnabled
      ? i18n._('results.sizing.hpa.gasPThMax.backupGasBoilerPower')
      : i18n._('results.sizing.hpa.gasPThMax.auxiliaryGasBoilerPower');
  }
  return [
    {
      title: i18n._('results.sizing.hpg'),
      rows: [
        {
          label: i18n._('results.sizing.selectedHp'),
          values: results.map((result) => result.ComputeResult.inp.HPGFilename)
        },
        {
          label: i18n._('results.sizing.hpg.hpgPthHeat'),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.PerSub.HPGPthHeatNormMax[sstName]
            )
          ),
          unit: UNIT.KILOWATT
        },
        {
          label: i18n._('results.sizing.hpg.hpgPthCold'),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.PerSub.HPGPthColdNormMax[sstName]
            )
          ),
          unit: UNIT.KILOWATT
        },
        {
          label: i18n._('results.sizing.hpg.hpgPthHeatMaxB0W35'),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.PerSub.HPGPthHeatMaxB0W35[sstName]
            )
          ),
          unit: UNIT.KILOWATT
        },
        {
          label: i18n._('results.sizing.hpg.hpBtesPThMax'),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.PerSub.HPBtesPThMax[sstName]
            )
          ),
          unit: UNIT.KILOWATT
        },
        {
          label: i18n._('results.sizing.hpg.hpSolarPThMax'),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.PerSub.HPSolarPThMax[sstName]
            )
          ),
          unit: UNIT.KILOWATT
        },
        {
          label: i18n._('results.sizing.hpg.hpSolarPthENMax'),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.PerSub.HPSolarPthENMax[sstName]
            )
          ),
          unit: UNIT.KILOWATT
        }
      ]
    },
    {
      title: i18n._('results.sizing.hpa'),
      rows: [
        {
          label: i18n._('results.sizing.selectedHp'),
          values: results.map((result) =>
            getShortHPName(result.ComputeResult.inp.HPAFilename)
          )
        },
        {
          label: i18n._('results.sizing.hpa.hpaPthHeat'),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.PerSub.HPAPthHeatNormMax[sstName]
            )
          ),
          unit: UNIT.KILOWATT
        },
        {
          label: i18n._('results.sizing.hpa.hpaPthCold'),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.PerSub.HPAPthColdNormMax[sstName]
            )
          ),
          unit: UNIT.KILOWATT
        },
        {
          label: i18n._('results.sizing.hpa.hpaPthHeatMaxA0W35'),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.PerSub.HPAPthHeatMaxA0W35[sstName]
            )
          ),
          unit: UNIT.KILOWATT
        }
      ]
    },
    {
      title: i18n._('results.sizing.boiler'),
      rows: [
        {
          label: gasBoilerLabel,
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.PerSub.GasPThMax[sstName]
            )
          ),
          unit: UNIT.KILOWATT
        }
      ]
    },
    {
      title: i18n._('results.sizing.coldStorage'),
      rows: [
        {
          label: i18n._('results.sizing.coldStorage.qItesMax'),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.PerSub.QItesMax[sstName]
            )
          ),
          unit: UNIT.KILOWATT_HOUR
        }
      ]
    }
  ];
};
