import { useLingui } from '@lingui/react';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

const ProjectAppsCell = ({ row }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { companyId } = useParams();
  //#endregion

  //#region [render]
  const designUrl = `/company/${companyId}/project/${row.original.AhsID}/design/compute?type=opti`;
  const configUrl = `/company/${companyId}/project/${row.original.AhsID}/configs`;
  const designClassName = row.original.hasCompute ? ' has-compute' : '';
  const configClassName = row.original.latestConfigId ? ' exists' : '';
  return (
    <div className='projects-table-apps'>
      <Link
        to={designUrl}
        className={'design' + designClassName}
        title={
          row.original.hasCompute
            ? i18n._('projects.hasCompute')
            : i18n._('projects.noCompute')
        }
      >
        {i18n._('app.design')}
      </Link>
      <Link
        to={configUrl}
        className={'config' + configClassName}
        title={
          row.original.latestConfigId
            ? i18n._('projects.hasConfig')
            : i18n._('projects.noConfig')
        }
      >
        {i18n._('app.config')}
      </Link>
    </div>
  );
  //#endregion
};

export default ProjectAppsCell;
