import React from 'react';
import { Spinner } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import OpaqueLayer from '../../../../components/OpaqueLayer/OpaqueLayer';
import { FORM_STATUS } from '../../../../constants';
import useUnsavedChanges from '../../../../hooks/useUnsavedChanges';
import './ConfigFormPage.css';

const ConfigFormPage = ({ formStatus, formErrors, save, children }) => {
  //#region [custom hooks]
  useUnsavedChanges(formStatus, formErrors, save);
  //#endregion

  //#region [render]
  return (
    <div className='config-form-page'>
      {createPortal(
        <OpaqueLayer
          visible={formStatus === FORM_STATUS.LOADING}
          className='config-form-opaque-layer'
        >
          <Spinner variant='dark' />
        </OpaqueLayer>,
        document.body
      )}
      {children}
    </div>
  );
  //#endregion
};

export default ConfigFormPage;
