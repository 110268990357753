import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import {
  BTES_TEMP_KEYS,
  HOURS_PER_YEAR,
  MONTHS
} from '../../../../../../../../../server/constants';
import {
  DETAILED_RESULTS_KEYS,
  DETAILED_RESULTS_SST_KEYS
} from '../../../../../../../../../server/models/design/result.model.js';
import LinesChart from '../../../../../../../components/Chart/LinesChart';
import { CHART_MODE } from '../../../../../../../constants';
import PopupContext from '../../../../../../../contexts/PopupContext';
import { DETAILED_RESULTS_CHART_COLORS } from '../../../../../../../styles/colors';
import { getResultFullName } from '../../../../../../../utils/compute.utils.js';
import {
  formatValue,
  isNull,
  isObjNullOrEmpty
} from '../../../../../../../utils/data.utils';
import { getHoursInterval } from '../../../../../../../utils/date.utils';
import { downloadFile } from '../../../../../../../utils/file.utils';
import './DetailedResults.css';

const getWidth = (nameWUnit) => {
  const { length } = nameWUnit;
  return length > 15 ? 130 + length * 3 : 80 + length * 3;
};

const DetailedResults = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [selectedDate, setSelectedDate] = useState({
    month: MONTHS[0],
    year: 0,
    interval: getHoursInterval(MONTHS[0], 0)
  });
  const [columns, setColumns] = useState([]);
  const [selectedCols, setSelectedCols] = useState(BTES_TEMP_KEYS);
  //#endregion

  //#region [effects]
  useEffect(() => {
    if (isObjNullOrEmpty(result.details)) return;
    const hoursCol = [
      {
        name: 'Hour',
        header: <span className='detailed-header'>Hour</span>,
        defaultWidth: 73
      }
    ];
    const keys = Object.assign(
      {},
      ...Object.values(DETAILED_RESULTS_SST_KEYS).map((obj) => obj.total)
    );

    const sstKeys = Object.entries(keys).reduce(
      (acc, [key, { unit, precision }]) => {
        result.ComputeResult.inp.substations.forEach((sst) => {
          acc[sst + '_' + key] = { unit, precision };
        });
        return acc;
      },
      {}
    );

    const cols = Object.entries(DETAILED_RESULTS_KEYS)
      .concat(Object.entries(sstKeys))
      .filter(([col, _]) => !isNull(result.details[col]))
      .map(([col, { unit, precision }]) => {
        const nameWUnit = unit
          ? i18n._('results.simulationParams.detailed.header', {
              col,
              unit: i18n._(`unit.${unit}`)
            })
          : col;
        return {
          name: col,
          header: (
            <span
              className={`detailed-header ${
                selectedCols.find((c) => c === col) ? 'bold' : ''
              }`}
              onClick={() => handleColClick(col)}
            >
              {nameWUnit}
            </span>
          ),
          defaultWidth: getWidth(nameWUnit),
          precision,
          nameWUnit,
          render: ({ value }) => <span className='detailed-cell'>{value}</span>
        };
      });
    setColumns(hoursCol.concat(cols));
  }, [result.details, selectedCols.length]);
  //#endregion

  //#region [memos]
  const rows = useMemo(() => {
    if (columns.length === 1) return [];
    const newRows = [];
    const hourStart = selectedDate.interval[0] - 1;
    const hourEnd = selectedDate.interval[1] - 1;
    for (let i = hourStart; i < hourEnd; ++i) {
      const row = {};
      row.Hour = i + 1;
      columns
        .filter((col) => col.name !== 'Hour')
        .forEach((col) => {
          row[col.name] = formatValue(
            result.details[col.name][i],
            col.precision
          );
        });
      newRows.push(row);
    }
    return newRows;
  }, [columns, selectedDate]);

  const data = useMemo(() => {
    const start = selectedDate.year * HOURS_PER_YEAR;
    const end = (selectedDate.year + 1) * HOURS_PER_YEAR;
    if (!result.details || columns.length === 1) return [];
    const oneYearArr = new Array(HOURS_PER_YEAR).fill(0);
    return selectedCols.map((key) => ({
      x: oneYearArr.map((_, i) => selectedDate.year * HOURS_PER_YEAR + i),
      y: result.details[key].slice(start, end),
      name: key,
      line: { color: DETAILED_RESULTS_CHART_COLORS[key] },
      mode: CHART_MODE.LINES
    }));
  }, [result.details, selectedCols, selectedDate]);
  //#endregion

  //#region [methods]
  const handleDownloadClick = async () => {
    try {
      let csvContent = `${columns.map((col) => col.nameWUnit).join(';')}\n`;
      const nbRows =
        HOURS_PER_YEAR * result.ComputeResult.inp.InitYearsSimulations;
      for (let i = 0; i < nbRows; ++i) {
        columns.forEach((col) => {
          csvContent +=
            col.name === 'Hour'
              ? i + 1
              : formatValue(result.details[col.name][i], col.precision);
          csvContent += ';';
        });
        csvContent += i < nbRows - 1 ? '\n' : '';
      }
      const filename = getResultFullName(i18n, result).replaceAll('.', '_');
      downloadFile(
        filename,
        new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleMonthChange = (evt) => {
    setSelectedDate((date) => ({
      ...date,
      interval: getHoursInterval(evt.target.value, date.year),
      month: evt.target.value
    }));
  };

  const handleYearChange = (evt) => {
    setSelectedDate((date) => ({
      ...date,
      interval: getHoursInterval(date.month, evt.target.value),
      year: evt.target.value
    }));
  };

  const handleColClick = (key) => {
    setSelectedCols((cols) => {
      const colIndex = cols.findIndex((col) => col === key);
      if (colIndex === -1) return [...cols, key];
      cols.splice(colIndex, 1);
      return [].concat(cols);
    });
  };
  //#endregion

  //#region [render]
  if (isObjNullOrEmpty(result.details)) return null;
  const years = result.ComputeResult.inp.InitYearsSimulations;
  return (
    <div>
      <Button
        variant='primary'
        className='detailed-btn-dl'
        onClick={handleDownloadClick}
      >
        {i18n._('results.simulationParams.detailed.dl')}
      </Button>
      <div className='detailed-select-body'>
        <span>{i18n._('results.simulationParams.detailed.selectPeriod')}</span>
        <Form.Select
          className='detailed-select'
          onChange={handleMonthChange}
          name='detailed-results-month-select'
        >
          {MONTHS.map((month, index) => (
            <option key={'detailed_month_' + index} value={month}>
              {i18n._(`month.${month}`)}
            </option>
          ))}
        </Form.Select>
        <Form.Select
          className='detailed-select'
          onChange={handleYearChange}
          name='detailed-results-year-select'
        >
          {new Array(years).fill(0).map((_, index) => (
            <option key={'detailed_year_' + index} value={index}>
              {i18n._('results.year', { year: index + 1 })}
            </option>
          ))}
        </Form.Select>
      </div>
      <span className='detailed-instructions'>
        {i18n._('results.simulationParams.detailed.table.instructions')}
      </span>
      <ReactDataGrid
        columns={columns}
        dataSource={rows}
        className='detailed-grid'
        showColumnMenuTool={false}
        sortable={false}
        reorderColumns={false}
      />
      <LinesChart
        data={data}
        layout={{
          xaxis: {
            title: i18n._('date.hours')
          }
        }}
        title={i18n._('results.year', {
          year: parseInt(selectedDate.year) + 1
        })}
        filename={i18n._('results.simulationParams.detailed.filename', {
          result: getResultFullName(i18n, result),
          year: parseInt(selectedDate.year) + 1
        })}
      />
    </div>
  );
};

export default DetailedResults;
