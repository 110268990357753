import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useMemo, useState } from 'react';
import { Badge, Button, Collapse, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Select, { components } from 'react-select';
import { MAX_RESULTS_FOR_COMPARE } from '../../../../../../../server/constants';
import reactSelectStyles from '../../../../../styles/react-select.style';
import { getOptionName } from '../../../../../utils/compute.utils';
import DeleteModal from '../DeleteModal/DeleteModal';
import './ComparedResultsSelect.css';

const ComparedResultsSelect = ({
  isCollapsed,
  selectedResultId,
  groupedOptions,
  compareResultsIds,
  onResultCheck,
  onResultsDelete,
  onResultsCompare
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { companyId, projectId } = useParams();
  const navigate = useNavigate();
  //#endregion

  //#region [states]
  const [isMenuOpen, setIsMenuOpen] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  //#endregion

  //#region [methods]
  const handleOptionClick = (resultId) => {
    navigate(
      `/company/${companyId}/project/${projectId}/design/results/${resultId}`
    );
  };

  const handleResultCheck = async (comparedResultId, checked) => {
    setIsMenuOpen(true);
    await onResultCheck(comparedResultId, checked);
  };

  const handleInputChange = (_, { action, prevInputValue }) => {
    if (action === 'menu-close' && !prevInputValue) {
      setIsMenuOpen(undefined);
    }
    return prevInputValue;
  };
  //#endregion

  //#region [select components]
  const SingleValue = (props) => {
    const label = i18n._('result.fullName', {
      group: props.data.groupLabel,
      option: getOptionName(i18n, props.data.goalPart, props.data.comment)
    });
    const hasTag =
      props.data.tags.feasibility ||
      props.data.tags.project ||
      props.data.tags.contractCommitment;
    return (
      <components.SingleValue {...props}>
        <span
          title={label}
          className='ellipsis'
          onClick={() => setIsMenuOpen(true)}
        >
          {label}
        </span>
        <div className='compared-results-single-value-right'>
          <span className='results-select-tags'>
            {props.data.tags.feasibility && (
              <Badge className='feasibility-badge'>
                {i18n._('tag.feasibility')}
              </Badge>
            )}
            {props.data.tags.project && (
              <Badge className='project-badge'>{i18n._('tag.project')}</Badge>
            )}
            {props.data.tags.contractCommitment && (
              <Badge className='contract-commitment-badge'>
                {i18n._('tag.contractCommitment')}
              </Badge>
            )}
          </span>
          {hasTag && (
            <div className='compared-results-single-value-separator'></div>
          )}
          <FontAwesomeIcon
            icon='trash-alt'
            className='compared-results-single-value-delete'
            title={i18n._('result.openDeleteModal')}
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      </components.SingleValue>
    );
  };

  const GroupHeading = (props) => {
    const ids = [selectedResultId, ...compareResultsIds];
    return (
      <div className='group-header'>
        <components.GroupHeading {...props} />
        {!props.data.options.every((option) => ids.includes(option.value)) && (
          <Button
            variant='outline-secondary'
            onClick={async () => {
              await onResultsCompare(
                props.data.options
                  .map((option) => option.value)
                  .filter((id) => id !== selectedResultId)
              );
            }}
          >
            <FontAwesomeIcon icon='table' />
            {i18n._('results.select.compare')}
          </Button>
        )}
      </div>
    );
  };

  const Option = (props) => {
    const label = getOptionName(i18n, props.data.goalPart, props.data.comment);
    return (
      <components.Option {...props}>
        <div className='result-page-option'>
          <Form.Check
            type='checkbox'
            onChange={(evt) =>
              handleResultCheck(props.data.value, evt.target.checked)
            }
            checked={props.data.checked}
            className={
              props.data.value !== selectedResultId
                ? 'compared-results-select-checkbox'
                : 'compared-results-select-checkbox-hidden'
            }
            disabled={
              compareResultsIds.length === MAX_RESULTS_FOR_COMPARE &&
              !props.data.checked
            }
            name={`result-option-${props.data.value}`}
            id={`result-option-${props.data.value}`}
          />
          <div
            className='result-option'
            onClick={() => handleOptionClick(props.data.value)}
          >
            <span className='result-option-label ellipsis' title={label}>
              {label}
            </span>
            <div className='results-select-tags'>
              {props.data.tags.feasibility && (
                <Badge className='feasibility-badge'>
                  {i18n._('tag.feasibility')}
                </Badge>
              )}
              {props.data.tags.project && (
                <Badge className='project-badge'>{i18n._('tag.project')}</Badge>
              )}
              {props.data.tags.contractCommitment && (
                <Badge className='contract-commitment-badge'>
                  {i18n._('tag.contractCommitment')}
                </Badge>
              )}
            </div>
          </div>
        </div>
      </components.Option>
    );
  };
  //#endregion

  //#region [memos]
  const selectedOption = useMemo(() => {
    return groupedOptions.reduce((acc, group) => {
      const option = group.options.find(
        (option) => option.value === selectedResultId
      );
      return option ? option : acc;
    }, null);
  }, [groupedOptions, selectedResultId]);
  //#endregion

  //#region [render]
  return (
    <div className='compared-results-select-card'>
      <Collapse in={!isCollapsed}>
        <div>
          <p className='compared-results-select-label'>
            {i18n._('results.select', { max: MAX_RESULTS_FOR_COMPARE - 1 })}
          </p>
        </div>
      </Collapse>
      <Select
        className='compared-results-select'
        isSearchable={false}
        value={selectedOption}
        menuIsOpen={isMenuOpen}
        openMenuOnClick={false}
        options={groupedOptions}
        styles={reactSelectStyles}
        components={{ SingleValue, GroupHeading, Option }}
        onInputChange={handleInputChange}
      />
      <DeleteModal
        isOpen={isModalOpen}
        groupedOptions={groupedOptions}
        selectedResultId={selectedResultId}
        onResultsDelete={onResultsDelete}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
  //#endregion
};

export default ComparedResultsSelect;
