// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-params-inputs-table {
  width: 100%;
}

.results-params-inputs-table tr {
  font-size: 15px;
  height: 30px;
}

.results-params-inputs-table tr:first-of-type td {
  border-top: 1px solid var(--border-color);
}

.results-params-inputs-table tr:last-of-type td {
  border-bottom: 1px solid var(--border-color);
}

.results-params-inputs-table tr td:first-of-type {
  border-left: 1px solid var(--border-color);
}

.results-params-inputs-table tr td:last-of-type {
  border-right: 1px solid var(--border-color);
}

.results-params-inputs-table tr td:first-of-type {
  padding-left: 10px;
}

.results-params-inputs-table tr td:last-of-type {
  text-align: end;
  padding-right: 10px;
}

.results-params-inputs-table tr:nth-of-type(2n) {
  background-color: white;
  color: black;
}

.results-params-inputs-table tr:nth-of-type(2n + 1) {
  background-color: rgb(235, 235, 243);
  color: black;
}

.results-params-inputs-btn {
  font-size: 15px;
  margin: 5px 0 10px 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/result/sections/simulationParams/components/RecursiveTable/InputsParamsTable.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,YAAY;AACd;;AAEA;EACE,eAAe;EACf,oBAAoB;AACtB","sourcesContent":[".results-params-inputs-table {\n  width: 100%;\n}\n\n.results-params-inputs-table tr {\n  font-size: 15px;\n  height: 30px;\n}\n\n.results-params-inputs-table tr:first-of-type td {\n  border-top: 1px solid var(--border-color);\n}\n\n.results-params-inputs-table tr:last-of-type td {\n  border-bottom: 1px solid var(--border-color);\n}\n\n.results-params-inputs-table tr td:first-of-type {\n  border-left: 1px solid var(--border-color);\n}\n\n.results-params-inputs-table tr td:last-of-type {\n  border-right: 1px solid var(--border-color);\n}\n\n.results-params-inputs-table tr td:first-of-type {\n  padding-left: 10px;\n}\n\n.results-params-inputs-table tr td:last-of-type {\n  text-align: end;\n  padding-right: 10px;\n}\n\n.results-params-inputs-table tr:nth-of-type(2n) {\n  background-color: white;\n  color: black;\n}\n\n.results-params-inputs-table tr:nth-of-type(2n + 1) {\n  background-color: rgb(235, 235, 243);\n  color: black;\n}\n\n.results-params-inputs-btn {\n  font-size: 15px;\n  margin: 5px 0 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
