// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-wrapper {
  border: 1px solid rgb(236, 237, 236);
  border-radius: 5px;
  overflow: hidden;
  margin: 10px 0;
  -webkit-box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);
}

.card-title {
  margin: 0px 0 0 0px;
  padding: 15px 0 15px 11px;
  background-color: rgb(239, 239, 239);
  border-bottom: 1px solid rgb(201, 201, 201);
  font-size: 17px;
  font-weight: bold;
}

.card-content {
  padding: 5px 11px;
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/Card/Card.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;EACd,wDAAwD;EACxD,gDAAgD;AAClD;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,oCAAoC;EACpC,2CAA2C;EAC3C,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,uBAAuB;AACzB","sourcesContent":[".card-wrapper {\n  border: 1px solid rgb(236, 237, 236);\n  border-radius: 5px;\n  overflow: hidden;\n  margin: 10px 0;\n  -webkit-box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);\n  box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);\n}\n\n.card-title {\n  margin: 0px 0 0 0px;\n  padding: 15px 0 15px 11px;\n  background-color: rgb(239, 239, 239);\n  border-bottom: 1px solid rgb(201, 201, 201);\n  font-size: 17px;\n  font-weight: bold;\n}\n\n.card-content {\n  padding: 5px 11px;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
