import { useLingui } from '@lingui/react';
import React from 'react';
import { HPG } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import EditTable from '../../../../components/EditTable/EditTable';

const HpgBloc = ({ form, onTableValueChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('cost.hpg')} level={2}>
      <div>
        <span className='param-input-label'>
          {i18n._('cost.InitHPGCostLabel')}
        </span>
        <EditTable
          titles={[
            i18n._('cost.InitHPGCostSlices'),
            i18n._('cost.InitHPGCost')
          ]}
          values={[form.InitHPGCost]}
          params={[HPG.InitHPGCost]}
          onChange={onTableValueChange}
        />
      </div>
    </Bloc>
  );
  //#endregion
};

export default HpgBloc;
