import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import {
  HP_TYPE,
  SRC_EXCHANGE
} from '../../../../../../../../server/constants';
import {
  getEx4x0Params,
  getExchangersParams
} from '../../../../../../../../server/models/config/thermalProduction/geostorage/hydraulic.model';
import Section from '../../../../../../components/Section/Section';
import Table from '../../../../../../components/Table/Table';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import {
  hasCapture,
  hasInjection
} from '../../../../../../utils/heatpump.utils';

const ExchangersSection = ({ onFormChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const handleFormCheck = (evt) => {
    const { checked, value } = evt.target;
    config.ConfigsSst[0].Data.geostorage.exchangers[value] = checked;
    onFormChange(config);
  };
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    const { heatpumps, geostorage } = config.ConfigsSst[0].Data;
    const { settings, exchangers } = geostorage;

    // on construit les params des échangeurs
    const ex4x0Params = getEx4x0Params(settings.source.type);
    const exchangersParams = getExchangersParams(
      exchangers.Ex400,
      exchangers.Ex410
    );
    const EXCHANGER = { ...ex4x0Params, ...exchangersParams };

    // on parcourt la liste des sources et on filtre les échangeurs en fonction
    const exchange = [];
    if (hasCapture(heatpumps.list)) exchange.push(SRC_EXCHANGE.CAPTURE);
    if (
      hasInjection(
        heatpumps.list,
        !!heatpumps.settings[HP_TYPE.AERO]?.btesInjection
      )
    )
      exchange.push(SRC_EXCHANGE.INJECTION);
    const entries = exchange.reduce((acc, src) => {
      Object.entries(EXCHANGER)
        .filter(([_, exchangeObj]) => exchangeObj[src])
        .forEach(([key, exchangeObj]) => {
          acc[key] = acc[key] || {};
          acc[key][src] = exchangeObj[src];
        });
      return acc;
    }, {});
    const colsNames = Object.keys(entries).map((colName) =>
      i18n._(`hydraulicConfig.table.th.${colName}`)
    );
    const header = [''].concat(colsNames);
    const firstCol = exchange.map((src) => i18n._(`src.exchange.${src}`));
    const exchangersCols = Object.values(entries).map(
      (equipmentPerSrcExchange) => {
        return Object.values(equipmentPerSrcExchange).map(
          (equipment, index) => {
            const checked = exchangers[equipment.value];
            const warning = checked !== equipment.default;
            const checkboxId = 'geo_ex_' + index + equipment.value;
            return (
              <div
                data-tooltip-content={
                  warning
                    ? checked
                      ? i18n._('equipment.notChecked.warning')
                      : i18n._('equipment.checked.warning')
                    : null
                }
                data-tooltip-id={'error_' + checkboxId}
                style={{ width: 'fit-content' }}
                key={checkboxId}
              >
                <Form.Check
                  type='checkbox'
                  label={equipment.value}
                  value={equipment.value}
                  onChange={handleFormCheck}
                  checked={checked}
                  className={warning ? 'warning' : ''}
                  id={'geo_ex_' + equipment.value}
                />
                {warning &&
                  createPortal(
                    <Tooltip
                      id={'error_' + checkboxId}
                      place='bottom'
                      className='error-tooltip'
                      arrowColor='#f04460'
                      opacity={1}
                    />,
                    document.body
                  )}
              </div>
            );
          }
        );
      }
    );
    const cols = [firstCol].concat(exchangersCols);
    return { header, cols };
  }, [config]);
  //#endregion

  //#region [render]
  return (
    <Section
      title={i18n._('config.geostorage.hydraulic.exchangers')}
      level={2}
      open
    >
      <Table header={data.header} cols={data.cols} />
    </Section>
  );
  //#endregion
};

export default ExchangersSection;
