import { useLingui } from '@lingui/react';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  getCompareResultName,
  getResultFullName
} from '../../utils/compute.utils';
import './ResultsElements.css';

const ResultsElements = ({ element, elementProps, comparedResults }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [visibilities, setVisibilities] = useState(
    [true].concat(new Array(comparedResults.length - 1).fill(false))
  );
  //#endregion

  //#region [methods]
  const handleBtnClick = (index) => {
    setVisibilities((visibilities) => {
      visibilities[index] = !visibilities[index];
      return [...visibilities];
    });
  };
  //#endregion

  //#region [render]
  let Element = element;
  return (
    <div>
      {comparedResults.length > 1 && (
        <div className='results-btns'>
          {comparedResults.map((_, index) => (
            <Button
              key={'compare_results_btn_' + index}
              onClick={() => handleBtnClick(index)}
              variant={visibilities[index] ? 'primary' : 'outline-secondary'}
              disabled={
                visibilities[index] &&
                visibilities.filter((v) => v).length === 1
              }
            >
              {i18n._('result', { index: index + 1 })}
            </Button>
          ))}
        </div>
      )}
      {comparedResults.map((result, index) => {
        const resultName = getResultFullName(i18n, result);
        return visibilities[index] ? (
          <div key={'compare_result_element_' + resultName + '_' + index}>
            {comparedResults.length > 1 && (
              <p className='results-title' title={resultName}>
                {getCompareResultName(i18n, result.Comment, index)}
              </p>
            )}
            <Element {...elementProps} result={result} />
          </div>
        ) : null;
      })}
    </div>
  );
  //#endregion
};

export default ResultsElements;
