// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-summary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 20px;
  width: 50%;
  padding: 0px 15px 15px 10px;
  border-radius: 5px;
  border: 1px solid rgb(236, 237, 236);
  -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.project-summary .custom-table {
  min-width: 400px;
}

@media (max-width: 1007px) {
  .project-summary {
    width: 100%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/ProjectSummary/ProjectSummary.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,4BAA4B;EAC5B,6BAA6B;MACzB,0BAA0B;UACtB,sBAAsB;EAC9B,SAAS;EACT,UAAU;EACV,2BAA2B;EAC3B,kBAAkB;EAClB,oCAAoC;EACpC,sDAAsD;EACtD,8CAA8C;EAC9C,2BAA2B;EAC3B,wBAAwB;EACxB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,WAAW;IACX,4BAA4B;QACxB,iBAAiB;YACb,QAAQ;EAClB;AACF","sourcesContent":[".project-summary {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  gap: 20px;\n  width: 50%;\n  padding: 0px 15px 15px 10px;\n  border-radius: 5px;\n  border: 1px solid rgb(236, 237, 236);\n  -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);\n  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);\n  height: -webkit-fit-content;\n  height: -moz-fit-content;\n  height: fit-content;\n}\n\n.project-summary .custom-table {\n  min-width: 400px;\n}\n\n@media (max-width: 1007px) {\n  .project-summary {\n    width: 100%;\n    -webkit-box-ordinal-group: 3;\n        -ms-flex-order: 2;\n            order: 2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
