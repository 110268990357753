const {
  DST_TEMP_DELTA,
  HP_TYPE,
  HEAT_TRANSFER_FLUID,
  NEED,
  UNIT
} = require('../../../../constants');
const { getDefaultValues } = require('../../../../utils/param.utils');

const HP_HEAT_TRANSFER_FLUID = Object.freeze({
  heatTransferFluid: {
    key: 'heatTransferFluid',
    required: true,
    default: HEAT_TRANSFER_FLUID.GLYCOL,
    values: Object.values(HEAT_TRANSFER_FLUID),
    translateValuesIds: Object.values(HEAT_TRANSFER_FLUID).map(
      (fluid) => `heatTransferFluid.${fluid.toLowerCase()}`
    ) // TODO côté front
  }
});

const HPG_P_HEAT = Object.freeze({
  pHeat: {
    key: 'pHeat',
    required: true,
    default: null,
    unit: UNIT.KILOWATT
  }
});

const HPG_P_COLD = Object.freeze({
  pCold: {
    key: 'pCold',
    required: true,
    default: null,
    unit: UNIT.KILOWATT
  }
});

const HPA_P_HEAT = Object.freeze({
  pHeat: {
    key: 'pHeat',
    required: true,
    default: null,
    unit: UNIT.KILOWATT
  }
});

const HPA_P_COLD = Object.freeze({
  pCold: {
    key: 'pCold',
    required: true,
    default: null,
    unit: UNIT.KILOWATT
  }
});

const HPA_BTES = Object.freeze({
  btesInjection: {
    key: 'btesInjection',
    default: true
  }
});

const getDefaultHPGSettingsValues = (needs) => {
  let hpgDefaultValues = {};
  if (needs.includes(NEED.HEAT) || needs.includes(NEED.DHW)) {
    hpgDefaultValues = { ...getDefaultValues(HPG_P_HEAT) };
  }
  if (needs.includes(NEED.COLD)) {
    hpgDefaultValues = { ...hpgDefaultValues, ...getDefaultValues(HPG_P_COLD) };
  }
  return hpgDefaultValues;
};

const getDefaultHPASettingsValues = (needs) => {
  let hpaDefaultValues = {};
  if (needs.includes(NEED.HEAT) || needs.includes(NEED.DHW)) {
    hpaDefaultValues = { ...getDefaultValues(HPA_P_HEAT) };
  }
  if (needs.includes(NEED.COLD)) {
    hpaDefaultValues = { ...hpaDefaultValues, ...getDefaultValues(HPA_P_COLD) };
  }
  return { ...hpaDefaultValues, ...getDefaultValues(HPA_BTES) };
};

const getDefaultSettingsValues = (needs) => {
  return {
    [HP_TYPE.GEO]: { ...getDefaultHPGSettingsValues(needs) },
    [HP_TYPE.AERO]: { ...getDefaultHPASettingsValues(needs) }
  };
};

// PAC aéro
const REGIMES_HEAT_HPA = Object.freeze({
  outsideTemp: {
    key: 'outsideTemp',
    required: true,
    default: -7,
    unit: UNIT.CELSIUS_DEGREE
  }
});

const REGIMES_COLD_HPA = Object.freeze({
  outsideTemp: {
    key: 'outsideTemp',
    required: true,
    default: 30,
    unit: UNIT.CELSIUS_DEGREE
  }
});

const REGIMES_DHW_HPA = Object.freeze({
  outsideTemp: {
    key: 'outsideTemp',
    required: true,
    default: -7,
    unit: UNIT.CELSIUS_DEGREE
  }
});

const REGIMES_HPA = Object.freeze({
  [NEED.HEAT]: REGIMES_HEAT_HPA,
  [NEED.COLD]: REGIMES_COLD_HPA,
  [NEED.DHW]: REGIMES_DHW_HPA
});

// PAC géo, côté BTES
const REGIMES_HEAT_HPG_BTES = Object.freeze({
  srcTemp: {
    key: 'srcTemp',
    required: true,
    default: 1,
    unit: UNIT.CELSIUS_DEGREE
  },
  srcTempDelta: {
    key: 'srcTempDelta',
    required: true,
    default: 4,
    unit: UNIT.KELVIN
  }
});

const REGIMES_COLD_HPG_BTES = Object.freeze({
  srcTemp: {
    key: 'srcTemp',
    required: true,
    default: 30,
    unit: UNIT.CELSIUS_DEGREE
  },
  srcTempDelta: {
    key: 'srcTempDelta',
    required: true,
    default: 5,
    unit: UNIT.KELVIN
  }
});

const REGIMES_DHW_HPG_BTES = Object.freeze({
  srcTemp: {
    key: 'srcTemp',
    required: true,
    default: 1,
    unit: UNIT.CELSIUS_DEGREE
  },
  srcTempDelta: {
    key: 'srcTempDelta',
    required: true,
    default: 4,
    unit: UNIT.KELVIN
  }
});

const REGIMES_HPG_BTES = Object.freeze({
  [NEED.HEAT]: REGIMES_HEAT_HPG_BTES,
  [NEED.COLD]: REGIMES_COLD_HPG_BTES,
  [NEED.DHW]: REGIMES_DHW_HPG_BTES
});

const getDefaultRegimesValues = (need, services) => {
  // PAC géo, côté BTES
  const srcTemp = REGIMES_HPG_BTES[need].srcTemp.default;
  const srcTempDelta = REGIMES_HPG_BTES[need].srcTempDelta.default;
  const srcTempOut =
    need === NEED.COLD ? srcTemp + srcTempDelta : srcTemp - srcTempDelta;

  // Distribution bâtiment
  const { temperature, temperatureBack } = services[need];

  // PAC aéro/géo, côté production
  let dstTempIn, dstTemp, dstTempDelta;
  if (need === NEED.COLD) {
    dstTempIn = temperatureBack - 1;
    dstTemp = temperature - 1;
    dstTempDelta = temperatureBack - temperature;
  } else if (need === NEED.HEAT) {
    dstTempIn = temperatureBack + 1;
    dstTemp = temperature + 1;
    dstTempDelta = temperature - temperatureBack;
  } else if (need === NEED.DHW) {
    dstTemp = temperature + 1;
    dstTempDelta = DST_TEMP_DELTA;
    dstTempIn = dstTemp - dstTempDelta;
  }

  return {
    [HP_TYPE.GEO]: { srcTemp, srcTempOut, srcTempDelta },
    [HP_TYPE.AERO]: { ...getDefaultValues(REGIMES_HPA[need]) },
    hpProd: { dstTempIn, dstTemp, dstTempDelta },
    buildingDistribution: { temperature, temperatureBack }
  };
};

const getDefaultHpsSettingsValues = (services) => {
  const regimes = services.needs.reduce((acc, need) => {
    acc[need] = {
      ...getDefaultRegimesValues(need, services)
    };
    return acc;
  }, {});

  return {
    types: [HP_TYPE.AERO, HP_TYPE.GEO],
    heatTransferFluid: HP_HEAT_TRANSFER_FLUID.heatTransferFluid.default,
    regimes,
    ...getDefaultSettingsValues(services.needs)
  };
};

module.exports = {
  HP_HEAT_TRANSFER_FLUID,
  HPG_P_HEAT,
  HPG_P_COLD,
  HPA_P_HEAT,
  HPA_P_COLD,
  HPA_BTES,
  REGIMES_HPA,
  REGIMES_HPG_BTES,
  getDefaultSettingsValues,
  getDefaultRegimesValues,
  getDefaultHpsSettingsValues
};
