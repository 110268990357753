import { useLingui } from '@lingui/react';
import React from 'react';
import ErrorBadge from '../../../../../../components/ErrorBadge/ErrorBadge';
import { formatValue } from '../../../../../../utils/data.utils';

const CapacityCellRenderer = ({ data, value, need, modalTarget }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  const badgeId = data.manufacturer + data.model;
  return value ? (
    formatValue(value, 0) + ' kW'
  ) : (
    <ErrorBadge
      tooltipContent={data.capacity[need].error}
      tooltipId={badgeId}
      portalContainer={document.getElementsByClassName(modalTarget)[0]}
    >
      {i18n._('error')}
    </ErrorBadge>
  );
  //#endregion
};

export default CapacityCellRenderer;
