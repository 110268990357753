// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-charts {
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 20px;
  padding: 10px 15px;
  border: 1px solid rgb(216, 216, 216);
  border-radius: 5px;
}

.results-charts .card-content {
  position: relative;
  padding-right: 20px;
}

.results-charts > button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 15px;
  -ms-flex-item-align: end;
      align-self: flex-end;
}

.results-charts > button svg {
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/result/sections/summary/components/ResultsCharts/ResultsCharts.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,4BAA4B;EAC5B,6BAA6B;MACzB,0BAA0B;UACtB,sBAAsB;EAC9B,SAAS;EACT,kBAAkB;EAClB,oCAAoC;EACpC,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;EAC1B,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;EACf,wBAAwB;MACpB,oBAAoB;AAC1B;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".results-charts {\n  background-color: white;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  gap: 20px;\n  padding: 10px 15px;\n  border: 1px solid rgb(216, 216, 216);\n  border-radius: 5px;\n}\n\n.results-charts .card-content {\n  position: relative;\n  padding-right: 20px;\n}\n\n.results-charts > button {\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n  font-size: 15px;\n  -ms-flex-item-align: end;\n      align-self: flex-end;\n}\n\n.results-charts > button svg {\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
