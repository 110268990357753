import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import { OPEX_TYPES } from '../../../../../../../../server/constants';
import TwoWayTable from '../../../../../../components/TwoWayTable/TwoWayTable';
import {
  formatValue,
  isArrNullOrEmpty
} from '../../../../../../utils/data.utils';

const DetailedOpexTable = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    const opexObjects = OPEX_TYPES.filter(
      (type) => !isArrNullOrEmpty(result.ComputeResult.summary[type])
    ).map((type) => ({
      type,
      values: result.ComputeResult.summary[type]
    }));
    if (isArrNullOrEmpty(opexObjects)) return null;

    const { InitYearsSimulations } = result.ComputeResult.inp;
    const horizontalTitles = [i18n._('results.average')].concat(
      Array.from({ length: InitYearsSimulations }, (_, i) =>
        i18n._('results.year', {
          year: i + 1
        })
      )
    );
    const verticalTitles = [];
    const values = [];
    opexObjects.forEach((opexObject) => {
      verticalTitles.push(
        i18n._(`results.economicAnalysis.opexType.${opexObject.type}`)
      );
      const average =
        opexObject.values.reduce((acc, curr) => acc + curr, 0) /
        InitYearsSimulations;
      values.push(
        [average, ...opexObject.values].map((value) => formatValue(value))
      );
    });

    return {
      horizontalTitles,
      verticalTitles,
      values
    };
  }, [result.ComputeID]);
  //#endregion

  //#region [render]
  return data ? (
    <TwoWayTable
      horizontalTitles={data.horizontalTitles}
      verticalTitles={data.verticalTitles}
      values={data.values}
    />
  ) : (
    <Alert variant='info'>{i18n._('results.noData')}</Alert>
  );
  //#endregion
};

export default DetailedOpexTable;
