import { UNIT } from '../../../../../../../server/constants';
import { getSafeData } from '../../../../../utils/data.utils';

export const summaryTemplate = (i18n, results) => {
  const { inp } = results[0].ComputeResult;
  let gasBoilerLabel = '';
  if (!inp.InitGasEnabled) {
    gasBoilerLabel = i18n._('results.sizing.hpa.gasPThMax.gasBoilerPower');
  } else {
    gasBoilerLabel = inp.InitReleveEnabled
      ? i18n._('results.sizing.hpa.gasPThMax.backupGasBoilerPower')
      : i18n._('results.sizing.hpa.gasPThMax.auxiliaryGasBoilerPower');
  }

  const template = {
    title: i18n._('results.summary.sizing'),
    rows: []
  };

  const someValue = (path, key) => {
    return results.some(
      (result) => getSafeData(() => result.ComputeResult[path][key]) !== 0
    );
  };

  const notDisplayedIfZero = [
    { key: 'BtesLength', path: 'params', unit: UNIT.METER },
    { key: 'TotalHPGPthHeatNormMax', path: 'summary', unit: UNIT.KILOWATT },
    { key: 'TotalHPGPthColdNormMax', path: 'summary', unit: UNIT.KILOWATT },
    { key: 'TotalHPAPthHeatNormMax', path: 'summary', unit: UNIT.KILOWATT },
    { key: 'TotalHPAPthColdNormMax', path: 'summary', unit: UNIT.KILOWATT },
    {
      key: 'TotalGasPThMax',
      path: 'summary',
      unit: UNIT.KILOWATT,
      label: gasBoilerLabel
    },
    { key: 'SolarThermalSurface', path: 'params', unit: UNIT.SQUARE_METER },
    { key: 'KiloWattCretePV', path: 'params', unit: UNIT.KILOWATT_PEAK },
    { key: 'TotalQItesMax', path: 'summary', unit: UNIT.KILOWATT_HOUR },
    { key: 'PartHpgTfp', path: 'summary', unit: UNIT.PERCENT }
  ];

  notDisplayedIfZero
    .filter((row) => someValue(row.path, row.key))
    .forEach((row) => {
      template.rows.push({
        label: row.label ?? i18n._(`results.summary.${row.key}`),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult[row.path][row.key])
        ),
        unit: row.unit
      });
    });

  const getValuesClassNames = (values, descending) => {
    if (values.length === 1) return [''];
    const valuesAndLabels = {};
    values.forEach((value) => {
      if (!valuesAndLabels.hasOwnProperty(value)) valuesAndLabels[value] = '';
    });
    const nbDifferentValues = Object.keys(valuesAndLabels).length;
    let classNames;
    switch (nbDifferentValues) {
      case 1:
        classNames = [''];
        break;
      case 2:
        classNames = ['worst', 'best'];
        break;
      case 3:
        classNames = ['worst', 'middle', 'best'];
        break;
      case 4:
        classNames = ['worst', 'bad', 'good', 'best'];
        break;
      case 5:
        classNames = ['worst', 'bad', 'middle', 'good', 'best'];
        break;
    }
    Object.keys(valuesAndLabels)
      .sort((a, b) => (descending ? b - a : a - b))
      .forEach((value, index) => {
        valuesAndLabels[value] = classNames[index];
      });
    return values.map((value) => valuesAndLabels[value]);
  };

  template.rows = [
    ...template.rows,
    {
      label: i18n._('results.summary.QBtesExtractMaxPerMeter'),
      values: results.map((result) =>
        getSafeData(() => result.ComputeResult.summary.QBtesExtractMaxPerMeter)
      ),
      unit: UNIT.WATT_PER_METER
    },
    {
      label: i18n._('results.summary.QBtesInjectMaxPerMeter'),
      values: results.map((result) =>
        getSafeData(() => result.ComputeResult.summary.QBtesInjectMaxPerMeter)
      ),
      unit: UNIT.WATT_PER_METER
    },
    {
      label: i18n._('results.summary.BtesT2Final'),
      values: results.map((result) =>
        getSafeData(() => result.ComputeResult.summary.BtesT2Final)
      ),
      unit: UNIT.CELSIUS_DEGREE
    },
    {
      label: i18n._('results.summary.EnergyFinal'),
      values: results.map((result) =>
        getSafeData(() => result.ComputeResult.summary.EnergyFinal)
      ),
      valuesClassNames: getValuesClassNames(
        results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.EnergyFinal)
        ),
        true
      ),
      unit: UNIT.KILOWATT_HOUR
    },
    {
      label: i18n._('results.summary.COPs.Global.Mean'),
      tooltipInfo: i18n._('results.summary.COPs.Global.Mean.info'),
      values: results.map((result) =>
        getSafeData(() => result.ComputeResult.summary.COPs.Global.Mean)
      ),
      valuesClassNames: getValuesClassNames(
        results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.COPs.Global.Mean)
        )
      ),
      precision: 2
    },
    {
      label: i18n._('results.summary.OverallYield'),
      values: results.map((result) =>
        getSafeData(() => result.ComputeResult.summary.OverallYield)
      ),
      valuesClassNames: getValuesClassNames(
        results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.OverallYield)
        )
      ),
      unit: UNIT.PERCENT
    },
    {
      label: i18n._('results.summary.TotalAvecFondChaleur'),
      values: results.map((result) =>
        getSafeData(
          () => result.ComputeResult.summary.CAPEX.TotalAvecFondChaleur
        )
      ),
      valuesClassNames: getValuesClassNames(
        results.map((result) =>
          getSafeData(
            () => result.ComputeResult.summary.CAPEX.TotalAvecFondChaleur
          )
        ),
        true
      ),
      unit: UNIT.EURO
    },
    {
      label: i18n._('results.summary.totalCapexOpex', {
        years: results[0].ComputeResult.inp.InitBalanceDuration
      }),
      values: results.map((result) =>
        getSafeData(() => result.ComputeResult.summary.TotalCost)
      ),
      valuesClassNames: getValuesClassNames(
        results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.TotalCost)
        ),
        true
      ),
      unit: UNIT.EURO
    }
  ];

  return template;
};
