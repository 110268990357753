import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { OTHER_OPEX_ACCENTA } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../constants';
import { getDefaultValue } from '../../../../utils/param.utils';

const SolutionOtherOpexBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('cost.solutionOtherOpex')} level={2}>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitOpexGaPerf')}
            value={form.InitOpexGaPerf}
            param={OTHER_OPEX_ACCENTA.InitOpexGaPerf}
            onChange={(value) => onInputChange('InitOpexGaPerf', value)}
            unit
            bottomText={getDefaultValue(
              i18n,
              OTHER_OPEX_ACCENTA.InitOpexGaPerf
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitOpexGaPerfEvol')}
            value={form.InitOpexGaPerfEvol}
            param={OTHER_OPEX_ACCENTA.InitOpexGaPerfEvol}
            onChange={(value) => onInputChange('InitOpexGaPerfEvol', value)}
            unit
            bottomText={getDefaultValue(
              i18n,
              OTHER_OPEX_ACCENTA.InitOpexGaPerfEvol
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitOpexMaint')}
            value={form.InitOpexMaint}
            param={OTHER_OPEX_ACCENTA.InitOpexMaint}
            onChange={(value) => onInputChange('InitOpexMaint', value)}
            unit
            bottomText={getDefaultValue(i18n, OTHER_OPEX_ACCENTA.InitOpexMaint)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitOpexMaintEvol')}
            value={form.InitOpexMaintEvol}
            param={OTHER_OPEX_ACCENTA.InitOpexMaintEvol}
            onChange={(value) => onInputChange('InitOpexMaintEvol', value)}
            unit
            bottomText={getDefaultValue(
              i18n,
              OTHER_OPEX_ACCENTA.InitOpexMaintEvol
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitOpexOther')}
            value={form.InitOpexOther}
            param={OTHER_OPEX_ACCENTA.InitOpexOther}
            onChange={(value) => onInputChange('InitOpexOther', value)}
            unit
            bottomText={getDefaultValue(i18n, OTHER_OPEX_ACCENTA.InitOpexOther)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitOpexOtherEvol')}
            value={form.InitOpexOtherEvol}
            param={OTHER_OPEX_ACCENTA.InitOpexOtherEvol}
            onChange={(value) => onInputChange('InitOpexOtherEvol', value)}
            unit
            bottomText={getDefaultValue(
              i18n,
              OTHER_OPEX_ACCENTA.InitOpexOtherEvol
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <ParamInput
        label={i18n._('cost.InitYearsBeforeStart')}
        value={form.InitYearsBeforeStart}
        param={OTHER_OPEX_ACCENTA.InitYearsBeforeStart}
        onChange={(value) => onInputChange('InitYearsBeforeStart', value)}
        unit
        bottomText={getDefaultValue(
          i18n,
          OTHER_OPEX_ACCENTA.InitYearsBeforeStart
        )}
        type={INPUT_TYPE.NUMBER}
      />
    </Bloc>
  );
  //#endregion
};

export default SolutionOtherOpexBloc;
