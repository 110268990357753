import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Button } from 'react-bootstrap';
import './Pagination.css';

const Pagination = ({
  canPreviousPage,
  canNextPage,
  nbPages,
  pageIndex,
  gotoPage,
  previousPage,
  nextPage
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <div className='pagination'>
      <Button
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
        variant='outline-secondary'
      >
        <FontAwesomeIcon icon='backward-step' />
      </Button>
      <Button
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
        variant='outline-secondary'
      >
        <FontAwesomeIcon icon='chevron-left' />
      </Button>
      <span>{i18n._('pagination', { pageIndex: pageIndex + 1, nbPages })}</span>
      <Button
        onClick={() => nextPage()}
        disabled={!canNextPage}
        variant='outline-secondary'
      >
        <FontAwesomeIcon icon='chevron-right' />
      </Button>
      <Button
        onClick={() => gotoPage(nbPages - 1)}
        disabled={!canNextPage}
        variant='outline-secondary'
      >
        <FontAwesomeIcon icon='forward-step' />
      </Button>
    </div>
  );
  //#endregion
};

export default Pagination;
