// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section {
  width: 100%;
}

.section .section-title {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.section :is(h1, h2, h3, h4, h5, h6),
.section .section-title svg {
  color: black;
}

.section .section-title .fa-caret-down,
.section .section-title .fa-caret-right {
  margin-right: 10px;
}

.section .section-title .fa-pen-to-square {
  margin-left: 18px;
  margin-bottom: 2px;
}

.section-content {
  padding-top: 5px;
  padding-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Section/Section.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,0BAA0B;EAC1B,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;;EAEE,YAAY;AACd;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".section {\n  width: 100%;\n}\n\n.section .section-title {\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n}\n\n.section :is(h1, h2, h3, h4, h5, h6),\n.section .section-title svg {\n  color: black;\n}\n\n.section .section-title .fa-caret-down,\n.section .section-title .fa-caret-right {\n  margin-right: 10px;\n}\n\n.section .section-title .fa-pen-to-square {\n  margin-left: 18px;\n  margin-bottom: 2px;\n}\n\n.section-content {\n  padding-top: 5px;\n  padding-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
