import React from 'react';

const defaultState = {
  openToast: () => {},
  openErrorToast: () => {},
  openInfoModal: () => {},
  openConfirmModal: () => {},
  openDirtyFormModal: () => {}
};

const PopupContext = React.createContext(defaultState);
export default PopupContext;
