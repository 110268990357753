import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { OTHER_OPEX_REF } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../constants';
import { getDefaultValue } from '../../../../utils/param.utils';

const ReferenceOtherOpex = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('cost.referenceOtherOpex')} level={2}>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitRefOpexGaPerf')}
            value={form.InitRefOpexGaPerf}
            param={OTHER_OPEX_REF.InitRefOpexGaPerf}
            onChange={(value) => onInputChange('InitRefOpexGaPerf', value)}
            unit
            bottomText={getDefaultValue(i18n, OTHER_OPEX_REF.InitRefOpexGaPerf)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitRefOpexGaPerfEvol')}
            value={form.InitRefOpexGaPerfEvol}
            param={OTHER_OPEX_REF.InitRefOpexGaPerfEvol}
            onChange={(value) => onInputChange('InitRefOpexGaPerfEvol', value)}
            unit
            bottomText={getDefaultValue(
              i18n,
              OTHER_OPEX_REF.InitRefOpexGaPerfEvol
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitRefOpexMaint')}
            value={form.InitRefOpexMaint}
            param={OTHER_OPEX_REF.InitRefOpexMaint}
            onChange={(value) => onInputChange('InitRefOpexMaint', value)}
            unit
            bottomText={getDefaultValue(i18n, OTHER_OPEX_REF.InitRefOpexMaint)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitRefOpexMaintEvol')}
            value={form.InitRefOpexMaintEvol}
            param={OTHER_OPEX_REF.InitRefOpexMaintEvol}
            onChange={(value) => onInputChange('InitRefOpexMaintEvol', value)}
            unit
            bottomText={getDefaultValue(
              i18n,
              OTHER_OPEX_REF.InitRefOpexMaintEvol
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitRefOpexOther')}
            value={form.InitRefOpexOther}
            param={OTHER_OPEX_REF.InitRefOpexOther}
            onChange={(value) => onInputChange('InitRefOpexOther', value)}
            unit
            bottomText={getDefaultValue(i18n, OTHER_OPEX_REF.InitRefOpexOther)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitRefOpexOtherEvol')}
            value={form.InitRefOpexOtherEvol}
            param={OTHER_OPEX_REF.InitRefOpexOtherEvol}
            onChange={(value) => onInputChange('InitRefOpexOtherEvol', value)}
            unit
            bottomText={getDefaultValue(
              i18n,
              OTHER_OPEX_REF.InitRefOpexOtherEvol
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default ReferenceOtherOpex;
