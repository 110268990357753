const { AHS_TYPE, COUNTRY, UNIT, USAGE } = require('../../constants');

const GENERAL = Object.freeze({
  AhsName: {
    key: 'AhsName',
    type: 'string',
    required: true,
    default: null,
    maxLength: 255
  },
  AhsDescription: {
    key: 'AhsDescription',
    type: 'string',
    default: null,
    maxLength: 800
  },
  InitBuildingSurface: {
    key: 'InitBuildingSurface',
    type: 'number',
    required: true,
    min: 100,
    max: 1000000,
    default: null,
    unit: UNIT.SQUARE_METER
  },
  AhsType: {
    key: 'AhsType',
    required: true,
    default: AHS_TYPE.CONSTRUCTION,
    type: 'string',
    values: Object.values(AHS_TYPE)
  },
  AhsMainUsage: {
    key: 'AhsMainUsage',
    required: true,
    default: USAGE.TERTIARY,
    type: 'string',
    values: Object.values(USAGE),
    translateValuesIds: Object.values(USAGE).map((usage) => `usage.${usage}`) // TODO côté front
  },
  ImageFileID: {
    key: 'ImageFileID',
    default: null,
    type: 'string',
    pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
  }
});

const LOCATION = Object.freeze({
  AhsAddress1: {
    key: 'AhsAddress1',
    type: 'string',
    required: true,
    minLength: 5,
    default: null,
    maxLength: 255
  },
  AhsZipCode: {
    key: 'AhsZipCode',
    type: 'string',
    required: true,
    pattern: /^[0-9]{5}$/,
    default: null
  },
  AhsCity: {
    key: 'AhsCity',
    type: 'string',
    required: true,
    minLength: 3,
    default: null,
    maxLength: 255
  },
  AhsCountry: {
    key: 'AhsCountry',
    required: true,
    default: 'FR',
    type: 'string',
    values: Object.keys(COUNTRY),
    translateValuesIds: Object.keys(COUNTRY).map(
      (country) => `country.${country}`
    ) // TODO côté front
  },
  InitLatitude: {
    key: 'InitLatitude',
    type: 'string',
    required: true,
    pattern:
      /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/,
    default: null
  },
  InitLongitude: {
    key: 'InitLongitude',
    type: 'string',
    required: true,
    pattern:
      /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/,
    default: null
  },
  InitAltitude: {
    key: 'InitAltitude',
    type: 'number',
    required: true,
    min: 0,
    max: 8850,
    default: 0,
    unit: UNIT.METER
  }
});

const GENERAL_PARAMS = [...Object.values(GENERAL), ...Object.values(LOCATION)];

module.exports = { GENERAL, LOCATION, GENERAL_PARAMS };
