// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.segments-headLoss-section .custom-table th,
.segments-headLoss-section .custom-table td {
  text-align: left;
}

.segments-headLoss-section .custom-table tr {
  height: 45px;
}

.segments-headLoss-section .custom-table tr td:first-of-type {
  font-weight: 600;
}

.geostorage-edit-fittings-btn {
  font-size: 14px;
  width: 100%;
  padding-top: 3px;
  padding-bottom: 3px;
}

.segments-headLoss-section .custom-table th:nth-of-type(2),
.segments-headLoss-section .custom-table td:nth-of-type(2),
.segments-headLoss-section .custom-table th:nth-of-type(5),
.segments-headLoss-section .custom-table td:nth-of-type(5) {
  width: 100px;
  min-width: 100px;
}

.segments-headLoss-section .custom-table th:nth-of-type(4),
.segments-headLoss-section .custom-table td:nth-of-type(4) {
  width: 130px;
  min-width: 130px;
}

.segments-headLoss-section .custom-table th:nth-of-type(7),
.segments-headLoss-section .custom-table td:nth-of-type(7) {
  min-width: 135px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/configs/thermalProduction/geostorage/calculations/sections/SegmentsHeadLossSection/SegmentsHeadLossSection.css"],"names":[],"mappings":"AAAA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;;;;EAIE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;;EAEE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;;EAEE,gBAAgB;AAClB","sourcesContent":[".segments-headLoss-section .custom-table th,\n.segments-headLoss-section .custom-table td {\n  text-align: left;\n}\n\n.segments-headLoss-section .custom-table tr {\n  height: 45px;\n}\n\n.segments-headLoss-section .custom-table tr td:first-of-type {\n  font-weight: 600;\n}\n\n.geostorage-edit-fittings-btn {\n  font-size: 14px;\n  width: 100%;\n  padding-top: 3px;\n  padding-bottom: 3px;\n}\n\n.segments-headLoss-section .custom-table th:nth-of-type(2),\n.segments-headLoss-section .custom-table td:nth-of-type(2),\n.segments-headLoss-section .custom-table th:nth-of-type(5),\n.segments-headLoss-section .custom-table td:nth-of-type(5) {\n  width: 100px;\n  min-width: 100px;\n}\n\n.segments-headLoss-section .custom-table th:nth-of-type(4),\n.segments-headLoss-section .custom-table td:nth-of-type(4) {\n  width: 130px;\n  min-width: 130px;\n}\n\n.segments-headLoss-section .custom-table th:nth-of-type(7),\n.segments-headLoss-section .custom-table td:nth-of-type(7) {\n  min-width: 135px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
