import { useLingui } from '@lingui/react';
import React from 'react';
import ResultsExpandableSection from '../../../../../components/ExpandableSection/ResultsExpandableSection.jsx';
import ResultsCards from '../../../../../components/ResultsCards/ResultsCards.jsx';
import Section from '../../../../../components/Section/Section';
import ResultsTable from '../../../../../components/TemplateTables/ResultsTable/ResultsTable.jsx';
import BtesEnergyBalanceTable from './components/BtesEnergyBalanceTable';
import BtesTemperatureLinesChart from './components/BtesTemperatureLinesChart';
import BtesTemperatureLinesChartPerYear from './components/BtesTemperatureLinesChartPerYear';
import {
  btesEnergybalanceTemplate,
  btesEvolutionTemplate,
  btesParametersTemplate
} from './templates';

const GeomodelingSection = ({ comparedResults, loadDetails }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Section title={i18n._('results.geomodeling')} open>
      <ResultsExpandableSection
        level={2}
        comparedResults={comparedResults}
        title={i18n._('results.geomodeling.btesEnergybalance')}
      >
        <ResultsCards
          element={BtesEnergyBalanceTable}
          comparedResults={comparedResults}
          title={i18n._('results.geomodeling.btesEnergybalance.table.title')}
        />
      </ResultsExpandableSection>
      <ResultsTable
        level={2}
        comparedResults={comparedResults}
        template={btesEnergybalanceTemplate(i18n, comparedResults)}
      />
      <ResultsTable
        level={2}
        comparedResults={comparedResults}
        template={btesEvolutionTemplate(i18n, comparedResults)}
      />
      <ResultsExpandableSection comparedResults={comparedResults}>
        <ResultsCards
          element={BtesTemperatureLinesChartPerYear}
          comparedResults={comparedResults}
          title={i18n._(
            'results.geomodeling.btesEvolution.btesTempLinesChartPerYear.title'
          )}
          onOpen={loadDetails}
        />
        <ResultsCards
          element={BtesTemperatureLinesChart}
          comparedResults={comparedResults}
          title={i18n._(
            'results.geomodeling.btesEvolution.btesTempLinesChart.title'
          )}
          onOpen={loadDetails}
        />
      </ResultsExpandableSection>
      <ResultsTable
        level={2}
        comparedResults={comparedResults}
        template={btesParametersTemplate(i18n, comparedResults)}
      />
    </Section>
  );
  //#endregion
};

export default React.memo(GeomodelingSection);
