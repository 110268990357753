import { useLingui } from '@lingui/react';
import React, { useContext, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { GAS_OPEX } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import ParamSelect from '../../../../components/ParamForm/ParamSelect';
import { INPUT_TYPE } from '../../../../constants';
import PopupContext from '../../../../contexts/PopupContext';
import {
  getGasEvols,
  getNextCompassYear
} from '../../../../utils/compass.utils';
import { getDefaultValue } from '../../../../utils/param.utils';

const GasBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openInfoModal } = useContext(PopupContext);
  //#endregion

  ///#region [refs]
  const evols = useRef(getGasEvols(i18n).join('\n'));
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('cost.gas')} level={2}>
      <Row>
        <Col>
          <ParamSelect
            label={i18n._('cost.InitGasPriceEvolMethod')}
            value={form.InitGasPriceEvolMethod}
            param={GAS_OPEX.InitGasPriceEvolMethod}
            onChange={(value) => onInputChange('InitGasPriceEvolMethod', value)}
            onInfoClick={() =>
              openInfoModal(
                i18n._('gasPriceEvolMethod.title'),
                i18n._('gasPriceEvolMethod.body', { evols: evols.current })
              )
            }
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitGasPriceSubscription')}
            value={form.InitGasPriceSubscription}
            param={GAS_OPEX.InitGasPriceSubscription}
            onChange={(value) =>
              onInputChange('InitGasPriceSubscription', value)
            }
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '-15px' }}>
        <Col></Col>
        <Col>
          <span className='param-input-small-text'>
            {getDefaultValue(i18n, GAS_OPEX.InitGasPriceSubscription)}
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitGasPrice')}
            value={form.InitGasPrice}
            param={GAS_OPEX.InitGasPrice}
            onChange={(value) => onInputChange('InitGasPrice', value)}
            unit
            bottomText={getDefaultValue(i18n, GAS_OPEX.InitGasPrice)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitGasPriceEvol')}
            value={form.InitGasPriceEvol}
            param={GAS_OPEX.InitGasPriceEvol}
            onChange={(value) => onInputChange('InitGasPriceEvol', value)}
            unit
            bottomText={i18n._(
              `cost.InitElecPriceEvol.bottom.${form.InitGasPriceEvolMethod}`,
              {
                year: getNextCompassYear(),
                default: GAS_OPEX.InitGasPriceEvol.default
              }
            )}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default GasBloc;
