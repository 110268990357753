const gapClassGetter = (data, value, need, gapError) => {
  if (!data[need].gap) return ['total-label-cell', 'flex-row-center'];
  const classes = ['gap-cell', 'flex-row-center'];
  if (gapError) {
    return [...classes, 'gap-base-cell'];
  } else if (value <= -0.1) {
    return [...classes, 'gap-danger-cell'];
  } else if (value >= 0.1) {
    return [...classes, 'gap-warning-cell'];
  } else if (value === 0) {
    return [...classes, 'gap-success-cell'];
  } else {
    return [...classes, 'gap-base-cell'];
  }
};

export default gapClassGetter;
