import { Component } from 'react';
import { findDOMNode } from 'react-dom';
import SchemaContext from '../../../../../contexts/SchemaContext.jsx';
import { h } from './RR.js';

export function Translate({ x, y, children }) {
  return h('g', { transform: `translate(${x || 0} ${y || 0})` }, children);
}
export function Rotate({ deg, children }) {
  return h('g', { transform: `rotate(${deg || 0})` }, children);
}

export function CRect(props) {
  const { x, y, width, height, ...attrs } = Object.assign(
    { x: 0, y: 0, width: 0, height: 0 },
    props
  );
  return h('rect', {
    x: x - width * 0.5,
    y: y - height * 0.5,
    width,
    height,
    ...attrs
  });
}

export function CText(props) {
  const { children, ...attrs } = props;
  return h(
    'text',
    {
      className: 'm',
      ...attrs
    },
    children
  );
}

export const presentInScope = (scope, showAll) => (x) =>
  showAll || scope[`Cxx_syst_${x}_Presente`] > 0;

export const Pr =
  (scope, showAll = false, invert = false) =>
  ([x, label]) =>
    !presentInScope(scope, showAll)(`${label}`)
      ? []
      : [
          invert
            ? h(
                'g',
                { transform: 'scale(1 -1)', key: '' + x + label },
                h('use', { href: '#probe', x, key: 'probe' + x + label })
              )
            : h('use', { href: '#probe', x, key: '' + x + label }),
          h(
            'text',
            {
              x,
              y: invert ? 25 : -15,
              'text-anchor': 'middle',
              key: '' + x + invert ? 25 : -15
            },
            label.replace(/^Hp0\d_/, '')
          )
        ];

export function Car(props) {
  const { rotate, x, y } = Object.assign({ rotate: 0, x: 0, y: 0 }, props);
  const transform = x || y ? `translate(${x || 0} ${y || 0}) ` : '';
  return h('g', { transform, key: 'car_' + rotate + x + y }, [
    //h('text',{y:-8,'stroke-width':0.1,'text-anchor':'middle'},'C.AR'),
    h('g', { transform: `rotate(${rotate || 0})` }, h('use', { href: '#car' }))
  ]);
}

export function Junction(props) {
  return h('use', {
    href: '#blackdisk',
    ...props,
    key: 'junction_' + props.x + props.y
  });
}

export function Tap(props) {
  let { rotate, label, x, y } = Object.assign(
    { rotate: 0, label: '', x: 0, y: 0 },
    props
  );
  const transform =
    (x || y ? `translate(${x || 0} ${y || 0}) ` : '') +
    (rotate ? `rotate(${rotate || 0})` : '');
  return h(
    'g',
    { className: 'Tap', transform, key: 'tap_' + rotate + label + x + y },
    [
      h('path', { d: `M 0 0 v 10 h -8`, fill: 'none', 'stroke-width': 5 }),
      h(
        Translate,
        { y: rotate ? 30 : 20 },
        h(Rotate, { deg: -rotate || 0 }, h('text', { className: 'm' }, label))
      )
    ]
  );
}

export function Pump({ rotate, label, double, x, y, on, text }) {
  const transform = x || y ? `translate(${x || 0} ${y || 0}) ` : '';
  const translations = double ? ['0 -5', '0 5'] : ['0 0'];
  const textAttrs = text ? text : { 'text-anchor': 'middle' };
  return h('g', { transform, key: 'pump_' + x + translations[0] + y }, [
    h('g', rotate ? { transform: `rotate(${rotate || 0})` } : {}, [
      translations.map((xy) =>
        h('g', { transform: `translate(${xy})` }, [
          h('circle', { r: 10, fill: 'none' })
        ])
      ),
      translations.map((xy) =>
        h(
          'g',
          { transform: `translate(${xy})` },
          h('path', {
            d: `M -9 0 l 15 -7 v 14 z`,
            stroke: 'black',
            fill: on ? 'lime' : '#333'
          })
        )
      )
    ]),
    h(
      CText,
      {
        y: double && Math.abs(rotate) != 90 ? -21 : -16,
        'stroke-width': 0.1,
        ...textAttrs
      },
      label
    )
  ]);
}

export function Valve({ rotate, label, x, y, on, ways }) {
  const transform = x || y ? `translate(${x || 0} ${y || 0}) ` : '';

  let tprops = { className: 'm', x: 10 + 5 * label.length, y: 0 };
  if (rotate == -90) tprops = { className: 'm', y: -17 };
  if (rotate == 90) tprops = { className: 'm', y: 20 };
  if (rotate == 180)
    tprops = { className: 'm', x: -8 - 5.5 * label.length, y: 0 };
  return h('g', { transform, key: 'valve_' + y + label + x }, [
    h('g', { transform: `rotate(${rotate || 0})` }, [
      h('path', {
        d: `M -3 -4 ${
          ways == 3 ? 'v 2 h -2 l -4 -2 v 8 l 4 -2 h 2 v 2' : 'v 8'
        } l -2 4 h 10 l -2 -4 v-8 l 2 -4 h -10 l 2 4 `,
        stroke: 'black',
        fill: on ? 'lime' : 'gray'
      }),
      h('path', { d: `M 3 0 h 4` }),
      h('rect', {
        x: 7,
        y: -3,
        width: 4,
        height: 6,
        stroke: '#000',
        fill: '#ddd'
      })
    ]),
    h('text', tprops, label)
  ]);
}

export function Et(props) {
  let { label, rotate, dy, fill, x, y } = Object.assign(
    { fill: '#f33', rotate: 0 },
    props
  );
  const transform = x || y ? `translate(${x || 0} ${y || 0}) ` : '';
  let deg = rotate || 0;
  dy = dy || (deg == 0 ? 50 : 20);
  const scale = 0.6;
  return h(
    'g',
    { transform, key: 'et_' + dy + scale + x + y },
    h(Rotate, { deg }, [
      h('g', { transform: `scale(${scale})` }, [
        h('rect', {
          x: -25,
          y: -10,
          width: 50,
          height: 20,
          rx: 10,
          fill: '#000'
        }),

        h('circle', { r: 20, stroke: '#000', fill: 'gray' }),
        h('circle', { r: 17, stroke: 'none', fill }),
        h(
          Rotate,
          { deg: -deg },
          h('path', {
            d: `M 0 0 l 6 -12`,
            stroke: '#fff',
            fill: '#fff',
            'stroke-width': 2
          })
        ),
        h('circle', { r: 3, stroke: '#fff', fill: '#f33' }),
        [0, 90, 180, 270].map((angle) =>
          h(
            'g',
            { transform: `rotate(${angle})` },
            h('circle', { cx: 15, r: 2, stroke: 'none', fill: '#fff' })
          )
        )
      ]),
      h('path', {
        d: `M ${scale * -40} 0 C ${scale * -40} 0 ${scale * -20} ${
          scale * -40
        } 0 ${scale * -20}`,
        fill: 'none'
      }),
      h('path', {
        d: `M 0 ${scale * -20} C ${scale * -10} ${scale * -30} ${scale * -10} ${
          -dy + 10
        } ${scale * -20} ${-dy}`,
        fill: 'none'
      }),
      h(
        Translate,
        { y: deg == 0 ? 20 : 30 },
        h(Rotate, { deg: -deg }, h('text', { className: 'm' }, label))
      ),

      h('g', { transform: `translate(${scale * -40} 0)` }, [
        h('path', { d: 'M 0 -10 v 20' }),
        h('circle', { cy: -10, r: 3, fill: '#00f' })
        //h(Translate,{y:20},h(Rotate,{deg:-deg},h('text',{className: 'm'},'T1')))
      ]),
      h('g', { transform: `translate(${scale * -20} ${-dy})` }, [
        h('path', { d: 'M 0 -10 v 20' }),
        h('circle', { cy: -10, r: 3, fill: '#f00' })
        //h(Translate,{y:-22},h(Rotate,{deg:-deg},h('text',{className: 'm'},'T2')))
      ])
    ])
  );
}

export function HpFan(props) {
  const { on, x, y, scale } = props;
  const transform =
    (x || y ? `translate(${x || 0} ${y || 0}) ` : '') +
    (scale ? `scale(${scale})` : '');
  return h(
    'g',
    { transform, key: 'hpfan_' + x + transform + y },
    h('use', { href: '#fan' + (on ? '-on' : '') })
  );
}

export function PipeBase(props) {
  let { invertedFlow, ...attrs } = props;
  let animate = '';
  if (attrs['stroke'] === undefined) attrs.stroke = 'black';
  if (attrs['fill'] === undefined) attrs.fill = 'none';
  if (['blue', 'red'].indexOf(attrs['stroke']) >= 0) {
    Object.assign(attrs, { 'stroke-dasharray': '8 2', 'stroke-width': 3 });
    animate = h('animate', {
      attributeName: 'stroke-dashoffset',
      from: 0,
      to: invertedFlow ? 10 : -10,
      dur: 4,
      repeatCount: 'indefinite'
    });
  }
  return h(
    'path',
    { ...attrs, key: 'pipebase_' + invertedFlow ? 10 : -10 },
    animate
  );
}

const debugPipe = false;

const logd = (e) => console.log(e.target.getAttribute('d'));
export class Pipe extends Component {
  componentDidMount() {
    if (debugPipe)
      findDOMNode(this).querySelector('path').addEventListener('click', logd);
  }
  componentWillUnmount() {
    if (debugPipe)
      findDOMNode(this)
        .querySelector('path')
        .removeEventListener('click', logd);
  }
  render() {
    let { name, invertedFlow, ...attrs } = this.props;
    let animate = '';
    if (attrs['stroke'] === undefined) attrs.stroke = 'black';
    if (attrs['fill'] === undefined) attrs.fill = 'none';
    if (['none', 'black'].indexOf(attrs['stroke']) < 0) {
      Object.assign(attrs, { 'stroke-dasharray': '8 2', 'stroke-width': 3 });
      animate = h('animate', {
        attributeName: 'stroke-dashoffset',
        from: 0,
        to: invertedFlow ? 10 : -10,
        dur: 5,
        repeatCount: 'indefinite'
      });
    }
    if (attrs.d.indexOf('undefined') >= 0 || attrs.d.indexOf('NaN') >= 0) {
      console.log('bad path', attrs);
    }

    return h(SchemaContext.Consumer, null, ({ showPipeNames }) =>
      showPipeNames
        ? h('g', {}, [
            h('path', {
              ...attrs,
              stroke: 'rgb(255,255,255,.1)',
              'stroke-width': 7
            }),
            h('path', attrs, animate),
            name ? h(PipeName, { name, d: attrs.d }) : ''
          ])
        : h('path', attrs, animate)
    );
  }
}

function PipeName(props) {
  const { name, d } = props,
    a = { 'text-anchor': 'middle', y: /H2/.test(name) ? 24 : -4 };
  let p = new PipePoints(d),
    [x, y] = p.middle();
  return h(
    'g',
    {
      transform: `translate(${x} ${y})` + (p.vertical() ? `rotate(-90)` : ''),
      key: 'pipename_' + `translate(${x} ${y})`
    },
    h('text', a, name)
  );
}

function PipePoints(d) {
  const p = [],
    a = d.replace(/^\s+|\s+$/g, '').split(/[ ,]/);
  const add = (a, b) => a.map((v, i) => v + b[i]);
  const mul = (a, n) => a.map((v) => v * n);
  let x = 0,
    y = 0;
  for (let i = 0; i < a.length; i++) {
    switch (a[i]) {
      case 'M':
        x = +a[i + 1];
        y = +a[i + 2];
        i += 2;
        p.push([x, y]);
        break;
      case 'L':
        x = +a[i + 1];
        y = +a[i + 2];
        i += 2;
        p.push([x, y]);
        break;
      case 'C':
        x = +a[i + 5];
        y = +a[i + 6];
        i += 6;
        p.push([x, y]);
        break;
      case 'A':
        x = +a[i + 6];
        y = +a[i + 7];
        i += 7;
        p.push([x, y]);
        break;
      case 'h':
        x += +a[i + 1];
        i += 1;
        p.push([x, y]);
        break;
      case 'v':
        y += +a[i + 1];
        i += 1;
        p.push([x, y]);
        break;
      case 'l':
        x += +a[i + 1];
        y += +a[i + 2];
        i += 2;
        p.push([x, y]);
        break;
    }
  }
  Object.assign(this, {
    p,
    start: () => p[0],
    end: () => p[p.length - 1],
    middle: () => mul(add(p[0], p[p.length - 1]), 0.5),
    vertical: () =>
      Math.abs(p[p.length - 1][0] - p[0][0]) <
      Math.abs(p[p.length - 1][1] - p[0][1])
  });
}

function RoundedPolygon({ sides, r, rx, x, y, ...props }) {
  const lside = r * 2 * Math.sin(Math.PI / sides);
  const r1 = (rx * Math.tan((Math.PI * (1 - 2 / sides)) / 2)).toFixed(2);
  const toRect = (r, t) => [r * Math.cos(t), r * Math.sin(t)];
  const sub = (a, b) => a.map((v, i) => v - b[i]);
  const add = (a, b) => a.map((v, i) => v + b[i]);
  const mul = (a, n) => a.map((v) => v * n);
  const str = (p) => p.map((v) => v.toFixed(2)).join(' ');
  const frad = (i) => (i * 2 * Math.PI) / sides - Math.PI / 2;

  let d =
    Array(sides)
      .fill(0)
      .map((_, i) => {
        const p = toRect(r, frad(i)),
          pprev = toRect(r, frad(i - 1)),
          p1 = add(p, mul(sub(pprev, p), (1 * rx) / lside)),
          pnext = toRect(r, frad(i + 1)),
          p2 = add(p, mul(sub(pnext, p), (1 * rx) / lside));
        return (
          (i == 0 ? 'M ' + str(p1) : `L ${str(p1)}`) +
          ` A ${r1} ${r1} ${360 / sides} 0 1 ${str(p2)}`
        );
      })
      .join(' ') + ' z';
  //console.log(d);
  const transform = `translate(${x || 0} ${y || 0})`;
  return h('g', { transform }, [h('path', { d, ...props })]);
}

export function transform(A, obj) {
  let m;
  if (typeof A == 'string') {
    if ((m = /matrix\(([^)]*)\)/i.exec(A))) {
      m = m[1].split(/\s+|,/).map((x) => +x);
      A = [+m[0], +m[1], +m[2], +m[3], +m[4], +m[5]];
    }
  }
  if (obj['d']) {
    obj['d'] = obj['d']
      .replace(/(?<! )([A-Z])|([A-Z])(?! )/gi, ' $& ')
      .replace(/\s+/g, ' ');
    const old = obj['d']
      .split(/\s+|,/)
      .filter((s) => s.length > 0)
      .map((x) => (isNaN(x) ? x : +x));
    const d = [];
    for (let i = 0; i < old.length; i++) {
      if (typeof old[i] == 'string') {
        d.push(old[i]);
      } else {
        d.push(
          Math.round(A[0] * old[i] + A[1] * old[i + 1] + A[4]),
          Math.round(A[2] * old[i] + A[3] * old[i + 1] + A[5])
        );
        i += 1;
      }
    }
    obj['d'] = d.join(' ');
  }
  return obj;
}

export function Exch(props) {
  const { x, y, label, scaleY } = Object.assign(
      { x: 0, y: 0, label: '', scaleY: 1 },
      props
    ),
    transform = scaleY == 1 ? '' : `scale(1 ${scaleY})`;

  return h(Translate, { x, y }, [
    h('use', { href: '#exch', transform }),
    label
      ? h(
          CText,
          {
            stroke: 'white',
            fill: 'white',
            dy: 1.5,
            style: 'stroke-width:.5',
            transform: `rotate(-90)`
          },
          label
        )
      : ''
  ]);
}
