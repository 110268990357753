import React from 'react';
import SectionTitle from '../SectionTitle';
import './Bloc.css';

const Bloc = ({ title, children, className, level }) => {
  return (
    <div className={`bloc${className ? ' ' + className : ''}`}>
      <SectionTitle level={level}>{title}</SectionTitle>
      <div className='bloc-children'>{children}</div>
    </div>
  );
};

export default Bloc;
