// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.other-persub-table.custom-table {
  width: 100%;
}

.other-persub-table.custom-table thead tr th:first-of-type,
.other-persub-table.custom-table tbody tr td:first-of-type {
  width: 300px;
  max-width: 300px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/result/sections/simulationParams/components/OtherPerSubTable/OtherPerSubTable.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;;EAEE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".other-persub-table.custom-table {\n  width: 100%;\n}\n\n.other-persub-table.custom-table thead tr th:first-of-type,\n.other-persub-table.custom-table tbody tr td:first-of-type {\n  width: 300px;\n  max-width: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
