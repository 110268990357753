import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { MODE, NEED } from '../../../../../../../../../server/constants';
import {
  getDefaultColdValues,
  getDefaultDhwValues,
  getDefaultHeatValues
} from '../../../../../../../../../server/models/config/buildingDescription/services/settings.model';
import Section from '../../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import './ServicesSection.css';

const ServicesSection = ({ onFormChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const handleNeedChange = (evt) => {
    const { value, checked } = evt.target;
    const { services } = config.ConfigsSst[0].Data;
    if (checked) {
      switch (value) {
        case NEED.HEAT:
          services[NEED.HEAT] = getDefaultHeatValues(
            services[NEED.COLD]?.combinedHotColdWaterTank,
            services[NEED.COLD]?.secondaryControl
          );
          break;
        case NEED.COLD:
          services[NEED.COLD] = getDefaultColdValues(
            services[NEED.HEAT]?.combinedHotColdWaterTank,
            services[NEED.HEAT]?.secondaryControl
          );
          break;
        case NEED.DHW:
          services[NEED.DHW] = getDefaultDhwValues();
          break;
      }
      services.needs.push(value);
    } else {
      services.needs = services.needs.filter((need) => need !== value);
    }
    onFormChange(config);
  };

  const handleModeChange = (evt) => {
    const { value, checked } = evt.target;
    const { modes } = config.ConfigsSst[0].Data.services;
    config.ConfigsSst[0].Data.services.modes = checked
      ? [...modes, value]
      : modes.filter((mode) => mode !== value);
    onFormChange(config);
  };
  //#endregion

  //#region [render]
  const { needs, modes } = config.ConfigsSst[0].Data.services;
  return (
    <Section
      title={i18n._('config.services.settings.providedServices')}
      level={2}
      open
    >
      {needs.length === 0 && (
        <p className='settings-noNeeds'>
          {i18n._('config.services.settings.noNeed')}
        </p>
      )}
      <div className='settings-services'>
        <div className='settings-column'>
          <p className='form-input-label'>
            {i18n._('config.services.settings.needs')}
          </p>
          {Object.values(NEED).map((need) => {
            return (
              <Form.Check
                key={need}
                type='switch'
                label={i18n._(`need.${need}`)}
                checked={needs.includes(need)}
                onChange={handleNeedChange}
                value={need}
              />
            );
          })}
        </div>
        <div className='settings-column'>
          <span className='form-input-label'>
            {i18n._('config.services.settings.modes')}
          </span>
          {Object.values(MODE).map((mode) => {
            return (
              <Form.Check
                key={mode}
                type='switch'
                label={i18n._(`mode.${mode}`)}
                checked={modes.includes(mode)}
                onChange={handleModeChange}
                value={mode}
              />
            );
          })}
        </div>
      </div>
    </Section>
  );
  //#endregion
};

export default ServicesSection;
