import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { getDescriptionSchema } from '../../../../../server/validation/description.validation';
import {
  fetchDescriptions,
  updateDescriptions
} from '../../../api/project.api';
import DescriptionsTabs from '../../../containers/DescriptionsTabs/DescriptionsTabs';
import AjvContext from '../../../contexts/AjvContext';
import PopupContext from '../../../contexts/PopupContext';
import { isArrNullOrEmpty } from '../../../utils/data.utils';
import { IS_DEV } from '../../../utils/env.utils';
import { getStdFilesError } from '../../../utils/substation.utils';
import './DescriptionsPage.css';

const DescriptionsPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  const { companyId, projectId } = useParams();
  //#endregion

  //#region [contexts]
  const { ajv } = useContext(AjvContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [forms, setForms] = useState([]);
  const [weatherForms, setWeatherForms] = useState([]);
  const [stdForms, setStdForms] = useState([]);
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        const descriptions = await fetchDescriptions(projectId);
        setForms(() => descriptions.forms);
        setWeatherForms(() => descriptions.weatherForms);
        setStdForms(() => descriptions.stdForms);
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, []);
  //#endregion

  //#region [methods]
  const handleSubmitClick = async () => {
    try {
      const formData = new FormData();
      weatherForms
        .filter((form) => form.file)
        .forEach((form) => {
          const encodedFilename = encodeURIComponent(form.file.name);
          const filename = `${form.fileId}_${encodedFilename}`;
          formData.append('weatherFiles', form.file, filename);
        });
      stdForms
        .filter((form) => form.file)
        .forEach((form) => {
          const encodedFilename = encodeURIComponent(form.file.name);
          const filename = `${form.fileId}_${encodedFilename}`;
          formData.append('stdFiles', form.file, filename);
        });
      formData.append('forms', JSON.stringify(forms));
      await updateDescriptions(projectId, formData);
      navigate(
        `/company/${companyId}/project/${projectId}/design/compute?type=opti`
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  //#region [effects]
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //#endregion

  //#region [render]
  if (
    isArrNullOrEmpty(forms) ||
    isArrNullOrEmpty(weatherForms) ||
    isArrNullOrEmpty(stdForms)
  )
    return null;

  // erreurs liées aux formulaires
  const formsErrors = forms
    .map((form) => {
      const schema = getDescriptionSchema(
        form.InitTemperatureHeat,
        form.InitTemperatureCold,
        form.InitWaterLawIsUsed
      );
      const validate = ajv.compile(schema);
      validate(form);
      if (IS_DEV && validate.errors) console.log(validate.errors);
      return validate.errors;
    })
    .filter((errors) => errors);

  // erreurs liées aux STD
  let stdFormsErrors = [];
  forms.forEach((form, formIndex) => {
    const descStdForms = stdForms.filter(
      (stdForm) => stdForm.projectId === form.AhsID && stdForm.summaryData
    );
    const error = getStdFilesError(i18n, form, formIndex, descStdForms);
    if (error) stdFormsErrors.push(error);
  });

  const isFormValid = !formsErrors.length && !stdFormsErrors.length;
  return (
    <div className='descriptions-page'>
      <DescriptionsTabs
        forms={forms}
        weatherForms={weatherForms}
        stdForms={stdForms}
        onFormsChange={setForms}
        onStdFormsChange={setStdForms}
        onWeatherFormsChange={setWeatherForms}
      />
      {formsErrors.map((_, formIndex) => (
        <Alert variant='danger' key={'form_error_' + formIndex}>
          {i18n._('description.descriptionError', {
            descIndex: formIndex + 1
          })}
        </Alert>
      ))}
      {stdFormsErrors.map((error, errorIndex) => (
        <Alert variant='danger' key={'std_form_error_' + errorIndex}>
          {error}
        </Alert>
      ))}
      <div className='bottom-page-btns'>
        <Button
          variant='primary'
          onClick={handleSubmitClick}
          disabled={!isFormValid}
        >
          {i18n._('save')}
        </Button>
      </div>
    </div>
  );
  //#endregion
};

export default DescriptionsPage;
