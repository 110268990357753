import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import { AgGridReact } from 'ag-grid-react';
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { HP_TYPE, REFRIGERANT } from '../../../../../../../../server/constants';
import { getDefaultSelectedHpValues } from '../../../../../../../../server/models/config/thermalProduction/heatpumps/heatpump.model';
import { fetchHpsCatalog } from '../../../../../../api/configSst.api';
import SetFilter from '../../../../../../components/SetFilter/SetFilter';
import TextFilter from '../../../../../../components/TextFilter/TextFilter';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../contexts/PopupContext';
import { formatValue } from '../../../../../../utils/data.utils';
import { getHpsCountStr } from '../../../../../../utils/heatpump.utils';
import CapacityCellRenderer from '../cells/CapacityCellRenderer';
import CompressorCellRenderer from '../cells/CompressorCellRenderer';
import CountCellRenderer from '../cells/CountCellRenderer/CountCellRenderer';
import GapCellRenderer from '../cells/GapCellRenderer';
import gapClassGetter from '../cells/gapClassGetter';
import './HpsCatalogModal.css';

const HpsCatalogModal = ({ isOpen, onClose, onFormChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [rowData, setRowData] = useState([]);
  const [pinnedRowData, setPinnedRowData] = useState([]);
  const [showUnselectableHps, setShowUnselectableHps] = useState(false);
  //#endregion

  //#region [refs]
  const catalogGrid = useRef();
  const totalGrid = useRef();
  const allRowData = useRef([]);
  const selectedHpsData = useRef([]);
  const rowDataWithoutUnselectableHps = useRef([]);
  //#endregion

  //#region [callbacks]
  const onGridReady = useCallback(async () => {
    try {
      catalogGrid.current.api.showLoadingOverlay();
      const { services, heatpumps } = config.ConfigsSst[0].Data;
      const catalog = await fetchHpsCatalog(config.ConfigsSst[0].ConfigSstID);
      const newPinnedDataObj = {};
      catalog.forEach((heatpump) => {
        const { model } = heatpump;
        const count = heatpumps.list.filter((hp) => hp.model === model).length;
        if (count > 0) {
          newPinnedDataObj[heatpump.model] = { ...heatpump, count };
        } else {
          const data = { ...heatpump, count: 0 };
          allRowData.current.push(data);
        }
      });
      const newRowData = allRowData.current.filter((hp) => {
        return services.needs.every(
          (need) => !hp.capacity[need].error?.includes('Unable to find')
        );
      });
      setRowData(() => newRowData);
      setPinnedRowData(() => Object.values(newPinnedDataObj));
      rowDataWithoutUnselectableHps.current = [].concat(newRowData);
      selectedHpsData.current = [...heatpumps.list];
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    } finally {
      catalogGrid.current.api.hideOverlay();
    }
  }, [config]);

  const handleSearchTextChange = useCallback((evt) => {
    catalogGrid.current.api.setQuickFilter(evt.target.value);
  }, []);
  //#endregion

  //#region [methods]
  const getNeedsCols = () => {
    let cols = [];
    const { needs } = config.ConfigsSst[0].Data.services;
    const modalTarget = 'hps-catalog-modal';
    needs.forEach((need) => {
      cols = cols.concat([
        {
          field: `capacity.${need}.regime`,
          headerName: i18n._(`heatpump.regime.${need}`),
          flex: 3,
          minWidth: 140,
          headerClass: 'flex-row-center',
          cellClass: 'flex-row-center'
        },
        {
          field: `capacity.${need}.ptherm`,
          headerName: i18n._(`heatpump.ptherm.${need}`),
          flex: 2,
          minWidth: 110,
          headerClass: 'flex-row-center',
          cellClass: 'flex-row-center',
          cellRenderer: CapacityCellRenderer,
          cellRendererParams: { need, modalTarget }
        }
      ]);
    });
    return cols;
  };

  const getNeedsTotalCols = () => {
    let cols = [];
    const { needs } = config.ConfigsSst[0].Data.services;
    needs.forEach((need) => {
      const totalRegimeCol = {
        field: `${[need]}.label`,
        flex: 3,
        minWidth: 140,
        cellClass: ({ data, value }) => gapClassGetter(data, value, need),
        colSpan: ({ data }) => (data[need].gap ? 2 : 1),
        cellRenderer: GapCellRenderer,
        cellRendererParams: { need }
      };
      const totalPCol = {
        field: `${[need]}.total`,
        flex: 2,
        minWidth: 110,
        cellClass: ['total-value-cell', 'flex-row-center'],
        cellRenderer: ({ value }) => formatValue(value, 0)
      };
      cols = cols.concat([totalRegimeCol, totalPCol]);
    });
    return cols;
  };

  const handleShowUnselectableBtnClick = () => {
    const show = !showUnselectableHps;
    setRowData(() =>
      show
        ? [].concat(allRowData.current)
        : [].concat(rowDataWithoutUnselectableHps.current)
    );
    setShowUnselectableHps(() => show);
  };

  //#region [methods]
  const handleAddHp = (hpToAdd) => {
    try {
      let hpIndex = rowData.findIndex((hp) => hp.model === hpToAdd.model);
      if (hpIndex === -1) {
        // la PAC est déjà dans les pinnedRowData : j'incrémente count
        setPinnedRowData((hps) => {
          hpIndex = hps.findIndex((hp) => hp.model === hpToAdd.model);
          hps[hpIndex].count++;
          return [].concat(hps);
        });
      } else {
        // la PAC n'est pas dans les pinnedRowData : je l'y ajoute avec count = 1
        const selectedHp = rowData[hpIndex];
        setRowData((hps) => {
          return hps.toSpliced(hpIndex, 1);
        });

        // je la supprime des rowData
        setPinnedRowData((hps) => {
          return hps.concat([{ ...selectedHp, count: 1 }]);
        });
      }
      const hp = getDefaultSelectedHpValues(
        hpToAdd,
        selectedHpsData.current.length
      );
      delete hp.count;
      selectedHpsData.current.push(hp);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleRemoveHp = (hpToRemove) => {
    try {
      const pinnedRowIndex = pinnedRowData.findIndex(
        (hp) => hp.model === hpToRemove.model
      );
      if (pinnedRowData[pinnedRowIndex].count > 1) {
        // il y a encore au moins 1 PAC de ce modèle qui est sélectionnée
        setPinnedRowData(() => {
          pinnedRowData[pinnedRowIndex].count--;
          return [].concat(pinnedRowData);
        });
      } else {
        // il n'y a plus de PAC de ce modèle qui est sélectionnée
        const selectedHp = pinnedRowData[pinnedRowIndex];
        setPinnedRowData((hps) => {
          return hps.toSpliced(pinnedRowIndex, 1);
        });
        setRowData((hps) => {
          return hps.concat([{ ...selectedHp, count: 0 }]);
        });
      }

      // on met à jour les positions des PACs sélectionnées
      const selectedHpIndex = selectedHpsData.current.findIndex(
        (data) => data.model === hpToRemove.model
      );
      const selectedHp = { ...selectedHpsData.current[selectedHpIndex] };
      selectedHpsData.current.splice(selectedHpIndex, 1);
      selectedHpsData.current.forEach((hp) => {
        if (hp.position > selectedHp.position) hp.position--;
      });
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  const handleValidateBtnClick = () => {
    config.ConfigsSst[0].Data.heatpumps.list = [...selectedHpsData.current];
    onFormChange(config);
    onClose();
  };
  //#endregion

  //#region [memos]
  const columnDefs = useMemo(() => {
    const needsCols = getNeedsCols();
    return [
      {
        field: 'count',
        headerName: i18n._('config.hps.modelCount'),
        sortable: false,
        flex: 1,
        minWidth: 80,
        headerClass: 'flex-row-center',
        cellClass: 'flex-row-center',
        cellRenderer: CountCellRenderer,
        cellRendererParams: {
          selectedHpsCount: selectedHpsData.current.length,
          addHp: handleAddHp,
          removeHp: handleRemoveHp
        }
      },
      {
        field: 'manufacturer',
        headerName: i18n._('config.hps.manufacturer'),
        filter: TextFilter,
        filterParams: {
          filterPlaceholder: i18n._('config.hps.manufacturer.filter')
        },
        sort: 'asc',
        flex: 2,
        minWidth: 125,
        headerClass: 'pl-1',
        cellClass: 'pl-1',
        tooltipField: 'manufacturer'
      },
      {
        field: 'model',
        headerName: i18n._('config.hps.model'),
        filter: TextFilter,
        filterParams: {
          filterPlaceholder: i18n._('config.hps.model.filter')
        },
        sort: 'asc',
        flex: 3,
        minWidth: 150,
        headerClass: 'pl-1',
        cellClass: 'pl-1',
        tooltipField: 'model'
      },
      {
        field: 'type',
        headerName: i18n._('config.hps.type'),
        filter: SetFilter,
        filterParams: {
          values: Object.values(HP_TYPE).map((type) => ({
            raw: type,
            formatted: i18n._(`heatpump.type.${type}`)
          }))
        },
        width: 100,
        headerClass: 'flex-row-center',
        cellClass: 'flex-row-center',
        cellRenderer: ({ value }) => i18n._(`heatpump.type.${value}`)
      },
      ...needsCols,
      {
        field: 'compressors',
        headerName: i18n._('config.hps.compressors'),
        flex: 2,
        minWidth: 130,
        headerClass: 'flex-row-center',
        cellClass: 'flex-row-center',
        cellRenderer: CompressorCellRenderer
      },
      {
        field: 'refrigerant',
        headerName: i18n._('config.hps.refrigerant'),
        filter: SetFilter,
        filterParams: {
          values: Object.values(REFRIGERANT).map((refrigerant) => ({
            raw: refrigerant
          }))
        },
        flex: 2,
        minWidth: 100,
        headerClass: 'flex-row-center',
        cellClass: 'flex-row-center'
      }
    ];
  }, [rowData, pinnedRowData]);

  const totalColumnDefs = useMemo(() => {
    const totalCols = getNeedsTotalCols();
    return [
      { flex: 1, minWidth: 80 },
      { flex: 2, minWidth: 125 },
      { flex: 3, minWidth: 150 },
      { width: 100 },
      ...totalCols,
      { flex: 2, minWidth: 130, cellClass: 'border-left' },
      { flex: 2, minWidth: 100 }
    ];
  }, []);

  const totalRowData = useMemo(() => {
    let rows = [{}, {}, {}];
    const { services } = config.ConfigsSst[0].Data;
    services.needs.forEach((need) => {
      const hpsTotal = selectedHpsData.current.reduce((acc, hp) => {
        acc += hp.capacity[need]?.ptherm ?? 0;
        return acc;
      }, 0);
      // total PAC
      rows[0][need] = {
        label: i18n._('selectedHps.total.hp'),
        total: hpsTotal
      };

      // total bâtiment
      rows[1][need] = {
        label: i18n._('selectedHps.total.building'),
        total: services[need].pMax
      };

      // écart (gap)
      rows[2][need] = {
        label: (hpsTotal - services[need].pMax) / services[need].pMax,
        gap: true
      };
    });
    return rows;
  }, [config.ConfigsSst[0].Data.services, selectedHpsData.current.length]);
  //#endregion

  //#region [render]
  const count = getHpsCountStr(i18n, selectedHpsData.current.length);
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      fullscreen
      className='hps-catalog-modal'
      centered
    >
      <Modal.Header closeButton>
        {i18n._('config.hps.selection.catalog')}
      </Modal.Header>
      <Modal.Body>
        <div className='hps-catalog'>
          <div className='hps-catalog-navbar'>
            <div className='hps-catalog-search'>
              <FontAwesomeIcon icon='magnifying-glass' />
              <Form.Control
                type='text'
                placeholder={i18n._('hpCatalog.search')}
                onChange={handleSearchTextChange}
              />
            </div>
            <Button onClick={handleShowUnselectableBtnClick}>
              <FontAwesomeIcon
                icon={showUnselectableHps ? 'eye-slash' : 'eye'}
              />
              {showUnselectableHps
                ? i18n._('config.hps.selection.hideUnselectable')
                : i18n._('config.hps.selection.showUnselectable')}
            </Button>
          </div>
          <p className='hps-catalog-count'>{count}</p>
          <div className='hps-catalogs'>
            <div className='ag-theme-alpine hps-catalog-wrapper'>
              <AgGridReact
                ref={catalogGrid}
                rowData={rowData}
                columnDefs={columnDefs}
                headerHeight={40}
                rowHeight={40}
                defaultColDef={{
                  editable: false,
                  sortable: true,
                  resizable: false
                }}
                pinnedTopRowData={pinnedRowData}
                onGridReady={onGridReady}
                alignedGrids={
                  totalGrid.current ? [totalGrid.current] : undefined
                }
                suppressCellFocus
                suppressMenuHide
                suppressMovableColumns
                suppressHorizontalScroll
                suppressScrollOnNewData
                alwaysShowVerticalScroll
                overlayLoadingTemplate={
                  '<div class="spinner-border text-dark"></div>'
                }
              />
            </div>
            <div className='hps-catalog-total-wrapper'>
              <AgGridReact
                ref={totalGrid}
                rowData={totalRowData}
                columnDefs={totalColumnDefs}
                headerHeight={0}
                rowHeight={40}
                alignedGrids={
                  catalogGrid.current ? [catalogGrid.current] : undefined
                }
                suppressCellFocus
                suppressMenuHide
                suppressMovableColumns
                alwaysShowVerticalScroll
              />
            </div>
          </div>
        </div>
        <Button onClick={handleValidateBtnClick}>{i18n._('validate')}</Button>
      </Modal.Body>
    </Modal>
  );
  //#endregion
};

export default HpsCatalogModal;
