import { useLingui } from '@lingui/react';
import { AgGridReact } from 'ag-grid-react';
import React, { useContext, useMemo, useRef } from 'react';
import Section from '../../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import { getHpsCountStr } from '../../../../../../../utils/heatpump.utils';
import CompressorCellRenderer from '../../cells/CompressorCellRenderer';
import DeleteCellRenderer from '../../cells/DeleteCellRenderer';
import NeedsSelectCellRenderer from '../../cells/NeedsSelectCellRenderer/NeedsSelectCellRenderer';
import PositionCellRenderer from '../../cells/PositionCellRenderer/PositionCellRenderer';
import StatusCellRenderer from '../../cells/StatusCellRenderer/StatusCellRenderer';
import './SelectedHpsSection.css';

const SelectedHpsSection = ({ onFormChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [refs]
  const grid = useRef();
  //#endregion

  //#region [memos]
  const columnDefs = useMemo(() => {
    // on récupère l'état de chaque colonne pour savoir lesquelles sont triées
    const colsStates = grid.current?.columnApi.getColumnState();

    return [
      {
        field: 'delete',
        headerName: '',
        sortable: false,
        width: 60,
        cellClass: 'flex-row-center',
        cellRenderer: DeleteCellRenderer,
        cellRendererParams: { onFormChange }
      },
      {
        field: 'manufacturer',
        headerName: i18n._('config.hps.manufacturer'),
        width: 140,
        headerClass: 'pl-1',
        cellClass: 'pl-1',
        tooltipField: 'manufacturer',
        sort: colsStates
          ? colsStates.find((col) => col.colId === 'manufacturer').sort
          : 'asc'
      },
      {
        field: 'model',
        headerName: i18n._('config.hps.model'),
        width: 150,
        headerClass: 'pl-1',
        cellClass: 'pl-1',
        tooltipField: 'model',
        sort: colsStates
          ? colsStates.find((col) => col.colId === 'model').sort
          : 'asc'
      },
      {
        field: 'type',
        headerName: i18n._('config.hps.type'),
        width: 100,
        headerClass: 'flex-row-center',
        cellClass: 'flex-row-center',
        valueFormatter: ({ value }) => i18n._(`heatpump.type.${value}`)
      },
      {
        field: 'needs',
        headerName: i18n._('config.hps.needs'),
        sortable: false,
        width: 370,
        headerClass: 'flex-row-center',
        cellClass: 'cell-multi-select',
        valueFormatter: ({ value }) => JSON.stringify(value),
        cellRenderer: NeedsSelectCellRenderer,
        cellRendererParams: { onFormChange }
      },
      {
        field: 'position',
        headerName: i18n._('config.hps.position'),
        sortable: false,
        width: 100,
        headerClass: 'flex-row-center',
        cellClass: 'cell-input',
        cellRenderer: PositionCellRenderer,
        cellRendererParams: { onFormChange }
      },
      {
        field: 'status',
        headerName: i18n._('config.hps.status'),
        sortable: false,
        width: 170,
        headerClass: 'flex-row-center',
        cellClass: 'cell-input',
        cellRenderer: StatusCellRenderer,
        cellRendererParams: { onFormChange }
      },
      {
        field: 'compressors',
        headerName: i18n._('config.hps.compressors'),
        width: 125,
        headerClass: 'flex-row-center',
        cellClass: 'flex-row-center',
        cellRenderer: CompressorCellRenderer
      },
      {
        field: 'refrigerant',
        headerName: i18n._('config.hps.refrigerant'),
        width: 90,
        headerClass: 'flex-row-center',
        cellClass: 'flex-row-center'
      }
    ];
  }, []);
  //#endregion

  //#region [render]
  const { list } = config.ConfigsSst[0].Data.heatpumps;
  const count = getHpsCountStr(i18n, list.length);
  return (
    <Section
      title={i18n._('config.hps.selection.selectedHp')}
      level={2}
      open
      className='selected-hps-section'
    >
      <p className='selected-hps-count'>{count}</p>
      <div
        className='ag-theme-alpine'
        style={{ height: 58 + 45 * list.length }}
      >
        <AgGridReact
          ref={grid}
          rowData={list}
          columnDefs={columnDefs}
          headerHeight={40}
          rowHeight={45}
          defaultColDef={{
            editable: false,
            sortable: true,
            resizable: false
          }}
          suppressCellFocus
          suppressMovableColumns
          suppressHorizontalScroll
        />
      </div>
    </Section>
  );
  //#endregion
};

export default SelectedHpsSection;
