const { GENERAL, LOCATION } = require('../models/design/general.model');

const GENERAL_SCHEMA = {
  type: 'object',
  properties: {
    [GENERAL.AhsName.key]: {
      type: GENERAL.AhsName.type,
      minLength: 1,
      isNotEmpty: true
    },
    [GENERAL.AhsDescription.key]: {
      oneOf: [{ type: 'string' }, { type: 'null' }]
    },
    [GENERAL.InitBuildingSurface.key]: {
      type: GENERAL.InitBuildingSurface.type,
      minimum: GENERAL.InitBuildingSurface.min,
      maximum: GENERAL.InitBuildingSurface.max
    },
    [GENERAL.AhsType.key]: {
      type: GENERAL.AhsType.type,
      enum: GENERAL.AhsType.values
    },
    [GENERAL.AhsMainUsage.key]: {
      type: GENERAL.AhsMainUsage.type,
      enum: GENERAL.AhsMainUsage.values
    },
    [GENERAL.ImageFileID.key]: {
      oneOf: [
        {
          type: 'string',
          pattern: GENERAL.ImageFileID.pattern.toString().slice(1, -1)
        },
        {
          type: 'string',
          enum: ['']
        },
        {
          type: 'null'
        }
      ]
    },
    [LOCATION.AhsAddress1.key]: {
      type: LOCATION.AhsAddress1.type,
      minLength: LOCATION.AhsAddress1.minLength,
      maxLength: LOCATION.AhsAddress1.maxLength,
      isNotEmpty: true
    },
    [LOCATION.AhsZipCode.key]: {
      type: LOCATION.AhsZipCode.type,
      pattern: LOCATION.AhsZipCode.pattern.toString().slice(1, -1),
      isNotEmpty: true
    },
    [LOCATION.AhsCity.key]: {
      type: LOCATION.AhsCity.type,
      minLength: LOCATION.AhsCity.minLength,
      maxLength: LOCATION.AhsCity.maxLength,
      isNotEmpty: true
    },
    [LOCATION.AhsCountry.key]: {
      type: LOCATION.AhsCountry.type,
      enum: LOCATION.AhsCountry.values
    },
    [LOCATION.InitLatitude.key]: {
      type: LOCATION.InitLatitude.type,
      pattern: LOCATION.InitLatitude.pattern.toString().slice(1, -1),
      isNotEmpty: true
    },
    [LOCATION.InitLongitude.key]: {
      type: LOCATION.InitLongitude.type,
      pattern: LOCATION.InitLongitude.pattern.toString().slice(1, -1),
      isNotEmpty: true
    },
    [LOCATION.InitAltitude.key]: {
      type: LOCATION.InitAltitude.type,
      minimum: LOCATION.InitAltitude.min,
      maximum: LOCATION.InitAltitude.max
    }
  },
  required: [
    GENERAL.AhsName.key,
    GENERAL.InitBuildingSurface.key,
    GENERAL.AhsType.key,
    GENERAL.AhsMainUsage.key,
    LOCATION.AhsAddress1.key,
    LOCATION.AhsZipCode.key,
    LOCATION.AhsCity.key,
    LOCATION.AhsCountry.key,
    LOCATION.InitLatitude.key,
    LOCATION.InitLongitude.key,
    LOCATION.InitAltitude.key
  ],
  additionalProperties: false
};

module.exports = { GENERAL_SCHEMA };
