import { useLingui } from '@lingui/react';
import React from 'react';
import { GAS } from '../../../../../../server/models/design/performance.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../constants';
import { getDefaultValue } from '../../../../utils/param.utils';

const GasBoilerBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('performance.gasBoiler')}>
      <ParamInput
        label={i18n._('performance.InitGasEfficiency')}
        value={form.InitGasEfficiency}
        param={GAS.InitGasEfficiency}
        onChange={(value) => onInputChange('InitGasEfficiency', value)}
        bottomText={getDefaultValue(i18n, GAS.InitGasEfficiency)}
        type={INPUT_TYPE.NUMBER}
      />
    </Bloc>
  );
  //#endregion
};

export default GasBoilerBloc;
