import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ITES } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../constants';
import { getDefaultValue } from '../../../../utils/param.utils';

const ItesBloc = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('cost.ites')} level={2}>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('cost.InitItesCostA')}
            value={form.InitItesCostA}
            param={ITES.InitItesCostA}
            onChange={(value) => onInputChange('InitItesCostA', value)}
            unit
            bottomText={getDefaultValue(i18n, ITES.InitItesCostA)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('cost.InitItesCostB')}
            value={form.InitItesCostB}
            param={ITES.InitItesCostB}
            onChange={(value) => onInputChange('InitItesCostB', value)}
            unit
            bottomText={getDefaultValue(i18n, ITES.InitItesCostB)}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default ItesBloc;
