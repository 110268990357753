import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { STORAGE_KEYS } from '../../../server/constants';
import useAuth from '../hooks/useAuth';

const RequireAuth = ({ children, redirectTo }) => {
  //#region [auth]
  const { user, handleUserChange, handleLogout } = useAuth();
  //#endregion

  //#region [states]
  const [loading, setLoading] = useState(true);
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        if (!localStorage.getItem(STORAGE_KEYS.ACCESS) || user?.UserID) {
          setLoading(false);
        } else {
          setLoading(true);
          await handleUserChange();
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
        await handleLogout();
      }
    })();
  }, [user?.UserID]);
  //#endregion

  //#region [render]
  const accessToken = localStorage.getItem(STORAGE_KEYS.ACCESS);
  return loading ? (
    <></>
  ) : accessToken ? (
    children
  ) : (
    <Navigate to={redirectTo} />
  );
  //#endregion
};

export default RequireAuth;
