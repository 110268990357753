import { useLingui } from '@lingui/react';
import React from 'react';
import { UNIT } from '../../../../../../../server/constants';
import { formatValueWithUnit } from '../../../../../utils/data.utils';

const HPGColdBtesPowerMaxModal = ({ max }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <div className='max-modal'>
      <p>{i18n._('compute.maxParam.top')}</p>
      <br />
      <p>{i18n._('compute.maxParam.hpgColdBtesPowerMax')}</p>
      <br />
      <p>
        {i18n._('compute.maxParam.bottom')}
        <span>{formatValueWithUnit(i18n, max, UNIT.KILOWATT)}</span>
      </p>
    </div>
  );
  //#endregion
};

export default React.memo(HPGColdBtesPowerMaxModal);
