import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import './ExpandableCard.css';

const ExpandableCard = ({ title, children }) => {
  //#region [states]
  const [isOpen, setIsOpen] = useState(true);
  //#endregion

  //#region [render]
  return (
    <div className='card-wrapper expandable-card'>
      <div className='expandable-card-title' onClick={() => setIsOpen(!isOpen)}>
        <FontAwesomeIcon icon={isOpen ? 'caret-down' : 'caret-right'} />
        {title}
      </div>
      {isOpen && <div className='card-content'>{children}</div>}
    </div>
  );
  //#endregion
};

export default ExpandableCard;
