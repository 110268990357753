import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import './Tile.css';

const TileRadio = ({ title, details, image, checked, disabled, onChange }) => {
  //#region [render]
  return (
    <div
      className={`tile tile-radio ${checked ? 'active' : ''}${
        disabled ? ' disabled' : ''
      }`}
      onClick={disabled ? null : () => onChange(!checked)}
    >
      <img src={image} alt={'tile ' + title} />
      <Row>
        <Col xs={9}>
          <span>
            <span className='tile-title'>{title}</span>
            {details && (
              <>
                <FontAwesomeIcon
                  icon='fa-regular fa-circle-question'
                  data-tooltip-id={'tile_radio_tooltip_' + title}
                  data-tooltip-content={details}
                />
                <Tooltip
                  id={'tile_radio_tooltip_' + title}
                  place='bottom'
                  className='info-tooltip'
                  opacity={1}
                />
              </>
            )}
          </span>
        </Col>
        <Col xs={3} className='ps-2'>
          <FontAwesomeIcon
            icon={(checked ? 'fa-solid' : 'fa-regular') + ' fa-circle'}
            onClick={() => onChange(true)}
          />
        </Col>
      </Row>
    </div>
  );
  //#endregion
};

export default React.memo(TileRadio);
