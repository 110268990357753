import { useLingui } from '@lingui/react';
import React from 'react';
import { STD_COLS } from '../../../../../../../../../server/models/design/description.model';
import { isNull } from '../../../../../../../utils/data.utils';

const StdModal = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <div>
      <p>{i18n._('description.stdDesc.acceptedFiles')}</p>
      <div className='description-file-modal-hl' />
      {Object.entries(STD_COLS).map(([stdColName, stdColObj], colIndex) => {
        let text = [stdColName];
        if (!isNull(stdColObj.min)) {
          text.push(
            i18n._('description.modal.min', {
              min: stdColObj.min
            })
          );
        }
        if (!isNull(stdColObj.max)) {
          text.push(
            i18n._('description.modal.max', {
              max: stdColObj.max
            })
          );
        }
        if (stdColObj.unit) {
          text.push(
            i18n._('description.modal.unit', {
              unit: i18n._(`unit.${stdColObj.unit}`)
            })
          );
        }
        if (!stdColObj.mandatory) {
          text.push(i18n._('description.modal.optional'));
        }
        return (
          <p key={'substationNeeds_' + stdColName + '_' + colIndex}>
            {text.join(', ')}
          </p>
        );
      })}
    </div>
  );
  //#endregion
};

export default StdModal;
