// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.worst {
  color: var(--danger-color);
}

.bad {
  color: #ff6600;
}

.middle {
  color: #fbbd32;
}

.good {
  color: #5a7c2e;
}

.best {
  color: var(--success-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/result/sections/summary/SummarySection.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".worst {\n  color: var(--danger-color);\n}\n\n.bad {\n  color: #ff6600;\n}\n\n.middle {\n  color: #fbbd32;\n}\n\n.good {\n  color: #5a7c2e;\n}\n\n.best {\n  color: var(--success-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
