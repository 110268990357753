import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import {
  HOURS_PER_YEAR,
  MILLISECONDS_PER_HOUR
} from '../../../../../../../../server/constants';
import LinesChart from '../../../../../../components/Chart/LinesChart';
import { CHART_MODE } from '../../../../../../constants';
import { DETAILED_RESULTS_CHART_COLORS } from '../../../../../../styles/colors';

const startDate = new Date(new Date().getFullYear(), 0, 0);
const BtesTemperatureLinesChart = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    if (!result.details) return null;
    const { InitYearsSimulations } = result.ComputeResult.inp;
    const newData = [];
    ['T1', 'T2'].forEach((key) => {
      const x = new Array(HOURS_PER_YEAR * InitYearsSimulations);
      const y = new Array(HOURS_PER_YEAR * InitYearsSimulations);
      let timestamp = startDate.getTime();
      for (let i = 0; i < HOURS_PER_YEAR * InitYearsSimulations; ++i) {
        timestamp += MILLISECONDS_PER_HOUR;
        x[i] = new Date(timestamp).toISOString();
        y[i] = result.details[key][i];
      }
      newData.push({
        x,
        y,
        line: { color: DETAILED_RESULTS_CHART_COLORS[key] },
        name: i18n._(`btes.${key}`),
        mode: CHART_MODE.LINES
      });
    });
    return newData;
  }, [result.details]);
  //#endregion

  //#region [render]
  const { InitYearsSimulations } = result.ComputeResult.inp;
  return data ? (
    <LinesChart
      data={data}
      title={i18n._('btesTempLinesChart.title', {
        years: InitYearsSimulations
      })}
      layout={{
        xaxis: {
          tickformat: '%Y'
        }
      }}
      filename={i18n._('plotly.filename', {
        projectName: result.ComputeResult.inp.description,
        title: i18n._('btesTempLinesChart.title', {
          years: InitYearsSimulations
        })
      })}
    />
  ) : null;
  //#endregion
};

export default BtesTemperatureLinesChart;
