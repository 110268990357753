import React, { useContext } from 'react';
import { getSizingParams } from '../../../../../../../../server/models/config/thermalProduction/heatpumps/heatpump.model';
import FormInput from '../../../../../../components/Form/FormInput';
import { INPUT_TYPE } from '../../../../../../constants';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../contexts/PopupContext';
import { isNull } from '../../../../../../utils/data.utils';
import { getTotalLevelsPerNeed } from '../../../../../../utils/heatpump.utils';

const NeedLevelsInputCellRenderer = ({ value, need, onFormChange }) => {
  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const handleLevelsChange = (need, val) => {
    try {
      const { heatpumps } = config.ConfigsSst[0].Data;
      heatpumps.levels[need] = isNull(val) ? totalLevelsPerNeed : val;
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const { list } = config.ConfigsSst[0].Data.heatpumps;
  let totalLevelsPerNeed;
  if (need) totalLevelsPerNeed = getTotalLevelsPerNeed(list, need);
  const SIZING = getSizingParams(totalLevelsPerNeed);
  return (
    <FormInput
      param={SIZING.levels}
      type={INPUT_TYPE.NUMBER}
      onBlur={(newValue) => handleLevelsChange(need, newValue)}
      value={value}
    />
  );
  //#endregion
};

export default NeedLevelsInputCellRenderer;
