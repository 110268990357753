import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FORM_STATUS } from '../../constants';
import './SaveButton.css';

const SaveButton = ({ disabled, formStatus, onClick }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  let btnClassName, btnContent, iconClassName;
  if (formStatus === FORM_STATUS.SAVED) {
    btnContent = i18n._('saved');
    btnClassName = 'saved';
    iconClassName = '';
  } else {
    btnContent =
      formStatus === FORM_STATUS.SAVING ? i18n._('saving') : i18n._('save');
    iconClassName = 'hidden';
  }
  return (
    <Button
      disabled={disabled || formStatus !== FORM_STATUS.DIRTY}
      onClick={onClick}
      className={`save-btn ${btnClassName}`}
    >
      {btnContent}
      {<FontAwesomeIcon icon='check' className={iconClassName} />}
    </Button>
  );
  //#endregion
};

export default React.memo(SaveButton);
