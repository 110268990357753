import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import './Sidebar.css';

const Sidebar = ({ sectionsIds, activeSectionId, onSectionClick }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region states
  const [isOpen, setIsOpen] = useState(false);
  //#endregion

  //#region render
  const loadIcon = (sectionId) =>
    require(`../../../../../image/section-${sectionId}.png`);
  const loadActiveIcon = (sectionId) =>
    require(`../../../../../image/section-${sectionId}-active.png`);
  return (
    <div className='results-sidebar'>
      <FontAwesomeIcon
        icon={isOpen ? 'circle-arrow-left' : 'circle-arrow-right'}
        className='results-sidebar-arrow'
        onClick={() => setIsOpen(!isOpen)}
      />
      <Nav
        className={
          isOpen ? 'results-sidebar-nav' : 'results-sidebar-nav nav-closed'
        }
      >
        {sectionsIds.map((sectionId) => (
          <Nav.Item key={'results_nav_item_' + sectionId}>
            <Nav.Link
              href={`#results_section_${sectionId}`}
              active={activeSectionId === sectionId}
              eventKey={sectionId}
              onClick={() => onSectionClick(sectionId)}
            >
              <img
                key={'results_img_' + sectionId}
                src={
                  activeSectionId === sectionId
                    ? loadActiveIcon(sectionId)
                    : loadIcon(sectionId)
                }
                className='results-sidebar-icon'
                title={!isOpen ? i18n._(`results.${sectionId}`) : null}
              />
              <span className={isOpen ? '' : 'hidden'}>
                {i18n._(`results.${sectionId}`)}
              </span>
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </div>
  );
  //#endregion
};

export default Sidebar;
