import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ST_EQUIPMENT } from '../../../../../../server/constants';
import { getSTParams } from '../../../../../../server/models/design/performance.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import ParamSelect from '../../../../components/ParamForm/ParamSelect';
import { INPUT_TYPE } from '../../../../constants';
import PopupContext from '../../../../contexts/PopupContext';
import azimuthImg from '../../../../image/azimuth.png';
import { getDefaultValue } from '../../../../utils/param.utils';

const SolarThermalBloc = ({
  form,
  onInputChange,
  onSolarThermalModelChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [render]
  const stModel = Object.values(ST_EQUIPMENT).find(
    (model) => model.value === form.InitSolarThermalModel
  );
  const { isCombined } = stModel;
  const ST = getSTParams(stModel);
  const disabledOptions = Object.values(ST_EQUIPMENT)
    .filter((st) => st.disabled)
    .map((st) => st.value);
  return (
    <Bloc title={i18n._('performance.solarThermal')}>
      <div className='performance-row'>
        <ParamSelect
          label={i18n._('performance.InitSolarThermalModel')}
          value={form.InitSolarThermalModel}
          param={ST.InitSolarThermalModel}
          onChange={onSolarThermalModelChange}
          disabledOptions={disabledOptions}
        />
        {isCombined && (
          <span className='performance-combined'>
            <FontAwesomeIcon icon='exclamation-circle' />
            {i18n._('performance.combined.st')}
          </span>
        )}
      </div>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.InitSolarSurfaceTilt')}
            value={form.InitSolarSurfaceTilt}
            param={ST.InitSolarSurfaceTilt}
            onChange={(value) => onInputChange('InitSolarSurfaceTilt', value)}
            topText={i18n._('performance.topSTInitSolarSurfaceTilt')}
            bottomText={getDefaultValue(i18n, ST.InitSolarSurfaceTilt)}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col className='performance-azimuth-col'>
          <ParamInput
            label={i18n._('performance.InitSolarSurfaceAzimuth')}
            value={form.InitSolarSurfaceAzimuth}
            param={ST.InitSolarSurfaceAzimuth}
            onChange={(value) =>
              onInputChange('InitSolarSurfaceAzimuth', value)
            }
            topText={i18n._('performance.topSTInitSolarSurfaceAzimuth')}
            bottomText={getDefaultValue(i18n, ST.InitSolarSurfaceAzimuth)}
            unit
            showInfoIcon
            onInfoClick={() =>
              openInfoModal(
                i18n._('performance.azimuthModalTitle'),
                <img
                  src={azimuthImg}
                  alt='azimuthImg'
                  style={{ width: '90%', height: '90%' }}
                />
              )
            }
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.InitSolarThermalHeta0')}
            value={form.InitSolarThermalHeta0}
            param={ST.InitSolarThermalHeta0}
            onChange={(value) => onInputChange('InitSolarThermalHeta0', value)}
            bottomText={getDefaultValue(i18n, ST.InitSolarThermalHeta0)}
            disabled={form.InitSolarThermalModel !== ST_EQUIPMENT.CUSTOM.value}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('performance.InitSolarThermalB1')}
            value={form.InitSolarThermalB1}
            param={ST.InitSolarThermalB1}
            onChange={(value) => onInputChange('InitSolarThermalB1', value)}
            bottomText={getDefaultValue(i18n, ST.InitSolarThermalB1)}
            disabled={form.InitSolarThermalModel !== ST_EQUIPMENT.CUSTOM.value}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.InitSolarThermalBu')}
            value={form.InitSolarThermalBu}
            param={ST.InitSolarThermalBu}
            onChange={(value) => onInputChange('InitSolarThermalBu', value)}
            bottomText={getDefaultValue(i18n, ST.InitSolarThermalBu)}
            disabled={form.InitSolarThermalModel !== ST_EQUIPMENT.CUSTOM.value}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('performance.InitSolarThermalEpsa')}
            value={form.InitSolarThermalEpsa}
            param={ST.InitSolarThermalEpsa}
            onChange={(value) => onInputChange('InitSolarThermalEpsa', value)}
            bottomText={getDefaultValue(i18n, ST.InitSolarThermalEpsa)}
            disabled={form.InitSolarThermalModel !== ST_EQUIPMENT.CUSTOM.value}
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.InitSolarThermalB2')}
            value={form.InitSolarThermalB2}
            param={ST.InitSolarThermalB2}
            onChange={(value) => onInputChange('InitSolarThermalB2', value)}
            bottomText={getDefaultValue(i18n, ST.InitSolarThermalB2)}
            disabled={form.InitSolarThermalModel !== ST_EQUIPMENT.CUSTOM.value}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('performance.InitSolarThermalHeadlossMceMax')}
            value={form.InitSolarThermalHeadlossMceMax}
            param={ST.InitSolarThermalHeadlossMceMax}
            onChange={(value) =>
              onInputChange('InitSolarThermalHeadlossMceMax', value)
            }
            bottomText={getDefaultValue(
              i18n,
              ST.InitSolarThermalHeadlossMceMax
            )}
            disabled={form.InitSolarThermalModel !== ST_EQUIPMENT.CUSTOM.value}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.InitSolarThermalFlowRate')}
            value={form.InitSolarThermalFlowRate}
            param={ST.InitSolarThermalFlowRate}
            onChange={(value) =>
              onInputChange('InitSolarThermalFlowRate', value)
            }
            bottomText={getDefaultValue(i18n, ST.InitSolarThermalFlowRate)}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('performance.InitSolarDeltaTemp')}
            value={form.InitSolarDeltaTemp}
            param={ST.InitSolarDeltaTemp}
            onChange={(value) => onInputChange('InitSolarDeltaTemp', value)}
            bottomText={getDefaultValue(i18n, ST.InitSolarDeltaTemp)}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.InitSolarTemperatureMin')}
            value={form.InitSolarTemperatureMin}
            param={ST.InitSolarTemperatureMin}
            onChange={(value) =>
              onInputChange('InitSolarTemperatureMin', value)
            }
            bottomText={getDefaultValue(i18n, ST.InitSolarTemperatureMin)}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
        <Col>
          <ParamInput
            label={i18n._('performance.InitSolarInjectionRatio')}
            value={form.InitSolarInjectionRatio}
            param={ST.InitSolarInjectionRatio}
            onChange={(value) =>
              onInputChange('InitSolarInjectionRatio', value)
            }
            bottomText={getDefaultValue(i18n, ST.InitSolarInjectionRatio)}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default SolarThermalBloc;
