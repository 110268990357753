import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../SectionTitle';
import './Section.css';

const Section = ({ title, children, to, open, level = 1, className }) => {
  //#region [states]
  const [isOpen, setIsOpen] = useState(open);
  //#endregion

  //#region [render]
  const penStyle = {
    1: { fontSize: '19px' },
    2: { fontSize: '16px' },
    3: { fontSize: '15px' }
  };
  return (
    <div className={`section${className ? ' ' + className : ''}`}>
      <SectionTitle
        className='cursor-pointer'
        level={level}
        onClick={() => setIsOpen(!isOpen)}
      >
        <FontAwesomeIcon icon={isOpen ? 'caret-down' : 'caret-right'} />
        {title}
        {to && (
          <Link to={to}>
            <FontAwesomeIcon
              icon='fa-solid fa-pen-to-square'
              style={penStyle[level]}
            />
          </Link>
        )}
      </SectionTitle>
      {isOpen && <div className='section-content'>{children}</div>}
    </div>
  );
  //#endregion
};

export default Section;
