import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import Card from '../../../../../../../components/Card/Card';
import LinesChart from '../../../../../../../components/Chart/LinesChart';
import { CHART_MODE, CHART_TYPE } from '../../../../../../../constants';
import { BASIC_COLORS } from '../../../../../../../styles/colors';
import { getOptionName } from '../../../../../../../utils/compute.utils';
import { getDeepValue } from '../../../../../../../utils/data.utils';
import './ResultsChart.css';
import {
  NO_VALUE,
  NO_VALUE_OPTION,
  OPTIONS,
  getFullPath,
  getOption
} from './options';

const ResultsChart = ({
  comparedResults,
  yLeftAxis,
  xAxis,
  yRightAxis,
  onYLeftChange,
  onXChange,
  onYRightChange,
  onDelete
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  const years = comparedResults[0].ComputeResult.inp.InitBalanceDuration;

  //#region [states]
  const [xAxisOptn, setXAxisOptn] = useState(getOption(xAxis, years));
  const [yLeftAxisOptn, setYLeftAxisOptn] = useState(
    getOption(yLeftAxis, years)
  );
  const [yRightAxisOptn, setYRightAxisOptn] = useState(
    getOption(yRightAxis, years)
  );
  //#endregion

  //#region [effects]
  useEffect(() => {
    setXAxisOptn(getOption(xAxis, years));
  }, [xAxis, years]);

  useEffect(() => {
    setYLeftAxisOptn(getOption(yLeftAxis, years));
  }, [yLeftAxis, years]);

  useEffect(() => {
    setYRightAxisOptn(getOption(yRightAxis, years));
  }, [yRightAxis, years]);
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    const newData = [];
    comparedResults.forEach((result, index) => {
      // nom du résultat
      const traceNameArr = [i18n._('result', { index: index + 1 })];
      const description =
        result.descriptionName ??
        i18n._('description', { index: result.descriptionIndex + 1 });
      traceNameArr.push(description);
      traceNameArr.push(getOptionName(i18n, result.GoalPart, result.Comment));
      const traceName = traceNameArr.join(
        i18n._('results.summary.chart.traceName.separator')
      );

      // y gauche
      const yLeftTrace = {
        y: [getDeepValue(result.ComputeResult, getFullPath(yLeftAxisOptn))],
        type: CHART_TYPE.SCATTER,
        mode: CHART_MODE.MARKERS,
        name: traceName,
        yaxis: 'y1',
        marker: {
          symbol: 'circle',
          size: 13,
          color: BASIC_COLORS[index]
        },
        hovertemplate: '%{y:.3s}'
      };

      // y droite
      let yRightTrace;
      if (yRightAxisOptn.key !== NO_VALUE) {
        yRightTrace = {
          y: [getDeepValue(result.ComputeResult, getFullPath(yRightAxisOptn))],
          type: CHART_TYPE.SCATTER,
          mode: CHART_MODE.MARKERS,
          name: traceName,
          yaxis: 'y2',
          marker: {
            symbol: 'triangle-up-open',
            size: 15,
            color: BASIC_COLORS[index]
          },
          hovertemplate: '%{y:.3s}'
        };
      }

      // x
      const x =
        xAxisOptn.key !== NO_VALUE
          ? [getDeepValue(result.ComputeResult, getFullPath(xAxisOptn))]
          : [index + 1];
      yLeftTrace.x = x;

      newData.push(yLeftTrace);
      if (yRightTrace) {
        yRightTrace.x = x;
        newData.push(yRightTrace);
      }
    });
    return newData;
  }, [
    comparedResults.length,
    xAxisOptn.key,
    yLeftAxisOptn.key,
    yRightAxisOptn.key
  ]);

  const filename = useMemo(() => {
    const name = [i18n._('results.summary.chart.filename')];
    name.push(i18n._(yLeftAxisOptn.translateId, yLeftAxisOptn.translateParams));
    if (xAxisOptn.key !== NO_VALUE)
      name.push(i18n._(xAxisOptn.translateId, xAxisOptn.translateParams));
    if (yRightAxisOptn.key !== NO_VALUE)
      name.push(
        i18n._(yRightAxisOptn.translateId, yRightAxisOptn.translateParams)
      );
    return name.join(i18n._('results.summary.chart.filename.separator'));
  }, [xAxisOptn.key, yLeftAxisOptn.key, yRightAxisOptn.key]);
  //#endregion

  //#region [methods]
  const handleXAxisChange = (evt) => {
    setXAxisOptn(getOption(evt.target.value, years));
    onXChange(evt.target.value);
  };

  const handleYLeftAxisChange = (evt) => {
    setYLeftAxisOptn(getOption(evt.target.value, years));
    onYLeftChange(evt.target.value);
  };

  const handleYRightAxisChange = (evt) => {
    setYRightAxisOptn(getOption(evt.target.value, years));
    onYRightChange(evt.target.value);
  };
  //#endregion

  //#region [render]
  if (!data) return null;
  const layout = {
    autosize: true,
    margin: { t: 50 },
    xaxis: {
      title: {
        text: i18n._(xAxisOptn.translateId, xAxisOptn.translateParams),
        font: { size: 12 }
      }
    },
    yaxis: {
      title: {
        text: i18n._(yLeftAxisOptn.translateId, yLeftAxisOptn.translateParams),
        standoff: 30,
        font: { size: 12 }
      },
      side: 'left',
      automargin: false
    },
    yaxis2: {
      title: {
        text: i18n._(
          yRightAxisOptn.translateId,
          yRightAxisOptn.translateParams
        ),
        standoff: 30,
        font: { size: 12 }
      },
      overlaying: 'y',
      side: 'right',
      automargin: false
    },
    legend: {
      x: 1.2,
      font: { size: 11 }
    }
  };
  if (xAxisOptn.key === NO_VALUE) {
    layout.xaxis.dtick = 1;
    delete layout.xaxis.title;
  } else {
    layout.xaxis.hoverformat = `.3s`;
  }
  return data ? (
    <Card>
      <div className='results-chart-selects'>
        <Form.Group>
          <Form.Label htmlFor='xAxis'>
            {i18n._('results.summary.chart.xAxis')}
          </Form.Label>
          <Form.Select
            className='ellipsis'
            value={xAxisOptn.key}
            onChange={handleXAxisChange}
            name='xAxis'
            id='xAxis'
          >
            {[NO_VALUE_OPTION].concat(OPTIONS(years)).map((option) => {
              return (
                <option
                  key={'x_' + option.key}
                  value={option.key}
                  disabled={
                    (option.key !== NO_VALUE &&
                      option.key === yLeftAxisOptn.key) ||
                    (option.key !== NO_VALUE &&
                      option.key === yRightAxisOptn.key)
                  }
                >
                  {i18n._(option.translateId, option.translateParams)}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor='yLeftAxis'>
            {i18n._('results.summary.chart.yLeftAxis')}
          </Form.Label>
          <Form.Select
            className='ellipsis'
            value={yLeftAxisOptn.key}
            onChange={handleYLeftAxisChange}
            name='yLeftAxis'
            id='yLeftAxis'
          >
            {OPTIONS(years).map((option) => (
              <option
                key={'yLeft_' + option.key}
                value={option.key}
                disabled={
                  (option.key !== NO_VALUE && option.key === xAxisOptn.key) ||
                  (option.key !== NO_VALUE && option.key === yRightAxisOptn.key)
                }
              >
                {i18n._(option.translateId, option.translateParams)}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor='yRightAxis'>
            {i18n._('results.summary.chart.yRightAxis')}
          </Form.Label>
          <Form.Select
            className='ellipsis'
            value={yRightAxisOptn.key}
            onChange={handleYRightAxisChange}
            name='yRightAxis'
            id='yRightAxis'
          >
            {[NO_VALUE_OPTION].concat(OPTIONS(years)).map((option) => (
              <option
                key={'yRight__' + option.key}
                value={option.key}
                disabled={
                  (option.key !== NO_VALUE &&
                    option.key === yLeftAxisOptn.key) ||
                  (option.key !== NO_VALUE && option.key === xAxisOptn.key)
                }
              >
                {i18n._(option.translateId, option.translateParams)}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
      {onDelete && (
        <>
          <FontAwesomeIcon
            icon='trash-alt'
            className='results-chart-trash'
            onClick={onDelete}
            data-tooltip-id='delete-tooltip'
            data-tooltip-content={i18n._('results.summary.chart.delete')}
          />
          <Tooltip
            id='delete-tooltip'
            place='bottom'
            className='results-chart-tooltip'
            opacity={1}
          />
        </>
      )}
      <LinesChart data={data} layout={layout} filename={filename} />
    </Card>
  ) : null;
  //#endregion
};

export default ResultsChart;
