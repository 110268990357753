export const NO_VALUE = 'noValue';

export const NO_VALUE_OPTION = {
  key: NO_VALUE,
  translateId: 'results.compareResults.graph.noValue'
};

export const OPTIONS = (years) => [
  {
    key: 'BtesLength',
    path: 'params',
    translateId: 'results.summary.BtesLength',
    precision: 0
  },
  {
    key: 'TotalHPGPthHeatNormMax',
    path: 'summary',
    translateId: 'results.summary.TotalHPGPthHeatNormMax',
    precision: 0
  },
  {
    key: 'TotalHPGPthColdNormMax',
    path: 'summary',
    translateId: 'results.summary.TotalHPGPthColdNormMax',
    precision: 0
  },
  {
    key: 'TotalHPAPthHeatNormMax',
    path: 'summary',
    translateId: 'results.summary.TotalHPAPthHeatNormMax',
    precision: 0
  },
  {
    key: 'TotalHPAPthColdNormMax',
    path: 'summary',
    translateId: 'results.summary.TotalHPAPthColdNormMax',
    precision: 0
  },
  {
    key: 'EnergyFinal',
    path: 'summary',
    translateId: 'results.summary.EnergyFinal',
    precision: 0
  },
  {
    key: 'PartHpgTfp',
    path: 'summary',
    translateId: 'results.summary.PartHpgTfp',
    precision: 0
  },
  {
    key: 'OverallYield',
    path: 'summary',
    translateId: 'results.summary.OverallYield',
    precision: 1
  },
  {
    key: 'TotalAvecFondChaleur',
    path: 'summary.CAPEX',
    translateId: 'results.summary.TotalAvecFondChaleur',
    precision: 0
  },
  {
    key: 'OPEXPerYear',
    path: 'summary',
    translateId: 'results.summary.OPEXPerYear',
    precision: 0
  },
  {
    key: 'TotalCost',
    path: 'summary',
    translateId: 'results.summary.TotalCost',
    translateParams: { years },
    precision: 0
  },
  {
    key: 'BtesT2Final',
    path: 'summary',
    translateId: 'results.summary.BtesT2Final',
    precision: 1
  }
];

export const getOption = (optnKey, years) => {
  return optnKey === NO_VALUE
    ? NO_VALUE_OPTION
    : OPTIONS(years).find((opt) => opt.key === optnKey);
};

export const getOptionKey = (optnKey, years) => getOption(optnKey, years)?.key;

export const getFullPath = (option) => `${option.path}.${option.key}`;

export const DEFAULT_OPTIONS_KEYS = {
  yLeftAxis: 'TotalCost',
  xAxis: 'BtesLength',
  yRightAxis: 'EnergyFinal'
};
