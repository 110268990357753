// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-cards-wrapper {
  width: 100%;
}

.results-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 5px 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ResultsCards/ResultsCards.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,WAAW;EACX,mBAAmB;MACf,eAAe;EACnB,aAAa;AACf","sourcesContent":[".results-cards-wrapper {\n  width: 100%;\n}\n\n.results-cards {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  width: 100%;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  gap: 5px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
