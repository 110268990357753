const AHS_TYPE = Object.freeze({
  CONSTRUCTION: 'CONSTRUCTION',
  RENOVATION: 'RENOVATION'
});

const APP = Object.freeze({
  DESIGN: 'design',
  CONFIG: 'config'
});

const BTES_COST = Object.freeze({
  FR: {
    '01': {
      InitBtesCostA: 103.6,
      InitBtesCostB: 2500
    },
    '02': {
      InitBtesCostA: 88,
      InitBtesCostB: 0
    },
    '03': {
      InitBtesCostA: 100.3,
      InitBtesCostB: 2500
    },
    '04': {
      InitBtesCostA: 80.1,
      InitBtesCostB: 5000
    },
    '05': {
      InitBtesCostA: 80.1,
      InitBtesCostB: 5000
    },
    '06': {
      InitBtesCostA: 86,
      InitBtesCostB: 7500
    },
    '07': {
      InitBtesCostA: 100.3,
      InitBtesCostB: 5000
    },
    '08': {
      InitBtesCostA: 85.3,
      InitBtesCostB: 2500
    },
    '09': {
      InitBtesCostA: 73.5,
      InitBtesCostB: 7500
    },
    10: {
      InitBtesCostA: 77.6,
      InitBtesCostB: 0
    },
    11: {
      InitBtesCostA: 68.2,
      InitBtesCostB: 5000
    },
    12: {
      InitBtesCostA: 87.4,
      InitBtesCostB: 5000
    },
    13: {
      InitBtesCostA: 80.1,
      InitBtesCostB: 5000
    },
    14: {
      InitBtesCostA: 81,
      InitBtesCostB: 2500
    },
    15: {
      InitBtesCostA: 59.6,
      InitBtesCostB: 2500
    },
    16: {
      InitBtesCostA: 87.3,
      InitBtesCostB: 2500
    },
    17: {
      InitBtesCostA: 102.7,
      InitBtesCostB: 2500
    },
    18: {
      InitBtesCostA: 103.4,
      InitBtesCostB: 0
    },
    19: {
      InitBtesCostA: 81.4,
      InitBtesCostB: 2500
    },
    21: {
      InitBtesCostA: 68.5,
      InitBtesCostB: 2500
    },
    22: {
      InitBtesCostA: 78.6,
      InitBtesCostB: 2500
    },
    23: {
      InitBtesCostA: 87.3,
      InitBtesCostB: 2500
    },
    24: {
      InitBtesCostA: 89.3,
      InitBtesCostB: 2500
    },
    25: {
      InitBtesCostA: 87.4,
      InitBtesCostB: 2500
    },
    26: {
      InitBtesCostA: 102.4,
      InitBtesCostB: 5000
    },
    27: {
      InitBtesCostA: 81.1,
      InitBtesCostB: 0
    },
    28: {
      InitBtesCostA: 106.8,
      InitBtesCostB: 0
    },
    29: {
      InitBtesCostA: 81.2,
      InitBtesCostB: 5000
    },
    30: {
      InitBtesCostA: 94.5,
      InitBtesCostB: 5000
    },
    31: {
      InitBtesCostA: 80.8,
      InitBtesCostB: 5000
    },
    32: {
      InitBtesCostA: 65.3,
      InitBtesCostB: 5000
    },
    33: {
      InitBtesCostA: 87.3,
      InitBtesCostB: 5000
    },
    34: {
      InitBtesCostA: 90.2,
      InitBtesCostB: 5000
    },
    35: {
      InitBtesCostA: 70.6,
      InitBtesCostB: 2500
    },
    36: {
      InitBtesCostA: 96.7,
      InitBtesCostB: 0
    },
    37: {
      InitBtesCostA: 82.9,
      InitBtesCostB: 0
    },
    38: {
      InitBtesCostA: 102.5,
      InitBtesCostB: 5000
    },
    39: {
      InitBtesCostA: 88.1,
      InitBtesCostB: 2500
    },
    40: {
      InitBtesCostA: 85.1,
      InitBtesCostB: 5000
    },
    41: {
      InitBtesCostA: 70.4,
      InitBtesCostB: 0
    },
    42: {
      InitBtesCostA: 83.3,
      InitBtesCostB: 2500
    },
    43: {
      InitBtesCostA: 62.4,
      InitBtesCostB: 2500
    },
    44: {
      InitBtesCostA: 66.7,
      InitBtesCostB: 2500
    },
    45: {
      InitBtesCostA: 107.5,
      InitBtesCostB: 0
    },
    46: {
      InitBtesCostA: 65.4,
      InitBtesCostB: 5000
    },
    47: {
      InitBtesCostA: 87.3,
      InitBtesCostB: 5000
    },
    48: {
      InitBtesCostA: 82.4,
      InitBtesCostB: 5000
    },
    49: {
      InitBtesCostA: 56.9,
      InitBtesCostB: 2500
    },
    50: {
      InitBtesCostA: 81,
      InitBtesCostB: 2500
    },
    51: {
      InitBtesCostA: 116.6,
      InitBtesCostB: 0
    },
    52: {
      InitBtesCostA: 92,
      InitBtesCostB: 2500
    },
    53: {
      InitBtesCostA: 67.9,
      InitBtesCostB: 2500
    },
    54: {
      InitBtesCostA: 100.5,
      InitBtesCostB: 2500
    },
    55: {
      InitBtesCostA: 81,
      InitBtesCostB: 2500
    },
    56: {
      InitBtesCostA: 66.4,
      InitBtesCostB: 2500
    },
    57: {
      InitBtesCostA: 99.1,
      InitBtesCostB: 2500
    },
    58: {
      InitBtesCostA: 85.1,
      InitBtesCostB: 0
    },
    59: {
      InitBtesCostA: 87.4,
      InitBtesCostB: 2500
    },
    60: {
      InitBtesCostA: 106.6,
      InitBtesCostB: 0
    },
    61: {
      InitBtesCostA: 81,
      InitBtesCostB: 0
    },
    62: {
      InitBtesCostA: 85.8,
      InitBtesCostB: 2500
    },
    63: {
      InitBtesCostA: 81,
      InitBtesCostB: 2500
    },
    64: {
      InitBtesCostA: 87.3,
      InitBtesCostB: 7500
    },
    65: {
      InitBtesCostA: 73.6,
      InitBtesCostB: 7500
    },
    66: {
      InitBtesCostA: 78.6,
      InitBtesCostB: 7500
    },
    67: {
      InitBtesCostA: 84,
      InitBtesCostB: 2500
    },
    68: {
      InitBtesCostA: 84,
      InitBtesCostB: 2500
    },
    69: {
      InitBtesCostA: 107.6,
      InitBtesCostB: 2500
    },
    70: {
      InitBtesCostA: 84,
      InitBtesCostB: 2500
    },
    71: {
      InitBtesCostA: 89.2,
      InitBtesCostB: 2500
    },
    72: {
      InitBtesCostA: 67.9,
      InitBtesCostB: 0
    },
    73: {
      InitBtesCostA: 97.1,
      InitBtesCostB: 5000
    },
    74: {
      InitBtesCostA: 97.1,
      InitBtesCostB: 5000
    },
    75: {
      InitBtesCostA: 97.3,
      InitBtesCostB: 0
    },
    76: {
      InitBtesCostA: 82.7,
      InitBtesCostB: 0
    },
    77: {
      InitBtesCostA: 72.4,
      InitBtesCostB: 0
    },
    78: {
      InitBtesCostA: 108.6,
      InitBtesCostB: 0
    },
    79: {
      InitBtesCostA: 87.3,
      InitBtesCostB: 2500
    },
    80: {
      InitBtesCostA: 76.9,
      InitBtesCostB: 0
    },
    81: {
      InitBtesCostA: 66.6,
      InitBtesCostB: 5000
    },
    82: {
      InitBtesCostA: 77.1,
      InitBtesCostB: 5000
    },
    83: {
      InitBtesCostA: 80.1,
      InitBtesCostB: 7500
    },
    84: {
      InitBtesCostA: 69.8,
      InitBtesCostB: 5000
    },
    85: {
      InitBtesCostA: 83.9,
      InitBtesCostB: 2500
    },
    86: {
      InitBtesCostA: 87.3,
      InitBtesCostB: 2500
    },
    87: {
      InitBtesCostA: 108.3,
      InitBtesCostB: 2500
    },
    88: {
      InitBtesCostA: 86,
      InitBtesCostB: 2500
    },
    89: {
      InitBtesCostA: 85.1,
      InitBtesCostB: 0
    },
    90: {
      InitBtesCostA: 84,
      InitBtesCostB: 2500
    },
    91: {
      InitBtesCostA: 93.6,
      InitBtesCostB: 0
    },
    92: {
      InitBtesCostA: 98.1,
      InitBtesCostB: 0
    },
    93: {
      InitBtesCostA: 114.5,
      InitBtesCostB: 0
    },
    94: {
      InitBtesCostA: 97.3,
      InitBtesCostB: 0
    },
    95: {
      InitBtesCostA: 107.4,
      InitBtesCostB: 0
    },
    '2A': {
      InitBtesCostA: 80.5,
      InitBtesCostB: 10000
    },
    '2B': {
      InitBtesCostA: 80.5,
      InitBtesCostB: 10000
    },
    France: {
      InitBtesCostA: 86,
      InitBtesCostB: 5000
    }
  }
});

const BTES_TEMP_KEYS = ['T1', 'T2', 'TEauInBtes', 'TEauOutBtes'];

const CALCULATION_STATUS = Object.freeze({
  IN_PROGRESS: 'progress',
  FINISHED: 'finished'
});

const COMPASS_STUDY = Object.freeze({
  startYear: 2023,
  endYear: 2030,
  elec: {
    prices: [260.2, 227, 202.7, 194.3, 186.9, 179.3, 171.2, 166.1],
    evolPercents: [0, -12.8, -10.7, -4.1, -3.8, -4.1, -4.5, -3]
  },
  gas: {
    prices: [167.3, 142, 113.6, 107.7, 101.8, 95.9, 90.1, 84.2],
    evolPercents: [0, -15.1, -20.0, -5.2, -5.5, -5.8, -6.0, -6.5]
  }
});

const COMPUTE_STATUS = Object.freeze({
  ERROR: 0,
  PENDING: 1,
  IN_PROGRESS: 2,
  CANCELED: 3,
  SUCCESS: 100
});

const COMPUTE_TYPE = Object.freeze({
  OPTI: 1,
  SIMU: 2
});

const COUNTRY = Object.freeze({
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas (the)',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia (Plurinational State of)',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory (the)',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands (the)',
  CF: 'Central African Republic (the)',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands (the)',
  CO: 'Colombia',
  KM: 'Comoros (the)',
  CD: 'Congo (the Democratic Republic of the)',
  CG: 'Congo (the)',
  CK: 'Cook Islands (the)',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czechia',
  CI: "Côte d'Ivoire",
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic (the)',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  SZ: 'Eswatini',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (the) [Malvinas]',
  FO: 'Faroe Islands (the)',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories (the)',
  GA: 'Gabon',
  GM: 'Gambia (the)',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See (the)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran (Islamic Republic of)',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: "Korea (the Democratic People's Republic of)",
  KR: 'Korea (the Republic of)',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic (the)",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands (the)',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia (Federated States of)',
  MD: 'Moldova (the Republic of)',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands (the)',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger (the)',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands (the)',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine, State of',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines (the)',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  MK: 'Republic of North Macedonia',
  RO: 'Romania',
  RU: 'Russian Federation (the)',
  RW: 'Rwanda',
  RE: 'Réunion',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan (the)',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands (the)',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates (the)',
  GB: 'United Kingdom of Great Britain and Northern Ireland (the)',
  UM: 'United States Minor Outlying Islands (the)',
  US: 'United States of America (the)',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela (Bolivarian Republic of)',
  VN: 'Viet Nam',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  AX: 'Åland Islands'
});

const DAYS_PER_MONTH = Object.freeze({
  january: 31,
  february: 28,
  march: 31,
  april: 30,
  may: 31,
  june: 30,
  july: 31,
  august: 31,
  september: 30,
  october: 31,
  november: 30,
  december: 31
});

const DELETED_NAMING = Object.freeze({
  UserFirstName: '_user_',
  UserLastName: '_deleted_'
});

const DEPARTMENT = Object.freeze({
  FR: {
    '01': {
      dep: 'Ain',
      region: 'Auvergne-Rhône-Alpes'
    },
    '02': {
      dep: 'Aisne',
      region: 'Hauts-de-France'
    },
    '03': {
      dep: 'Allier',
      region: 'Auvergne-Rhône-Alpes'
    },
    '04': {
      dep: 'Alpes-de-Haute-Provence',
      region: "Provence-Alpes-Côte d'Azur"
    },
    '05': {
      dep: 'Hautes-Alpes',
      region: "Provence-Alpes-Côte d'Azur"
    },
    '06': {
      dep: 'Alpes-Maritimes',
      region: "Provence-Alpes-Côte d'Azur"
    },
    '07': {
      dep: 'Ardèche',
      region: 'Auvergne-Rhône-Alpes'
    },
    '08': {
      dep: 'Ardennes',
      region: 'Grand Est'
    },
    '09': {
      dep: 'Ariège',
      region: 'Occitanie'
    },
    10: {
      dep: 'Aube',
      region: 'Grand Est'
    },
    11: {
      dep: 'Aude',
      region: 'Occitanie'
    },
    12: {
      dep: 'Aveyron',
      region: 'Occitanie'
    },
    13: {
      dep: 'Bouches-du-Rhône',
      region: "Provence-Alpes-Côte d'Azur"
    },
    14: {
      dep: 'Calvados',
      region: 'Normandie'
    },
    15: {
      dep: 'Cantal',
      region: 'Auvergne-Rhône-Alpes'
    },
    16: {
      dep: 'Charente',
      region: 'Nouvelle-Aquitaine'
    },
    17: {
      dep: 'Charente-Maritime',
      region: 'Nouvelle-Aquitaine'
    },
    18: {
      dep: 'Cher',
      region: 'Centre-Val de Loire'
    },
    19: {
      dep: 'Corrèze',
      region: 'Nouvelle-Aquitaine'
    },
    21: {
      dep: "Côte-d'Or",
      region: 'Bourgogne-Franche-Comté'
    },
    22: {
      dep: "Côtes-d'Armor",
      region: 'Bretagne'
    },
    23: {
      dep: 'Creuse',
      region: 'Nouvelle-Aquitaine'
    },
    24: {
      dep: 'Dordogne',
      region: 'Nouvelle-Aquitaine'
    },
    25: {
      dep: 'Doubs',
      region: 'Bourgogne-Franche-Comté'
    },
    26: {
      dep: 'Drôme',
      region: 'Auvergne-Rhône-Alpes'
    },
    27: {
      dep: 'Eure',
      region: 'Normandie'
    },
    28: {
      dep: 'Eure-et-Loir',
      region: 'Centre-Val de Loire'
    },
    29: {
      dep: 'Finistère',
      region: 'Bretagne'
    },
    30: {
      dep: 'Gard',
      region: 'Occitanie'
    },
    31: {
      dep: 'Haute-Garonne',
      region: 'Occitanie'
    },
    32: {
      dep: 'Gers',
      region: 'Occitanie'
    },
    33: {
      dep: 'Gironde',
      region: 'Nouvelle-Aquitaine'
    },
    34: {
      dep: 'Hérault',
      region: 'Occitanie'
    },
    35: {
      dep: 'Ille-et-Vilaine',
      region: 'Bretagne'
    },
    36: {
      dep: 'Indre',
      region: 'Centre-Val de Loire'
    },
    37: {
      dep: 'Indre-et-Loire',
      region: 'Centre-Val de Loire'
    },
    38: {
      dep: 'Isère',
      region: 'Auvergne-Rhône-Alpes'
    },
    39: {
      dep: 'Jura',
      region: 'Bourgogne-Franche-Comté'
    },
    40: {
      dep: 'Landes',
      region: 'Nouvelle-Aquitaine'
    },
    41: {
      dep: 'Loir-et-Cher',
      region: 'Centre-Val de Loire'
    },
    42: {
      dep: 'Loire',
      region: 'Auvergne-Rhône-Alpes'
    },
    43: {
      dep: 'Haute-Loire',
      region: 'Auvergne-Rhône-Alpes'
    },
    44: {
      dep: 'Loire-Atlantique',
      region: 'Pays de la Loire'
    },
    45: {
      dep: 'Loiret',
      region: 'Centre-Val de Loire'
    },
    46: {
      dep: 'Lot',
      region: 'Occitanie'
    },
    47: {
      dep: 'Lot-et-Garonne',
      region: 'Nouvelle-Aquitaine'
    },
    48: {
      dep: 'Lozère',
      region: 'Occitanie'
    },
    49: {
      dep: 'Maine-et-Loire',
      region: 'Pays de la Loire'
    },
    50: {
      dep: 'Manche',
      region: 'Normandie'
    },
    51: {
      dep: 'Marne',
      region: 'Grand Est'
    },
    52: {
      dep: 'Haute-Marne',
      region: 'Grand Est'
    },
    53: {
      dep: 'Mayenne',
      region: 'Pays de la Loire'
    },
    54: {
      dep: 'Meurthe-et-Moselle',
      region: 'Grand Est'
    },
    55: {
      dep: 'Meuse',
      region: 'Grand Est'
    },
    56: {
      dep: 'Morbihan',
      region: 'Bretagne'
    },
    57: {
      dep: 'Moselle',
      region: 'Grand Est'
    },
    58: {
      dep: 'Nièvre',
      region: 'Bourgogne-Franche-Comté'
    },
    59: {
      dep: 'Nord',
      region: 'Hauts-de-France'
    },
    60: {
      dep: 'Oise',
      region: 'Hauts-de-France'
    },
    61: {
      dep: 'Orne',
      region: 'Normandie'
    },
    62: {
      dep: 'Pas-de-Calais',
      region: 'Hauts-de-France'
    },
    63: {
      dep: 'Puy-de-Dôme',
      region: 'Auvergne-Rhône-Alpes'
    },
    64: {
      dep: 'Pyrénées-Atlantiques',
      region: 'Nouvelle-Aquitaine'
    },
    65: {
      dep: 'Hautes-Pyrénées',
      region: 'Occitanie'
    },
    66: {
      dep: 'Pyrénées-Orientales',
      region: 'Occitanie'
    },
    67: {
      dep: 'Bas-Rhin',
      region: 'Grand Est'
    },
    68: {
      dep: 'Haut-Rhin',
      region: 'Grand Est'
    },
    69: {
      dep: 'Rhône',
      region: 'Auvergne-Rhône-Alpes'
    },
    70: {
      dep: 'Haute-Saône',
      region: 'Bourgogne-Franche-Comté'
    },
    71: {
      dep: 'Saône-et-Loire',
      region: 'Bourgogne-Franche-Comté'
    },
    72: {
      dep: 'Sarthe',
      region: 'Pays de la Loire'
    },
    73: {
      dep: 'Savoie',
      region: 'Auvergne-Rhône-Alpes'
    },
    74: {
      dep: 'Haute-Savoie',
      region: 'Auvergne-Rhône-Alpes'
    },
    75: {
      dep: 'Paris',
      region: 'Île-de-France'
    },
    76: {
      dep: 'Seine-Maritime',
      region: 'Normandie'
    },
    77: {
      dep: 'Seine-et-Marne',
      region: 'Île-de-France'
    },
    78: {
      dep: 'Yvelines',
      region: 'Île-de-France'
    },
    79: {
      dep: 'Deux-Sèvres',
      region: 'Nouvelle-Aquitaine'
    },
    80: {
      dep: 'Somme',
      region: 'Hauts-de-France'
    },
    81: {
      dep: 'Tarn',
      region: 'Occitanie'
    },
    82: {
      dep: 'Tarn-et-Garonne',
      region: 'Occitanie'
    },
    83: {
      dep: 'Var',
      region: "Provence-Alpes-Côte d'Azur"
    },
    84: {
      dep: 'Vaucluse',
      region: "Provence-Alpes-Côte d'Azur"
    },
    85: {
      dep: 'Vendée',
      region: 'Pays de la Loire'
    },
    86: {
      dep: 'Vienne',
      region: 'Nouvelle-Aquitaine'
    },
    87: {
      dep: 'Haute-Vienne',
      region: 'Nouvelle-Aquitaine'
    },
    88: {
      dep: 'Vosges',
      region: 'Grand Est'
    },
    89: {
      dep: 'Yonne',
      region: 'Bourgogne-Franche-Comté'
    },
    90: {
      dep: 'Territoire de Belfort',
      region: 'Bourgogne-Franche-Comté'
    },
    91: {
      dep: 'Essonne',
      region: 'Île-de-France'
    },
    92: {
      dep: 'Hauts-de-Seine',
      region: 'Île-de-France'
    },
    93: {
      dep: 'Seine-Saint-Denis',
      region: 'Île-de-France'
    },
    94: {
      dep: 'Val-de-Marne',
      region: 'Île-de-France'
    },
    95: {
      dep: "Val-d'Oise",
      region: 'Île-de-France'
    },
    971: {
      dep: 'Guadeloupe',
      region: 'Guadeloupe'
    },
    972: {
      dep: 'Martinique',
      region: 'Martinique'
    },
    973: {
      dep: 'Guyane',
      region: 'Guyane'
    },
    974: {
      dep: 'La Réunion',
      region: 'La Réunion'
    },
    976: {
      dep: 'Mayotte',
      region: 'Mayotte'
    },
    '2A': {
      dep: 'Corse-du-Sud',
      region: 'Corse'
    },
    '2B': {
      dep: 'Haute-Corse',
      region: 'Corse'
    }
  }
});

const DHW_TYPE = Object.freeze({
  STORAGE: 'storage',
  SEMI: 'semiInstantaneous',
  PREPARATORY: 'preparatoryTank'
});

const DST_TEMP_DELTA = 5;

const ERROR_ENGINE = Object.freeze({
  unknown: {
    message: true
  },
  redis_unavailable: {
    message: false,
    causes: [{ actions: 2 }]
  },
  end_allreject: {
    message: true,
    causes: [{ actions: 3 }]
  },
  end_all_crash: {
    message: true,
    causes: [{ actions: 2 }, { actions: 2 }, { actions: 1 }]
  },
  pre_no_cold_in_std: {
    message: false,
    causes: [{ actions: 2 }]
  },
  pre_no_hot_in_std: {
    message: false,
    causes: [{ actions: 2 }]
  },
  pre_no_dhw_in_std: {
    message: false,
    causes: [{ actions: 2 }]
  },
  pre_hybridpv_capex: {
    message: false,
    causes: [{ actions: 1 }]
  },
  pre_no_hpa_power: {
    message: false,
    causes: [{ actions: 2 }]
  },
  pre_no_sst_hpg_power: {
    message: false,
    causes: [{ actions: 1 }]
  },
  pre_no_hpg_power: {
    message: false,
    causes: [{ actions: 1 }]
  },
  pre_no_btes_length: {
    message: false,
    causes: [{ actions: 1 }]
  },
  pre_btes_too_small: {
    message: false,
    causes: [{ actions: 1 }, { actions: 2 }]
  },
  pre_btes_dist_depth_mismatch: {
    message: false,
    causes: [{ actions: 2 }]
  }
});

const ERRORS_ABORTED_COMPUTE = ['runtime error', 'canceled'];

const EXCHANGER_KEY_WORD = Object.freeze({
  EVAP: 'evaporator',
  COND: 'condensor'
});

const EXCHANGER_POSITION = Object.freeze({
  ABSENT: 'absent',
  COLD_SIDE: 'coldSide',
  HEAT_SIDE: 'heatSide'
});

const EXCHANGER_POWER_TYPE = Object.freeze({
  PEVAP: 'pevap',
  PCOND: 'pcond'
});

const FILE_TYPE = Object.freeze({
  WEATHER: 'WEATHER_FILE',
  STATION_NEED: 'STATION_NEED_FILE',
  IMAGE: 'IMAGE_FILE',
  HP: 'HP_FILE'
});

const FONDS_CHALEUR_YEARS = 20;

const GEOLOGY_A_MIN = 1.3 / 2.297e6;

const GEOLOGY_A_MAX = 6 / 2.297e6;

const GEOSTORAGE_EXCHANGER = Object.freeze({
  EX400: 'Ex400',
  EX410: 'Ex410',
  PR401: 'Pr401',
  PR411: 'Pr411',
  PR402: 'Pr402',
  PR412: 'Pr412',
  PR403: 'Pr403',
  PR413: 'Pr413',
  PR404: 'Pr404',
  PR414: 'Pr414'
});

const GEOSTORAGE_HE = Object.freeze({
  S400: 'S400',
  ET400: 'Et400',
  PU400: 'Pu400',
  PU410: 'Pu410',
  VM400: 'Vm400',
  VM410: 'Vm410'
});

const HEAT_TRANSFER_FLUID = Object.freeze({
  GLYCOL: 'glycol',
  WATER: 'water'
});

const HOURS_PER_YEAR = 8760;

const HP_HE = Object.freeze({
  COLD_WATER_TANK: 'coldWaterTank',
  PUE: 'PuE',
  PUC: 'PuC',
  VDE: 'Vde',
  VDC: 'Vdc',
  EC0: 'Ec0'
});

const HP_EXCHANGER = Object.freeze({
  EXHP0: 'ExHp0',
  VE: 'Ve',
  VC: 'Vc',
  HP0_PR01: 'Hp0_Pr01',
  HP0_PR02: 'Hp0_Pr02'
});

const HP_STATUS = Object.freeze({
  MAIN: 'main',
  BACKUP: 'backup'
});

const HP_TYPE = Object.freeze({
  GEO: 1,
  AERO: 2
});

const INIT_OBJECTIVE = Object.freeze({
  CAPEX_OPEX: 'CAPEX+OPEX',
  TRI: 'TRI'
});

const ITES_EQUIPMENT = Object.freeze({
  FAFCO: {
    labelId: 'itesModel.fafco', // todo côté front
    value: 'Fafco Icebat'
  }
});

const LANGUAGE = Object.freeze({
  FR: 'fr',
  EN: 'en'
});

const MAX_COST = 999999999999999;

const MAX_DESCRIPTIONS = 5;

const MAX_HEATPUMPS = 8;

const MAX_RESULTS_FOR_COMPARE = 5;

const METHOD_EVOL_ELEC_PRICE = Object.freeze({
  MANUAL: 'manual',
  STUDY: 'study'
});

const METHOD_EVOL_GAS_PRICE = Object.freeze({
  MANUAL: 'manual',
  STUDY: 'study'
});

const MILLISECONDS_PER_HOUR = 3600000;

const MODE = Object.freeze({
  SNOW_REMOVAL: 'snowRemoval',
  LOAD_SHEDDING: 'loadShedding'
});

const MODULE_PRIMARY_HE = Object.freeze({
  V100: 'V100',
  V200: 'V200',
  V300: 'V300',
  V800: 'V800',
  S100: 'S100',
  S200: 'S200',
  S300: '300',
  S800: 'S800',
  PR100A: 'Pr100a',
  PR200A: 'Pr200a',
  PR300A: 'Pr300a',
  PR100S: 'Pr100s',
  PR200S: 'Pr200s',
  PR300S: 'Pr300s',
  ET100: 'Et100',
  ET200: 'Et200',
  ET300: 'Et300',
  ET800: 'Et800',
  PR111: 'Pr111',
  PR211: 'Pr211',
  PR311: 'Pr311',
  PR811: 'Pr811',
  PR112: 'Pr112',
  PR212: 'Pr212',
  PR312: 'Pr312',
  PR812: 'Pr812',
  PR113: 'Pr113',
  PR213: 'Pr213',
  PR313: 'Pr313',
  PR813: 'Pr813',
  PR114: 'Pr114',
  PR214: 'Pr214',
  PR314: 'Pr314',
  PR814: 'Pr814'
});

const MODULE_SECONDARY_HE = Object.freeze({
  PU100: 'Pu100',
  PU200: 'Pu200',
  PU300: 'Pu300',
  PU120: 'Pu120',
  PU220: 'Pu220',
  PR121: 'Pr121',
  PR221: 'Pr221',
  PR321: 'Pr321',
  PR120: 'Pr120',
  PR220: 'Pr220',
  VM120: 'Vm120'
});

const MODULE_TYPE = Object.freeze({
  PRIMARY: 'primaryModule',
  SECONDARY: 'secondaryModule'
});

const MONTHS = Object.keys(DAYS_PER_MONTH);

const NEED = Object.freeze({
  HEAT: 'heat',
  COLD: 'cold',
  DHW: 'dhw'
});

const OPERATING_POINTS_SOURCE = Object.freeze({
  BTES_CAPTURE: 'btesCapture',
  BTES_INJECTION: 'btesInjection',
  COLD_PRODUCTION: 'coldProduction',
  HEAT_PRODUCTION: 'heatProduction',
  DHW_PRODUCTION: 'dhwProduction',
  HP_EXCHANGER: 'hpExchanger'
});

const OPEX_TYPES = Object.freeze([
  'OpexElecSupply',
  'OpexPVSaved',
  'OpexPVSoldToProject',
  'OpexPVSoldToGrid',
  'OpexElecTransportAndTaxes',
  'OpexGasSupply',
  'OpexGasCarbonTax',
  'OpexOperationAndMaintenance',
  'OpexGaPerf',
  'OpexOther',
  'OpexPerYear'
]);

const PER_SUB_OTHER_KEYS = Object.freeze([
  'MaxLossPressureCond',
  'MaxFlowrateCond',
  'MaxLossPressureEvap',
  'MaxFlowrateEvap',
  'MaxLossPressureInj',
  'MaxFlowrateInj',
  'MaxLossPressureLoop',
  'MaxFlowrateLoop',
  'MaxLossPressureCondTfp',
  'MaxFlowrateCondTfp',
  'MaxLossPressureEvapTfp',
  'MaxFlowrateEvapTfp',
  'MaxLossPressureCondHpg',
  'MaxFlowrateCondHpg',
  'MaxLossPressureEvapHpg',
  'MaxFlowrateEvapHpg'
]);

const PIPE_MATERIAL = Object.freeze({
  STEEL: 'steel',
  STAINLESS_STEEL: 'stainlessSteel',
  HDPE: 'hdpe'
});

const PIPE_NOMINAL_DIAMETER = {
  [PIPE_MATERIAL.STEEL]: [
    15, 20, 25, 32, 40, 50, 65, 80, 100, 125, 150, 200, 250, 300
  ],
  [PIPE_MATERIAL.STAINLESS_STEEL]: [
    15, 20, 25, 32, 40, 50, 65, 80, 100, 125, 150, 200, 250, 300
  ],
  [PIPE_MATERIAL.HDPE]: [
    15, 20, 25, 32, 40, 50, 63, 75, 90, 110, 140, 160, 200, 280, 315
  ]
};

const PROBES_PER_CONNECTION = Object.freeze([1, 2, 3]);

const PV_EQUIPMENT = Object.freeze({
  VERTEX: {
    labelId: 'pvModel.vertex', // todo côté front
    value: 'Vertex S - Trina Solar 400',
    linkedSt: 'CUSTOM',
    data: {
      InitPVEfficiency: 0.208,
      InitPVModuleType: 'silicium_monocrystal',
      InitPVTemperatureCoefGammaModule: -0.0034,
      InitPVHybridEnabled: false
    }
  },
  DUALSUN: {
    // Plus utilisé : il est affiché en front car présent dans des projets, mais n'est plus sélectionnable.
    labelId: 'pvModel.dualsun',
    value: 'DUALSUN SPRING',
    linkedSt: 'DUALSUN SPRING',
    data: {
      InitPVEfficiency: 0.2,
      InitPVModuleType: 'silicium_monocrystal',
      InitPVTemperatureCoefGammaModule: -0.0034,
      InitPVHybridEnabled: true
    },
    disabled: true
  },
  DUALSUN_SPRING4: {
    labelId: 'pvModel.dualsun.spring4', // todo côté front
    value: 'DUALSUN SPRING4',
    linkedSt: 'DUALSUN SPRING4 not insulated',
    data: {
      InitPVEfficiency: 0.218,
      InitPVModuleType: 'silicium_monocrystal',
      InitPVTemperatureCoefGammaModule: -0.0031,
      InitPVHybridEnabled: true
    }
  },
  CUSTOM: {
    labelId: 'pvModel.custom', // todo côté front
    value: 'CUSTOM',
    linkedSt: 'CUSTOM',
    data: {
      InitPVEfficiency: 0.15,
      InitPVModuleType: 'silicium_monocrystal',
      InitPVTemperatureCoefGammaModule: -0.0034,
      InitPVHybridEnabled: false
    }
  }
});

const PV_MOUNTING_TYPE_EQUIPMENT = Object.freeze({
  CLOSE_MOUNT: {
    labelId: 'mountingType.closeMountGlassGlass', // todo côté front
    value: 'close_mount_glass_glass'
  },
  OPEN_GLASS_GLASS: {
    labelId: 'mountingType.openRackGlassGlass', // todo côté front
    value: 'open_rack_glass_glass'
  },
  OPEN_GLASS_POLYMER: {
    labelId: 'mountingType.openRackGlassPolymer', // todo côté front
    value: 'open_rack_glass_polymer'
  },
  INSULATED_GLASS_POLYMER: {
    labelId: 'mountingType.insulatedBackGlassPolymer', // todo côté front
    value: 'insulated_back_glass_polymer'
  }
});

const REDIS_TIMEOUT = process.env.NODE_ENV === 'production' ? 30000 : 10000;

const REDIS_TIMEOUT_KEY = 'timeout';

const REFERENCE_SOLUTION = Object.freeze({
  GAS_CHILLER: 'Gas',
  HPA: 'Hpa'
});

const REFRIGERANT = Object.freeze({
  R410A: 'R410A',
  R134A: 'R134A',
  R32: 'R32',
  R454B: 'R454B',
  R513A: 'R513A'
});

const REGEX_EMAIL = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';

const REGEX_UUID =
  '[\\da-f]{8}-[\\da-f]{4}-[\\da-f]{4}-[\\da-f]{4}-[\\da-f]{12}';

const SEGMENT_POINT = Object.freeze({
  HP: 'hp',
  COLLECTOR: 'collector',
  PROBE: 'probe',
  PROBE_FOOT: 'probeFoot',
  PROBE_HEAD: 'probeHead'
});

const SOCKET_CHANNEL_VERSION = 'compute-version';

const SOCKET_MSG_TYPE = Object.freeze({
  LOAD_RESULT: 'load-result',
  LOAD: 'load',
  COMPUTE: 'compute',
  COMPUTE_CLIENT_IDS: 'compute-clientIDs',
  COMPUTE_IDS: 'compute-ids',
  COMPUTE_RESULT: 'compute-result',
  COMPUTE_PROGRESS: 'compute-progress',
  COMPUTE_DETAIL: 'compute-detail',
  CANCELED: 'canceled',
  CANCEL: 'cancel',
  COMPUTE_FINISHED: 'compute-finished',
  VERSION: 'version',
  ERROR: 'error'
});

const SOCKET_ROOM_VERSION = 'version-room';

const SOURCE = Object.freeze({
  TFP: 'tfp',
  HPA: 'hpa',
  HPG: 'hpg',
  GAS: 'gas',
  ST: 'st',
  NDW: 'ndw',
  GEOCOOLING: 'geocooling',
  SUM: 'sum'
});

const SPECIFIC_HEAT_CAPACITY = Object.freeze({
  [HEAT_TRANSFER_FLUID.GLYCOL]: 4.186,
  [HEAT_TRANSFER_FLUID.WATER]: 3.7
});

const SRC_EXCHANGE = Object.freeze({
  CAPTURE: 'capture',
  INJECTION: 'injection'
});

const SRC_TYPE = Object.freeze({
  BTES_GLYCOL_WATER: 'btesGlycolWater',
  BTES_CLEAN_WATER: 'btesCleanWater',
  GROUNDWATER: 'groundWater',
  GLYCOOL_LOOP: 'glycolLoop',
  CLEAN_WATER_LOOP: 'cleanWaterLoop'
});

const ST_EQUIPMENT = Object.freeze({
  FAFCO: {
    labelId: 'stModel.fafco', // todo côté front
    value: 'FAFCO 1N',
    linkedPv: 'CUSTOM',
    data: {
      InitSolarThermalB1: 12.58,
      InitSolarThermalB2: 3.59,
      InitSolarThermalBu: 0.044,
      InitSolarThermalEpsa: 0.85,
      InitSolarThermalFlowRate: 0.02,
      InitSolarThermalHeta0: 0.887,
      InitSolarThermalS: 3,
      InitSolarThermalHeadlossMceMax: 0.09
    }
  },
  HELOIPAC: {
    labelId: 'stModel.heliopac', // todo côté front
    value: 'Heliopac Solerpool',
    linkedPv: 'CUSTOM',
    data: {
      InitSolarThermalB1: 7.61,
      InitSolarThermalB2: 16.23,
      InitSolarThermalBu: 0.052,
      InitSolarThermalEpsa: 0.85,
      InitSolarThermalFlowRate: 0.04,
      InitSolarThermalHeta0: 1.11,
      InitSolarThermalS: 3,
      InitSolarThermalHeadlossMceMax: 0.09
    }
  },
  EUROVIA: {
    labelId: 'stModel.eurovia', // todo côté front
    value: 'EUROVIA POWER ROAD',
    linkedPv: 'CUSTOM',
    data: {
      InitSolarThermalB1: 6.553,
      InitSolarThermalB2: 0.0676,
      InitSolarThermalBu: 0.199,
      InitSolarThermalEpsa: 0.85,
      InitSolarThermalFlowRate: 0.04,
      InitSolarThermalHeta0: 0.2,
      InitSolarThermalS: 19.2,
      InitSolarThermalHeadlossMceMax: 0.09,
      InitSolarTemperatureMin: 1,
      InitSolarDeltaTemp: 4
    }
  },
  DUALSUN: {
    // Plus utilisé : il est affiché en front car présent dans des projets, mais n'est plus sélectionnable.
    labelId: 'stModel.dualsun', // todo côté front
    value: 'DUALSUN SPRING',
    linkedPv: 'DUALSUN SPRING',
    data: {
      InitSolarThermalB1: 11.02,
      InitSolarThermalB2: 4.802,
      InitSolarThermalBu: 0.0192,
      InitSolarThermalEpsa: 0.62,
      InitSolarThermalFlowRate: 0.02,
      InitSolarThermalHeta0: 0.53,
      InitSolarThermalS: 1.65,
      InitSolarThermalHeadlossMceMax: 0.09,
      InitSolarTemperatureMin: -3,
      InitSolarDeltaTemp: 4
    },
    disabled: true
  },
  DUALSUN_SPRING4_NON_INSULATED: {
    labelId: 'stModel.dualsun.spring4.nonInsulated', // todo côté front
    value: 'DUALSUN SPRING4 not insulated',
    linkedPv: 'DUALSUN SPRING4',
    data: {
      InitSolarThermalB1: 13,
      InitSolarThermalB2: 5.6,
      InitSolarThermalBu: 0.0394,
      InitSolarThermalEpsa: 0.62,
      InitSolarThermalFlowRate: 0.014,
      InitSolarThermalHeta0: 0.508,
      InitSolarThermalS: 1.95,
      InitSolarThermalHeadlossMceMax: 0.026,
      InitSolarTemperatureMin: -3,
      InitSolarDeltaTemp: 4
    }
  },
  DUALSUN_SPRING4_WITH_FINS: {
    labelId: 'stModel.dualsun.spring4.withFins', // todo côté front
    value: 'DUALSUN SPRING4 with fins',
    linkedPv: 'DUALSUN SPRING4',
    data: {
      InitSolarThermalB1: 15.5,
      InitSolarThermalB2: 7.3,
      InitSolarThermalBu: 0.0883,
      InitSolarThermalEpsa: 0.62,
      InitSolarThermalFlowRate: 0.014,
      InitSolarThermalHeta0: 0.521,
      InitSolarThermalS: 1.95,
      InitSolarThermalHeadlossMceMax: 0.026,
      InitSolarTemperatureMin: -3,
      InitSolarDeltaTemp: 4
    }
  },
  CUSTOM: {
    labelId: 'stModel.custom', // todo côté front
    value: 'CUSTOM',
    linkedPv: 'CUSTOM',
    data: {
      InitSolarThermalB1: 12.58,
      InitSolarThermalB2: 3.49,
      InitSolarThermalBu: 0.044,
      InitSolarThermalEpsa: 0.85,
      InitSolarThermalFlowRate: 0.02,
      InitSolarThermalHeta0: 0.887,
      InitSolarThermalHeadlossMceMax: 0.09,
      InitSolarTemperatureMin: -3,
      InitSolarDeltaTemp: 4,
      InitSolarThermalS: 0
    }
  }
});

const STATUS_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  SERVICE_UNAVAILABLE: 503
};

const STD_NEED_HEADERS = ['HEAT_NEED', 'COLD_NEED', 'HOTWATER_NEED'];

const STORAGE_KEYS = Object.freeze({
  ACCESS: 'access',
  COMPANY: 'company',
  LANGUAGE: 'language',
  REFRESH: 'refresh'
});

const TAG = Object.freeze({
  FEASIBILITY: {
    key: 'feasibility',
    value: 1 // 2 puissance 0
  },
  PROJECT: {
    key: 'project',
    value: 2 // 2 puissance 1
  },
  CONTRACT_COMMITMENT: {
    key: 'contractCommitment',
    value: 4 // 2 puissance 2
  }
});

const UNIT = Object.freeze({
  CELSIUS_DEGREE: 'celsiusDegree',
  CUBIC_METER: 'cubicMeter',
  CUBIC_METER_PER_HOUR: 'cubicMeterPerHour',
  DEGREES: 'degrees',
  DN_OD: 'dnod',
  EURO: 'euro',
  EURO_PER_CUBIC_METER: 'euroPerCubicMeter',
  EURO_PER_CUBIC_METER_PER_HOUR: 'euroPerCubicMeterPerHour',
  EURO_PER_KILOVOLT_AMPERE: 'euroPerKilovoltAmpere',
  EURO_PER_KILOWATT: 'euroPerKilowatt',
  EURO_PER_KILOWATT_HOUR: 'euroPerKilowattHour',
  EURO_PER_KILOWATT_THERMAL: 'euroPerKilowattThermal',
  EURO_PER_KILOWATT_HOUR_ELECTRIC: 'euroPerKilowattHourElectric',
  EURO_PER_KILOWATT_HOUR_THERMAL: 'euroPerKilowattHourThermal',
  EURO_PER_METER: 'euroPerMeter',
  EURO_PER_SQUARE_METER: 'euroPerSquareMeter',
  EURO_PER_YEAR: 'euroPerYear',
  GRAM_PER_SQUARE_METER: 'gramPerSquareMeter',
  HOUR: 'hour',
  JOULE_PER_CUBIC_METER_PER_KELVIN: 'joulePerCubicMeterPerKelvin',
  JOULE_PER_KELVIN_PER_CUBIC_METER: 'joulePerKelvinPerCubicMeter',
  KELVIN: 'kelvin',
  KELVIN_METER_PER_WATT: 'kelvinMeterPerWatt',
  KILOGRAM: 'kilogram',
  KILOGRAM_CO2EQ: 'kilogramCO2Eq',
  KILOGRAM_CO2EQ_PER_YEAR: 'kilogramCO2EqPerYear',
  KILOGRAM_PER_SECOND_PER_SQUARE_METER: 'kilogramPerSecondPerSquareMeter',
  KILOVOLT_AMPERE: 'kilovoltampere',
  KILOWATT: 'kilowatt',
  KILOWATT_HOUR: 'kilowattHour',
  KILOWATT_HOUR_ELECTRIC: 'kilowattHourElectric',
  KILOWATT_HOUR_PER_METER: 'kilowattHourPerMeter',
  KILOWATT_HOUR_PER_SQUARE_METER: 'kilowattHourPerSquareMeter',
  KILOWATT_HOUR_PER_YEAR: 'kilowattHourPerYear',
  KILOWATT_HOUR_THERMAL: 'kilowattHourThermal',
  KILOWATT_PEAK: 'kilowattPeak',
  KILOWATT_PER_SQUARE_METER: 'kilowattPerSquareMeter',
  MEGAWATT_HOUR: 'megawattHour',
  METER: 'meter',
  METER_PER_SECOND: 'meterPerSecond',
  MILLIMETER: 'millimeter',
  MINUTES: 'minutes',
  PERCENT: 'percent',
  PERCENT_PER_KELVIN_DEGREES: 'percentPerKelvinDegrees',
  PERCENT_PER_YEAR: 'percentPerYear',
  PRIMARY_ENERGY_KILOWATT_HOUR: 'primaryEnergyKilowattHour',
  SECOND_PER_METER: 'secondPerMeter',
  SQUARE_METER: 'squareMeter',
  WATER_COLUMN_METER: 'waterColumnMeter',
  WATT: 'watt',
  WATT_HOUR_PER_SQUARE_METER: 'wattHourPerSquareMeter',
  WATT_PER_METER: 'wattPerMeter',
  WATT_PER_METER_KELVIN: 'wattPerMeterKelvin',
  WATT_PER_METER_PER_KELVIN: 'wattPerMeterPerKelvin',
  WATT_PER_SQUARE_METER_KELVIN: 'wattPerSquareMeterKelvin',
  WATT_SECOND_PER_CUBIC_METER_KELVIN: 'wattSecondPerCubicMeterKelvin',
  YEAR: 'year'
});

const USAGE = Object.freeze({
  TERTIARY: 'TERTIARY',
  HOUSING: 'HOUSING',
  HOTEL: 'HOTEL',
  WAREHOUSE: 'WAREHOUSE'
});

const USE = Object.freeze({
  HEAT: 'heat',
  COLD: 'cold',
  HOTWATER: 'hotwater',
  INJECTION: 'injection',
  SUM: 'sum',
  GLOBAL: 'global'
});

module.exports = {
  AHS_TYPE,
  APP,
  BTES_COST,
  BTES_TEMP_KEYS,
  CALCULATION_STATUS,
  COMPASS_STUDY,
  COMPUTE_STATUS,
  COMPUTE_TYPE,
  COUNTRY,
  DAYS_PER_MONTH,
  DELETED_NAMING,
  DEPARTMENT,
  DHW_TYPE,
  DST_TEMP_DELTA,
  ERROR_ENGINE,
  ERRORS_ABORTED_COMPUTE,
  EXCHANGER_KEY_WORD,
  EXCHANGER_POSITION,
  EXCHANGER_POWER_TYPE,
  FILE_TYPE,
  FONDS_CHALEUR_YEARS,
  GEOLOGY_A_MIN,
  GEOLOGY_A_MAX,
  GEOSTORAGE_EXCHANGER,
  GEOSTORAGE_HE,
  HEAT_TRANSFER_FLUID,
  HP_HE,
  HP_EXCHANGER,
  HP_STATUS,
  HP_TYPE,
  HOURS_PER_YEAR,
  INIT_OBJECTIVE,
  ITES_EQUIPMENT,
  LANGUAGE,
  MAX_COST,
  MAX_DESCRIPTIONS,
  MAX_HEATPUMPS,
  MAX_RESULTS_FOR_COMPARE,
  METHOD_EVOL_ELEC_PRICE,
  METHOD_EVOL_GAS_PRICE,
  MILLISECONDS_PER_HOUR,
  MODE,
  MODULE_PRIMARY_HE,
  MODULE_SECONDARY_HE,
  MODULE_TYPE,
  MONTHS,
  NEED,
  OPERATING_POINTS_SOURCE,
  OPEX_TYPES,
  PER_SUB_OTHER_KEYS,
  PIPE_MATERIAL,
  PIPE_NOMINAL_DIAMETER,
  PROBES_PER_CONNECTION,
  PV_EQUIPMENT,
  PV_MOUNTING_TYPE_EQUIPMENT,
  REDIS_TIMEOUT,
  REDIS_TIMEOUT_KEY,
  REFERENCE_SOLUTION,
  REFRIGERANT,
  REGEX_EMAIL,
  REGEX_UUID,
  SEGMENT_POINT,
  SOCKET_CHANNEL_VERSION,
  SOCKET_MSG_TYPE,
  SOCKET_ROOM_VERSION,
  SOURCE,
  SPECIFIC_HEAT_CAPACITY,
  SRC_EXCHANGE,
  SRC_TYPE,
  ST_EQUIPMENT,
  STATUS_CODE,
  STD_NEED_HEADERS,
  STORAGE_KEYS,
  TAG,
  UNIT,
  USAGE,
  USE
};
