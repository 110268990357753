import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useRef } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import { SST } from '../../../../../../../../server/models/design/description.model';
import { fetchStdFile } from '../../../../../../api/stdFile.api';
import ParamInput from '../../../../../../components/ParamForm/ParamInput';
import ParamSelect from '../../../../../../components/ParamForm/ParamSelect';
import { INPUT_TYPE } from '../../../../../../constants';
import PopupContext from '../../../../../../contexts/PopupContext';
import { downloadFile } from '../../../../../../utils/file.utils';
import './SubstationTab.css';
import StdModal from './components/StdModal';

const SubstationTab = ({
  sstForm,
  sstIndex,
  formIndex,
  stdForm,
  onInputChange,
  onFileChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openInfoModal, openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [refs]
  const inputOpenFileRef = useRef();
  //#endregion

  //#region [methods]
  const getStdData = (key) => {
    return Math.round(stdForm.summaryData[key]).toLocaleString();
  };

  const handleDlClick = async () => {
    try {
      const file = stdForm.file ?? (await fetchStdFile(stdForm.fileId));
      downloadFile(file.name, new Blob([file], { type: 'text/csv' }));
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const isChild = formIndex > 0;
  return (
    <div className='substation-section'>
      <ParamInput
        value={sstForm.InitStationName ?? ''}
        disabled={isChild}
        onChange={(value) => onInputChange('InitStationName', value)}
        param={SST.InitStationName}
        label={i18n._('description.InitStationName')}
        type={INPUT_TYPE.TEXT}
        notDefaultValueHilighted={false}
      />
      <Row>
        <Col>
          <ParamInput
            value={sstForm.InitBuildingSurface ?? ''}
            disabled={isChild}
            onChange={(value) => onInputChange('InitBuildingSurface', value)}
            param={SST.InitBuildingSurface}
            unit
            label={i18n._('description.InitBuildingSurface')}
            type={INPUT_TYPE.NUMBER}
            notDefaultValueHilighted={false}
          />
        </Col>
        <Col>
          <ParamSelect
            value={sstForm.InitStationUse}
            disabled={isChild}
            onChange={(value) => onInputChange('InitStationUse', value)}
            param={SST.InitStationUse}
            label={i18n._('description.InitStationUse')}
            notDefaultValueHilighted={false}
          />
        </Col>
      </Row>
      <div>
        <p className='param-input-label description-file-label'>
          {i18n._('description.std')}
          <FontAwesomeIcon
            icon='circle-question'
            className='modal-info-icon'
            onClick={() =>
              openInfoModal(i18n._('description.stdDesc.title'), <StdModal />)
            }
          />
        </p>
        <div className='description-file-btns'>
          <input
            ref={inputOpenFileRef}
            type='file'
            accept='.csv'
            style={{ display: 'none' }}
            onChange={onFileChange}
          />
          <Button
            variant='primary'
            onClick={() => inputOpenFileRef.current.click()}
          >
            <FontAwesomeIcon icon='upload' />
            {i18n._('browse')}
          </Button>
          {stdForm.fileId && (
            <Button variant='outline-secondary' onClick={handleDlClick}>
              <FontAwesomeIcon icon='download' />
              {i18n._('download')}
            </Button>
          )}
        </div>
        <div className='description-file-csv-body'>
          {!stdForm.fileId ? (
            <Alert variant='danger'>{i18n._('description.noStd')}</Alert>
          ) : (
            <>
              <span className='csv-file'>
                <FontAwesomeIcon icon='file-csv' />
                {stdForm.filename}
                {stdForm.summaryData && (
                  <>
                    <FontAwesomeIcon
                      icon='fa-regular fa-circle-question'
                      data-tooltip-id={
                        'tooltip_' + formIndex + '_summary_data_' + sstIndex
                      }
                      data-tooltip-content={i18n._('description.summaryData', {
                        sumHeat: getStdData('sumHeat'),
                        maxHeat: getStdData('maxHeat'),
                        sumCold: getStdData('sumCold'),
                        maxCold: getStdData('maxCold'),
                        sumHotwater: getStdData('sumHotwater'),
                        maxHotwater: getStdData('maxHotwater')
                      })}
                    />
                    <Tooltip
                      id={'tooltip_' + formIndex + '_summary_data_' + sstIndex}
                      place='right'
                      className='info-tooltip'
                      opacity={1}
                    />
                  </>
                )}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
  //#endregion
};

export default SubstationTab;
