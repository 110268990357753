import { useLingui } from '@lingui/react';
import React from 'react';
import pvImg from '../../../../image/equipment-photovoltaic.png';
import Section from '../../../../components/Section/Section';
import TileCheckbox from '../../../../components/Tile/TileCheckbox';

const ElectricProductionSection = ({ form, onInputChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Section
      title={i18n._('description.electricProduction')}
      open
      className='description-production-section'
    >
      <TileCheckbox
        checked={form.InitPhotovoltaicEnabled}
        image={pvImg}
        onChange={(checked) =>
          onInputChange('InitPhotovoltaicEnabled', checked)
        }
        title={i18n._('description.InitPhotovoltaicEnabled')}
      />
    </Section>
  );
  //#endregion
};

export default ElectricProductionSection;
