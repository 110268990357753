import Sockette from 'sockette';
import { IS_DEV } from '../utils/env.utils';

class WebSocketClient {
  constructor() {
    this.existingSockets = {};
  }

  isConnected(room) {
    return this.existingSockets[room]?.readyState === WebSocket.OPEN;
  }

  connect(room, openHandler, closeHandler, messageHandler) {
    if (this.existingSockets[room]) return;
    this.existingSockets[room] = new Sockette(
      (location.protocol === 'http:' ? 'ws://' : 'wss://') +
        `${global.location.host}/api-v1?room=${room}`,
      {
        timeout: 5e3,
        maxAttempts: 10,
        onmessage: async (msg) => {
          await messageHandler(JSON.parse(msg.data));
        },
        onerror: (code) => {
          console.error('ws connect error:', code);
          if (!this.isConnected(room)) this.disconnect(room);
        },
        onopen: () => {
          if (IS_DEV) console.info('websocket connected to room:', room);
          this.existingSockets[room].readyState = WebSocket.OPEN;
          openHandler();
        },
        onclose: () => {
          if (IS_DEV) console.info('websocket disconnected from room:', room);
          if (!this.existingSockets[room]) return;
          this.existingSockets[room].readyState = WebSocket.CLOSED;
          closeHandler();
        }
      }
    );
  }

  disconnect(room) {
    if (!this.existingSockets[room]) return;
    this.existingSockets[room].close();
    delete this.existingSockets[room];
  }

  send(room, data) {
    if (!(room in this.existingSockets)) {
      console.warn('not connected to room', room);
      return;
    }
    this.existingSockets[room].send(JSON.stringify(data));
  }
}

export default new WebSocketClient();
