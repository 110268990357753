import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import { HP_HE } from '../../../../../../../../server/constants';
import { getHydraulicEquipmentParams } from '../../../../../../../../server/models/config/thermalProduction/heatpumps/heatpump.model';
import Section from '../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../contexts/PopupContext';
import { getSortedNeeds } from '../../../../../../utils/config.utils';
import { isNull } from '../../../../../../utils/data.utils';
import NeedBadge from '../components/NeedBadge';

const HydraulicEquipmentSection = ({ onFormChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const checkForEquipmentWarning = (checked, defaultValue) =>
    isNull(defaultValue) ? false : defaultValue !== checked;

  const handleEquipmentChange = (evt, hpId) => {
    try {
      const { checked, value } = evt.target;
      const { list } = config.ConfigsSst[0].Data.heatpumps;
      const index = list.findIndex((hp) => hp.id === hpId);
      list[index].hydraulicEquipment[value] = checked;
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const { list } = config.ConfigsSst[0].Data.heatpumps;
  return (
    <Section title={i18n._('config.hps.hydraulic.equipment')} level={2} open>
      <div className='custom-table-wrapper'>
        <table className='custom-table'>
          <thead>
            <tr>
              <th>{i18n._('config.hps.position')}</th>
              <th>{i18n._('config.hps.model')}</th>
              <th>{i18n._('config.hps.type')}</th>
              <th>{i18n._('config.needs')}</th>
              {Object.values(HP_HE).map((equipment) => (
                <th key={'col_' + equipment}>
                  {i18n._(`hydraulicConfig.table.th.${equipment}`)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {list
              .sort((hp1, hp2) => hp1.position - hp2.position)
              .map((hp, index) => {
                const params = getHydraulicEquipmentParams(hp);
                const sortedNeeds = getSortedNeeds(hp.needs);
                return (
                  <tr key={'row_he_' + index}>
                    <td>{hp.position}</td>
                    <td>{hp.model}</td>
                    <td>{i18n._(`heatpump.type.${hp.type}`)}</td>
                    <td>
                      <div className='equipment-table-badges'>
                        {sortedNeeds.map((need) => (
                          <NeedBadge
                            key={'badge_' + index + need}
                            need={need}
                          />
                        ))}
                      </div>
                    </td>
                    {Object.values(params).map((param) => {
                      const checked = hp.hydraulicEquipment[param.key];
                      const warning = checkForEquipmentWarning(
                        checked,
                        param.default
                      );
                      const checkboxId = 'hp_he_' + index + param.key;
                      return (
                        <td key={'col_he_' + param.key}>
                          <div
                            data-tooltip-content={
                              warning
                                ? checked
                                  ? i18n._('equipment.notChecked.warning')
                                  : i18n._('equipment.checked.warning')
                                : null
                            }
                            data-tooltip-id={'error_' + checkboxId}
                            style={{ width: 'fit-content' }}
                          >
                            <Form.Check
                              type='checkbox'
                              label={
                                param.key === HP_HE.COLD_WATER_TANK
                                  ? ''
                                  : i18n._(
                                      `hydraulicConfig.table.td.${param.key}`,
                                      {
                                        position: hp.position
                                      }
                                    )
                              }
                              value={param.key}
                              onChange={(evt) =>
                                handleEquipmentChange(evt, hp.id)
                              }
                              checked={!!checked}
                              className={warning ? 'warning' : ''}
                              disabled={
                                !isNull(param.enabled) && !param.enabled
                              }
                              id={'hps_he_' + param.key}
                            />
                            {warning &&
                              createPortal(
                                <Tooltip
                                  id={'error_' + checkboxId}
                                  place='bottom'
                                  className='error-tooltip'
                                  arrowColor='#f04460'
                                  opacity={1}
                                />,
                                document.body
                              )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </Section>
  );
  //#endregion
};

export default HydraulicEquipmentSection;
