// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.weather-section {
  border: 1px solid lightgray;
  border-radius: 0.25rem;
  padding: 15px;
}

.weather-section .alert {
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/containers/DescriptionsTabs/DescriptionTab/blocs/WeatherBloc/WeatherBloc.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".weather-section {\n  border: 1px solid lightgray;\n  border-radius: 0.25rem;\n  padding: 15px;\n}\n\n.weather-section .alert {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
