import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './NotFoundPage.css';

const NotFoundPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  //#endregion

  //#region [render]
  return (
    <div className='not-found-page'>
      <FontAwesomeIcon icon='face-sad-cry' />
      <h1>{i18n._('notfound.lost1')}</h1>
      <h2>{i18n._('notfound.lost2')}</h2>
      <Button onClick={() => navigate('/')}>
        {i18n._('notfound.homepage')}
      </Button>
    </div>
  );
  //#endregion
};

export default NotFoundPage;
