// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fitting-modal-header {
  display: block;
}

.fitting-modal-header h1 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 0;
}

.fitting-modal-header p {
  font-size: 16px;
  font-style: italic;
  color: rgb(141, 141, 141);
  margin-bottom: 0;
}

.fitting-modal-body {
  display: grid;
  grid-template-columns: -webkit-max-content 14px 20px 14px;
  grid-template-columns: max-content 14px 20px 14px;
  grid-gap: 7px;
  /* Les lignes s'adapteront à la hauteur du contenu */
  grid-auto-rows: auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.fitting-modal-body svg {
  color: var(--primary-color);
  cursor: pointer;
  font-size: 14px;
}

.fitting-modal-body svg.disabled {
  color: var(--light-grey);
  cursor: auto;
}

.fitting-modal-counter-value {
  justify-self: center;
}

.fitting-edited {
  color: var(--warning-color);
}

.fitting-modal-header h1,
.fitting-modal-header p,
.fitting-modal-body span {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/FittingsModal/FittingsModal.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,yDAAyD;EACzD,iDAAiD;EACjD,aAAa;EACb,oDAAoD;EACpD,oBAAoB;EACpB,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;AAC7B;;AAEA;EACE,2BAA2B;EAC3B,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,YAAY;AACd;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;;;EAGE,yBAAyB;KACtB,sBAAsB;MACrB,qBAAqB;UACjB,iBAAiB;AAC3B","sourcesContent":[".fitting-modal-header {\n  display: block;\n}\n\n.fitting-modal-header h1 {\n  font-size: 20px;\n  font-weight: 600;\n  margin-top: 0;\n}\n\n.fitting-modal-header p {\n  font-size: 16px;\n  font-style: italic;\n  color: rgb(141, 141, 141);\n  margin-bottom: 0;\n}\n\n.fitting-modal-body {\n  display: grid;\n  grid-template-columns: -webkit-max-content 14px 20px 14px;\n  grid-template-columns: max-content 14px 20px 14px;\n  grid-gap: 7px;\n  /* Les lignes s'adapteront à la hauteur du contenu */\n  grid-auto-rows: auto;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n.fitting-modal-body svg {\n  color: var(--primary-color);\n  cursor: pointer;\n  font-size: 14px;\n}\n\n.fitting-modal-body svg.disabled {\n  color: var(--light-grey);\n  cursor: auto;\n}\n\n.fitting-modal-counter-value {\n  justify-self: center;\n}\n\n.fitting-edited {\n  color: var(--warning-color);\n}\n\n.fitting-modal-header h1,\n.fitting-modal-header p,\n.fitting-modal-body span {\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
