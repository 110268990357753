import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Badge, Spinner } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import {
  COMPUTE_STATUS,
  COMPUTE_TYPE,
  UNIT
} from '../../../../../../../../server/constants';
import {
  formatValueWithUnit,
  roundNumber
} from '../../../../../../utils/data.utils';
import { formatDateAndHour } from '../../../../../../utils/date.utils';
import './ComputeCard.css';

const ComputeCard = ({ compute, chartColor }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { companyId, projectId } = useParams();
  //#endregion

  //#region [render]
  let badge;
  let badgeClassName;
  let formattedPercent;
  let goal;
  switch (compute.Status) {
    case COMPUTE_STATUS.PENDING:
      badgeClassName = 'compute-card-badge-pending';
      badge = i18n._('computeCard.status.pending');
      break;
    case COMPUTE_STATUS.IN_PROGRESS:
      badgeClassName = 'compute-card-badge-inProgress';
      badge = i18n._('computeCard.status.inProgress');
      formattedPercent = formatValueWithUnit(
        i18n,
        roundNumber(compute.Percent, 0),
        UNIT.PERCENT
      );
      break;
    case COMPUTE_STATUS.SUCCESS:
      badgeClassName = 'compute-card-badge-success';
      badge = i18n._('computeCard.status.success');
      break;
    case COMPUTE_STATUS.ERROR:
      badgeClassName = 'compute-card-badge-error';
      badge = i18n._('computeCard.status.error');
      break;
    case COMPUTE_STATUS.CANCELED:
      badgeClassName = 'compute-card-badge-canceled';
      badge = i18n._('computeCard.status.canceled');
      break;
  }
  if (compute.GoalPart?.value) {
    goal = i18n._(`compute.goal.${compute.GoalPart.key}`, {
      value: formatValueWithUnit(
        i18n,
        compute.GoalPart.value * 100,
        UNIT.PERCENT
      )
    });
  }
  return (
    <div
      className={`compute-card ${
        compute.Status === COMPUTE_STATUS.IN_PROGRESS ? 'inprogress' : ''
      }`}
    >
      <div className='compute-card-top'>
        <div className='compute-card-left'>
          <Badge className={badgeClassName}>{badge}</Badge>
          {compute.Type === COMPUTE_TYPE.OPTI &&
            (compute.Status === COMPUTE_STATUS.IN_PROGRESS ||
              compute.Status === COMPUTE_STATUS.SUCCESS) && (
              <FontAwesomeIcon
                icon='chart-line'
                style={{ color: chartColor }}
              />
            )}
          <span className='compute-card-left-span'>
            <FontAwesomeIcon icon='fa-regular fa-calendar' />
            {formatDateAndHour(i18n, compute.created_at)}
          </span>
          {goal && (
            <span className='compute-card-left-span'>
              <FontAwesomeIcon icon='bullseye' />
              {goal}
            </span>
          )}
        </div>
        {formattedPercent && (
          <span className='compute-card-percent'>
            <Spinner variant='dark' />
            {formattedPercent}
          </span>
        )}
        {compute.Status === COMPUTE_STATUS.SUCCESS && (
          <Link
            to={`/company/${companyId}/project/${projectId}/design/results/${compute.ComputeID}`}
          >
            <FontAwesomeIcon icon='eye' />
            {i18n._('computeCard.result')}
          </Link>
        )}
      </div>
      {compute.Type === COMPUTE_TYPE.OPTI &&
        (compute.Status === COMPUTE_STATUS.IN_PROGRESS ||
          compute.Status === COMPUTE_STATUS.SUCCESS) && (
          <div className='compute-card-bottom'>
            {compute.Dataviz && (
              <div className='compute-card-bottom-dataviz'>
                {compute.Status === COMPUTE_STATUS.IN_PROGRESS && (
                  <span>
                    <FontAwesomeIcon icon='stopwatch' />
                    {i18n._('computeCard.dataviz.remaining', {
                      remaining: compute.Dataviz.remaining
                    })}
                  </span>
                )}
                <span>
                  <FontAwesomeIcon icon='check-circle' />
                  {i18n._('computeCard.dataviz.success', {
                    success: compute.Dataviz.success
                  })}
                </span>
                <span>
                  <FontAwesomeIcon icon='exclamation-circle' />
                  {i18n._('computeCard.dataviz.failure', {
                    failure: compute.Dataviz.failure
                  })}
                </span>
              </div>
            )}
            {compute.message && (
              <p className='compute-card-bottom-msg'>{compute.message}</p>
            )}
          </div>
        )}
      {compute.Status === COMPUTE_STATUS.ERROR && (
        <div className='compute-card-bottom'>
          <span>
            <FontAwesomeIcon icon='xmark' />
            <span>{compute.Error}</span>
          </span>
        </div>
      )}
    </div>
  );
  //#endregion
};

export default ComputeCard;
